<div id="wrapper">
  <!-- Top Bar Start -->
  <app-header [user]="user" style="width: 100%" [elementName]="'hcdashboard'"></app-header>{{ user }}
  <!-- <app-header *ngIf="!subscribed" [header-data]="headerData"></app-header>    Top Bar End -->
  <!-- ========== Left Sidebar Start ========== -->

  <!-- <app-sidemenu elementName="hcdashboard"></app-sidemenu> -->

  <!-- Left Sidebar End -->
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="content-page enlarged" style="background: #f5f5f5">
    <div class="content mx-auto">
      <div class="row pt-5 rowwrapper">
        <div class="col-xs-12 col-sm-12 col-md-4">
          <div
            class="
              d-flex
              flex-column
              align-items-start
              h-100
              justify-content-center
            "
          >
            <div>
              <span class="save-more"> Save More</span>
              <span class="with-good-plans">with Good Plans</span>
            </div>
            <img src="assets/images/img.png" width="80%" />
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-4">
          <div class="price-box-wrapper starter">
            <div class="price-header d-flex justify-content-center">
              <div
                class="
                  plan-type
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <img
                  src="assets/img/Email-icon.png"
                  width="30px"
                  style="margin-right: 10px"
                />Starter
              </div>
              <button
                class="btn btn-new btn-pos-abs greybtn"
                (click)="upgrade('starter')"
              >
                Request Pricing
              </button>
            </div>
            <div class="details-points">
              <div class="heading">Access to premium data</div>

              <ul class="list">
                <li>Physician Search</li>
                <li>Verified Email &amp; Phone Number</li>
                <li>Hospital Analytics</li>
                <li>Executive Search</li>
                <li>List Management</li>
                <li>Export List</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-4">
          <div class="price-box-wrapper enterprise">
            <div class="price-header d-flex justify-content-center">
              <div
                class="
                  plan-type
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <img
                  src="assets/img/rocket1.png"
                  width="30px"
                  style="margin-right: 10px"
                />Enterprise
              </div>
              <button
                class="btn btn-new btn-pos-abs"
                (click)="upgrade('enterprise')"
              >
                Request Pricing
              </button>
            </div>
            <div class="details-points">
              <div class="heading">Access to premium data</div>

              <ul class="list">
                <li>Physician Search</li>
                <li>Verified Email &amp; Phone Number</li>
                <li>Hospital Analytics</li>
                <li>Executive Search</li>
                <li>List Management</li>
                <li>Export List</li>
                <li>Drug Information</li>
                <li>API Access</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ============================================================== -->
  <!-- Start right Content End -->
  <!-- ============================================================== -->
</div>
