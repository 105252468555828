import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { interval } from 'rxjs';
import { ParagonIntegrationService } from 'src/app/modules/healthcare/services/paragon-integration.service';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
@Component({
	selector: 'app-list-integration-menu',
	templateUrl: './list-integration-menu.component.html',
	styleUrls: ['./list-integration-menu.component.css']
})
export class ListIntegrationMenuComponent implements OnInit {
	@Input() listDetails: any = [];
	@Output() clearSelectedValue: EventEmitter<any> = new EventEmitter<any>();
	status: any = 'exporting';
	interval: any = undefined;
	loader: boolean = false;
	runningTask: any = [];
	exportLoader: boolean = false;
	activatedIntegrations: any = [];
	selectedIntegration: any = [];
	integrationList: any = [];
	constructor(private paragonService: ParagonIntegrationService, private amplizService: AmplizService) {
		this.interval = interval(25000).subscribe(() => this.updateValues());
		this.paragonService.activatedIntegrations.subscribe((integrations) => {
			this.activatedIntegrations = integrations;
		});
		this.paragonService.activatedUserIntegrations.subscribe((val) => {
			this.integrationList = val;
		});
	}

	ngOnInit(): void {
		this.loader = true;
		this.updateValues();
	}

	get isIntegrationSelected() {
		return Object.keys(this.selectedIntegration).length > 0;
	}

	get exportBtnText() {
		return this.exportLoader ? 'Preparing Data' : 'Export';
	}

	get isTaskRunning() {
		return this.runningTask.length > 0 ? true : false;
	}

	get isListSelected() {
		return this.listDetails.listId !== undefined ? true : false;
	}

	async updateValues() {
		this.paragonService.getExecutingTasks().subscribe(async (res: any) => {
			await this.saveCurrentTask(res.workflowExecutions);
			this.loader = false;
		}),
			(err) => {
				console.log('err', err);
				this.loader = false;
			};
	}

	saveCurrentTask(task: any) {
		// if (this.runningTask.taskCount !== task.taskCount)
		this.runningTask = task;
	}

	exportCRM() {
		if (!this.isIntegrationSelected) return;
		this.exportLoader = true;
		this.amplizService.fetchExportList(this.listDetails.listId).subscribe(async (res) => {
			await this.paragonService.exportList(res, this.selectedIntegration.type);
			setTimeout(async () => {
				await this.updateValues();
			}, 2000);
			setTimeout(async () => {
				await this.clearSelected();
				this.exportLoader = false;
			}, 3000);
		}),
			(err) => {
				console.log('err', err);
				this.exportLoader = false;
			};
	}

	onIntegrationChange(integration: any) {
		this.selectedIntegration = integration.value;
	}

	async clearSelected() {
		await this.clearSelectedValue.emit();
	}

	ngOnDestroy() {
		this.interval && this.interval.unsubscribe(); //Destroying the interval for update
	}
}
