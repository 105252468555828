import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { FilterStorageService } from 'src/app/modules/B2B/services/filter-storage.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { ImagingDataService } from 'src/app/modules/ImagingCenter/services/imaging-data.service';
import { ImagingService } from 'src/app/modules/ImagingCenter/services/imaging.service';
import { SearchPayorExecutive } from '../../models/search-payor-executive.model';
import { DataService } from '../../service/data.service';
import { PayorService } from '../../service/payor.service';
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, finalize } from 'rxjs/operators';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';

@Component({
	selector: 'app-payor-filter-executive',
	templateUrl: './payor-filter-executive.component.html',
	styleUrls: ['./payor-filter-executive.component.css']
})
export class PayorFilterExecutiveComponent implements OnInit {
	@Output() onFilterChange = new EventEmitter<any>();
	@Input() isSubscribed: boolean = false;
	@Input() isPaid: boolean = false;
	filterData: SearchPayorExecutive = new SearchPayorExecutive();
	tempDialogFilters: SearchPayorExecutive = new SearchPayorExecutive();
	dialogRef: any;
	dialogState: boolean = false;
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;
	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showConfirmDialog: boolean = false;
	//company Variables
	companyList: Array<any> = [];

	//title
	titleList: Array<any> = [];

	// industry

	industryList: Array<any> = [];

	// seniority
	seniorityList: Array<any> = [];

	//department

	departmentList: Array<any> = [
		'Business Development',
		'Customer Services/Customer',
		'Engineering',
		'Finance',
		'Human Resources',
		'IT',
		'Marketing',
		'Operations',
		'Others',
		'Purchasing/Procurement',
		'R&D',
		'Sales',
		'Top Level Management',
		'Training'
	];

	//contacts

	//Country Variables
	countryList: Array<any> = [];
	selectedCountry: Array<any> = [];

	//State Variables
	stateList: Array<any> = [];
	selectedStates: Array<any> = [];

	//City Variables

	//Search List variables
	cityList: Array<any> = [];
	selectedCities: Array<any> = [];

	// Revenue Variables
	revenueList: Array<any> = [];
	includedRevenueRange: any = [];
	subscription: Subscription;
	mapingValue = {
		'Payors/health Insurance': 0,
		'Preferred Provider Organization': 1,
		'Point Of Service': 2,
		'Exclusive Provider Organization': 3,
		'Health Maintenance Organization': 4
	};

	//Imaging equipments

	mcoTypeList: string[] = [];

	constructor(
		private b2bService: ImagingService,
		private filterStorageService: FilterStorageService,
		private dataService: DataService,
		private payourService: PayorService,
		private loaderService: LoaderService,
		private messageService: MessageService,
		private amplizService: AmplizService,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService
	) {}

	ngOnInit() {
		this.getPersistData();
		this.getSeniorityList();
		// this.getRevenueList();
		this.makeImagingEqipmentList();
		this.subscription = this.dataService.payourSearchData.subscribe((res) => {
			if (res.fromSearch) {
				this.filterData = res.data;
				this.omitChanges();
			}
		});
	}

	makeImagingEqipmentList() {
		this.mcoTypeList = [
			'Payors/health Insurance',
			'Preferred Provider Organization',
			'Point Of Service',
			'Exclusive Provider Organization',
			'Health Maintenance Organization'
		];
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
		this.dataService.passSearchPayourInput(this.filterData, false);
	}
	getSeniorityList() {
		this.b2bService.getSeniorityList('').subscribe((res) => {
			this.seniorityList = res.seniorityList;
		});
	}

	// getRevenueList() {
	// 	this.filterData.fullName;
	// 	this.b2bService.getRevenueList().subscribe((res) => {
	// 		this.revenueList = res.revenueList;
	// 	});
	// }

	// Company functions

	companyValueChanges(item) {
		if (item && item.length > 1) {
			this.payourService.getCompanyList(item).subscribe((res) => {
				this.companyList = res.companyListMCO;
			});
		}
	}
	// TITLE FUNCTIONS......................

	titleValueChanges(item) {
		if (item && item.length > 3) {
			const body = {
				searchPhrase: item,
				previousSearchedTitle: [...this.filterData.titleInclude, ...this.filterData.titleExclude]
			};
			this.payourService
				.getTitlesList(body)
				.pipe(debounceTime(500))
				.subscribe((res) => {
					this.titleList = res.titleAll;
				});
		} else {
			this.titleList = [];
		}
	}
	// INDUSTRY FUNCTIONS......................

	industryValueChanges(item) {
		if (item && item.length > 1) {
			const body = {
				searchPhrase: item
				// previouslySearchedTerm: [
				//   ...this.filterData.industryInclude,
				//   ...this.filterData.industryExclude,
				// ],
			};
			this.b2bService.getIndustryList(body).subscribe((res) => {
				this.industryList = res.industryListImagingCenter;
			});
		} else {
			this.industryList = [];
		}
	}
	// SKILL FUNCTIONS......................

	hanldeLocationValueChange(locationBody: any) {
		this.omitChanges();
	}

	getPersistData() {
		const newFilterData = this.filterStorageService.get('payour_executive_filter_data');
		Object.keys(newFilterData).map((el) => {
			this.filterData[el] = newFilterData[el];
		});
		this.omitChanges();
		this.getRecordCount();
	}
	get getMCO() {
		return this.filterData.industry ? [this.filterData.industry] : [];
	}

	storeFilterData() {
		this.filterStorageService.set('payour_executive_filter_data', this.filterData);
	}

	omitChanges() {
		this.onFilterChange.emit(this.filterData.toJson());
		this.storeFilterData();
	}

	clearFilter() {
		const isValid = this.filterData.validateImagingSearch();
		if (isValid) {
			this.filterData = new SearchPayorExecutive();
			this.omitChanges();
		}
	}
	ngOnChanges() {
		this.isPaid = !this.isSubscribed;
		// this.isPaid = false;
		//
	}
	async requestPricing() {
		const emailId = await localStorage.getItem('email_id');
		this.loaderService.display(true);
		const body = { package: 'Enterprise', email: emailId };
		this.amplizService.getPrice(body).subscribe(
			(res) => {
				this.loaderService.display(false);

				this.messageService.display(true, 'Thanks for asking, will get back to you in 24 hrs');
			},
			(error) => {
				this.loaderService.display(false);
				this.messageService.displayError(true, error.error.msg ? error.error.msg : 'Server Error !!!');
			}
		);
	}
	clearTempFilter() {
		this.tempDialogFilters = new SearchPayorExecutive();
		this.getRecordCount();
	}
	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		Object.keys(this.filterData).map((el) => {
			this.tempDialogFilters[el] = this.filterData[el];
		});
		this.getRecordCount();
		this.dialogState = true;
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}
	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, cloneDeep(this.filterData));
	}
	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearTempFilter();
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}
	getRecordCount() {
		this.recordCountLoader.next(true);
		this.payourService.searchMCOExecutives(this.tempDialogFilters.toJson()).subscribe(
			(res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				this.recordCountLoader.next(false);
			},
			(err) => {
				this.recordCountLoader.next(false);
			}
		);
	}
	applyMoreFilters() {
		Object.keys(this.tempDialogFilters).map((el) => {
			this.filterData[el] = this.tempDialogFilters[el];
		});
		this.omitChanges();
		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}
	get tempTitleData() {
		return [...this.tempDialogFilters.titleInclude, ...this.tempDialogFilters.titleExclude];
	}
}
