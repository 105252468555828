
<div class="row landingDashboard" >
    
    <div class="col-md-6">
        <app-common-card title="Emails"></app-common-card>
    </div>
    <div class="col-md-6" >
        <app-common-card title="Companies" ></app-common-card>
    </div>
    <div class="col-md-6">
        <app-department-card></app-department-card>
        
    </div>
    <div class="col-md-6">
        <app-seniority-card></app-seniority-card>
    </div>
    <div class="col-md-6">
        <app-industry-card></app-industry-card>
    </div>

    <div class="col-md-6">
        <app-revenue-card></app-revenue-card>
    </div>
    <div class="col-md-6">
        <app-employee-list-card></app-employee-list-card>
    </div>
    <div class="col-md-6">
        <app-apac-location-card></app-apac-location-card>
    </div>

    <app-share-button></app-share-button>

    

    
</div>
