import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { B2bService } from 'src/app/modules/B2B/services/b2b.service';
import { FilterStorageService } from 'src/app/modules/B2B/services/filter-storage.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { SearchImagingModel } from '../../../ImagingCenter/models/SearchImagingModel';
import { ImagingDataService } from '../../../ImagingCenter/services/imaging-data.service';
import { ImagingService } from '../../../ImagingCenter/services/imaging.service';
import { SearchPhysicianGroup } from '../../models/search-physician-group';
import { PayorService } from '../../service/payor.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';

@Component({
	selector: 'app-physician-group-filter',
	templateUrl: './physician-group-filter.component.html',
	styleUrls: ['./physician-group-filter.component.css']
})
export class PhysicianGroupFilterComponent implements OnInit {
	@Output() onFilterChange = new EventEmitter<any>();
	@Input() isSubscribed: boolean = false;
	@Input() isPaid: boolean = false;
	filterData: SearchPhysicianGroup = new SearchPhysicianGroup();
	tempDialogFilters: SearchPhysicianGroup = new SearchPhysicianGroup();
	dialogRef: any;
	dialogState: boolean = false;
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;
	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showConfirmDialog: boolean = false;
	companyList: Array<any> = [];

	countryList: Array<any> = [];
	selectedCountry: Array<any> = [];

	stateList: Array<any> = [];
	selectedStates: Array<any> = [];

	cityList: Array<any> = [];
	selectedCities: Array<any> = [];
	selectable = true;
	removable = true;

	revenueList: Array<any> = [];
	includedRevenueRange: any = [];
	subscription: Subscription;
	mapingValue = {
		'Payors/health Insurance': 0,
		'Preferred Provider Organization': 1,
		'Point Of Service': 2,
		'Exclusive Provider Organization': 3,
		'Health Maintenance Organization': 4
	};
	@ViewChild('codeClassificationInput', { static: false })
	codeClassificationInput: ElementRef<HTMLInputElement>;
	codeClassificationControl = new FormControl();
	@ViewChild('vendorNameInput', { static: false })
	vendorNameInput: ElementRef<HTMLInputElement>;
	vendorNameControl = new FormControl();
	@ViewChild('insuranceInput', { static: false })
	insuranceInput: ElementRef<HTMLInputElement>;
	insuranceControl = new FormControl();
	separatorKeysCodes: number[] = [ENTER, COMMA];
	productNameList: string[] = [];
	vendorList: string[] = [];
	insuranceList: string[] = [];

	//Imaging equipments

	numberOfImagingEquipmentList: string[] = [];
	groupPracticeNumber: string[] = ['1-10', '11-25', '26-50', '50-100', '100-250', '250-500', '500+'];

	constructor(
		private b2bService: ImagingService,
		private filterStorageService: FilterStorageService,
		private dataService: ImagingDataService,
		private payourService: PayorService,
		private loaderService: LoaderService,
		private messageService: MessageService,
		private amplizService: AmplizService,
		private renderer: Renderer2,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService
	) {}

	ngOnInit() {
		this.getPersistData();
		this.makeImagingEqipmentList();
		this.loadController();
	}
	loadController() {
		this.codeClassificationControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getCPTCodes(hv);
			}
		});
		this.vendorNameControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getVendorList(hv);
			}
		});
		this.insuranceControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getInsuranceList(hv);
			}
		});
	}

	makeImagingEqipmentList() {
		this.numberOfImagingEquipmentList = [
			'Single/Multi-Specialty Physician Group',
			'Independent Practice Association',
			'Academic/Faculty Practice'
		];
	}
	get selectedCodes() {
		return [
			...this.filterData.ehremrproductname,
			...this.filterData.ehremrvendorname,
			...this.filterData.insurance
		];
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}
	ngOnChanges() {
		this.isPaid = !this.isSubscribed;
	}

	companyValueChanges(item) {
		if (item && item.length > 1) {
			this.payourService.getPhysicianCompanyList(item).subscribe((res) => {
				this.companyList = res.companyListPhysicianGroup;
			});
		}
	}
	getPersistData() {
		const newFilterData = this.filterStorageService.get('Physician_group_filter');
		Object.keys(newFilterData).map((el) => {
			this.filterData[el] = newFilterData[el];
		});
		this.omitChanges();
		this.getRecordCount();
	}
	async requestPricing() {
		const emailId = await localStorage.getItem('email_id');
		this.loaderService.display(true);
		const body = { package: 'Enterprise', email: emailId };
		this.amplizService.getPrice(body).subscribe(
			(res) => {
				this.loaderService.display(false);

				this.messageService.display(true, 'Thanks for asking, will get back to you in 24 hrs');
			},
			(error) => {
				this.loaderService.display(false);
				this.messageService.displayError(true, error.error.msg ? error.error.msg : 'Server Error !!!');
			}
		);
	}

	storeFilterData() {
		this.filterStorageService.set('Physician_group_filter', this.filterData);
	}

	omitChanges() {
		this.onFilterChange.emit(this.filterData.toJson());
		this.storeFilterData();
	}

	clearFilter() {
		const isValid = this.filterData.validateImagingSearch();
		if (isValid) {
			this.filterData = new SearchPhysicianGroup();
			this.omitChanges();
		}
	}
	hanldeLocationValueChange(locationBody: any) {
		this.omitChanges();
	}
	getCPTCodes(search = '') {
		this.payourService.getProductName(search).subscribe((res) => {
			this.productNameList = res?.ehremrproductnameList || [];
		});
	}
	getVendorList(search = '') {
		this.payourService.getVendorList(search).subscribe((res) => {
			this.vendorList = res?.ehremrvendornameList || [];
		});
	}
	getInsuranceList(search = '') {
		this.payourService.getInsuranceList(search).subscribe((res) => {
			this.insuranceList = res?.insuranceList || [];
		});
	}
	addProductName(event): void {
		this.filterData.ehremrproductname = [
			...new Set([...this.filterData.ehremrproductname, event.option.value])
		];

		this.codeClassificationInput.nativeElement.value = '';
		setTimeout(() => {
			this.getCPTCodes();
		}, 500);

		this.omitChanges();
		this.storeFilterData();
	}
	addVendorName(event): void {
		this.filterData.ehremrvendorname = [...new Set([...this.filterData.ehremrvendorname, event.option.value])];

		this.vendorNameInput.nativeElement.value = '';
		setTimeout(() => {
			this.getVendorList();
		}, 500);

		this.omitChanges();
		this.storeFilterData();
	}
	addInsurance(event): void {
		this.filterData.insurance = [...new Set([...this.filterData.insurance, event.option.value])];

		this.insuranceInput.nativeElement.value = '';
		setTimeout(() => {
			this.getInsuranceList();
		}, 500);

		this.omitChanges();
		this.storeFilterData();
	}
	removeProductName(code: string) {
		this.filterData.ehremrproductname = this.filterData.ehremrproductname.filter((el) => el !== code);
		this.getCPTCodes();
		this.omitChanges();
		this.storeFilterData();
	}
	removeVendorName(code: string) {
		this.filterData.ehremrvendorname = this.filterData.ehremrvendorname.filter((el) => el !== code);
		this.getVendorList();
		this.omitChanges();
		this.storeFilterData();
	}
	removeInsurance(code: string) {
		this.filterData.insurance = this.filterData.insurance.filter((el) => el !== code);
		this.getInsuranceList();
		this.omitChanges();
		this.storeFilterData();
	}
	triggerAutoFocus(eleId: string) {
		const element = this.renderer.selectRootElement(eleId);
		setTimeout(() => element.focus(), 100);
	}
	clearTempFilter() {
		const isValid = this.tempDialogFilters.validateImagingSearch();
		if (isValid) {
			this.tempDialogFilters = new SearchPhysicianGroup();
			this.getRecordCount();
		}
	}
	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		Object.keys(this.filterData).map((el) => {
			this.tempDialogFilters[el] = this.filterData[el];
		});
		this.getRecordCount();
		this.dialogState = true;
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}
	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, cloneDeep(this.filterData));
	}
	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearTempFilter();
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}
	getRecordCount() {
		this.recordCountLoader.next(true);
		this.payourService.searchPhysicianGroup(this.tempDialogFilters.toJson()).subscribe(
			(res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				this.recordCountLoader.next(false);
			},
			(err) => {
				this.recordCountLoader.next(false);
			}
		);
	}
	applyMoreFilters() {
		Object.keys(this.tempDialogFilters).map((el) => {
			this.filterData[el] = this.tempDialogFilters[el];
		});
		this.omitChanges();
		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}
	get insuranceData() {
		return [
			...this.tempDialogFilters.ehremrproductname,
			...this.tempDialogFilters.ehremrvendorname,
			...this.tempDialogFilters.insurance
		];
	}
	get getInsuranceData() {
		return [
			...this.filterData.ehremrproductname,
			...this.filterData.ehremrvendorname,
			...this.filterData.insurance
		];
	}
}
