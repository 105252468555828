<div id="wrapper">
	<app-header [user]="user" style="width: 100%" [elementName]="'physician'"></app-header>{{ user }}
	<!-- <app-header *ngIf="!subscribed" [header-data]="headerData"></app-header> -->
	<!-- <app-sidemenu elementName="physician"></app-sidemenu> -->

	<div class="content-page">
		<!-- Start content -->
		<div class="content row">
			<app-filter-physician
				(onFilterChange)="filterChanged($event)"
				[leadWithEmail]="leadWithEmail"
				[isSubscribed]="subscribed"
				(filterCleared)="checkSelectAll(false)"
				(onSaveSearch)="saveSearch($event)"
			></app-filter-physician>
			<div class="physician-content" style="position: relative">
				<app-skeleton-loader
					*ngIf="showLoader"
					[compactLoader]="true"
					[loopCount]="10"
				></app-skeleton-loader>

				<div class="physician-content-header">
					<div class="toggle-container ml-2">
						<div class="form-check" *ngIf="totalSearchResult.length > 0">
							<input
								*ngIf="showSaveAllCheckbox"
								class="form-check-input checkbox-input"
								type="checkbox"
								id="flexCheckDefault"
								(change)="checkSelectAll($event.target.checked)"
								[checked]="selectedPhysicianInCurrentPage.length == totalSearchResult.length"
								[disabled]="!(isPaid == true || isSpecialityUser == true)"
							/>
							<div
								(click)="checkSelectAll(false)"
								*ngIf="showSaveAllPArtial"
								class="form-check-input partiallySelected"
							>
								<div class="insideContent"></div>
							</div>
						</div>
						<!-- single loader -->
						<div class="toggle-container" *ngIf="showCountLoader">
							<div class="toggle">
								<span class="placeholder-item"></span>
								<span class="placeholder-item w-50"></span>
							</div>
							<div class="toggle">
								<span class="placeholder-item"></span>
								<span class="placeholder-item w-50"></span>
							</div>
							<div class="toggle">
								<span class="placeholder-item"></span>
								<span class="placeholder-item w-50"></span>
							</div>
							<div class="toggle">
								<span class="placeholder-item"></span>
								<span class="placeholder-item w-50"></span>
							</div>
						</div>
						<!-- single loader -->
						<div
							class="toggle"
							[ngClass]="tab === 1 ? 'active' : ''"
							(click)="ngTab(1)"
							*ngIf="!showCountLoader"
						>
							<span class="big-txt">{{ totalCount | numberWithCommas }}</span>
							<span class="sm-txt">Total Result</span>
						</div>
						<!-- {{netNewCount}} -->
						<div
							class="toggle"
							[ngClass]="tab === 2 ? 'active' : ''"
							(click)="ngTab(2)"
							*ngIf="!showCountLoader"
						>
							<span class="big-txt" *ngIf="netNewCount >= 0; else loading">{{
								netNewCount | numberWithCommas
							}}</span>
							<ng-template #loading>
								<div class="loading"></div>
							</ng-template>
							<span class="sm-txt">Net New</span>
						</div>
						<div
							class="toggle"
							[ngClass]="tab === 3 ? 'active' : ''"
							(click)="ngTab(3)"
							*ngIf="!showCountLoader"
						>
							<span class="big-txt" *ngIf="netNewCount >= 0; else loading">{{
								HcoCount | numberWithCommas
							}}</span>
							<ng-template #loading>
								<div class="loading"></div>
							</ng-template>
							<span class="sm-txt">HCO's</span>
						</div>
						<div
							class="toggle"
							[ngClass]="tab === 4 ? 'active' : ''"
							(click)="ngTab(4)"
							*ngIf="!showCountLoader"
						>
							<span class="big-txt" *ngIf="netNewCount >= 0; else loading">Provider</span>
							<ng-template #loading>
								<div class="loading"></div>
							</ng-template>
							<span class="sm-txt">Breakdown</span>
						</div>

						<div class="toggle" *ngIf="selectedPhysician.length > 0">
							<app-save-phycisian-modal
								[selectedItems]="selectedPhysician"
								title="Save +"
								(successfullyAdded)="
									selectedPhysician = []; selectedPhysicianInCurrentPage = []; getPhysicianSearchData()
								"
								[isDisabled]="!(isPaid == true || isSpecialityUser == true)"
								[filterParams]="this.physicianSearchParameter.toJson()"
							></app-save-phycisian-modal>
						</div>

						<!-- <div class="toggle">
							<app-unlock></app-unlock>
						</div> -->
					</div>
					<div class="toggle-container">
						<div class="d-flex align-items-center mb-2 mr-2">
							<button
								class="saved-search-btn"
								(click)="setShowSavedSearch(true)"
								matTooltip="Saved Searches"
								[matTooltipPosition]="'above'"
								matTooltipClass="tool-tip"
							>
								<img src="../../../../../assets/images/save-search.png" height="20" />
							</button>
							<!-- <button (click)="setShowSavedSearch(true)">Saved Searches</button> -->
						</div>
						<div class="toggle width-auto align-items-start mr-0">
							<span class="text-grey font-12 ml-2">Email</span>
							<div class="onoffswitch">
								<input
									type="checkbox"
									name="onoffswitch"
									class="onoffswitch-checkbox"
									id="emailonoffswitch"
									tabindex="0"
									[(ngModel)]="leadWithEmail"
									(change)="handleChange(leadWithEmail, 'leadWithEmail')"
								/>
								<label class="onoffswitch-label" for="emailonoffswitch">
									<span class="onoffswitch-inner"></span>
									<span class="onoffswitch-switch"></span>
								</label>
							</div>
						</div>
						<!-- <div class="d-flex" style="align-items: center">


              <div [ngClass]="{'lockBtn': isPaid===false}">
                <mat-icon class="lockimg">lock</mat-icon>
              <div class="toggle-container" style="align-items: center">
                <span class="text-grey font-13">Provider with independent</span>
                <div class="toggle width-auto">

                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      class="onoffswitch-checkbox"
                      id="phoneonoffswitch"
                      tabindex="1"
                      [(ngModel)]="leadWithProvider"
                      (change)="handleChange(leadWithProvider, 'Independent')"
                    />
                    <label class="onoffswitch-label" for="phoneonoffswitch">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>

              </div>
            </div>
            </div> -->
					</div>
				</div>

				<div class="" *ngIf="unLockDiv == false">
					<!-- <app-skeleton-loader *ngIf="showLoader" [loopCount]="5"></app-skeleton-loader> -->
					<div class="physician-content-main" *ngIf="tab == 1">
						<!-- <app-skeleton-loader *ngIf="showLoader" [loopCount]="5"></app-skeleton-loader> -->
						<div *ngIf="totalSize > 0">
							<div *ngFor="let item of totalSearchResult; let i = index">
								<app-physician-image-card
									[isPaid]="isPaid"
									[checkboxDisabled]="physicianCheckboxDisabled(item)"
									[checkboxValue]="selectedPhysicianInCurrentPage.indexOf(item.physicianId) !== -1"
									(checkBoxChanged)="handleIndividualCheckboxChange($event, item)"
									[isPhysician]="true"
									[physicianData]="item"
									(DataRefreshed)="getPhysicianSearchData()"
									[currentCredit]="currentCredit"
									[dataIndex]="i"
								></app-physician-image-card>
							</div>
						</div>
					</div>

					<div class="physician-content-main" *ngIf="tab == 2">
						<div class="totalSize > 0">
							<div *ngFor="let item of totalSearchResult; let i = index">
								<app-physician-image-card
									[isPaid]="isPaid"
									[checkboxDisabled]="physicianCheckboxDisabled(item)"
									[checkboxValue]="selectedPhysicianInCurrentPage.indexOf(item.physicianId) !== -1"
									(checkBoxChanged)="handleIndividualCheckboxChange($event, item)"
									[isPhysician]="true"
									[physicianData]="item"
									(DataRefreshed)="getPhysicianSearchData()"
									[currentCredit]="currentCredit"
									[dataIndex]="i"
								></app-physician-image-card>
							</div>
						</div>
					</div>

					<div class="physician-content-main" *ngIf="tab == 3 && totalSize > 0">
						<app-physician-hospital-card
							[hospitalList]="uniqueHospitalList"
							(offsetChange)="getUniqueHospitalList($event)"
							[(offset)]="uniqueHospitalOffset"
							[showViewMoreBtn]="uniqueHospitalShowMoreBtn"
						></app-physician-hospital-card>
					</div>
					<div class="physician-content-main" *ngIf="tab == 4 && totalSize > 0">
						<app-hc-stats [physicianFilters]="physicianSearchParameter"></app-hc-stats>
					</div>
				</div>

				<div class="h-100" *ngIf="unLockDiv">
					<app-upgrade></app-upgrade>
				</div>

				<div class="layout h-100" *ngIf="noResult">
					<div
						class="d-flex justify-content-center align-items-center align-self-center h-100 flex-column"
					>
						<div class="text-center">
							<img src="assets/img/Physician.png" width="30%" />
						</div>
						<h5 class="text-center pa-4">No results found, Try to search again</h5>
					</div>
				</div>

				<div class="search_pagination_wrapper" *ngIf="showPagination">
					<div class="row">
						<div class="col-md-6 col-sm-12">
							<div class="paginationWrapper">
								<div class="pull-left paginationInfo">
									{{ offset + 1 | numberWithCommas }}-{{
										(offset + limit > totalSize ? totalSize : limit + offset) | numberWithCommas
									}}
									of {{ totalSize | numberWithCommas }}
								</div>
								<ul
									*ngIf="pager.pages && pager.pages.length"
									class="pagination"
									style="float: left !important"
								>
									<!-- <li [ngClass]="{disabled:pager.currentPage === 1}">
                          <a *ngIf="pager.currentPage === 1"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                          <a (click)="setPage(1)" *ngIf="pager.currentPage !== 1"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                        </li> -->
									<li [ngClass]="{ disabled: pager.currentPage === 1 }">
										<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
											><i class="fa fa-chevron-left" aria-hidden="true"></i
										></a>
										<a
											*ngIf="pager.currentPage !== 1"
											(click)="setPage(pager.currentPage - 1, true)"
											style="border-radius: 5px 0 0 5px !important"
											><i class="fa fa-chevron-left" aria-hidden="true"></i
										></a>
									</li>

									<li class="opt" *ngIf="isPaid == true">
										<mat-form-field class="mx-3" style="max-width: 75px; color: #040404 !important">
											<mat-label>Records Per Page</mat-label>
											<mat-select [(ngModel)]="limit" (selectionChange)="handleRecordsChange()">
												<mat-option [value]="opt" *ngFor="let opt of pageNumberOptions">{{ opt }}</mat-option>
											</mat-select>
										</mat-form-field>
									</li>
									<li
										[ngClass]="{
											disabled:
												pager.currentPage === pager.totalPages ||
												(pager.currentPage === 2 && subscribed === false)
										}"
									>
										<a
											*ngIf="pager.currentPage === pager.totalPages"
											style="border-radius: 0 5px 5px 0 !important"
											><i class="fa fa-chevron-right" aria-hidden="true"></i
										></a>
										<a
											*ngIf="pager.currentPage !== pager.totalPages"
											(click)="setPage(pager.currentPage + 1, false)"
											style="border-radius: 0 5px 5px 0 !important"
											><i class="fa fa-chevron-right" aria-hidden="true"></i
										></a>
									</li>
									<!-- <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                            <a *ngIf="pager.currentPage === pager.totalPages"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                          <a  *ngIf="pager.currentPage !== pager.totalPages" (click)="setPage(pager.totalPages)"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                        </li> -->
								</ul>
							</div>
						</div>
						<div class="col-md-6 col-sm-12 p-0 m-0 pb-2 pt-3"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<ng-container *ngIf="showSavedSearches">
		<app-hc-save-search-list
			[visible]="showSavedSearches"
			(onVisibleChange)="setShowSavedSearch($event)"
		></app-hc-save-search-list>
	</ng-container>
</div>
