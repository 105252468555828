import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StaticDropdownService {
	HCP_FILTERS = {
		hospital_firm_type: ['Health System', 'Hospital', 'Others', 'Physician Group'],
		hospital_classification: [
			'Assisted Living Facility',
			'Childrens Hospital',
			'Critical Access Hospital',
			'Department of Defense Hospital',
			'Federally Qualified Health Center',
			'Health System',
			'Home Health Agency',
			'Hospice',
			'Long Term Acute Care Hospital',
			'Psychiatric Hospital',
			'Rehabilitation Hospital',
			'Renal Dialysis Facility',
			'Retail Clinic',
			'Rural Health Clinic',
			'Short Term Acute Care Hospital',
			'Single/Multi-Specialty Physician Group',
			'Skilled Nursing Facility',
			'Specialty Clinics',
			'Urgent Care Clinic',
			'VA Hospital'
		],
		ehr_product_classification: ['Complete EHR', 'Modular EHR'],
		number_of_claims: ['0-50', '51-100', '101-250', '251-500', 'Above 500'],
		ageList: ['Under 40', '41-50', '51-60', '61-70', 'Over 70']
	};

	constructor() {}
}
