<div class="recent_card" style="width: 23rem">
	<div class="form-check" style="pointer-events: none;">
		<mat-chip class="searchIcon">
			<!-- <i class="fa fas fa-bookmark"></i> -->
			<svg
				viewBox="0 0 24 24"
				width="18"
				height="18"
				stroke="currentColor"
				stroke-width="2"
				fill="none"
				stroke-linecap="round"
				stroke-linejoin="round"
				class="css-i6dzq1"
			>
				<path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
			</svg>
		</mat-chip>
	</div>
	<div class="recent_content">
		<div class="flex-row">
			<form [@inputAnimation] *ngIf="showInput" class="flex-row nameForm">
				<input name="searchName" class="inputField" #name placeholder="Name" [(ngModel)]="searchName" />
				<i (click)="handleChangeNameClicked($event)" class="fa fas fa-check"></i>
				<i (click)="handleInputCancel()" class="fa fas fa-close"></i>
			</form>
			<span *ngIf="!showInput" class="comapany_name">{{ searchData.searchName }}</span>
		</div>
		<div class="active_ago">
			<span style="color: #999; cursor: text">{{ searchData.savedDateTime | minuteDay }}</span>
		</div>
	</div>
	<div class="optionIcon">
		<div class="dropdown">
			<div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
				<svg
					viewBox="0 0 24 24"
					width="22"
					height="22"
					stroke="currentColor"
					stroke-width="2"
					fill="none"
					stroke-linecap="round"
					stroke-linejoin="round"
					class="css-i6dzq1"
				>
					<circle cx="12" cy="12" r="1"></circle>
					<circle cx="12" cy="5" r="1"></circle>
					<circle cx="12" cy="19" r="1"></circle>
				</svg>
			</div>

			<!-- <button class="btn btn-secondary dropdown-toggle" type="button">Dropdown button</button> -->
			<div class="dropdown-menu menuWrapper" aria-labelledby="dropdownMenuButton">
				<div mat-menu-item (click)="handleRenameClick()">
					<i class="fa fas fa-pencil"></i>
					Rename
				</div>
				<button mat-menu-item (click)="makeSearch()">
					<i class="fa fas fa-repeat"></i>Search again
				</button>
			</div>
		</div>

		<!-- </ng-container> -->
		<!-- <mat-menu #menu2="matMenu" position="before" class="menu">
      <div class="menuWrapper">
        <div mat-menu-item (click)="handleRenameClick()">
          <i class="fa fas fa-pencil"></i>
          Rename
        </div>
        <button mat-menu-item (click)="makeSearch()">
          <i class="fa fas fa-repeat"></i>Search again
        </button>
      </div>
    </mat-menu> -->
	</div>
</div>
