import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saved-no-data',
  templateUrl: './saved-no-data.component.html',
  styleUrls: ['./saved-no-data.component.css']
})
export class SavedNoDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
