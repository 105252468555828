<div class="card column filter-component" style="width: 23rem">
	<div class="image-filter">
		<div class="flex-row filter-header">
			<div>
				<h6 class="card-title titleCardStyle">Showing results for</h6>
			</div>
			<div
				class="searchButton primary"
				[ngClass]="filterData.validateImagingSearch() ? 'primaryBtn' : ''"
				(click)="clearFilter()"
			>
				<span> Clear </span>
			</div>
		</div>
		<mat-accordion>
			<mat-expansion-panel #panelCodes hideToggle="true">
				<mat-expansion-panel-header
					[ngClass]="panelCodes.expanded ? 'expanded' : ''"
					[collapsedHeight]="'70px'"
					[expandedHeight]="'70px'"
				>
					<mat-panel-title>
						<div>Center</div>
						<div class="sub-header">Center Name</div>
						<div class="selected">
							<mat-chip-list #addedHospitals aria-label="Hospital name">
								<mat-basic-chip *ngIf="groupSelectedForCenterTab.length > 0" [ngClass]="'selected'">
									{{ groupSelectedForCenterTab[0] }}
								</mat-basic-chip>
								<span *ngIf="groupSelectedForCenterTab?.length > 1" class="additional-selection">
									+ {{ groupSelectedForCenterTab.length - 1 }} more
								</span>
							</mat-chip-list>
						</div>
					</mat-panel-title>
					<mat-panel-description>
						<mat-icon class="matrl-plus" *ngIf="isSubscribed"
							>{{ panelCodes.expanded ? 'remove' : 'add' }}
						</mat-icon>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<span class="form-label">Center Name</span>
				<mat-form-field>
					<mat-chip-list #experienceList aria-label="Experience input">
						<mat-basic-chip
							*ngFor="let name of selectedCenterName"
							[selectable]="selectable"
							[removable]="removable"
							(removed)="removeCenterName(name)"
						>
							{{ name }}
							<mat-icon matChipRemove>close</mat-icon>
						</mat-basic-chip>
						<input
							#centerName
							id="centerName"
							placeholder=""
							aria-label="Experience Input"
							matInput
							[matAutocomplete]="centerNameAutoComplete"
							floatPlaceholder="never"
							[matChipInputFor]="experienceList"
							[formControl]="centerNameController"
						/>
					</mat-chip-list>

					<mat-autocomplete
						#centerNameAutoComplete="matAutocomplete"
						class="app-autocomplete"
						(optionSelected)="onCenterSelect($event)"
					>
						<mat-option *ngFor="let cptCode of companyList" [value]="cptCode">
							{{ cptCode }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>

				<span class="form-label">Number of locations</span>
				<mat-form-field>
					<mat-select [placeholder]="" (selectionChange)="onCompanyCodeSelect()" [(ngModel)]="companyCodeModel">
						<mat-select-trigger>
							<mat-basic-chip
								class="chip-list"
								*ngFor="let item of noOfImagingLocation"
								(removed)="removeCompanyCode(item)"
							>
								{{ item }}
								<mat-icon matChipRemove class="chip-list">close</mat-icon>
							</mat-basic-chip>
						</mat-select-trigger>
						<mat-option [value]="item" *ngFor="let item of noOfCompanyList">{{ item }}</mat-option>
					</mat-select>
				</mat-form-field>
			</mat-expansion-panel>
			<app-expand-item
				title="Imaging Equipments"
				placeholder="Select imaging equipments"
				[selectedData]="ImagingExpandData"
				[isPaid]="!isSubscribed"
			>
				<app-signle-select-drop-down
					title="Number of equipments"
					[list]="numberOfImagingEquipmentList"
					[(selectedData)]="filterData.noOfEqupment"
					(onItemSelect)="omitChanges()"
				></app-signle-select-drop-down>
				<app-auto-complete-drop-drow
					title="Name of the equipments"
					[(list)]="imagingEqupmentsList"
					[(selectedData)]="filterData.imagingEquipments"
					(onItemSelect)="omitChanges()"
					(onChange)="getImagingCenterEquipment($event)"
				></app-auto-complete-drop-drow>
			</app-expand-item>
			<app-expand-item
				title="Procedure Codes"
				placeholder="ICD 10"
				[selectedData]="filterData.icdTenCodes"
				[isPaid]="!isSubscribed"
			>
				<app-auto-complete-drop-drow
					title="ICD 10"
					[(list)]="icptCodeList"
					[(selectedData)]="filterData.icdTenCodes"
					(onItemSelect)="omitChanges()"
					(onChange)="getCpdtList($event)"
					[itemName]="'codeDescription'"
					[itemValue]="'code'"
				></app-auto-complete-drop-drow>
			</app-expand-item>
			<!-- Imaging equipment ends -->

			<div class="location">
				<app-individual-location-filter
					[countryList]="countryList"
					(locationValueChanged)="hanldeLocationValueChange($event)"
					[disabled]="!isSubscribed"
					[(selectedStatesFromFilter)]="filterData.stateList"
					[(selectedCitiesFromFilter)]="filterData.cityList"
				>
				</app-individual-location-filter>
			</div>
			<app-more-fliter-button
				[isPaid]="!isSubscribed"
				(openMoreFilter)="openMoreFilter()"
			></app-more-fliter-button>
		</mat-accordion>
	</div>
</div>

<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="recordCountLoader"
		[totalRecords]="moreFilterRecordCount.value"
		(clear)="moreFilterAccordian.closeAll(); clearTempFilter()"
		(applyFilter)="applyMoreFilters()"
		(closeFilter)="closeDialogBox('mainDialog')"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Center"
					[(selectedData)]="tempDialogFilters.centerName"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Center Name"
						[(list)]="companyList"
						[(selectedData)]="tempDialogFilters.centerName"
						(onItemSelect)="getRecordCount()"
						(onChange)="getCenterName($event)"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Imaging Equipments"
					class="dialog-flex-container"
					[selectedData]="tempImagingExpandData"
				>
					<app-signle-select-drop-down
						title="Number of equipments"
						[list]="numberOfImagingEquipmentList"
						[(selectedData)]="tempDialogFilters.noOfEqupment"
						(onItemSelect)="getRecordCount()"
					></app-signle-select-drop-down>
					<app-auto-complete-drop-drow
						title="Name of the equipments"
						[(list)]="imagingEqupmentsList"
						[(selectedData)]="tempDialogFilters.imagingEquipments"
						(onItemSelect)="getRecordCount()"
						(onChange)="getImagingCenterEquipment($event)"
					></app-auto-complete-drop-drow>
					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.noOfEqupment"
							(onChange)="getRecordCount()"
							[isSingle]="true"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.imagingEquipments"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Procedure Codes"
					[(selectedData)]="tempDialogFilters.icdTenCodes"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="ICD 10"
						[(list)]="icptCodeList"
						[(selectedData)]="tempDialogFilters.icdTenCodes"
						(onItemSelect)="getRecordCount()"
						(onChange)="getCpdtList($event)"
						[itemName]="'codeDescription'"
						[itemValue]="'code'"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-location-filter
					class="dialog-flex-container"
					[countryList]="[]"
					(locationValueChanged)="getRecordCount()"
					[(selectedStatesFromFilter)]="tempDialogFilters.stateList"
					[(selectedCitiesFromFilter)]="tempDialogFilters.cityList"
				>
				</app-location-filter>
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<app-more-fliter-close-dialog
		[(showDialog)]="showConfirmDialog"
		(closeFilter)="closeDialogBox('mainDialog', true)"
	></app-more-fliter-close-dialog>
</ng-template>
