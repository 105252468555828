<div id="wrapper">
  <!-- Top Bar Start -->
  <app-header [user]="user" [elementName]="'apis'" style="width: 100%"></app-header>{{ user }}
  <!-- <app-header *ngIf="!subscribed" [header-data]="headerData"></app-header>    Top Bar End -->
  <!-- ========== Left Sidebar Start ========== -->

  <!-- <app-sidemenu elementName="apis"></app-sidemenu> -->

  <!-- Left Sidebar End -->
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="content-page enlarged" style="background: #f5f5f5">
    <div class="content mx-auto" style="height: 100vh">
      <div class="col-sm-12 d-flex h-100">
        <div class="wrapper-page d-flex align-items-center">
          <div class="api-card-box">
            <div class="api-box-header d-flex bd-highlight px-5 py-4">
              <div class="mr-auto bd-highlight">
                <i class="fa fa-code"></i> API
              </div>
              <div class="bd-highlight d-flex align-self-center">
                <a
                  type="button"
                  class="btn btn-outline-primary rounded-btn"
                  href="https://www.ampliz.com/health-docs"
                  target="_BLANK"
                >
                  See Documentation
                </a>
              </div>
            </div>
            <div class="api-box-body bd-highlight p-5">
              <div class="keyGenWrap">
                <div class="createdOn" *ngIf="createdOn">
                  Created on: {{ createdOn | date: "dd LLLL yyyy" }}
                </div>
                <div
                  class="showCopyMessage"
                  id="showCopyMessage"
                  *ngIf="showCopyMessage === true"
                >
                  <i class="fa fa-check" aria-hidden="true"></i> Copied
                </div>
                <input
                  type="text"
                  readonly
                  class="label-inputbox w-100"
                  [disabled]="subscribed == false"
                  placeholder="Generate API Key"
                  [(ngModel)]="apiKey"
                  #userinput
                />
                <div
                  class="suggestion"
                  [class]="subscribed == false ? 'text-disabled' : ''"
                >
                  Your API key is private. Make sure you only share it with
                  people you trust.
                </div>
              </div>
              <div class="text-center mt-3" *ngIf="subscribed == false">
                <button
                  type="button"
                  class="btn btn-primary-locked btn-yellow btn-lg rounded-btn"
                  (click)="upgradeBtn()"
                >
                  <i class="fa fa-lock" aria-hidden="true"></i> Request to
                  Unlock
                </button>
              </div>
              <div
                class="d-flex justify-content-center mt-3"
                *ngIf="subscribed == true"
              >
                <button
                  type="button"
                  class="
                    btn btn-primary btn-primary-generate btn-lg
                    rounded-btn
                  "
                  (click)="generateKey()"
                  *ngIf="buttonShow[0] == true"
                >
                  <i class="fa fa-key" aria-hidden="true"></i> Generate Key
                </button>
                <button
                  type="button"
                  class="
                    btn btn-primary btn-primary-generate btn-lg
                    rounded-btn
                  "
                  (click)="copied(userinput)"
                  *ngIf="buttonShow[1] == true"
                >
                  <i class="fa fa-copy" aria-hidden="true"></i> Copy
                </button>
                <button
                  type="button"
                  class="
                    btn btn-primary
                    disabled
                    btn-primary-generate btn-lg
                    rounded-btn
                  "
                  *ngIf="buttonShow[2] == true"
                >
                  <i class="fa fa-copy" aria-hidden="true"></i> Copied
                </button>
              </div>
            </div>
            <div class="account-content">
              <div class="text-center m-b-20">
                <!-- <p class="text-muted m-b-0 font-13 line-h-24">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, </p> -->
              </div>

              <div class="clearfix"></div>
            </div>
          </div>
          <!-- end card-box-->
        </div>
        <!-- end wrapper -->
      </div>
    </div>
    <!-- Start content -->

    <app-footer></app-footer>
    <!-- content -->
  </div>

  <!-- ============================================================== -->
  <!-- End Right content here -->
  <!-- ============================================================== -->
</div>
