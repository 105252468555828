<div class="wrapper">
	<div class="container row">
		<!-- Location Stats -->
		<div class="col-12">
			<span class="chart-title">Provider's Practice Location</span>

			<span *ngIf="loader" class="loader mt-4"></span>
			<highcharts-chart
				id="container"
				*ngIf="!loader"
				[Highcharts]="Highcharts"
				[constructorType]="chartConstructor"
				[options]="mapOptions"
				style="width: 100%; height: 600px; display: block"
			>
			</highcharts-chart>
		</div>

		<!-- Specialty Stats -->
		<div class="col-12">
			<span class="chart-title">Provider's Specialty</span>

			<span *ngIf="specialityData.loader" class="loader mt-4"></span>
			<apx-chart
				*ngIf="!specialityData.loader"
				[series]="specialityChart.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="specialityChart.xaxis"
				style="width: 100%; padding-top: 1rem; height: 100%"
			></apx-chart>
		</div>

		<!-- ProviderType Stats -->
		<div class="col-12 d-flex flex-column">
			<span class="chart-title">Provider Type</span>

			<span *ngIf="providerTypeData.loader" class="loader mt-4"></span>
			<apx-chart
				*ngIf="!providerTypeData.loader"
				[series]="providerTypeChart.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="providerTypeChart.xaxis"
				style="width: 100%; padding-top: 3rem; height: 100%"
			></apx-chart>
		</div>

		<!-- Gender Stats -->
		<div class="col-12 d-flex flex-column">
			<span class="chart-title">Provider's Gender</span>

			<span *ngIf="genderData.loader" class="loader mt-4"></span>
			<apx-chart
				*ngIf="!genderData.loader"
				[series]="genderChart.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="genderChart.xaxis"
				style="width: 100%; padding-top: 3rem; height: 100%"
			></apx-chart>
		</div>

		<!-- Licence State Stats -->
		<div class="col-12">
			<span class="chart-title">Provider's Licence State</span>

			<span *ngIf="licenceStateData.loader" class="loader mt-4"></span>
			<highcharts-chart
				id="container"
				*ngIf="!licenceStateData.loader"
				[Highcharts]="Highcharts"
				[constructorType]="chartConstructor"
				[options]="licenceStateMapOptions"
				style="width: 100%; height: 600px; display: block"
			>
			</highcharts-chart>
		</div>

		<!-- Age Range Stats -->
		<div class="col-12 d-flex flex-column">
			<span class="chart-title">Provider's Age Range</span>

			<span *ngIf="ageRangeData.loader" class="loader mt-4"></span>
			<apx-chart
				*ngIf="!ageRangeData.loader"
				[series]="ageRangeChart.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="ageRangeChart.xaxis"
				style="width: 100%; padding-top: 3rem; height: 100%"
			></apx-chart>
		</div>

		<!-- Total Claim Stats -->
		<div class="col-12 d-flex flex-column">
			<span class="chart-title">Total Claims</span>

			<span *ngIf="totalClaimRangeData.loader" class="loader mt-4"></span>
			<apx-chart
				*ngIf="!totalClaimRangeData.loader"
				[series]="totalClaimRangeChart.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="totalClaimRangeChart.xaxis"
				style="width: 100%; padding-top: 3rem; height: 100%"
			></apx-chart>
		</div>

		<!-- Firm Type Stats -->
		<div class="col-12 d-flex flex-column">
			<span class="chart-title">Firm Type</span>

			<span *ngIf="firmTypeData.loader" class="loader mt-4"></span>
			<apx-chart
				*ngIf="!firmTypeData.loader"
				[series]="firmTypeChart.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="firmTypeChart.xaxis"
				style="width: 100%; padding-top: 3rem; height: 100%"
			></apx-chart>
		</div>

		<!-- Licence State Stats -->
		<!-- <div class="col-12 d-flex flex-column">
		<span class="chart-title">Licence State</span>

		<span *ngIf="licenceStateData.loader" class="loader mt-4"></span>
		<apx-chart
			*ngIf="!licenceStateData.loader"
			[series]="licenceStateChart.series"
			[chart]="chartOptions.chart"
			[dataLabels]="chartOptions.dataLabels"
			[plotOptions]="chartOptions.plotOptions"
			[xaxis]="licenceStateChart.xaxis"
			style="width: 100%; padding-top: 3rem; height: 100%"
		></apx-chart>
	</div> -->

		<!-- Classification Stats -->
		<div class="col-12 d-flex flex-column">
			<span class="chart-title">Hospital Classification</span>

			<span *ngIf="classificationData.loader" class="loader mt-4"></span>
			<apx-chart
				*ngIf="!classificationData.loader"
				[series]="classificationChart.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="classificationChart.xaxis"
				style="width: 100%; padding-top: 3rem; height: 100%"
			></apx-chart>
		</div>

		<!-- Language Stats -->
		<div class="col-12 d-flex flex-column">
			<span class="chart-title">Provider's Language</span>

			<span *ngIf="languageData.loader" class="loader mt-4"></span>
			<apx-chart
				*ngIf="!languageData.loader"
				[series]="languageChart.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="languageChart.xaxis"
				style="width: 100%; padding-top: 3rem; height: 100%"
			></apx-chart>
		</div>

		<!-- Education Stats -->
		<div class="col-12 d-flex flex-column">
			<span class="chart-title">Provider's Education</span>

			<span *ngIf="educationData.loader" class="loader mt-4"></span>
			<apx-chart
				*ngIf="!educationData.loader"
				[series]="educationChart.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="educationChart.xaxis"
				style="width: 100%; padding-top: 3rem; height: 100%"
			></apx-chart>
		</div>

		<!-- City Stats -->
		<!-- <div class="col-12 d-flex flex-column">
		<span class="chart-title">City</span>

		<span *ngIf="cityData.loader" class="loader mt-4"></span>
		<apx-chart
			*ngIf="!cityData.loader"
			[series]="cityChart.series"
			[chart]="chartOptions.chart"
			[dataLabels]="chartOptions.dataLabels"
			[plotOptions]="chartOptions.plotOptions"
			[xaxis]="cityChart.xaxis"
			style="width: 100%; padding-top: 3rem; height: 100%"
		></apx-chart>
	</div> -->
	</div>
</div>
