import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ParagonIntegrationService } from 'src/app/modules/healthcare/services/paragon-integration.service';
@Component({
	selector: 'app-sales-force-export-btn',
	templateUrl: './sales-force-export-btn.component.html',
	styleUrls: ['./sales-force-export-btn.component.css']
})
export class SalesForceExportBtnComponent implements ICellRendererAngularComp {
	constructor(private paragonService: ParagonIntegrationService) {}
	public params: any;

	get isDisabled() {
		return this.paragonService.btnStatus.getValue() == 'Enable' ? true : false;
	}

	agInit(params: any): void {
		this.params = params;
		//
	}
	refresh(params?: any): boolean {
		return true;
	}

	btnClickedHandler() {
		this.params.clicked(this.params);
	}
}
