<div class="card column filter-component" style="width: 23rem">
	<div class="image-filter">
		<div class="flex-row filter-header">
			<div>
				<h6 class="card-title titleCardStyle">Showing results for</h6>
			</div>
			<div
				class="searchButton primary"
				[ngClass]="filterData.validateImagingSearch() ? 'primaryBtn' : ''"
				(click)="clearFilter()"
			>
				<span> Clear </span>
			</div>
		</div>
		<mat-accordion>
			<app-individual-filter
				#companyAuto
				(valueChanges)="companyValueChanges($event)"
				[totalItems]="companyList"
				[(includedItems)]="filterData.company"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Company name"
				title="Company"
			></app-individual-filter>

			<app-individual-filter
				#fullnameText
				type="text"
				[totalItems]="[]"
				[(includedItems)]="filterData.fullName"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Contact name"
				title="Full Name"
				[disabled]="!isSubscribed"
			>
			</app-individual-filter>

			<app-individual-filter
				#titleAuto
				(valueChanges)="titleValueChanges($event)"
				[totalItems]="titleList"
				[(includedItems)]="filterData.titleInclude"
				[(excludedItems)]="filterData.titleExclude"
				includePlaceholder="Include titles"
				title="Title"
				excludePlaceholder="Exclude title"
				(includedItemsChange)="omitChanges()"
				(excludedItemsChange)="omitChanges()"
				[disabled]="!isSubscribed"
			>
			</app-individual-filter>
			<app-expand-item
				title="Department"
				[selectedData]="filterData.department"
				placeholder="Select Department"
				[isPaid]="!isSubscribed"
			>
				<app-select-drop-down
					title="Select Department"
					[list]="departmentList"
					[(selectedData)]="filterData.department"
					(onItemSelect)="omitChanges()"
				></app-select-drop-down>
			</app-expand-item>
			<app-expand-item
				title="Seniority"
				[selectedData]="filterData.seniority"
				placeholder="Select Seniority"
				[isPaid]="!isSubscribed"
			>
				<app-select-drop-down
					title="Select Seniority"
					[list]="seniorityList"
					[(selectedData)]="filterData.seniority"
					(onItemSelect)="omitChanges()"
				></app-select-drop-down>
			</app-expand-item>

			<app-expand-item
				title="MCO"
				placeholder="MCO Type"
				[selectedData]="filterData.industry"
				[isPaid]="!isSubscribed"
				[isString]="true"
			>
				<app-signle-select-drop-down
					title="MCO Type"
					[list]="mcoTypeList"
					[(selectedData)]="filterData.industry"
					(onItemSelect)="omitChanges()"
				></app-signle-select-drop-down>
			</app-expand-item>

			<div class="location">
				<app-individual-location-filter
					[countryList]="countryList"
					(locationValueChanged)="hanldeLocationValueChange($event)"
					[disabled]="!isSubscribed"
					[(selectedStatesFromFilter)]="filterData.stateList"
					[(selectedCitiesFromFilter)]="filterData.cityList"
				>
				</app-individual-location-filter>
			</div>
			<app-more-fliter-button
				[isPaid]="!isSubscribed"
				(openMoreFilter)="openMoreFilter()"
			></app-more-fliter-button>
		</mat-accordion>
	</div>
	<div class="upgrade-card" (click)="requestPricing()" *ngIf="isPaid">
		<div>
			<div class="blt-background">
				<mat-icon class="mt-ico">bolt</mat-icon>
			</div>
		</div>
		<div style="padding-left: 10px">
			<div class="upgrd-to-unlock">Request to unlock more filters</div>
			<div class="improve-search">Improve your search result with additional filters</div>
		</div>
	</div>
</div>

<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="recordCountLoader"
		[totalRecords]="moreFilterRecordCount.value"
		(clear)="moreFilterAccordian.closeAll(); clearTempFilter()"
		(applyFilter)="applyMoreFilters()"
		(closeFilter)="closeDialogBox('mainDialog')"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Company"
					[(selectedData)]="tempDialogFilters.company"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Company Name"
						[(list)]="companyList"
						[(selectedData)]="tempDialogFilters.company"
						(onItemSelect)="getRecordCount()"
						(onChange)="companyValueChanges($event)"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item class="dialog-flex-container" title="Title" [selectedData]="tempTitleData">
					<app-auto-complete-drop-drow
						title="Include titles"
						[(list)]="titleList"
						[(selectedData)]="tempDialogFilters.titleInclude"
						(onItemSelect)="getRecordCount()"
						(onChange)="titleValueChanges($event)"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="Exclude titles"
						[(list)]="titleList"
						[(selectedData)]="tempDialogFilters.titleExclude"
						(onItemSelect)="getRecordCount()"
						(onChange)="titleValueChanges($event)"
					></app-auto-complete-drop-drow>
					<app-chip-container
						[(data)]="tempDialogFilters.titleInclude"
						(onChange)="getRecordCount()"
					></app-chip-container>
					<app-chip-container
						[(data)]="tempDialogFilters.titleExclude"
						(onChange)="getRecordCount()"
					></app-chip-container>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Full Name"
					[(selectedData)]="tempDialogFilters.fullName"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-keyword-input
						title="Contact Name"
						[storageKey]="'ltc_fullNameList_list'"
						[(selectedData)]="tempDialogFilters.fullName"
						(onItemAdd)="getRecordCount()"
					></app-keyword-input>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Department"
					[(selectedData)]="tempDialogFilters.department"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Department"
						[list]="departmentList"
						[(selectedData)]="tempDialogFilters.department"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Seniority"
					[(selectedData)]="tempDialogFilters.seniority"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Seniority"
						[list]="seniorityList"
						[(selectedData)]="tempDialogFilters.seniority"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="MCO"
					[selectedData]="tempDialogFilters.industry ? [tempDialogFilters.industry] : []"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-signle-select-drop-down
						title="MCO Type"
						[list]="mcoTypeList"
						[(selectedData)]="tempDialogFilters.industry"
						(onItemSelect)="getRecordCount()"
					></app-signle-select-drop-down>
				</app-more-filter-expand-item>
				<app-location-filter
					class="dialog-flex-container"
					[countryList]="[]"
					(locationValueChanged)="getRecordCount()"
					[(selectedStatesFromFilter)]="tempDialogFilters.stateList"
					[(selectedCitiesFromFilter)]="tempDialogFilters.cityList"
				>
				</app-location-filter>
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<app-more-fliter-close-dialog
		[(showDialog)]="showConfirmDialog"
		(closeFilter)="closeDialogBox('mainDialog', true)"
	></app-more-fliter-close-dialog>
</ng-template>
