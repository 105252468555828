<!-- <div id="wrapper">
	<app-header [user]="" style="width: 100%" [elementName]="'hospital'"></app-header>

	<div class="content-page" style="height: 100% !important">
		<div
			class="content"
			*ngIf="hospitalOverviewResult !== undefined"
			style="padding: 98px 0px !important"
		>
			<div class="row">
				<div class="col-sm-12">
					<div class="header-card d-flex">
						<div class="flex-shrink">
							<div class="left-side-card d-flex flex-column">
								<img [src]="hospitalOverviewResult.hospitalInfoData.logoLink" width="90px" height="90px" />
								<span class="btn-grey-bordered mt-1 text-center" (click)="notCorrectDrawer = true"
									>Not correct</span
								>
							</div>
						</div>

						<div class="flex-grow-1 row ml-3">
							<div class="col-12">
								<h4 class="fnt-Hospital">
									{{ hospitalOverviewResult.hospitalInfoData.hospitalName }}
								</h4>
							</div>
							<div class="col-12 d-flex flex-wrap right-side-card p-0">
								<div
									class="executive_hospital info-width"
									*ngIf="
										hospitalOverviewResult.hospitalInfoData.webAddress.length > 0 &&
										hospitalOverviewResult.hospitalInfoData.webAddress !== undefined
									"
								>
									<a
										style="color: #4b4b4b !important"
										[href]="'http://' + hospitalOverviewResult.hospitalInfoData.webAddress"
										target="_blank"
										><i class="mdi mdi-web icon"></i>View website</a
									>
								</div>
								<div
									class="executive_hospital info-width"
									*ngIf="
										hospitalOverviewResult.hospitalInfoData.hospitalPhone.length > 0 &&
										hospitalOverviewResult.hospitalInfoData.hospitalPhone !== undefined
									"
								>
									<span
										><i class="mdi mdi-phone-in-talk icon"></i
										>{{ hospitalOverviewResult.hospitalInfoData.hospitalPhone }}</span
									>
								</div>
								<div
									class="executive_hospital info-width"
									*ngIf="
										hospitalOverviewResult.hospitalInfoData.hospitalFax.length > 0 &&
										hospitalOverviewResult.hospitalInfoData.hospitalFax !== undefined
									"
								>
									<span
										><i class="mdi mdi-fax icon"></i
										>{{ hospitalOverviewResult.hospitalInfoData.hospitalFax }}</span
									>
								</div>
								<div
									class="executive_hospital info-width"
									*ngIf="
										hospitalOverviewResult.hospitalInfoData.address1.length > 0 ||
										hospitalOverviewResult.hospitalInfoData.address2.length > 0
									"
								>
									<span
										><i class="mdi mdi-map-marker-radius icon"></i
										>{{ hospitalOverviewResult.hospitalInfoData.address1 }}
										{{ hospitalOverviewResult.hospitalInfoData.address2 }}</span
									>
								</div>

								<div
									class="executive_hospital info-width"
									*ngIf="
										hospitalOverviewResult.hospitalInfoData.region.length > 0 &&
										hospitalOverviewResult.hospitalInfoData.region !== undefined
									"
								>
									<strong class="text-dark-14">Region : &nbsp;</strong>
									{{ hospitalOverviewResult.hospitalInfoData.region }}
								</div>
								<div
									class="executive_hospital"
									*ngIf="
										hospitalOverviewResult.hospitalInfoData.geographic_Classification.length > 0 &&
										hospitalOverviewResult.hospitalInfoData.geographic_Classification !== undefined
									"
								>
									<strong class="text-dark-14">Geo Classification : &nbsp;</strong>
									{{ hospitalOverviewResult.hospitalInfoData.geographic_Classification }}
								</div>
							</div>
							<div class="col-12">
								<div class="social_icon_wrap">
									<ul>
										<li *ngIf="hospitalOverviewResult?.hospitalInfoData?.linkedInLink">
											<img
												src="assets/img/socialicons/linkedin.png"
												width="15px"
												height="15px"
												(click)="openSocial(hospitalOverviewResult?.hospitalInfoData?.linkedInLink)"
												class="clickable"
											/>
										</li>
										<li *ngIf="hospitalOverviewResult?.hospitalInfoData?.twitterLink">
											<img
												src="assets/img/socialicons/twitter.png"
												width="15px"
												height="15px"
												(click)="openSocial(hospitalOverviewResult?.hospitalInfoData?.twitterLink)"
												class="clickable"
											/>
										</li>
										<li *ngIf="hospitalOverviewResult?.hospitalInfoData?.facebookLink">
											<img
												src="assets/img/socialicons/facebook.png"
												width="15px"
												height="15px"
												(click)="openSocial(hospitalOverviewResult?.hospitalInfoData?.facebookLink)"
												class="clickable"
											/>
										</li>
										<li *ngIf="hospitalOverviewResult?.hospitalInfoData?.instagramLink">
											<img
												src="assets/img/socialicons/instagram.png"
												width="15px"
												height="15px"
												(click)="openSocial(hospitalOverviewResult?.hospitalInfoData?.instagramLink)"
												class="clickable"
											/>
										</li>
										<li *ngIf="hospitalOverviewResult?.hospitalInfoData?.googleLink">
											<img
												src="assets/img/socialicons/search.png"
												width="15px"
												height="15px"
												(click)="openSocial(hospitalOverviewResult?.hospitalInfoData?.googleLink)"
												class="clickable"
											/>
										</li>
										<li *ngIf="hospitalOverviewResult?.hospitalInfoData?.pinterestLink">
											<img
												src="assets/img/socialicons/pinterest.png"
												width="15px"
												height="15px"
												(click)="openSocial(hospitalOverviewResult?.hospitalInfoData?.pinterestLink)"
												class="clickable"
											/>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-sm-12">
					<div style="padding-top: 10px">
						<div class="physician-content">
							<mat-tab-group (selectedTabChange)="tabClick($event)">
								<mat-tab label="Overview">
									<ng-template matTabContent>
										<div class="p-5">
											<div class="row">
												<div class="col-sm-12 col-md-4 mb-4">
													<div
														class="align-self-center"
														*ngIf="
															hospitalOverviewResult.hospitalInfoData.firmType !== undefined &&
															hospitalOverviewResult.hospitalInfoData.firmType.length > 0
														"
													>
														<div class="executive_hospital">
															<strong class="text-dark">Entity Type : &nbsp;</strong>
															{{ hospitalOverviewResult.hospitalInfoData.firmType }}
														</div>
													</div>
												</div>
												<div
													class="col-sm-12 col-md-5 mb-4"
													*ngIf="
														hospitalOverviewResult.hospitalInfoData.hospitalType !== undefined &&
														hospitalOverviewResult.hospitalInfoData.hospitalType.length > 0
													"
												>
													<div class="align-self-center">
														<div class="executive_hospital">
															<strong class="text-dark">Hospital Type : &nbsp;</strong>
															{{ hospitalOverviewResult.hospitalInfoData.hospitalType }}
														</div>
													</div>
												</div>
												<div
													class="col-sm-12 col-md-3 mb-4"
													*ngIf="
														hospitalOverviewResult.hospitalInfoData.companyStatus !== undefined &&
														hospitalOverviewResult.hospitalInfoData.companyStatus.length > 0
													"
												>
													<div class="align-self-center">
														<div class="executive_hospital">
															<strong class="text-dark">Hospital Status : &nbsp;</strong>
															{{ hospitalOverviewResult.hospitalInfoData.companyStatus }}
														</div>
													</div>
												</div>
												<div
													class="col-sm-12 col-md-4 mb-4"
													*ngIf="
														hospitalOverviewResult.hospitalInfoData.ownership !== undefined &&
														hospitalOverviewResult.hospitalInfoData.ownership.length > 0
													"
												>
													<div class="align-self-center">
														<div class="executive_hospital">
															<strong class="text-dark">Ownership Type : &nbsp;</strong>
															{{ hospitalOverviewResult.hospitalInfoData.ownership }}
														</div>
													</div>
												</div>
												<div
													class="col-sm-12 col-md-5 mb-4"
													*ngIf="
														hospitalOverviewResult.hospitalInfoData.cBSA !== undefined &&
														hospitalOverviewResult.hospitalInfoData.cBSA.length > 0
													"
												>
													<div class="align-self-center">
														<div class="executive_hospital">
															<strong class="text-dark">Core-Based Statistical Area : &nbsp;</strong>
															{{ hospitalOverviewResult.hospitalInfoData.cBSA }}
														</div>
													</div>
												</div>
												<div
													class="col-sm-12 col-md-3 mb-4"
													*ngIf="
														hospitalOverviewResult.hospitalInfoData.fIPS_County_Code !== undefined &&
														hospitalOverviewResult.hospitalInfoData.fIPS_County_Code.length > 0
													"
												>
													<div class="align-self-center">
														<div class="executive_hospital">
															<strong class="text-dark">FIPS Country Code : &nbsp;</strong>
															{{ hospitalOverviewResult.hospitalInfoData.fIPS_County_Code }}
														</div>
													</div>
												</div>
												<div
													class="col-sm-12 col-md-4"
													*ngIf="
														hospitalOverviewResult.hospitalInfoData.accreditation_Agency !== undefined &&
														hospitalOverviewResult.hospitalInfoData.accreditation_Agency.length > 0
													"
												>
													<div class="align-self-center">
														<div class="executive_hospital">
															<strong class="text-dark">Accreditation Agency : &nbsp;</strong>
															{{ hospitalOverviewResult.hospitalInfoData.accreditation_Agency }}
														</div>
													</div>
												</div>
												<div
													class="col-sm-12 col-md-4"
													*ngIf="
														hospitalOverviewResult.hospitalInfoData.market_Concentration_Index !== undefined &&
														hospitalOverviewResult.hospitalInfoData.market_Concentration_Index.length > 0
													"
												>
													<div class="align-self-center">
														<div class="executive_hospital">
															<strong class="text-dark">Market Concentration Index : &nbsp;</strong>
															{{ hospitalOverviewResult.hospitalInfoData.market_Concentration_Index }}
														</div>
													</div>
												</div>
												<div
													class="col-sm-12 col-md-4"
													*ngIf="
														hospitalOverviewResult.hospitalInfoData.medicare_Administrative_Contractors !==
															undefined &&
														hospitalOverviewResult.hospitalInfoData.medicare_Administrative_Contractors.length > 0
													"
												>
													<div class="align-self-center">
														<div class="executive_hospital">
															<strong class="text-dark">MAC/Fis : &nbsp;</strong>
															{{ hospitalOverviewResult.hospitalInfoData.medicare_Administrative_Contractors }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</ng-template>
								</mat-tab>

								<mat-tab [label]="'Physician (' + physicianCount + ')'">
									<ng-template matTabContent>
										<div class="p-4">
											<div class="" *ngIf="unLockDiv == false">
												<table class="table">
													<thead class="thead-light">
														<tr>
															<th scope="col" class="text-muted">Physician Name</th>
															<th scope="col" class="text-muted">Title</th>
															<th scope="col" class="text-muted">Email Address</th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let item of totalSearchResult">
															<td scope="row" class="light-muted">
																<a
																	[routerLink]="['/physicianOverview', item.physicianId]"
																	target="_blank"
																	style="color: #2d8cff"
																	>{{ item.fullName }}</a
																>
															</td>
															<td class="light-muted">{{ item.specialty }}</td>
															<td class="fnt_email-ff">
																{{ item.emailAddress }}
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="h-100" *ngIf="unLockDiv">
												<app-upgrade></app-upgrade>
											</div>
											<div class="search_pagination_wrapper">
												<div class="row">
													<div class="col-md-6 col-sm-12">
														<div class="paginationWrapper" *ngIf="totalSize > 0">
															<div class="pull-left paginationInfo" *ngIf="totalSize > 0">
																{{ offset + 1 | numberWithCommas }}-{{
																	(offset + count > totalSize ? totalSize : count + offset) | numberWithCommas
																}}
																of {{ totalSize | numberWithCommas }}
															</div>
															<ul
																*ngIf="pager.pages && pager.pages.length"
																class="pagination"
																style="float: left !important"
															>
																<li
																	[ngClass]="{
																		disabled: pager.currentPage === 1
																	}"
																>
																	<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
																		><i class="fa fa-chevron-left" aria-hidden="true"></i
																	></a>
																	<a
																		*ngIf="pager.currentPage !== 1"
																		(click)="setPage(pager.currentPage - 1)"
																		style="border-radius: 5px 0 0 5px !important"
																		><i class="fa fa fa-chevron-left" aria-hidden="true"></i
																	></a>
																</li>

																<li
																	[ngClass]="{
																		disabled:
																			pager.currentPage === pager.totalPages ||
																			(pager.currentPage === 2 && subscribed === false)
																	}"
																>
																	<a
																		*ngIf="pager.currentPage === pager.totalPages"
																		style="border-radius: 0 5px 5px 0 !important"
																		><i class="fa fa-chevron-right" aria-hidden="true"></i
																	></a>
																	<a
																		*ngIf="pager.currentPage !== pager.totalPages"
																		(click)="setPage(pager.currentPage + 1)"
																		style="border-radius: 0 5px 5px 0 !important"
																		><i class="fa fa-chevron-right" aria-hidden="true"></i
																	></a>
																</li>
															</ul>
														</div>
													</div>
													<div class="col-md-6 col-sm-12 p-0 m-0 pb-2 pt-3"></div>
												</div>
											</div>
										</div>
									</ng-template>
								</mat-tab>
								<mat-tab [label]="'Executive(' + executiveCount + ')'" *ngIf="executiveCount > 0">
									<ng-template matTabContent>
										<div class="p-4">
											<div class="" *ngIf="unLockDiv == false">
												<table class="table">
													<thead class="thead-light">
														<tr>
															<th scope="col" class="text-muted">Executive Name</th>
															<th scope="col" class="text-muted">Title</th>
															<th scope="col" class="text-muted">Email Address</th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let item of totalSearchResult">
															<td scope="row" class="light-muted">
																<a
																	[routerLink]="['/executiveOverview', item.executiveId]"
																	target="_blank"
																	style="color: #2d8cff"
																	>{{ item.fullName }}</a
																>
															</td>
															<td class="light-muted">{{ item.title }}</td>
															<td class="fnt_email-ff">
																{{ item.emailAddress }}
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="h-100" *ngIf="unLockDiv">
												<app-upgrade></app-upgrade>
											</div>
											<div class="search_pagination_wrapper">
												<div class="row">
													<div class="col-md-6 col-sm-12">
														<div class="paginationWrapper" *ngIf="totalSize > 0">
															<div class="pull-left paginationInfo" *ngIf="totalSize > 0">
																{{ offset + 1 | numberWithCommas }}-{{
																	(offset + count > totalSize ? totalSize : count + offset) | numberWithCommas
																}}
																of {{ totalSize | numberWithCommas }}
															</div>
															<ul
																*ngIf="pager.pages && pager.pages.length"
																class="pagination"
																style="float: left !important"
															>
																<li
																	[ngClass]="{
																		disabled: pager.currentPage === 1
																	}"
																>
																	<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
																		><i class="fa fa-chevron-left" aria-hidden="true"></i
																	></a>
																	<a
																		*ngIf="pager.currentPage !== 1"
																		(click)="setPageE(pager.currentPage - 1)"
																		style="border-radius: 5px 0 0 5px !important"
																		><i class="fa fa fa-chevron-left" aria-hidden="true"></i
																	></a>
																</li>

																<li
																	[ngClass]="{
																		disabled:
																			pager.currentPage === pager.totalPages ||
																			(pager.currentPage === 2 && subscribed === false)
																	}"
																>
																	<a
																		*ngIf="pager.currentPage === pager.totalPages"
																		style="border-radius: 0 5px 5px 0 !important"
																		><i class="fa fa-chevron-right" aria-hidden="true"></i
																	></a>
																	<a
																		*ngIf="pager.currentPage !== pager.totalPages"
																		(click)="setPageE(pager.currentPage + 1)"
																		style="border-radius: 0 5px 5px 0 !important"
																		><i class="fa fa-chevron-right" aria-hidden="true"></i
																	></a>
																</li>
															</ul>
														</div>
													</div>
													<div class="col-md-6 col-sm-12 p-0 m-0 pb-2 pt-3"></div>
												</div>
											</div>
										</div>
									</ng-template>
								</mat-tab>
								<mat-tab label="Finance">
									<ng-template matTabContent>
										<div class="verticalTab">
											<mat-tab-group (selectedTabChange)="vtabClick($event)" [selectedIndex]="0">
												<mat-tab label="Earnings" class="justify-content-start">
													<div class="vTabTableWrapper">
														<table class="table">
															<tbody>
																<tr *ngFor="let item of dataResult | keyvalue">
																	<td class="light-muted w-75">
																		{{ item.key | startcase }}
																	</td>
																	<td class="align-right light-body">
																		<strong class="fnt-dllr">{{ item.value }}</strong>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</mat-tab>
												<mat-tab label="Cost and Budget">
													<div class="vTabTableWrapper">
														<table class="table">
															<tbody>
																<tr *ngFor="let item of dataResult | keyvalue">
																	<td class="light-muted w-75">
																		{{ item.key | startcase }}
																	</td>
																	<td class="align-right light-body">
																		<strong class="fnt-dllr">{{ item.value }}</strong>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</mat-tab>
												<mat-tab label="Income and EBITDA">
													<div class="vTabTableWrapper">
														<table class="table">
															<tbody>
																<tr *ngFor="let item of dataResult | keyvalue">
																	<td class="light-muted w-75">
																		{{ item.key | startcase }}
																	</td>
																	<td class="align-right light-body">
																		<strong class="fnt-dllr">{{ item.value }}</strong>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</mat-tab>
												<mat-tab label="Assets">
													<div class="vTabTableWrapper">
														<table class="table">
															<tbody>
																<tr *ngFor="let item of dataResult | keyvalue">
																	<td class="light-muted w-75">
																		{{ item.key | startcase }}
																	</td>
																	<td class="align-right light-body">
																		<strong class="fnt-dllr">{{ item.value }}</strong>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</mat-tab>
												<mat-tab label="Liabilities">
													<div class="vTabTableWrapper">
														<table class="table">
															<tbody>
																<tr *ngFor="let item of dataResult | keyvalue">
																	<td class="light-muted w-75">
																		{{ item.key | startcase }}
																	</td>
																	<td class="align-right light-body">
																		<strong class="fnt-dllr">{{ item.value }}</strong>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</mat-tab>
											</mat-tab-group>
										</div>
									</ng-template>
								</mat-tab>
								<mat-tab label="Specification">
									<ng-template matTabContent>
										<div class="vTabTableWrapper2">
											<table class="table">
												<tbody>
													<tr *ngFor="let item of dataResult | keyvalue">
														<td class="light-muted w-75">
															{{ item.key | startcase }}
														</td>
														<td class="align-right light-body">
															<strong class="fnt-dllr">{{ item.value }}</strong>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</ng-template>
								</mat-tab>
								<mat-tab label="Hospital Stats">
									<ng-template matTabContent>
										<div class="vTabTableWrapper2">
											<table class="table">
												<tbody>
													<tr *ngFor="let item of dataResult | keyvalue">
														<td class="light-muted w-75">
															{{ item.key | startcase }}
														</td>
														<td class="align-right light-body">
															<strong class="fnt-dllr">{{ item.value }}</strong>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</ng-template>
								</mat-tab>
								<mat-tab label="Alliances">
									<ng-template matTabContent>
										<div class="vTabTableWrapper2">
											<table class="table">
												<tbody>
													<tr *ngFor="let item of dataResult | keyvalue">
														<td class="light-muted w-75">
															{{ item.key | startcase }}
														</td>
														<td class="align-right light-body">
															<strong class="fnt-dllr">{{ item.value }}</strong>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</ng-template>
								</mat-tab>
								<mat-tab label="CPT CODES">
									<ng-template matTabContent>
										<app-hospital-hscpc-details [physicianId]="hospitalId1"></app-hospital-hscpc-details>
									</ng-template>
								</mat-tab>
								<mat-tab label="HCPCS CODES">
									<ng-template matTabContent>
										<app-hospital-cpt-details [physicianId]="hospitalId1"></app-hospital-cpt-details>
									</ng-template>
								</mat-tab>
							</mat-tab-group>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<app-hospital-notcorrect
		[notCorrectDiv]="notCorrectDrawer"
		(cancelBtnClick)="cancelBtnClick($event)"
		[hospitalId]="hospitalOverviewResult?.hospitalInfoData?.hospitalId"
		[hospitalName]="hospitalOverviewResult?.hospitalInfoData?.hospitalName"
		*ngIf="notCorrectDrawer"
	></app-hospital-notcorrect>
</div> -->

<div id="wrapper">
	<app-header [user]="" style="width: 100%" [elementName]="'hospital'"></app-header>

	<div class="content-page" style="height: 100% !important">
		<div class="content" *ngIf="hospitalOverview !== undefined" style="padding: 98px 0px !important">
			<div class="row">
				<div class="col-sm-12">
					<div class="content-wrapper">
						<div class="menu-container card">
							<div class="menu-header d-flex flex-column">
								<div class="d-flex" style="justify-content: space-between">
									<div class="d-flex flex-column">
										<h1>{{ hospitalOverview['Hospital Name'] }}</h1>
										<p>{{ hospitalOverview['Location'] }}</p>
									</div>
									<div class="d-flex flex-column">
										<img
											[src]="hospitalOverviewSocials['Logo Link']"
											width="60px"
											height="60px"
											style="border-radius: 5px"
										/>
									</div>
								</div>
								<div class="mt-3">
									<button class="download-btn" [class.disabled-btn]="!subscribed" (click)="downloadPDF()">
										<span *ngIf="!loading">Download PDF</span>
										<mat-icon style="margin-left: 6px; margin-top: 3px" class="filter-title" *ngIf="!subscribed"
											>lock</mat-icon
										>
										<div *ngIf="loading" class="bars"></div>
									</button>
								</div>
							</div>
							<div class="menu-filter-container">
								<div class="scrollable-menu">
									<ng-container *ngFor="let attribute of attributes; i as index" ngForTrackBy="i">
										<div
											class="menu-filter"
											[class.active]="attribute.active"
											(click)="activateFilter(attribute.name)"
										>
											<p>{{ attribute.name }}</p>
											<i class="fa fa-chevron-down" aria-hidden="true"></i>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
						<div class="main-container card">
							<ng-container *ngIf="getActiveFilter.name == 'Overview'">
								<div class="scrollable-container">
									<div class="info-card">
										<div class="container">
											<div class="keyvalue-container">
												<ng-container *ngFor="let detail of hospitalOverviewDetails | keyvalue">
													<div
														class="keyvalue"
														*ngIf="detail.key !== 'Noofexecutives' && detail.key !== 'No Of Physician'"
													>
														<span>{{ detail.key === 'Number Ofbeds' ? 'Number Of Beds' : detail.key }}</span>
														<p *ngIf="detail.value !== ''">{{ detail.value }}</p>
														<p *ngIf="detail.value === ''" style="color: #919191">--</p>
													</div>
													<div class="keyvalue" *ngIf="detail.key === 'Noofexecutives'">
														<span>Number Of Executives</span>
														<p
															*ngIf="detail.value !== ''"
															(click)="navigateToExecutive()"
															style="cursor: pointer; text-decoration: underline"
														>
															{{ detail.value }}
														</p>
														<p *ngIf="detail.value === ''" style="color: #919191">--</p>
													</div>
													<div class="keyvalue" *ngIf="detail.key === 'No Of Physician'">
														<span>Number Of HCPs</span>
														<!-- (click)="navigateToExecutive()" -->
														<p *ngIf="detail.value !== ''">
															{{ detail.value }}
														</p>
														<p *ngIf="detail.value === ''" style="color: #919191">--</p>
													</div>
												</ng-container>
											</div>

											<div class="map_iframe_container">
												<iframe
													[frameBorder]="0"
													width="100%"
													height="400"
													[src]="mapUrl"
													style="border-radius: 10px; border: 1px solid #e0e0e0"
												></iframe>
											</div>
										</div>
									</div>

									<div class="info-card mt-2">
										<div class="d-flex justify-content-between">
											<h3>Top HCPs</h3>
											<div>
												<mat-form-field appearance="fill">
													<mat-label>Select Stats</mat-label>
													<mat-select [(value)]="selectedHcpStats" (selectionChange)="onHcpChange($event)">
														<mat-option *ngFor="let stats of hcpStatsOptions" [value]="stats">{{
															stats
														}}</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</div>
										<div class="d-flex flex-column">
											<ng-container
												class="d-flex justify-content-center w-full"
												*ngIf="selectedHcpStats === 'Specialty'"
											>
												<div *ngIf="hcpStats.specialty.loader" class="mx-auto w-full">
													<span class="spin-loader"></span>
												</div>
												<div *ngIf="hcpStats.specialty.data.length > 0">
													<apx-chart
														*ngIf="!hcpStats.specialty.loader"
														[series]="specialtyChart.series"
														[chart]="chartOptions.chart"
														[dataLabels]="chartOptions.dataLabels"
														[plotOptions]="chartOptions.plotOptions"
														[xaxis]="specialtyChart.xaxis"
														[grid]="chartOptions.grid"
														style="width: 100%; padding-top: 3rem; height: 100%"
													></apx-chart>
												</div>
											</ng-container>
											<ng-container
												class="d-flex justify-content-center w-full"
												*ngIf="selectedHcpStats === 'Genders'"
											>
												<span *ngIf="hcpStats.gender.loader" class="spin-loader"></span>
												<div *ngIf="hcpStats.gender.data.length > 0">
													<apx-chart
														*ngIf="!hcpStats.gender.loader"
														[series]="genderChart.series"
														[chart]="chartOptions.chart"
														[dataLabels]="chartOptions.dataLabels"
														[plotOptions]="chartOptions.plotOptions"
														[xaxis]="genderChart.xaxis"
														style="width: 100%; padding-top: 3rem; height: 100%"
													></apx-chart>
												</div>
											</ng-container>
											<!-- <ng-container class="d-flex justify-content-center w-full" *ngIf="selectedHcpStats === 'States'">
												<span *ngIf="hcpStats.state.loader" class="spin-loader"></span>
												<div *ngIf="hcpStats.state.data.length > 0">
													<apx-chart
														*ngIf="!hcpStats.state.loader"
														[series]="stateChart.series"
														[chart]="chartOptions.chart"
														[dataLabels]="chartOptions.dataLabels"
														[plotOptions]="chartOptions.plotOptions"
														[xaxis]="stateChart.xaxis"
														style="width: 100%; padding-top: 3rem; height: 100%"
													></apx-chart>
												</div>
											</ng-container> -->
										</div>
									</div>

									<div class="info-card mt-2">
										<div class="d-flex justify-content-between">
											<h3>Top Executive</h3>
											<div>
												<mat-form-field appearance="fill">
													<mat-label>Select Stats</mat-label>
													<mat-select
														[(value)]="selectedExecutiveStat"
														(selectionChange)="onExecutiveStatChange($event)"
													>
														<mat-option *ngFor="let stats of executiveStatsOptions" [value]="stats">{{
															stats
														}}</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</div>
										<div class="d-flex flex-column">
											<ng-container
												class="d-flex mx-auto w-full"
												*ngIf="selectedExecutiveStat === 'Department'"
											>
												<div class="w-full mx-auto" *ngIf="executiveStats.department.loader">
													<span class="spin-loader"></span>
												</div>
												<div *ngIf="executiveStats.department.data.length > 0">
													<apx-chart
														*ngIf="!executiveStats.department.loader"
														[series]="departmentChart.series"
														[chart]="chartOptions.chart"
														[dataLabels]="chartOptions.dataLabels"
														[plotOptions]="chartOptions.plotOptions"
														[xaxis]="departmentChart.xaxis"
														[grid]="chartOptions.grid"
														style="width: 100%; padding-top: 3rem; height: 100%"
													></apx-chart>
												</div>
											</ng-container>
											<ng-container
												class="d-flex justify-content-center w-full"
												*ngIf="selectedExecutiveStat === 'Seniority'"
											>
												<span *ngIf="executiveStats.seniority.loader" class="spin-loader"></span>
												<div *ngIf="executiveStats.seniority.data.length > 0">
													<apx-chart
														*ngIf="!executiveStats.seniority.loader"
														[series]="seniorityChart.series"
														[chart]="chartOptions.chart"
														[dataLabels]="chartOptions.dataLabels"
														[plotOptions]="chartOptions.plotOptions"
														[xaxis]="seniorityChart.xaxis"
														style="width: 100%; padding-top: 3rem; height: 100%"
													></apx-chart>
												</div>
											</ng-container>
											<!-- <ng-container class="d-flex justify-content-center w-full" *ngIf="selectedExecutiveStat === 'States'">
												<span *ngIf="executiveStats.state.loader" class="spin-loader"></span>
												<div *ngIf="executiveStats.state.data.length > 0">
													<apx-chart
														*ngIf="!executiveStats.state.loader"
														[series]="executiveStateChart.series"
														[chart]="chartOptions.chart"
														[dataLabels]="chartOptions.dataLabels"
														[plotOptions]="chartOptions.plotOptions"
														[xaxis]="executiveStateChart.xaxis"
														style="width: 100%; padding-top: 3rem; height: 100%"
													></apx-chart>
												</div>
											</ng-container> -->
										</div>
									</div>
								</div>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'HCPs'">
								<app-hospital-physician-details
									[hospitalID]="hospitalId1"
									[hospitalName]="hospitalOverview['Hospital Name']"
									[subscribed]="subscribed"
								>
								</app-hospital-physician-details>
							</ng-container>
							<ng-container *ngIf="getActiveFilter.name == 'Executives'">
								<app-hospital-executive-details
									[hospitalID]="hospitalId1"
									[hospitalName]="hospitalOverview['Hospital Name']"
									[subscribed]="subscribed"
								>
								</app-hospital-executive-details>
							</ng-container>
							<ng-container *ngIf="getActiveFilter.name == 'HCPCS Codes'">
								<app-hospital-hscpc-details [physicianId]="hospitalId2"></app-hospital-hscpc-details>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'CPT Codes'">
								<app-hospital-cpt-details [physicianId]="hospitalId2"></app-hospital-cpt-details>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'Finance'">
								<app-hospital-finance-details
									[hospitalID]="hospitalId2"
									[hospitalName]="hospitalOverview['Hospital Name']"
									[subscribed]="subscribed"
								></app-hospital-finance-details>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'Specification'">
								<app-hospital-specification
									[hospitalID]="hospitalId2"
									[hospitalName]="hospitalOverview['Hospital Name']"
									[subscribed]="subscribed"
								></app-hospital-specification>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'Value Stats'">
								<app-hospital-value-stats
									[hospitalID]="hospitalId2"
									[hospitalName]="hospitalOverview['Hospital Name']"
									[subscribed]="subscribed"
								></app-hospital-value-stats>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'Hospital Stats'">
								<app-hospital-stats
									[hospitalID]="hospitalId2"
									[hospitalName]="hospitalOverview['Hospital Name']"
									[subscribed]="subscribed"
								></app-hospital-stats>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'Alliances'">
								<app-hospital-alliances
									[hospitalID]="hospitalId2"
									[hospitalName]="hospitalOverview['Hospital Name']"
									[subscribed]="subscribed"
								></app-hospital-alliances>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'Total Stats'">
								<app-hospital-total-stats
									[hospitalID]="hospitalId2"
									[hospitalName]="hospitalOverview['Hospital Name']"
									[subscribed]="subscribed"
								></app-hospital-total-stats>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'Technology'">
								<app-hospital-techiq
									[hospitalID]="hospitalId2"
									[hospitalName]="hospitalOverview['Hospital Name']"
									[subscribed]="subscribed"
								></app-hospital-techiq>
							</ng-container>

							<ng-container *ngIf="getActiveFilter.name == 'ICD 10'">
								<app-hospital-icd10 [hospitalID]="hospitalId2"></app-hospital-icd10>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<app-hospital-notcorrect
		[notCorrectDiv]="notCorrectDrawer"
		(cancelBtnClick)="cancelBtnClick($event)"
		[hospitalId]="hospitalOverviewDetails?.hospitalID"
		[hospitalName]="hospitalOverview?.hospitalName"
		*ngIf="notCorrectDrawer"
	></app-hospital-notcorrect>
</div>
