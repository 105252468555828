import { Component, Input, OnInit } from '@angular/core';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { ImagingService } from 'src/app/modules/ImagingCenter/services/imaging.service';
import { DataService } from '../../service/data.service';
import { PayorService } from '../../service/payor.service';
import { SearchClinicalModal } from '../../models/clinical-model/search-clinical.modal';
import { ClinicalModelList } from '../../models/clinical-model/clinical.model';
import { PaginationService } from 'src/app/modules/healthcare/services/pagination.service';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';

@Component({
	selector: 'app-clinical-info',
	templateUrl: './clinical-info.component.html',
	styleUrls: ['./clinical-info.component.css']
})
export class ClinicalInfoComponent implements OnInit {
	@Input() isSubscribed: boolean = true;
	subscriptions: any = [];
	tab = 1;
	selectedFilter: SearchClinicalModal = new SearchClinicalModal();
	tabItems = [{ name: `Total(0)`, icon: { name: '' } }];
	clinicalList: ClinicalModelList = new ClinicalModelList();
	pageNumberOptions: Array<any> = [5, 10, 25, 50];
	totalItemCount: number = 0;
	pager: any = {};

	currentTab: number = 1;
	showLoader: boolean = false;
	noResult: boolean = true;
	showUpgradeCard: boolean = false;
	subscribed: boolean = false;
	constructor(
		private imagingService: ImagingService,
		private dataService: DataService,
		private amplizService: AmplizService,
		private payorService: PayorService,
		private loaderService: SkeletonloaderService
	) {}

	ngOnInit() {
		this.getDashboardDetails();
		this.searchClinicalList();
	}

	async getDashboardDetails() {
		setTimeout(() => {
			this.loaderService.status.subscribe((res) => {
				this.showLoader = res;
			});
		});

		const authToken = await localStorage.getItem('auth_token');
		// const userId = await localStorage.getItem('user_id');
		const refreshToken = await localStorage.getItem('refresh_token');
		//
		if (authToken !== null && refreshToken !== null) {
			this.amplizService.getDashboardDetails().subscribe(
				(res) => {
					this.subscriptions = res.Subscriptions;
					if (this.subscriptions[0].SubscriptionType == 'Free') {
						localStorage.setItem('SubscriptionisActive', 'false');
						this.subscribed = false;
					}
					if (this.subscriptions[0].SubscriptionType == 'Paid') {
						localStorage.setItem('SubscriptionisActive', 'true');
						this.subscribed = true;
					}
				},
				(error) => {
					if (error.status === 401) {
						this.amplizService.logout();
					}
					//
				}
			);
		} else {
			this.amplizService.logout();
		}
	}

	get isSubscribe() {
		return this.subscribed;
	}

	get decrementEnabled() {
		return this.selectedFilter.offset > 0;
	}

	get incrementEnabled() {
		return (
			this.selectedFilter.offset + this.selectedFilter.count < this.totalItemCount && !this.showUpgradeCard
		);
	}
	handleTabChange(event) {
		this.currentTab = event;
	}
	handleFilterChanged(event: SearchClinicalModal) {
		this.selectedFilter = event;
		this.searchClinicalList();
	}
	changeTabItems() {
		this.tabItems[0].name = `Total (${this.numberWithCommas(this.totalItemCount)})`;
	}
	numberWithCommas(x: number) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	handleRecordsChange() {
		this.selectedFilter.offset = 0;
		this.searchClinicalList();
	}
	async searchClinicalList(page = 0) {
		this.showLoader = true;
		this.loaderService.display(true);
		this.payorService
			.searchClinical({
				...this.selectedFilter
			})
			.subscribe(
				(res) => {
					this.showLoader = false;
					this.clinicalList.clinicalList = res.clinicInfoList;

					this.totalItemCount = res.totalResult;
					if (this.clinicalList.clinicalList.length !== 0) {
						this.noResult = false;
					} else {
						this.noResult = true;
					}
					this.loaderService.display(false);
					this.changeTabItems();
				},
				(err) => {
					this.loaderService.display(false);
					this.clinicalList.clinicalList = [];
					this.showLoader = false;
					this.noResult = true;
				}
			);
	}
	incrementPage() {
		if (this.incrementEnabled) {
			this.selectedFilter.offset = this.selectedFilter.offset + this.selectedFilter.count;
			// console.log(this.selectedFilter);
			if (this.subscribed) {
				this.showUpgradeCard = false;
				this.searchClinicalList();
			} else {
				this.showUpgradeCard = true;
			}
		}
	}

	decrementPage() {
		if (this.decrementEnabled) {
			this.showUpgradeCard = false;
			this.selectedFilter.offset = this.selectedFilter.offset - this.selectedFilter.count;
			this.searchClinicalList();
		}
	}
	setPage(page: any) {
		this.selectedFilter.offset = this.selectedFilter.count * (page - 1) + 1;
		this.searchClinicalList(page);
	}
}
