import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ParagonIntegrationService } from '../../services/paragon-integration.service';

@Component({
	selector: 'app-crm-panel',
	templateUrl: './crm-panel.component.html',
	styleUrls: ['./crm-panel.component.css']
})
export class CrmPanelComponent implements OnInit {
	@Output() panelClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() show: boolean = false;
	integrationList: any = [];
	activatedIntegrations: any = [];

	constructor(private paragonService: ParagonIntegrationService) {
		this.paragonService.integration.subscribe((val) => {
			this.integrationList = val;
		});
		// this.paragonService.paraUser.subscribe((val) => {
		// 	this.getActivatedIntegrationsList(val.integrations);
		// });
		this.paragonService.activatedIntegrations.subscribe((integrations) => {
			this.activatedIntegrations = integrations;
		});
	}

	ngOnInit(): void {}

	findActivatedIntegration(integration: string) {
		if (!this.show) return;
		return this.activatedIntegrations.includes(integration);
	}

	openPortal(type: string) {
		this.paragonService.connectParagon(type);
	}
}
