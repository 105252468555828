<div class="card column filter-component" style="width: 23rem">
	<div class="flex-row header">
		<div>
			<h6 class="card-title titleCardStyle">Showing results for</h6>
		</div>
		<span (click)="clearAll()" class="clearAll" style="cursor: pointer">Clear</span>
	</div>
	<mat-accordion>
		<app-expand-item
			title="Hospital"
			placeholder="Enter Hospital"
			[selectedData]="filterData.hospitalName"
			[isPaid]="false"
		>
			<app-auto-complete-drop-drow
				[(list)]="filteredHospitals"
				[(selectedData)]="filterData.hospitalName"
				(onItemSelect)="omitChange()"
				(onChange)="getHospitalName($event)"
				itemName="hospitalName"
				itemValue="hospitalName"
				[loader]="hospitalLoader"
			></app-auto-complete-drop-drow>
		</app-expand-item>
		<app-expand-item
			title="Hospital Type"
			placeholder="Select Hospital Type"
			[selectedData]="filterData.hospitalType"
			[isPaid]="!isSubscribed"
		>
			<app-select-drop-down
				[(list)]="hospitalTypeList"
				[(selectedData)]="filterData.hospitalType"
				(onItemSelect)="omitChange()"
			></app-select-drop-down>
		</app-expand-item>
		<app-expand-item
			title="Procedure Codes"
			placeholder="CPT/HCPCS"
			[isPaid]="!isSubscribed"
			[selectedData]="ProcedureCodes"
		>
			<app-auto-complete-drop-drow
				title="CPT"
				[(list)]="cptCodeList"
				[(selectedData)]="filterData.cptCodes"
				(onItemSelect)="omitChange()"
				(onChange)="getCPTCodes($event)"
				itemName="id"
				itemValue="cptCode"
			></app-auto-complete-drop-drow>
			<app-auto-complete-drop-drow
				title="HCPCS"
				[(list)]="hscpcCodeList"
				[(selectedData)]="filterData.hcpcsCodes"
				(onItemSelect)="omitChange()"
				(onChange)="getHSCPCCodes($event)"
				itemName="id"
				itemValue="hcpcsCode"
			></app-auto-complete-drop-drow>
			<app-auto-complete-drop-drow
				title="ICD 10"
				[(list)]="icptCodeList"
				[(selectedData)]="filterData.icdTenCodes"
				(onItemSelect)="omitChange()"
				(onChange)="getCpdtList($event)"
				itemName="id"
				itemValue="code"
				[loader]="icdLoader"
			></app-auto-complete-drop-drow>
		</app-expand-item>
		<app-expand-item
			title="Number of Beds"
			placeholder="Select number of beds"
			[selectedData]="filterData.numberOFBeds"
			[isPaid]="!isSubscribed"
		>
			<app-select-drop-down
				title="Select number of beds"
				[list]="noOfBedList"
				[(selectedData)]="filterData.numberOFBeds"
				(onItemSelect)="omitChange()"
			></app-select-drop-down>
		</app-expand-item>
		<div class="location">
			<app-individual-location-filter
				[countryList]="[]"
				(locationValueChanged)="omitChange()"
				[disabled]="!isSubscribed"
				[(selectedStatesFromFilter)]="filterData.stateList"
				[(selectedCitiesFromFilter)]="filterData.city"

			>
			</app-individual-location-filter>
		</div>
		<app-more-fliter-button
			[isPaid]="!isSubscribed"
			(openMoreFilter)="openMoreFilter()"
		></app-more-fliter-button>
	</mat-accordion>

	<!-- added by Rupesh end-->
</div>
<div class="upgrade-card" (click)="requestPricing()" *ngIf="isPaid">
	<div>
		<div class="blt-background">
			<mat-icon class="mt-ico">bolt</mat-icon>
		</div>
	</div>
	<div style="padding-left: 10px">
		<div class="upgrd-to-unlock">Request to unlock more filters</div>
		<div class="improve-search">Improve your search result with additional filters</div>
	</div>
</div>

<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="recordCountLoader"
		[totalRecords]="moreFilterRecordCount.value"
		(clear)="moreFilterAccordian.closeAll(); clearTempFilter()"
		(applyFilter)="applyMoreFilters()"
		(closeFilter)="closeDialogBox('mainDialog')"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Hospital"
					[(selectedData)]="tempDialogFilters.hospitalName"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Enter Hospital"
						[(list)]="filteredHospitals"
						[(selectedData)]="tempDialogFilters.hospitalName"
						(onItemSelect)="getRecordCount()"
						(onChange)="getHospitalName($event)"
						itemName="hospitalName"
						itemValue="hospitalName"
						[loader]="hospitalLoader"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Hospital Type"
					[(selectedData)]="tempDialogFilters.hospitalType"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Hospital Type"
						[list]="hospitalTypeList"
						[(selectedData)]="tempDialogFilters.hospitalType"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Procedure Codes"
					[selectedData]="tempProcedureCodes"
				>
					<app-auto-complete-drop-drow
						title="CPT"
						[(list)]="cptCodeList"
						[(selectedData)]="tempDialogFilters.cptCodes"
						(onItemSelect)="getRecordCount()"
						(onChange)="getCPTCodes($event)"
						itemName="id"
						itemValue="cptCode"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="HCPCS"
						[(list)]="hscpcCodeList"
						[(selectedData)]="tempDialogFilters.hcpcsCodes"
						(onItemSelect)="getRecordCount()"
						(onChange)="getHSCPCCodes($event)"
						itemName="id"
						itemValue="hcpcsCode"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="ICD 10"
						[(list)]="icptCodeList"
						[(selectedData)]="tempDialogFilters.icdTenCodes"
						(onItemSelect)="getRecordCount()"
						(onChange)="getCpdtList($event)"
						itemName="id"
						itemValue="code"
						[loader]="icdLoader"
					></app-auto-complete-drop-drow>
					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.cptCodes"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.hcpcsCodes"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.icdTenCodes"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Number of Beds"
					[(selectedData)]="tempDialogFilters.numberOFBeds"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select number of beds"
						[list]="noOfBedList"
						[(selectedData)]="tempDialogFilters.numberOFBeds"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-location-filter
					class="dialog-flex-container"
					[countryList]="[]"
					(locationValueChanged)="getRecordCount()"
					[(selectedStatesFromFilter)]="tempDialogFilters.stateList"
					[(selectedCitiesFromFilter)]="tempDialogFilters.city"
				>
				</app-location-filter>
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<app-more-fliter-close-dialog
		[(showDialog)]="showConfirmDialog"
		(closeFilter)="closeDialogBox('mainDialog', true)"
	></app-more-fliter-close-dialog>
</ng-template>
