<div class="flex-row bulkSaveCardCont">
  <p class="selectedText mr-3">
    <span class="count">
      {{ allImagingsSelected ? totalItemCount : selectedImagings.length }}
      {{ imageCount }}
    </span>
    Contacts are selected
  </p>
  <p class="mr-3 clearText" (click)="handleClearPress()">Clear Selection</p>
  <div class="flex-row flex1">
    <app-save-clinic-executive-modal
      (successfullyAdded)="handleContactAddList()"
      title="Save"
      [allContactsSelected]="allImagingsSelected"
      [selectedItems]="selectedImagings"
      [totalItemCount]="totalItemCount"
      [selectedFilter]="selectedFilter"></app-save-clinic-executive-modal>
  </div>
</div>
