import { Component, OnInit, Input } from '@angular/core';
import { AmplizService } from '../../../services/ampliz.service';

@Component({
	selector: 'app-hospital-value-stats',
	templateUrl: './hospital-value-stats.component.html',
	styleUrls: ['./hospital-value-stats.component.css']
})
export class HospitalValueStatsComponent implements OnInit {
	@Input() hospitalID: string = '';
	@Input() hospitalName: string = '';
	@Input() subscribed: boolean;

	valueStatsList: any = [];

	loading: boolean = false;

	summaryScores: any = [];
	years: any;
	selectedYear: any;

	constructor(private amplizService: AmplizService) {}

	ngOnInit(): void {
		this.getHospitalValueStats();
	}

	getHospitalValueStats() {
		if (
			JSON.parse(sessionStorage.getItem('valueStatsList')) == null &&
			JSON.parse(sessionStorage.getItem('summaryScores')) == null
		) {
			this.amplizService.getHospitalValueStats(this.hospitalID).subscribe((res) => {
				this.valueStatsList = res.hospitalValueStats;
				delete this.valueStatsList['HospitalID'];
				delete this.valueStatsList['IDN ID'];
				delete this.valueStatsList['IDN Parent ID'];

				this.summaryScores = this.valueStatsList['SummaryScores&HistoricalQlt_InP'];

				this.years = Object.keys(this.summaryScores);
				this.selectedYear = this.years[this.years.length - 1];

				delete this.valueStatsList['SummaryScores&HistoricalQlt_InP'];

				sessionStorage.setItem('valueStatsList', JSON.stringify(this.valueStatsList));
				sessionStorage.setItem('summaryScores', JSON.stringify(this.summaryScores));
				sessionStorage.setItem('summaryScoresYears', JSON.stringify(this.years));
			});
		} else {
			this.valueStatsList = JSON.parse(sessionStorage.getItem('valueStatsList'));
			this.summaryScores = JSON.parse(sessionStorage.getItem('summaryScores'));
			this.years = JSON.parse(sessionStorage.getItem('summaryScoresYears'));
			this.selectedYear = this.years[this.years.length - 1];
		}
	}

	onYearChange(event: any) {
		this.selectedYear = event.value;
	}

	downloadPdf() {
		if (!this.subscribed) return;
		this.loading = true;
		this.amplizService.hospitalValueStatsPdfDownload(this.hospitalID, this.selectedYear).subscribe(
			(res) => {
				const blob = new Blob([res.body], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${this.hospitalName}_ValueStats.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setTimeout(() => {
					this.loading = false;
				}, 100);
			},
			(err) => {
				this.loading = false;
				console.log('ERR', err);
			}
		);
	}
}
