<mat-expansion-panel #panelTitle hideToggle="true" [disabled]="isPaid">
	<mat-expansion-panel-header
		[ngClass]="panelTitle.expanded ? 'expanded' : ''"
		[collapsedHeight]="height"
		[expandedHeight]="height"
		class="expanda-container"
	>
		<mat-panel-title style="width: 100%">
			<div><mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>{{ title }}</div>
			<div class="header-info" *ngIf="selectedData.length == 0 && placeholder">{{ placeholder }}</div>
			<div class="selected" *ngIf="selectedData.length > 0">
				<mat-chip-list #includedTitleList aria-label="Included title">
					<mat-basic-chip *ngIf="selectedData?.length > 0" [ngClass]="'selected'">
						<span *ngIf="!isString"> {{ itemName ? selectedData[0][itemName] : selectedData[0] }}</span>
						<span *ngIf="isString">{{ selectedData }}</span>
					</mat-basic-chip>
					<span *ngIf="selectedData?.length > 1" class="additional-selection">
						<span *ngIf="!isString">+ {{ selectedData.length - 1 }} more</span>
					</span>
				</mat-chip-list>
			</div>
		</mat-panel-title>
		<mat-panel-description>
			<mat-icon class="matrl-plus">{{ panelTitle.expanded ? 'remove' : 'add' }}</mat-icon>
		</mat-panel-description>
	</mat-expansion-panel-header>
	<ng-content></ng-content>
</mat-expansion-panel>
