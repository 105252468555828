<div id="wrapper">
	<app-header style="width: 100%" [elementName]="'physician-group'"></app-header>
	<!-- <app-sidemenu elementName="payor"></app-sidemenu> -->
	<div style="margin-top: 90px">
		<app-hc-top-tab
			[activeLink]="currentTab"
			[tabItems]="tabItems"
			[isPrimary]="true"
			(tabChanged)="handleTabChange($event)"
			[fontSize]="'14px'"
		>
		</app-hc-top-tab>
	</div>
	<div *ngIf="currentTab == 0">
		<app-physician-group-company></app-physician-group-company>
	</div>
	<div *ngIf="currentTab == 1">
		<app-physician-group-executive></app-physician-group-executive>
	</div>
</div>
