import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-signle-select-drop-down',
	templateUrl: './signle-select-drop-down.component.html',
	styleUrls: ['./signle-select-drop-down.component.css']
})
export class SignleSelectDropDownComponent implements OnInit, OnChanges {
	@Input() selectedData: string = '';
	@Input() title: string = '';
	@Input() itemName: any = '';
	@Input() itemValue: any = '';
	@Input() placeholder: any = '';
	@Input() list: Array<any> = [];
	@Input() returnObject: boolean = false;
	@Output() onItemSelect = new EventEmitter();
	selectable = true;
	removable = true;

	@ViewChild('autoInput')
	autoInput: ElementRef<HTMLInputElement>;
	control: UntypedFormControl = new UntypedFormControl();

	@Output() selectedDataChange = new EventEmitter();
	@Output() listChange = new EventEmitter();

	constructor(private cdRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.cdRef.detectChanges();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['selectedData'] && !changes['selectedData'].isFirstChange()) {
			this.updateFormControl();
		}
	}

	private updateFormControl(): void {
		this.control.setValue(this.selectedData);
		this.cdRef.detectChanges();
	}
	selectItem(item) {
		this.omitChange();
	}
	remove() {
		this.control.setValue('');
		this.selectedData = '';
		this.omitChange();
	}
	omitChange() {
		this.selectedDataChange.emit(this.selectedData);
		this.onItemSelect.emit();
	}
}
