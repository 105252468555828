import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayorComponent } from './container/payor/payor.component';
import { PayorCompanyComponent } from './container/payor-company/payor-company.component';
import { PayorExecutiveComponent } from './container/payor-executive/payor-executive.component';
import { PayourCompanyOverviewComponent } from './container/payour-company-overview/payour-company-overview.component';
import { PayourExecutiveOverviewComponent } from './container/payour-executive-overview/payour-executive-overview.component';
import { BulkSavePayorCardComponent } from './components/bulk-save-payor-card/bulk-save-payor-card.component';
import { PayorCardComponent } from './components/payor-card/payor-card.component';
import { PayorCenterCardComponent } from './components/payor-center-card/payor-center-card.component';
import { PayorFilterExecutiveComponent } from './components/payor-filter-executive/payor-filter-executive.component';
import { PayorNotCorrectComponent } from './components/payor-not-correct/payor-not-correct.component';
import { SavePayourModalComponent } from './components/save-payour-modal/save-payour-modal.component';
import { PayorFilterComponent } from './components/payor-filter/payor-filter.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AgGridModule } from 'ag-grid-angular';
import { RouterModule } from '@angular/router';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { BasicModule } from '../basic/basic.module';
import { HealthcareModule } from '../healthcare/healthcare.module';
import { LTCModule } from '../LTC/ltc.module';
import { ImagingCenterModule } from '../ImagingCenter/imaging-center.module';
import { PhysicianGroupComponent } from './container/physician-group/physician-group.component';
import { PhysicianGroupCompanyComponent } from './container/physician-group-company/physician-group-company.component';
import { PhysicianGroupExecutiveComponent } from './container/physician-group-executive/physician-group-executive.component';
import { PhysicianGroupFilterComponent } from './components/physician-group-filter/physician-group-filter.component';
import { PhysicianGroupExecutiveFilterComponent } from './components/physician-group-executive-filter/physician-group-executive-filter.component';
import { PhysicianGroupCardComponent } from './components/physician-group-card/physician-group-card.component';
import { SavePhysicianGroupModalComponent } from './components/save-physician-group-modal/save-physician-group-modal.component';
import { PhysicianGroupCenterCardComponent } from './components/physician-group-center-card/physician-group-center-card.component';
import { PhysicianGroupNotCorrectComponent } from './components/physician-group-not-correct/physician-group-not-correct.component';
import { PhysicianGroupCompanyOverviewComponent } from './container/physician-group-company-overview/physician-group-company-overview.component';
import { PhysicianGroupExecutiveOverviewComponent } from './container/physician-group-executive-overview/physician-group-executive-overview.component';
import { PhysicianGroupBulkSaveComponent } from './components/physician-group-bulk-save/physician-group-bulk-save.component';
import { ClinicalGroupComponent } from './container/clinical-group/clinical-group.component';
import { ClinicalFilterComponent } from './components/clinical-filter/clinical-filter.component';
import { ClinicalCardComponent } from './components/clinical-card/clinical-card.component';
import { ClinicalExecutiveComponent } from './container/clinical-executive/clinical-executive.component';
import { ClinicalInfoComponent } from './container/clinical-info/clinical-info.component';
import { ClinicalExecutiveCardComponent } from './components/clinical-executive-card/clinical-executive-card.component';
import { ClinicalExecutiveFilterComponent } from './components/clinical-executive-filter/clinical-executive-filter.component';
import { ClinicalOverviewComponent } from './container/clinical-overview/clinical-overview.component';
import { ClinicalExecutiveOverviewComponent } from './container/clinical-executive-overview/clinical-executive-overview.component';
import { BulkSaveClinicExecutiveCardComponent } from './components/bulk-save-clinic-executive-card/bulk-save-clinic-executive-card.component';
import { SaveClinicExecutiveModalComponent } from './components/save-clinic-executive-modal/save-clinic-executive-modal.component';
import { ClinicNotCorrectComponent } from './components/clinic-not-correct/clinic-not-correct.component';

const appRoutes = [
  {
    path: 'payor',
    component: PayorComponent,
  },
  {
    path: 'payor/:mcoExecutiveId',
    component: PayourExecutiveOverviewComponent,
  },
  {
    path: 'payor-company/:mcoCompanyId',
    component: PayourCompanyOverviewComponent,
  },
  {
    path: 'physician-group',
    component: PhysicianGroupComponent,
  },
  {
    path: 'physician-group/:phyGroupCompanyId',
    component: PhysicianGroupExecutiveOverviewComponent,
  },
  {
    path: 'physician-group-company/:phyGroupCompanyId',
    component: PhysicianGroupCompanyOverviewComponent,
  },
  {
    path: 'clinical',
    component: ClinicalGroupComponent,
  },
  {
    path: 'clinicalOverview/:clinicId',
    component: ClinicalOverviewComponent,
  },
  {
    path: 'clinicalExecutiveOverview/:clinicExecutiveId',
    component: ClinicalExecutiveOverviewComponent,
  },
];

@NgModule({
  declarations: [
    PayorComponent,
    PayorCompanyComponent,
    PayorExecutiveComponent,
    PayourCompanyOverviewComponent,
    PayourExecutiveOverviewComponent,
    BulkSavePayorCardComponent,
    PayorCardComponent,
    PayorCenterCardComponent,
    PayorFilterExecutiveComponent,
    PayorNotCorrectComponent,
    SavePayourModalComponent,
    PayorFilterComponent,
    PhysicianGroupComponent,
    PhysicianGroupCompanyComponent,
    PhysicianGroupExecutiveComponent,
    PhysicianGroupFilterComponent,
    PhysicianGroupExecutiveFilterComponent,
    PhysicianGroupCardComponent,
    SavePhysicianGroupModalComponent,
    PhysicianGroupCenterCardComponent,
    PhysicianGroupNotCorrectComponent,
    PhysicianGroupCompanyOverviewComponent,
    PhysicianGroupExecutiveOverviewComponent,
    PhysicianGroupBulkSaveComponent,
    ClinicalGroupComponent,
    ClinicalFilterComponent,
    ClinicalCardComponent,
    ClinicalExecutiveComponent,
    ClinicalInfoComponent,
    ClinicalExecutiveCardComponent,
    ClinicalExecutiveFilterComponent,
    ClinicalOverviewComponent,
    ClinicalExecutiveOverviewComponent,
    BulkSaveClinicExecutiveCardComponent,
    SaveClinicExecutiveModalComponent,
    ClinicNotCorrectComponent,
  ],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgGridModule,
    CommonComponentsModule,
    BasicModule,
    HealthcareModule,
    LTCModule,
    ImagingCenterModule,
  ],
})
export class PayorModule {}
