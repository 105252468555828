import { Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
	selector: 'app-expand-item',
	templateUrl: './expand-item.component.html',
	styleUrls: ['./expand-item.component.css']
})
export class ExpandItemComponent implements OnInit {
	@Input() isPaid: boolean = true;
	@Input() selectedData: Array<any> = [];
	@Input() title: Array<any> = [];
	@Input() placeholder: Array<any> = [];
	@Input() height: string = '70px';
	@Input() itemName: any = '';
	@Input() itemValue: any = '';
	@Input() isString: boolean = false;

	constructor(private renderer: Renderer2) {}

	ngOnInit(): void {}
	triggerAutoFocus(eleId: string) {
		const element = this.renderer.selectRootElement(eleId);
		setTimeout(() => element.focus(), 100);
	}
}
