
<div id="wrapper">
	<app-header style="width: 100%" [elementName]="'team-member'"></app-header>
	<!-- <app-sidemenu elementName="team-member"></app-sidemenu> -->
	<div class="content-page enlarged">
		<!-- [style.height.px]="updatedHeight" -->
		<div class="content row" *ngIf="isAdmin">
			<div class="side-panel col-4 p-0">
				<app-member-side-panel
					(addMember)="handleAddMember($event)"
					(openUserInfo)="openUserInfo($event)"
					(adminCredits)="handleAdminDetail($event)"
				></app-member-side-panel>
			</div>
			<div class="action-panel col">
				<!-- [userInfo]="userInfo" -->
				<app-member-action-panel></app-member-action-panel>
			</div>
		</div>

		<!-- <div class="content row justify-content-center align-content-center">
			PAID FEATURE
		</div> -->
	</div>
</div>
