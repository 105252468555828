<div class="dialog-flex-item">
	<div class="expansion-body">
		<mat-expansion-panel [disabled]="disabled" #panelLoc hideToggle>
			<mat-expansion-panel-header
				[ngClass]="panelLoc.expanded ? 'expanded' : ''"
				[collapsedHeight]="'70px'"
				[expandedHeight]="'70px'"
			>
				<mat-panel-title>
					<div class="d-flex align-items-center">
						{{ title }} 
						<div class="ml-2 clear-more-filter" *ngIf="chipData.length > 0 || selectedCount > 0">
							<button (click)="clearFilter(); $event.stopPropagation()">
								<mat-icon class="clear-more-filter-icon">close</mat-icon>
								<span class="clear-more-filter-text">
									{{ selectedCount > 0 ? selectedCount : chipData.length }}
								</span>
							</button>
						</div>
					</div>
				</mat-panel-title>
				<mat-panel-description>
					<mat-icon class="matrl-plus">{{ panelLoc.expanded ? 'remove' : 'add' }}</mat-icon>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<ng-content></ng-content>
		</mat-expansion-panel>
		<div class="second-header d-flex" *ngIf="chipData.length > 0 && !panelLoc.expanded">
			<ng-content *ngIf="!single; else showChips" select=".chips-container" class="display-flex"></ng-content>
			<ng-template #showChips>
				<mat-chip-list #includeSpecialityList>
					<mat-basic-chip *ngFor="let item of chipData" [removable]="removable" (removed)="remove(item)">
						{{ itemName ? item[itemName] : item }}
						<mat-icon matChipRemove>close</mat-icon>
					</mat-basic-chip>
				</mat-chip-list>
			</ng-template>
		</div>
	</div>
</div>
