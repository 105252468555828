<div class="physican_container card-animate">
	<div class="container">
		<div class="row">
			<div class="col-sm-12" [ngClass]="smallResolution ? 'col-md-8' : 'col-md-9'">
				<div class="physician_card">
					<div class="physician_content ml-3">
						<h5 class="physician_name" (click)="onImagingNameClicked(clinicalData)">
							{{ clinicalData.name }}
						</h5>
						<div class="physician_info">
							<span class="d-flex"
								><i class="fa fa-map-marker mr-1" style="font-size: 15px" aria-hidden="true"></i
								>{{ location }}</span
							>
							<ng-container *ngIf="getWebAddress">
								<span class="d-flex">
									<!-- <i class="fa fa-globe" aria-hidden="true"></i> -->
									<i-feather
										name="Globe"
										class="imgCardIcon mr-1"
										style="height: 15px; width: 15px"
									></i-feather>
									<a [href]="getWebAddress" class="physican_email" target="_blank"> View Website </a>
								</span>
							</ng-container>
						</div>

						<div class="speciality-container">
							<ng-container *ngFor="let specialty of firstSpecialities">
								<span class="speciality-chip">{{ specialty.specialty }} ({{ specialty.count }})</span>
							</ng-container>
							<span
								*ngIf="clinicalData.specialtyCounts.length > 3"
								class="speciality-chip blue-chip"
								(click)="openSpecialityDialog()"
								>+ {{ clinicalData.specialtyCounts.length - 3 }} Specialities</span
							>
						</div>

						<!------------>
					</div>
				</div>
			</div>
			<div class="col-sm-12" [ngClass]="smallResolution ? 'col-md-4' : 'col-md-3'">
				<div class="physican_additionInfo">
					<ng-container *ngIf="clinicalData.noOfExecutive && clinicalData.noOfExecutive != null">
						<span class="physician_values" (click)="searchHospitalInExecutives()">
							<i class="fa fa-user" aria-hidden="true"></i>
							{{ clinicalData.noOfExecutive }} Executives</span
						>
					</ng-container>
					<ng-container *ngIf="clinicalData.noOfPhysician && clinicalData.noOfPhysician != null">
						<span class="physician_values" (click)="searchHospitalInHcps()">
							<i class="fa fa-user-md" aria-hidden="true"></i>
							{{ clinicalData.noOfPhysician }} HCPs</span
						>
					</ng-container>
					<ng-container *ngIf="clinicalData.phone && clinicalData.phone != null">
						<span class="physician_phone">
							<i class="fa fa-phone" aria-hidden="true"></i>
							{{ clinicalData.phone }}</span
						>
					</ng-container>

					<div class="saveBtn_wrp imgCenterCard">
						<app-clinic-not-correct [imagingId]="clinicalData.clinicId"></app-clinic-not-correct>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #specialityDialog>
	<div class="dialog-header mb-1">
		<span>Specialties</span>
		<mat-icon class="closeIcon" (click)="dialogRef.close()">close</mat-icon>
	</div>

	<div class="scroll-dialog-content">
		<div class="speciality-container">
			<ng-container *ngFor="let specialty of clinicalData.specialtyCounts">
				<span class="speciality-chip">{{ specialty.specialty }} ({{ specialty.count }})</span>
			</ng-container>
		</div>
	</div>
</ng-template>
