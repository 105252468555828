import { Countries } from '../models/Country.model';

export var countries: Countries [] = [
{ code:"US", code3:"USA", name:"United States of America", number:"1",flag:"US" },
{ code:"UK", code3:"UK", name:"United Kingdom", number:"44",flag:"GB" },
{ code:"IN", code3:"IND", name:"India", number:"91",flag:"IN" },
{ code:"AF", code3:"AFG", name:"Afghanistan", number:"4",flag:"AF"},
{ code:"AL", code3:"ALB", name:"Albania", number:"8",flag:"AL" },
{ code:"DZ", code3:"DZA", name:"Algeria", number:"12",flag:"DZ" },
{ code:"AS", code3:"ASM", name:"American Samoa", number:"16",flag:"AS" },
{ code:"AD", code3:"AND", name:"Andorra", number:"20",flag:"AD" },
{ code:"AO", code3:"AGO", name:"Angola", number:"24",flag:"AO" },
{ code:"AI", code3:"AIA", name:"Anguilla", number:"660",flag:"AI" },
{ code:"AQ", code3:"ATA", name:"Antarctica", number:"10",flag:"AQ" },
{ code:"AG", code3:"ATG", name:"Antigua and Barbuda", number:"28",flag:"AG" },
{ code:"AR", code3:"ARG", name:"Argentina", number:"32",flag:"AR" },
{ code:"AM", code3:"ARM", name:"Armenia", number:"51",flag:"AM" },
{ code:"AW", code3:"ABW", name:"Aruba", number:"533",flag:"AW" },
{ code:"AU", code3:"AUS", name:"Australia", number:"36",flag:"AU" },
{ code:"AT", code3:"AUT", name:"Austria", number:"40",flag:"AT" },
{ code:"AZ", code3:"AZE", name:"Azerbaijan", number:"31",flag:"AZ" },
{ code:"BS", code3:"BHS", name:"Bahamas (the)", number:"44",flag:"BS" },
{ code:"BH", code3:"BHR", name:"Bahrain", number:"48",flag:"BH" },
{ code:"BD", code3:"BGD", name:"Bangladesh", number:"50",flag:"BD" },
{ code:"BB", code3:"BRB", name:"Barbados", number:"52",flag:"BB" },
{ code:"BY", code3:"BLR", name:"Belarus", number:"112",flag:"BY" },
{ code:"BE", code3:"BEL", name:"Belgium", number:"56",flag:"BE" },
{ code:"BZ", code3:"BLZ", name:"Belize", number:"84",flag:"BZ" },
{ code:"BJ", code3:"BEN", name:"Benin", number:"204",flag:"BJ" },
{ code:"BM", code3:"BMU", name:"Bermuda", number:"60",flag:"BM" },
{ code:"BT", code3:"BTN", name:"Bhutan", number:"64",flag:"BT" },
{ code:"BO", code3:"BOL", name:"Bolivia (Plurinational State of)", number:"68",flag:"BO" },
{ code:"BQ", code3:"BES", name:"Bonaire, Sint Eustatius and Saba", number:"535",flag:"BQ" },
{ code:"BA", code3:"BIH", name:"Bosnia and Herzegovina", number:"70",flag:"BA" },
{ code:"BW", code3:"BWA", name:"Botswana", number:"72",flag:"BW" },
{ code:"BV", code3:"BVT", name:"Bouvet Island", number:"74",flag:"BV" },
{ code:"BR", code3:"BRA", name:"Brazil", number:"76",flag:"BR" },
{ code:"IO", code3:"IOT", name:"British Indian Ocean Territory (the)", number:"86",flag:"IO" },
{ code:"BN", code3:"BRN", name:"Brunei Darussalam", number:"96",flag:"BN" },
{ code:"BG", code3:"BGR", name:"Bulgaria", number:"100",flag:"BG" },
{ code:"BF", code3:"BFA", name:"Burkina Faso", number:"854",flag:"BF" },
{ code:"BI", code3:"BDI", name:"Burundi", number:"108",flag:"BI" },
{ code:"CV", code3:"CPV", name:"Cabo Verde", number:"132",flag:"CV" },
{ code:"KH", code3:"KHM", name:"Cambodia", number:"116",flag:"KH" },
{ code:"CM", code3:"CMR", name:"Cameroon", number:"120",flag:"CM" },
{ code:"CA", code3:"CAN", name:"Canada", number:"124",flag:"CA" },
{ code:"KY", code3:"CYM", name:"Cayman Islands (the)", number:"136",flag:"KY" },
{ code:"CF", code3:"CAF", name:"Central African Republic (the)", number:"140",flag:"CF" },
{ code:"TD", code3:"TCD", name:"Chad", number:"148",flag:"TD" },
{ code:"CL", code3:"CHL", name:"Chile", number:"152",flag:"CL" },
{ code:"CN", code3:"CHN", name:"China", number:"156",flag:"CN" },
{ code:"CX", code3:"CXR", name:"Christmas Island", number:"162",flag:"CX" },
{ code:"CC", code3:"CCK", name:"Cocos (Keeling) Islands (the)", number:"166",flag:"CC" },
{ code:"CO", code3:"COL", name:"Colombia", number:"170",flag:"CO" },
{ code:"KM", code3:"COM", name:"Comoros (the)", number:"174",flag:"KM" },
{ code:"CD", code3:"COD", name:"Congo (the Democratic Republic of the)", number:"180",flag:"CD" },
{ code:"CG", code3:"COG", name:"Congo (the)", number:"178",flag:"CG" },
{ code:"CK", code3:"COK", name:"Cook Islands (the)", number:"184",flag:"CK" },
{ code:"CR", code3:"CRI", name:"Costa Rica", number:"188",flag:"CR" },
{ code:"HR", code3:"HRV", name:"Croatia", number:"191",flag:"HR" },
{ code:"CU", code3:"CUB", name:"Cuba", number:"192",flag:"CU" },
{ code:"CW", code3:"CUW", name:"Curaçao", number:"531",flag:"CW" },
{ code:"CY", code3:"CYP", name:"Cyprus", number:"196",flag:"CY" },
{ code:"CZ", code3:"CZE", name:"Czechia", number:"203",flag:"CZ" },
{ code:"CI", code3:"CIV", name:"Côte d'Ivoire", number:"384",flag:"CI" },
{ code:"DK", code3:"DNK", name:"Denmark", number:"208",flag:"DK" },
{ code:"DJ", code3:"DJI", name:"Djibouti", number:"262",flag:"DJ" },
{ code:"DM", code3:"DMA", name:"Dominica", number:"212",flag:"DM" },
{ code:"DO", code3:"DOM", name:"Dominican Republic (the)", number:"214",flag:"DO" },
{ code:"EC", code3:"ECU", name:"Ecuador", number:"218",flag:"EC" },
{ code:"EG", code3:"EGY", name:"Egypt", number:"818",flag:"EG" },
{ code:"SV", code3:"SLV", name:"El Salvador", number:"222",flag:"SV" },
{ code:"GQ", code3:"GNQ", name:"Equatorial Guinea", number:"226",flag:"GQ" },
{ code:"ER", code3:"ERI", name:"Eritrea", number:"232",flag:"ER" },
{ code:"EE", code3:"EST", name:"Estonia", number:"233",flag:"EE" },
{ code:"SZ", code3:"SWZ", name:"Eswatini", number:"748",flag:"SZ" },
{ code:"ET", code3:"ETH", name:"Ethiopia", number:"231",flag:"ET" },
{ code:"FK", code3:"FLK", name:"Falkland Islands (the) [Malvinas]", number:"238",flag:"FK" },
{ code:"FO", code3:"FRO", name:"Faroe Islands (the)", number:"234",flag:"FO" },
{ code:"FJ", code3:"FJI", name:"Fiji", number:"242",flag:"FJ" },
{ code:"FI", code3:"FIN", name:"Finland", number:"246",flag:"FI" },
{ code:"FR", code3:"FRA", name:"France", number:"250",flag:"FR" },
{ code:"GF", code3:"GUF", name:"French Guiana", number:"254",flag:"GF" },
{ code:"PF", code3:"PYF", name:"French Polynesia", number:"258",flag:"PF" },
{ code:"TF", code3:"ATF", name:"French Southern Territories (the)", number:"260",flag:"TF" },
{ code:"GA", code3:"GAB", name:"Gabon", number:"266",flag:"GA" },
{ code:"GM", code3:"GMB", name:"Gambia (the)", number:"270",flag:"GM" },
{ code:"GE", code3:"GEO", name:"Georgia", number:"268",flag:"GE" },
{ code:"DE", code3:"DEU", name:"Germany", number:"276",flag:"DE" },
{ code:"GH", code3:"GHA", name:"Ghana", number:"288",flag:"GH" },
{ code:"GI", code3:"GIB", name:"Gibraltar", number:"292",flag:"GI" },
{ code:"GR", code3:"GRC", name:"Greece", number:"300",flag:"GR" },
{ code:"GL", code3:"GRL", name:"Greenland", number:"304",flag:"GL" },
{ code:"GD", code3:"GRD", name:"Grenada", number:"308",flag:"GD" },
{ code:"GP", code3:"GLP", name:"Guadeloupe", number:"312",flag:"GP" },
{ code:"GU", code3:"GUM", name:"Guam", number:"316",flag:"GU" },
{ code:"GT", code3:"GTM", name:"Guatemala", number:"320",flag:"GT" },
{ code:"GG", code3:"GGY", name:"Guernsey", number:"831",flag:"GG" },
{ code:"GN", code3:"GIN", name:"Guinea", number:"324",flag:"GN" },
{ code:"GW", code3:"GNB", name:"Guinea-Bissau", number:"624",flag:"GW" },
{ code:"GY", code3:"GUY", name:"Guyana", number:"328",flag:"GY" },
{ code:"HT", code3:"HTI", name:"Haiti", number:"332",flag:"HT" },
{ code:"HM", code3:"HMD", name:"Heard Island and McDonald Islands", number:"334",flag:"HM" },
{ code:"VA", code3:"VAT", name:"Holy See (the)", number:"336",flag:"VA" },
{ code:"HN", code3:"HND", name:"Honduras", number:"340",flag:"HN" },
{ code:"HK", code3:"HKG", name:"Hong Kong", number:"344",flag:"HK" },
{ code:"HU", code3:"HUN", name:"Hungary", number:"348",flag:"HU" },
{ code:"IS", code3:"ISL", name:"Iceland", number:"352",flag:"IS" },
{ code:"IN", code3:"IND", name:"India", number:"91",flag:"IN" },
{ code:"ID", code3:"IDN", name:"Indonesia", number:"360",flag:"ID" },
{ code:"IR", code3:"IRN", name:"Iran (Islamic Republic of)", number:"364",flag:"IR" },
{ code:"IQ", code3:"IRQ", name:"Iraq", number:"368",flag:"IQ" },
{ code:"IE", code3:"IRL", name:"Ireland", number:"372",flag:"IE" },
{ code:"IM", code3:"IMN", name:"Isle of Man", number:"833",flag:"IM" },
{ code:"IL", code3:"ISR", name:"Israel", number:"376",flag:"IL" },
{ code:"IT", code3:"ITA", name:"Italy", number:"380",flag:"IT" },
{ code:"JM", code3:"JAM", name:"Jamaica", number:"388",flag:"JM" },
{ code:"JP", code3:"JPN", name:"Japan", number:"392",flag:"JP" },
{ code:"JE", code3:"JEY", name:"Jersey", number:"832",flag:"JE" },
{ code:"JO", code3:"JOR", name:"Jordan", number:"400",flag:"JO" },
{ code:"KZ", code3:"KAZ", name:"Kazakhstan", number:"398",flag:"KZ" },
{ code:"KE", code3:"KEN", name:"Kenya", number:"404",flag:"KE" },
{ code:"KI", code3:"KIR", name:"Kiribati", number:"296",flag:"KI" },
{ code:"KP", code3:"PRK", name:"Korea (the Democratic People's Republic of)", number:"408",flag:"KP" },
{ code:"KR", code3:"KOR", name:"Korea (the Republic of)", number:"410",flag:"KR" },
{ code:"KW", code3:"KWT", name:"Kuwait", number:"414",flag:"KW" },
{ code:"KG", code3:"KGZ", name:"Kyrgyzstan", number:"417",flag:"KG" },
{ code:"LA", code3:"LAO", name:"Lao People's Democratic Republic (the)", number:"418",flag:"LA" },
{ code:"LV", code3:"LVA", name:"Latvia", number:"428",flag:"LV" },
{ code:"LB", code3:"LBN", name:"Lebanon", number:"422",flag:"LB" },
{ code:"LS", code3:"LSO", name:"Lesotho", number:"426",flag:"LS" },
{ code:"LR", code3:"LBR", name:"Liberia", number:"430",flag:"LR" },
{ code:"LY", code3:"LBY", name:"Libya", number:"434",flag:"LY" },
{ code:"LI", code3:"LIE", name:"Liechtenstein", number:"438",flag:"LI" },
{ code:"LT", code3:"LTU", name:"Lithuania", number:"440",flag:"LT" },
{ code:"LU", code3:"LUX", name:"Luxembourg", number:"442",flag:"LU" },
{ code:"MO", code3:"MAC", name:"Macao", number:"446",flag:"MO" },
{ code:"MG", code3:"MDG", name:"Madagascar", number:"450",flag:"MG" },
{ code:"MW", code3:"MWI", name:"Malawi", number:"454",flag:"MW" },
{ code:"MY", code3:"MYS", name:"Malaysia", number:"458",flag:"MY" },
{ code:"MV", code3:"MDV", name:"Maldives", number:"462",flag:"MV" },
{ code:"ML", code3:"MLI", name:"Mali", number:"466",flag:"ML" },
{ code:"MT", code3:"MLT", name:"Malta", number:"470",flag:"MT" },
{ code:"MH", code3:"MHL", name:"Marshall Islands (the)", number:"584",flag:"MH" },
{ code:"MQ", code3:"MTQ", name:"Martinique", number:"474",flag:"MQ" },
{ code:"MR", code3:"MRT", name:"Mauritania", number:"478",flag:"MR" },
{ code:"MU", code3:"MUS", name:"Mauritius", number:"480",flag:"MU" },
{ code:"YT", code3:"MYT", name:"Mayotte", number:"175",flag:"YT" },
{ code:"MX", code3:"MEX", name:"Mexico", number:"484",flag:"MX" },
{ code:"FM", code3:"FSM", name:"Micronesia (Federated States of)", number:"583",flag:"FM" },
{ code:"MD", code3:"MDA", name:"Moldova (the Republic of)", number:"498",flag:"MD" },
{ code:"MC", code3:"MCO", name:"Monaco", number:"492",flag:"MC" },
{ code:"MN", code3:"MNG", name:"Mongolia", number:"496",flag:"MN" },
{ code:"ME", code3:"MNE", name:"Montenegro", number:"499",flag:"ME" },
{ code:"MS", code3:"MSR", name:"Montserrat", number:"500",flag:"MS" },
{ code:"MA", code3:"MAR", name:"Morocco", number:"504",flag:"MA" },
{ code:"MZ", code3:"MOZ", name:"Mozambique", number:"508",flag:"MZ" },
{ code:"MM", code3:"MMR", name:"Myanmar", number:"104",flag:"MM" },
{ code:"NA", code3:"NAM", name:"Namibia", number:"516",flag:"NA" },
{ code:"NR", code3:"NRU", name:"Nauru", number:"520",flag:"NR" },
{ code:"NP", code3:"NPL", name:"Nepal", number:"524",flag:"NP" },
{ code:"NL", code3:"NLD", name:"Netherlands (the)", number:"528",flag:"NL" },
{ code:"NC", code3:"NCL", name:"New Caledonia", number:"540",flag:"NC" },
{ code:"NZ", code3:"NZL", name:"New Zealand", number:"554",flag:"NZ" },
{ code:"NI", code3:"NIC", name:"Nicaragua", number:"558",flag:"NI" },
{ code:"NE", code3:"NER", name:"Niger (the)", number:"562",flag:"NE" },
{ code:"NG", code3:"NGA", name:"Nigeria", number:"566",flag:"NG" },
{ code:"NU", code3:"NIU", name:"Niue", number:"570",flag:"NU" },
{ code:"NF", code3:"NFK", name:"Norfolk Island", number:"574",flag:"NF" },
{ code:"MP", code3:"MNP", name:"Northern Mariana Islands (the)", number:"580",flag:"MP" },
{ code:"NO", code3:"NOR", name:"Norway", number:"578",flag:"NO" },
{ code:"OM", code3:"OMN", name:"Oman", number:"512",flag:"OM" },
{ code:"PK", code3:"PAK", name:"Pakistan", number:"586",flag:"PK" },
{ code:"PW", code3:"PLW", name:"Palau", number:"585",flag:"PW" },
{ code:"PS", code3:"PSE", name:"Palestine, State of", number:"275",flag:"PS" },
{ code:"PA", code3:"PAN", name:"Panama", number:"591",flag:"PA" },
{ code:"PG", code3:"PNG", name:"Papua New Guinea", number:"598",flag:"PG" },
{ code:"PY", code3:"PRY", name:"Paraguay", number:"600",flag:"PY" },
{ code:"PE", code3:"PER", name:"Peru", number:"604",flag:"PE" },
{ code:"PH", code3:"PHL", name:"Philippines (the)", number:"608",flag:"PH" },
{ code:"PN", code3:"PCN", name:"Pitcairn", number:"612",flag:"PN" },
{ code:"PL", code3:"POL", name:"Poland", number:"616",flag:"PL" },
{ code:"PT", code3:"PRT", name:"Portugal", number:"620",flag:"PT" },
{ code:"PR", code3:"PRI", name:"Puerto Rico", number:"630",flag:"PR" },
{ code:"QA", code3:"QAT", name:"Qatar", number:"634",flag:"QA" },
{ code:"MK", code3:"MKD", name:"Republic of North Macedonia", number:"807",flag:"MK" },
{ code:"RO", code3:"ROU", name:"Romania", number:"642",flag:"RO" },
{ code:"RU", code3:"RUS", name:"Russian Federation (the)", number:"643",flag:"RU" },
{ code:"RW", code3:"RWA", name:"Rwanda", number:"646",flag:"RW" },
{ code:"RE", code3:"REU", name:"Réunion", number:"638",flag:"RE" },
{ code:"BL", code3:"BLM", name:"Saint Barthélemy", number:"652",flag:"BL" },
{ code:"SH", code3:"SHN", name:"Saint Helena, Ascension and Tristan da Cunha", number:"654",flag:"SH" },
{ code:"KN", code3:"KNA", name:"Saint Kitts and Nevis", number:"659",flag:"KN" },
{ code:"LC", code3:"LCA", name:"Saint Lucia", number:"662",flag:"LC" },
{ code:"MF", code3:"MAF", name:"Saint Martin (French part)", number:"663",flag:"MF" },
{ code:"PM", code3:"SPM", name:"Saint Pierre and Miquelon", number:"666",flag:"PM" },
{ code:"VC", code3:"VCT", name:"Saint Vincent and the Grenadines", number:"670",flag:"VC" },
{ code:"WS", code3:"WSM", name:"Samoa", number:"882",flag:"WS" },
{ code:"SM", code3:"SMR", name:"San Marino", number:"674",flag:"SM" },
{ code:"ST", code3:"STP", name:"Sao Tome and Principe", number:"678",flag:"ST" },
{ code:"SA", code3:"SAU", name:"Saudi Arabia", number:"682",flag:"SA" },
{ code:"SN", code3:"SEN", name:"Senegal", number:"686",flag:"SN" },
{ code:"RS", code3:"SRB", name:"Serbia", number:"688",flag:"RS" },
{ code:"SC", code3:"SYC", name:"Seychelles", number:"690",flag:"SC" },
{ code:"SL", code3:"SLE", name:"Sierra Leone", number:"694",flag:"SL" },
{ code:"SG", code3:"SGP", name:"Singapore", number:"702",flag:"SG" },
{ code:"SX", code3:"SXM", name:"Sint Maarten (Dutch part)", number:"534",flag:"SX" },
{ code:"SK", code3:"SVK", name:"Slovakia", number:"703",flag:"SK" },
{ code:"SI", code3:"SVN", name:"Slovenia", number:"705",flag:"SI" },
{ code:"SB", code3:"SLB", name:"Solomon Islands", number:"90",flag:"SB" },
{ code:"SO", code3:"SOM", name:"Somalia", number:"706",flag:"SO" },
{ code:"ZA", code3:"ZAF", name:"South Africa", number:"710",flag:"ZA" },
{ code:"GS", code3:"SGS", name:"South Georgia and the South Sandwich Islands", number:"239",flag:"GS" },
{ code:"SS", code3:"SSD", name:"South Sudan", number:"728",flag:"SS" },
{ code:"ES", code3:"ESP", name:"Spain", number:"724",flag:"ES" },
{ code:"LK", code3:"LKA", name:"Sri Lanka", number:"144",flag:"LK" },
{ code:"SD", code3:"SDN", name:"Sudan (the)", number:"729",flag:"SD" },
{ code:"SR", code3:"SUR", name:"Suriname", number:"740",flag:"SR" },
{ code:"SJ", code3:"SJM", name:"Svalbard and Jan Mayen", number:"744",flag:"SJ" },
{ code:"SE", code3:"SWE", name:"Sweden", number:"752",flag:"SE" },
{ code:"CH", code3:"CHE", name:"Switzerland", number:"756",flag:"CH" },
{ code:"SY", code3:"SYR", name:"Syrian Arab Republic", number:"760",flag:"SY" },
{ code:"TW", code3:"TWN", name:"Taiwan", number:"158",flag:"TW" },
{ code:"TJ", code3:"TJK", name:"Tajikistan", number:"762",flag:"TJ" },
{ code:"TZ", code3:"TZA", name:"Tanzania, United Republic of", number:"834",flag:"TZ" },
{ code:"TH", code3:"THA", name:"Thailand", number:"764",flag:"TH" },
{ code:"TL", code3:"TLS", name:"Timor-Leste", number:"626",flag:"TL" },
{ code:"TG", code3:"TGO", name:"Togo", number:"768",flag:"TG" },
{ code:"TK", code3:"TKL", name:"Tokelau", number:"772",flag:"TK" },
{ code:"TO", code3:"TON", name:"Tonga", number:"776",flag:"TO" },
{ code:"TT", code3:"TTO", name:"Trinidad and Tobago", number:"780",flag:"TT" },
{ code:"TN", code3:"TUN", name:"Tunisia", number:"788",flag:"TN" },
{ code:"TR", code3:"TUR", name:"Turkey", number:"792",flag:"TR" },
{ code:"TM", code3:"TKM", name:"Turkmenistan", number:"795",flag:"TM" },
{ code:"TC", code3:"TCA", name:"Turks and Caicos Islands (the)", number:"796",flag:"TC" },
{ code:"TV", code3:"TUV", name:"Tuvalu", number:"798",flag:"TV" },
{ code:"UG", code3:"UGA", name:"Uganda", number:"800",flag:"UG" },
{ code:"UA", code3:"UKR", name:"Ukraine", number:"804",flag:"UA" },
{ code:"AE", code3:"ARE", name:"United Arab Emirates (the)", number:"784",flag:"AE" },
{ code:"GB", code3:"GBR", name:"United Kingdom of Great Britain and Northern Ireland (the)", number:"826",flag:"GB" },
{ code:"UM", code3:"UMI", name:"United States Minor Outlying Islands (the)", number:"581",flag:"UM" },
{ code:"US", code3:"USA", name:"United States of America", number:"1",flag:"US" },
{ code:"UY", code3:"URY", name:"Uruguay", number:"858",flag:"UY" },
{ code:"UZ", code3:"UZB", name:"Uzbekistan", number:"860",flag:"UZ" },
{ code:"VU", code3:"VUT", name:"Vanuatu", number:"548",flag:"VU" },
{ code:"VE", code3:"VEN", name:"Venezuela (Bolivarian Republic of)", number:"862",flag:"VE" },
{ code:"VN", code3:"VNM", name:"Viet Nam", number:"704",flag:"VN" },
{ code:"VG", code3:"VGB", name:"Virgin Islands (British)", number:"92",flag:"VG" },
{ code:"VI", code3:"VIR", name:"Virgin Islands (U.S.)", number:"850",flag:"VI" },
{ code:"WF", code3:"WLF", name:"Wallis and Futuna", number:"876",flag:"WF" },
{ code:"EH", code3:"ESH", name:"Western Sahara", number:"732",flag:"EH" },
{ code:"YE", code3:"YEM", name:"Yemen", number:"887",flag:"YE" },
{ code:"ZM", code3:"ZMB", name:"Zambia", number:"894",flag:"ZM" },
{ code:"ZW", code3:"ZWE", name:"Zimbabwe", number:"716",flag:"ZW" },
{ code:"AX", code3:"ALA", name:"Åland Islands", number:"248",flag:"AX" }
];