import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AmplizService } from '../../services/ampliz.service';

@Component({
	selector: 'app-hc-save-search-list',
	templateUrl: './hc-save-search-list.component.html',
	styleUrls: ['./hc-save-search-list.component.css']
})
export class HcSaveSearchListComponent implements OnInit {
	@Input() visible: boolean = false;
	@Output() onVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	savedSearchList: Array<any> = [];
	isShowMore: boolean = false;

	count: number = 100;
	offset: number = 0;

	constructor(private amplizService: AmplizService) {}

	ngOnChanges(changes: SimpleChanges) {
		if (this.visible === true) {
			this.getSaveSearchList();
		}
	}

	ngOnInit(): void {
		this.getSaveSearchList();
	}

	showMoreClicked() {
		this.offset += 100;
		this.getSaveSearchList();
	}

	getSaveSearchList() {
		this.amplizService.getSavedSearchList(this.offset, this.count).subscribe((res) => {
			this.savedSearchList.push(...res.saved_search_list);
			this.offset < 100
				? (this.savedSearchList = res.saved_search_list)
				: this.savedSearchList.push(...res.saved_search_list);
			const tempOffset = this.offset == 0 ? this.offset + this.count : this.offset;

			this.isShowMore = res.total_count > tempOffset ? true : false;
		});
	}

	closePanel() {
		this.onVisibleChange.emit(false);
		this.visible = false;
	}
}
