import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-more-fliter-close-dialog',
	templateUrl: './more-fliter-close-dialog.component.html',
	styleUrls: ['./more-fliter-close-dialog.component.css']
})
export class MoreFliterCloseDialogComponent implements OnInit {
	@Output() closeFilter = new EventEmitter();
	@Output() showDialogChange = new EventEmitter();
	@Input() showDialog = false;
	constructor() {}

	ngOnInit(): void {}
	handleCloseDialogBox() {
		this.showDialog = false;
		this.showDialogChange.emit(this.showDialog);
	}
	handleCloseFilter() {
		this.closeFilter.emit();
	}
}
