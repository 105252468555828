import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { Imaging } from 'src/app/modules/ImagingCenter/models/ImagingModel';
import { SearchImagingExecutivesModel } from 'src/app/modules/ImagingCenter/models/SearchImagingExecutivesModel';
import { SearchImagingModel } from 'src/app/modules/ImagingCenter/models/SearchImagingModel';
import { ImagingDataService } from 'src/app/modules/ImagingCenter/services/imaging-data.service';
import { ImagingService } from 'src/app/modules/ImagingCenter/services/imaging.service';
import { SearchPhysicianExecutive } from '../../models/search-physician-executive';
import { DataService } from '../../service/data.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DataService as HcDataService } from 'src/app/modules/healthcare/services/data.service';

@Component({
	selector: 'app-physician-group-center-card',
	templateUrl: './physician-group-center-card.component.html',
	styleUrls: ['./physician-group-center-card.component.css']
})
export class PhysicianGroupCenterCardComponent implements OnInit {
	@Input() imagingInfo: any;
	b2bSaveDrawer: boolean = false;
	showMore: boolean = true;
	sliceLength: number = 5;
	industryAndSkillset: Array<string> = [];
	notCorrectReasons: Array<string> = ['Company name', 'Location'];
	@ViewChild('contactPanelTrigger') contactPanelTrigger: MatMenuTrigger;
	smallResolution: boolean = false;
	@Input() checkboxSelected: boolean = false;
	@Input() isSubscribed: boolean = false;
	@Output() checkboxChange = new EventEmitter();
	@Input() checkboxDisabled: boolean = false;
	notCorrectDrawer: boolean = false;

	constructor(
		private dataService: DataService,
		private router: Router,
		private imagingService: ImagingService,
		private loaderService: LoaderService,
		private messageService: MessageService,
		private clipboard: Clipboard,
		private hcDataService: HcDataService
	) {}

	ngOnInit() {
		if (window.innerWidth < 1100) {
			this.smallResolution = true;
		} else {
			this.smallResolution = false;
		}
	}

	openUrl(type) {
		const url = this.imagingInfo[type];
		if (url !== '') {
			window.open('https://' + url, 'popUpWindow');
		}
	}

	doSearchImaging(key: string, skill: any) {
		const imagingObj: SearchPhysicianExecutive = new SearchPhysicianExecutive();
		imagingObj[key] = [skill];
		this.dataService.passSearchPhysicianExecutiveInput(imagingObj);
		this.dataService.changeSelectedTab(1);
	}

	searchPhysician(value: any) {
		this.hcDataService.passPhysicianFromPhysicianGroup(value);
		this.router.navigate(['/physician']);
	}

	cancelBtnClick(value: boolean) {
		this.notCorrectDrawer = value;
	}
	onImagingNameClicked(iv) {
		if (iv.phyGroupCompanyId !== null && iv.phyGroupCompanyId !== undefined) {
			this.router.navigate([]).then((result) => {
				window.open(`physician-group-company/${iv.phyGroupCompanyId}`, '_blank');
			});
			// this.loaderService.display(true);
			// const body = {
			// 	icId: iv.phyGroupCompanyId
			// };
			// this.imagingService.viewImagingCenterFromList(body).subscribe(
			// 	(res) => {
			// 		this.loaderService.display(false);
			// 	},
			// 	(err) => {
			// 		this.loaderService.display(false);
			// 	}
			// );
		}
	}
	get phoneNumber() {
		return this?.imagingInfo?.phoneNumber?.join(',') || '';
	}

	get location() {
		let loc = [];
		if (this.imagingInfo.address != '' && this.imagingInfo.address) {
			loc.push(this.imagingInfo.address);
		}
		if (this.imagingInfo.city != '') {
			loc.push(this.imagingInfo.city);
		}
		if (this.imagingInfo.state != '') {
			loc.push(this.imagingInfo.state);
		}
		if (this.imagingInfo.country != '') {
			loc.push(this.imagingInfo.country);
		}
		return loc.join(', ');
	}
	handleAction(action: any, value?: any) {
		if (action === 'close') {
			this.contactPanelTrigger.closeMenu();
		} else {
			this.clipboard.copy(value);
			this.messageService.display(true, 'Copied!');
		}
	}
}
