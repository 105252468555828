<div class="code_container">
	<div class="row p-4">
		<div class="col-md-3 col-sm-12 p-0 m-0 header_container">
			<h3 class="table_heading"></h3>
		</div>
		<div class="col-md-9 col-sm-12">
			<div class="paginationWrapper">
				<div class="paginationWrapper" *ngIf="totalCount > 0">
					<ul class="pagination">
						<li
							[ngClass]="{
								disabled: pager.currentPage === 1
							}"
						>
							<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
								><i class="fa fa-chevron-left" aria-hidden="true"></i
							></a>
							<a
								*ngIf="pager.currentPage !== 1"
								(click)="onPaginationClick(pager.currentPage - 1)"
								style="border-radius: 5px 0 0 5px !important"
								><i class="fa fa fa-chevron-left" aria-hidden="true"></i
							></a>
						</li>
						<li
							[ngClass]="{
								disabled: pager.currentPage === pager.totalPages
							}"
						>
							<a *ngIf="pager.currentPage === pager.totalPages" style="border-radius: 0 5px 5px 0 !important"
								><i class="fa fa-chevron-right" aria-hidden="true"></i
							></a>
							<a
								*ngIf="pager.currentPage !== pager.totalPages"
								(click)="onPaginationClick(pager.currentPage + 1)"
								style="border-radius: 0 5px 5px 0 !important"
								><i class="fa fa-chevron-right" aria-hidden="true"></i
							></a>
						</li>
					</ul>
					<div class="pull-right paginationInfo">
						{{ offset }}-{{ count }} of
						{{ totalCount }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<ag-grid-angular
		style="width: 100%; height: 1500px; max-height: 600px"
		class="ag-theme-material"
		(gridReady)="onGridReady($event)"
		#agGrid
		[defaultColDef]="defaultColDef"
		[columnDefs]="columnDefs"
		[animateRows]="true"
		(cellClicked)="onCellClicked($event)"
		[sortingOrder]="sortingOrders"
		(firstDataRendered)="onFirstDataRendered($event)"
		[paginationPageSize]="paginationPageSize"
		[frameworkComponents]="frameworkComponents"
	>
	</ag-grid-angular>
</div>
