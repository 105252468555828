<div class="code_container">
	<div class="row p-2">
		<div class="main-header">
			<div class="action-container col-sm-4">
				<div class="group">
					<svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
						<g>
							<path
								d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
							></path>
						</g>
					</svg>
					<input placeholder="Search CPT Code" type="search" class="input" [formControl]="cptSearchControl" matInput/>
				</div>
			</div>
			<div class="col-sm-8">
				<div class="paginationWrapper">
					<div class="paginationWrapper" *ngIf="totalCount > 0">
						<ul class="pagination">
							<li
								[ngClass]="{
									disabled: pager.currentPage === 1
								}"
							>
								<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
									><i class="fa fa-chevron-left" aria-hidden="true"></i
								></a>
								<a
									*ngIf="pager.currentPage !== 1"
									(click)="onPaginationClick(pager.currentPage - 1)"
									style="border-radius: 5px 0 0 5px !important"
									><i class="fa fa fa-chevron-left" aria-hidden="true"></i
								></a>
							</li>
							<li
								[ngClass]="{
									disabled: pager.currentPage === pager.totalPages
								}"
							>
								<a
									*ngIf="pager.currentPage === pager.totalPages"
									style="border-radius: 0 5px 5px 0 !important"
									><i class="fa fa-chevron-right" aria-hidden="true"></i
								></a>
								<a
									*ngIf="pager.currentPage !== pager.totalPages"
									(click)="onPaginationClick(pager.currentPage + 1)"
									style="border-radius: 0 5px 5px 0 !important"
									><i class="fa fa-chevron-right" aria-hidden="true"></i
								></a>
							</li>
						</ul>
						<div class="pull-right paginationInfo">
							{{ offset }}-{{ count }} of
							{{ totalCount }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<ag-grid-angular
			style="width: 100%; height: 500px; padding: 0px 2px"
			class="ag-theme-material"
			(gridReady)="onGridReady($event)"
			#agGrid
			[defaultColDef]="defaultColDef"
			[columnDefs]="columnDefs"
			[animateRows]="true"
			[sortingOrder]="sortingOrders"
			[paginationPageSize]="paginationPageSize"
			[frameworkComponents]="frameworkComponents"
		>
		</ag-grid-angular>
	</div>
</div>
