<div class="btn-group" role="group" aria-label="Contact Info">
  <button
    matTooltip="{{ emailToShow.join('\n') }}"
    type="button"
    class="btn btn-sm email-btn"
  >
    <svg
      viewBox="0 0 24 24"
      width="16"
      height="16"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="css-i6dzq1"
    >
      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
      <polyline points="22,6 12,13 2,6"></polyline>
    </svg>
  </button>

  <button
    *ngIf="phone !== ''"
    [matTooltip]="phone ? phone : 'Phone number not available'"
    type="button"
    class="btn btn-sm phone-btn"
    matTooltipClass="tool-tip"
  >
    <svg
      viewBox="0 0 24 24"
      width="16"
      height="16"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="css-i6dzq1"
    >
      <path
        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
      ></path>
    </svg>
  </button>
  <button
    [matTooltip]="linkedIn ? linkedIn : 'Linkedin ID not available'"
    type="button"
    class="btn btn-sm linkedin-btn"
    matTooltipClass="tool-tip"
  >
    <!-- <img src="src/assets/images/logos_linkedin-icon.svg" /> -->
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5790_4457)">
        <path
          d="M11.0766 11.11H9.15043V8.09344C9.15043 7.37412 9.13758 6.44813 8.14861 6.44813C7.14538 6.44813 6.99187 7.23188 6.99187 8.04108V11.1098H5.06573V4.90656H6.91483V5.7543H6.94073C7.12578 5.43789 7.39319 5.17758 7.71448 5.00112C8.03576 4.82466 8.3989 4.73864 8.7652 4.75224C10.7175 4.75224 11.0774 6.03639 11.0774 7.70699L11.0766 11.11ZM2.8923 4.05867C2.27495 4.05877 1.7744 3.55837 1.7743 2.94102C1.7742 2.32367 2.27454 1.82312 2.89189 1.82302C3.50924 1.82287 4.00979 2.32327 4.00989 2.94061C4.00994 3.23708 3.89223 3.52142 3.68264 3.73109C3.47305 3.94077 3.18876 4.0586 2.8923 4.05867ZM3.85541 11.11H1.9272V4.90656H3.85536V11.11L3.85541 11.11ZM12.0369 0.0341493H0.959309C0.435754 0.0282587 0.00639844 0.447661 0 0.971216V12.095C0.00619531 12.6188 0.4355 13.0386 0.959258 13.0331H12.0369C12.5618 13.0396 12.9927 12.6198 13 12.095V0.970352C12.9925 0.445782 12.5615 0.0264305 12.0369 0.033286"
          fill="#0A66C2"
        />
      </g>
      <defs>
        <clipPath id="clip0_5790_4457">
          <rect width="13" height="13" fill="white" transform="translate(0 0.0332031)" />
        </clipPath>
      </defs>
    </svg>
  </button>
</div>
