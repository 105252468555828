import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Condition for the APIs in which Auth Token is not needed(3rd party APIs)
		if (req.headers.get('Anonymous') !== null) {
			const newHeaders = req.headers.delete('Anonymous');
			const newRequest = req.clone({ headers: newHeaders });
			return next.handle(newRequest);
		} else {
			const userToken = localStorage.getItem('auth_token');
			const modifiedReq = req.clone({
				headers: req.headers.set('Authorization', `Bearer ${userToken}`)
			});
			return next.handle(modifiedReq);
		}
	}
}
