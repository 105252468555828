import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from './modules/B2B/services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Ampliz';
  constructor(
  ) {

  }

  ngOnInit() {
  }

}