<!-- Start content -->
<div class="content-page content-group_1">
	<div class="row col imaging-wrapper">
		<app-clinical-filter
			(onFilterChange)="handleFilterChanged($event)"
			[isSubscribed]="subscribed"
		></app-clinical-filter>
		<div class="physician-content" style="position: relative">
			<app-skeleton-loader *ngIf="showLoader" [loopCount]="10"></app-skeleton-loader>
			<div>
				<app-hc-top-tab [tabItems]="tabItems" [isPrimary]="true" [showLoader]="showLoader">
					<div class="flex1 flex-row">
						<div style="flex: 1"></div>

						<div class="opt selectVal">
							<mat-form-field style="max-width: 50px; border: none; padding-top: 10px">
								<mat-select
									[disabled]="!subscribed"
									[(ngModel)]="selectedFilter.count"
									(selectionChange)="handleRecordsChange()"
								>
									<mat-option [value]="opt" *ngFor="let opt of pageNumberOptions">{{ opt }} </mat-option>
								</mat-select>
								<div *ngIf="!isSubscribed" class="my-1 selectDisabled"></div>
							</mat-form-field>
						</div>

						<!-- pagination part start -->
						<div class="paginationText">
							{{ selectedFilter.offset + 1 }}
							-
							{{
								selectedFilter.offset + selectedFilter.count < totalItemCount
									? selectedFilter.offset + selectedFilter.count
									: totalItemCount
							}}
							of {{ totalItemCount | numberWithCommas }} clinics
						</div>
						<mat-icon
							(click)="decrementPage()"
							class="leftIcon"
							aria-hidden="false"
							aria-label="Example home icon"
							[ngClass]="decrementEnabled ? '' : 'disabled'"
						>
							keyboard_arrow_left</mat-icon
						>
						<mat-icon
							(click)="incrementPage()"
							class="rightIcon"
							aria-hidden="false"
							aria-label="Example home icon"
							[ngClass]="incrementEnabled ? '' : 'disabled'"
						>
							keyboard_arrow_right</mat-icon
						>
					</div>
				</app-hc-top-tab>

				<div class="">
					<div class="physician-content-main" *ngIf="tab == 1">
						<div class="totalCount > 0">
							<div *ngFor="let item of clinicalList.clinicalList; let i = index">
								<app-clinical-card [clinicalData]="item" [dataIndex]="i"></app-clinical-card>
							</div>
						</div>
					</div>
				</div>

				<!-- <div class="search_pagination_wrapper">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="paginationWrapper" *ngIf="totalItemCount > 0">
                <div
                  class="pull-left paginationInfo"
                  *ngIf="totalItemCount > 0">
                  {{ selectedFilter.offset + 1 }}
                  -
                  {{
                    selectedFilter.offset + selectedFilter.count <
                    totalItemCount
                      ? selectedFilter.offset + selectedFilter.count
                      : totalItemCount
                  }}
                  of {{ totalItemCount | numberWithCommas }}
                </div>
                <ul class="pagination" style="float: left !important">
                  <li [ngClass]="{ disabled: decrementEnabled }">
                    <a
                      (click)="decrementPage()"
                      style="border-radius: 5px 0 0 5px !important"
                      ><i class="fa fa fa-chevron-left" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li [ngClass]="incrementEnabled ? '' : 'disabled'">
                    <a
                      (click)="incrementPage()"
                      style="border-radius: 0 5px 5px 0 !important"
                      ><i class="fa fa-chevron-right" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 p-0 m-0 pb-2 pt-3"></div>
          </div>
        </div>
      </div> -->
				<div class="layout h-100" *ngIf="noResult">
					<div class="d-flex justify-content-center align-items-center align-self-center h-100 flex-column">
						<div class="text-center">
							<img src="assets/img/Hospital.png" width="30%" />
						</div>
						<h5 class="text-center pa-4">No results found, Try to search again</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
