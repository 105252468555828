<div class="container">
  <div class="col-md-12 ml-auto">
    
    <div *ngFor="let alertMessage of alertMessages;index as i" [@enterAnimation] class="alert alert-d main-box"
    [ngClass]="{'error-border': alertMessage?.isError,'success-border': !alertMessage?.isError}"
    [ngStyle]="{'top':i*100+25+'px'}"
    >
      <a class="icon-box">
        <i class="bi bi-check2 fa-lg "
        [ngClass]="{'error-icon': alertMessage?.isError,'icon': !alertMessage?.isError}"
        style="margin: 10px 5px 10px 5px"></i>
      </a>
      <div style="display: inline-block;">
        <p style="margin-left: 20px; font-size:15px; letter-spacing: 1px; padding-bottom: 20px; ">
        <b class="success-text" *ngIf="!alertMessage?.isError">Success! </b>
        <b class="error-text" *ngIf="alertMessage?.isError">Alert! </b>
        <small style="font-size: 15px; margin-left:2px;">{{alertMessage?.title}}</small>
        <br/>
        <small>{{alertMessage?.message}} </small>
      </p>
      </div>
      <a
        class="close-icon"
        href="#"
        data-dismiss="alert"
        aria-label="Close"
        (click)="close(i)"
      >
        <span>Close</span>
      </a>
    </div>
  
  </div>
</div>
