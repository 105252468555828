<div class="card column filter-component" style="width: 23rem">
  <div class="people-filter">
    <div class="flex-row filter-header">
      <div>
        <h6 class="card-title titleCardStyle">Showing results for</h6>
      </div>

      <div *ngIf="validateSaveSearch()" class="active-filter-container">
        <!-- clear-filter-btn starts -->
        <app-clear-filter [activeFilterCount]="activeFilterCount" (clearAllFilters)="clearAll()"></app-clear-filter>
        <!-- clear-filter-btn ends -->
        <div class="searchButton" [ngClass]="validateSaveSearch() ? 'primaryBtn' : ''">
          <span (click)="invokeSearchList()"> Save Search </span>
        </div>
      </div>
    </div>
    <div class="solid"></div>
    <app-search-quota *ngIf="searchQuota < 10" [isSubscribed]="isSubscribed" [dailyQuota]="totalQuota.dailySearchQuota" [usedQuota]="totalQuota.dailyUsedQuota"></app-search-quota>
    <div>
      <mat-accordion>
        <!-- Company -->

        <mat-expansion-panel [disabled]="searchQuota <= 0" #panelComp hideToggle>
          <!-- (opened)="triggerAutoFocus('#includeCompanyInput')" -->
          <mat-expansion-panel-header
            [ngClass]="panelComp.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Company</div>
              <div class="sub-header" *ngIf="includedCompanyList.length == 0 && !panelComp.expanded">Company name</div>
              <div class="selected" *ngIf="includedCompanyList.length > 0">
                <mat-chip-list #includeSpecialityList aria-label="Included speciality">
                  <mat-basic-chip *ngIf="includedCompanyList?.length > 0" [ngClass]="'selected'">
                    {{ includedCompanyList[0] }}
                  </mat-basic-chip>
                  <span *ngIf="includedCompanyList?.length > 1" class="additional-selection">
                    + {{ includedCompanyList.length - 1 }} more
                  </span>
                </mat-chip-list>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelComp.expanded ? 'remove' : 'add'
              }}</mat-icon>
              <mat-icon class="filter-title lock-icon" *ngIf="searchQuota <= 0">lock</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <div>
              <mat-form-field class="example-chip-list mt-3">
                <mat-chip-list #includedCompany aria-label="includedCompany">
                  <mat-basic-chip
                    *ngFor="let content of includedCompanyList"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeCompany(content)"
                    class="chip-list"
                  >
                    {{ content }}
                    <mat-icon matChipRemove *ngIf="removable" class="chip-list">close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="Company name"
                    #includeCompanyInput
                    id="#includeCompany"
                    aria-label="Include Speciality"
                    matInput
                    [formControl]="companyListControl"
                    [matAutocomplete]="includeCompanyAuto"
                    [matChipInputFor]="includedCompany"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="includedCompanyList.length > 0"
                  (click)="clearItems($event, 'includedCompanyList')"
                  >close</mat-icon
                >
                <mat-autocomplete
                  #includeCompanyAuto="matAutocomplete"
                  (optionSelected)="selectedCompany($event, 'auto')"
                  class="app-autocomplete"
                >
                  <mat-option style="color: #c2c2c2" *ngIf="recentCompany.length > 0">RECENTLY USED</mat-option>
                  <mat-option *ngFor="let company of recentCompany" [value]="company">
                    <mat-icon style="color: #c2c2c2">access_time</mat-icon>
                    <span *ngIf="company.companyName">{{ company.companyName }}</span>
                    <span *ngIf="!company.companyName">{{ company }}</span>
                  </mat-option>
                  <hr />
                  <mat-option *ngFor="let spec of companyList" [value]="spec">
                    <div class="flex-row">
                      <div style="width: 40px">
                        <img
                          [src]="spec.companyLogo"
                          width="25"
                          style="border-radius: 10px"
                          *ngIf="spec.companyLogo != ''"
                        />
                        <mat-icon style="color: #ccc" *ngIf="spec.companyLogo == ''">business</mat-icon>
                      </div>
                      <div style="flex: 1">
                        {{ spec.companyName }}
                      </div>
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- company end -->

        <!-- Company Keywords panel -->
        <!-- <mat-expansion-panel
          [disabled]="searchQuota <= 0"
          #panelKeyword
          hideToggle
        >
          <mat-expansion-panel-header
            [ngClass]="panelKeyword.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Company Keywords</div>
              <div
                class="sub-header"
                *ngIf="
                  selectedCompanyKeywords.length == 0 && !panelKeyword.expanded
                "
              >
                Select company keywords
              </div>

              <mat-chip-list
                *ngIf="selectedCompanyKeywords.length > 0"
                cdkDropList
                cdkDropListOrientation="horizontal"
              >
                <mat-basic-chip
                  [ngClass]="'selected'"
                  [selectable]="selectable"
                  [removable]="removable"
                  class="mt-1"
                >
                  {{ selectedCompanyKeywords[0] }} 
                </mat-basic-chip>
                <span
                  *ngIf="selectedCountry?.length > 1"
                  class="additional-selection"
                >
                  + {{ selectedCompanyKeywords.length - 1 }} more
                </span>
              </mat-chip-list>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelKeyword.expanded ? "remove" : "add"
              }}</mat-icon>
              <mat-icon
                class="filter-title lock-icon"
                *ngIf="searchQuota <= 0"
                >lock</mat-icon
              >
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <div>
              <mat-form-field class="mt-3">
                <mat-chip-list
                  #companyKeywordChipList
                  aria-label="Country input"
                >
                  <mat-basic-chip
                    *ngFor="let keyword of selectedCompanyKeywords"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeCompanyKeyword(keyword.label)"
                    class="chip-list"
                  >
                    {{ keyword }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="Keywords"
                    #comapanyKeywordInput
                    aria-label="companyKeyword"
                    matInput
                    [formControl]="companyKeywordControl"
                    [matChipInputFor]="companyKeywordChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="companyKeywordSelected($event)"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="selectedCompanyKeywords.length > 0"
                  (click)="removeCompanyKeyword('all')"
                >
                  close</mat-icon
                >

              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel> -->
        <!-- Company Keywords end -->

        <!-- Location panel -->
        <mat-expansion-panel [disabled]="searchQuota <= 0" #panelLoc hideToggle>
          <mat-expansion-panel-header
            [ngClass]="panelLoc.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Location</div>
              <div class="sub-header" *ngIf="selectedCountry.length == 0 && !panelLoc.expanded">Select location</div>

              <mat-chip-list
                *ngIf="selectedCountry.length > 0"
                cdkDropList
                cdkDropListOrientation="horizontal"
                class="mt-1"
              >
                <mat-basic-chip [ngClass]="'selected'" [selectable]="selectable" [removable]="removable">
                  {{ selectedCountry[0].country ? selectedCountry[0].country : selectedCountry[0] }}
                  <!-- <mat-icon (click)="removeCountry()" matChipRemove class="existing-chip-list"
                >close</mat-icon
              > -->
                </mat-basic-chip>
                <span *ngIf="selectedCountry?.length > 1" class="additional-selection">
                  + {{ selectedCountry.length - 1 }} more
                </span>
              </mat-chip-list>
              <!-- <div class="selected" *ngIf="includedSpecialities.length > 0">
            <mat-chip-list
              #includeSpecialityList
              aria-label="Included speciality"
            >
              <mat-basic-chip
                *ngIf="includedSpecialities?.length > 0"
                [ngClass]="'selected'"
              >
                {{ includedSpecialities[0] }}
              </mat-basic-chip>
              <span
                *ngIf="includedSpecialities?.length > 1"
                class="additional-selection"
              >
                + {{ includedSpecialities.length - 1 }} more
              </span>
            </mat-chip-list>
          </div> -->
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{ panelLoc.expanded ? 'remove' : 'add' }}</mat-icon>
              <mat-icon class="filter-title lock-icon" *ngIf="searchQuota <= 0">lock</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <div>
              <mat-form-field class="mt-3">
                <mat-chip-list #countryChipList aria-label="Country input">
                  <mat-basic-chip
                    *ngFor="let country of selectedCountry"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeCountry(country.country)"
                  >
                    {{ country.country ? country.country : country }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="Country"
                    #countryInput
                    aria-label="stateList"
                    matInput
                    [formControl]="countryControl"
                    [matAutocomplete]="countryAuto"
                    floatPlaceholder="never"
                    [matChipInputFor]="countryChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-icon matSuffix class="closeIcon" *ngIf="selectedCountry.length > 0" (click)="removeCountry('all')">
                  close</mat-icon
                >

                <mat-autocomplete
                  #countryAuto="matAutocomplete"
                  (optionSelected)="countrySelected($event)"
                  panelClass="app-autocomplete"
                  class="app-autocomplete"
                >
                  <mat-option *ngFor="let country of countryList" [value]="country">
                    <div class="row align-items-center">
                      <img class="col-2 country-logo" width="10px" [src]="country.flagUrl" />
                      <span class="col-10">{{ country.country }}</span>
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field>
                <mat-chip-list #statesChipList aria-label="State input">
                  <mat-basic-chip
                    *ngFor="let state of selectedStates"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeState(state)"
                  >
                    {{ state.stateFullName ? state.stateFullName : state }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="State"
                    #stateInput
                    aria-label="stateList"
                    matInput
                    [formControl]="stateControl"
                    [matAutocomplete]="stateAuto"
                    floatPlaceholder="never"
                    [matChipInputFor]="statesChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-icon matSuffix class="closeIcon" *ngIf="selectedStates.length > 0" (click)="clearState()"
                  >close
                </mat-icon>

                <mat-autocomplete
                  #stateAuto="matAutocomplete"
                  (optionSelected)="selectStates($event)"
                  panelClass="app-autocomplete"
                  class="app-autocomplete"
                >
                  <mat-option *ngFor="let state of stateList" [value]="state">
                    {{ state.stateFullName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field>
                <mat-chip-list #cityListChip aria-label="city name input">
                  <mat-basic-chip
                    *ngFor="let city of selectedCities"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="onCityDeselect(city)"
                  >
                    {{ city.city ? city.city : city }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="City"
                    #cityInput
                    aria-label="Search city"
                    matInput
                    [formControl]="cityControl"
                    [matAutocomplete]="cityAutoComplete"
                    floatPlaceholder="never"
                    [matChipInputFor]="cityListChip"
                    [matChipInputSeparatorKeyCodes]="[]"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="selectedCities.length > 0"
                  (click)="clearItems($event, 'selectedCities')"
                  >close</mat-icon
                >

                <mat-autocomplete #cityAutoComplete="matAutocomplete" class="app-autocomplete">
                  <mat-option *ngFor="let city of cityList" [value]="city" (click)="onCitySelect(city)">
                    {{ city.city }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Location end -->
        <!-- Industry panel -->

        <mat-expansion-panel [disabled]="searchQuota <= 0" #panelIndustry hideToggle>
          <mat-expansion-panel-header
            [ngClass]="panelIndustry.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Industry</div>
              <div class="sub-header" *ngIf="includedIndustryList.length == 0 && !panelIndustry.expanded">
                Select Industry
              </div>
              <div class="selected" *ngIf="includedIndustryList.length > 0">
                <mat-chip-list #includeSpecialityList aria-label="Included industry">
                  <mat-basic-chip *ngIf="includedIndustryList?.length > 0" [ngClass]="'selected'">
                    {{ includedIndustryList[0] }}
                  </mat-basic-chip>
                  <span *ngIf="includedIndustryList?.length > 1" class="additional-selection">
                    + {{ includedIndustryList.length - 1 }} more
                  </span>
                </mat-chip-list>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelIndustry.expanded ? 'remove' : 'add'
              }}</mat-icon>
              <mat-icon class="filter-title lock-icon" *ngIf="searchQuota <= 0">lock</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <div>
              <mat-form-field class="mt-3">
                <mat-chip-list #includeIndustryList aria-label="Include speciality input">
                  <mat-basic-chip
                    *ngFor="let industry of includedIndustryList"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeIndustry(industry)"
                  >
                    {{ industry }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-basic-chip>
                  <input
                    [placeholder]="'Include industry'"
                    #includeIndustryInput
                    aria-label="Include Industry"
                    matInput
                    [formControl]="includeIndustryControl"
                    [matAutocomplete]="includeIndustryAuto"
                    floatPlaceholder="never"
                    [matChipInputFor]="includeIndustryList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="includedIndustryList.length > 0"
                  (click)="clearItems($event, 'includedIndustryList')"
                  >close</mat-icon
                >
                <mat-autocomplete #includeIndustryAuto="matAutocomplete" class="industryOpt app-autocomplete">
                  <div *ngIf="recentIncludeIndustry.length > 0">
                    <mat-option style="color: #c2c2c2">RECENTLY USED</mat-option>
                    <mat-option
                      class="industryOpt"
                      [disabled]="true"
                      *ngFor="let industry of recentIncludeIndustry"
                      [value]="industry"
                    >
                      <mat-icon style="color: #c2c2c2">access_time</mat-icon>
                      <span (click)="checkIncludeIndustry($event, industry)">
                        {{ industry }}
                      </span>
                    </mat-option>
                    <hr *ngIf="industryList.length > 0" />
                  </div>
                  <mat-option [disabled]="true" *ngFor="let industry of industryList" [value]="industry">
                    <div (click)="checkIncludeIndustry($event, industry)">
                      {{ industry }}
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-chip-list aria-label="Terms selection" #includedTerms>
                <mat-basic-chip
                  *ngFor="let st of selectedIndustryList"
                  class="suggested"
                  [selectable]="true"
                  value="st"
                  [selected]="st"
                >
                  <span class="chip-body"> {{ st }}</span>
                  <span class="chip-add"> +</span>
                  <span class="chip-remove">- </span>
                </mat-basic-chip>
              </mat-chip-list>

              <mat-form-field class="mt-3">
                <mat-chip-list #excludeIndustryList aria-label="Exclude Industry">
                  <mat-basic-chip
                    *ngFor="let industry of excludedIndustryList"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeExcludeIndustry(industry)"
                  >
                    {{ industry }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-basic-chip>

                  <input
                    placeholder="Exclude Industry"
                    #excludeIndustryInput
                    matInput
                    [matChipInputFor]="excludeIndustryList"
                    [formControl]="excludeIndustryControl"
                    [matAutocomplete]="excludeIndustryAuto"
                    floatPlaceholder="never"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="excludedIndustryList.length > 0"
                  (click)="clearItems($event, 'excludedIndustryList')"
                  >close</mat-icon
                >
                <mat-autocomplete #excludeIndustryAuto="matAutocomplete" class="industryOpt app-autocomplete">
                  <div *ngIf="recentExcludeIndustry.length > 0">
                    <mat-option [disabled]="true" style="color: #c2c2c2">RECENTLY USED</mat-option>
                    <mat-option [disabled]="true" *ngFor="let industry of recentExcludeIndustry" [value]="industry">
                      <mat-icon style="color: #c2c2c2">access_time</mat-icon>
                      <span (click)="checkExcludeIndustry($event, industry)">
                        {{ industry }}
                      </span>
                    </mat-option>
                    <hr *ngIf="industryList.length > 0" />
                  </div>
                  <mat-option
                    [disabled]="true"
                    style="cursor: pointer"
                    *ngFor="let industry of industryList"
                    [value]="industry"
                  >
                    <div (click)="checkExcludeIndustry($event, industry)">
                      {{ industry }}
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Industry end -->
        <!-- Employee range panel -->

        <mat-expansion-panel [disabled]="searchQuota <= 0" #panelEmployee hideToggle>
          <mat-expansion-panel-header
            [ngClass]="panelEmployee.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Employee</div>
              <div class="sub-header" *ngIf="includedEmployeeRange.length == 0 && !panelEmployee.expanded">
                Select employee range
              </div>
              <div class="selected" *ngIf="includedEmployeeRange.length > 0">
                <mat-chip-list #includeEmployeeList aria-label="Included Employee">
                  <mat-basic-chip *ngIf="includedEmployeeRange?.length > 0" [ngClass]="'selected'">
                    {{ includedEmployeeRange[0] }}
                  </mat-basic-chip>
                  <span *ngIf="includedEmployeeRange?.length > 1" class="additional-selection">
                    + {{ includedEmployeeRange.length - 1 }} more
                  </span>
                </mat-chip-list>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelEmployee.expanded ? 'remove' : 'add'
              }}</mat-icon>
              <mat-icon class="filter-title lock-icon" *ngIf="searchQuota <= 0">lock</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <mat-form-field class="example-chip-list mt-3">
              <mat-select
                placeholder="Select Employee Range"
                multiple
                (selectionChange)="omitChanges()"
                [(ngModel)]="includedEmployeeRange"
              >
                <mat-select-trigger>
                  <mat-chip-list>
                    <mat-basic-chip
                      (removed)="removeEmployeeRange(i)"
                      *ngFor="let item of includedEmployeeRange; index as i"
                      class="chip-list"
                    >
                      {{ item }}
                      <mat-icon matChipRemove class="chip-list">close</mat-icon>
                    </mat-basic-chip>
                  </mat-chip-list>
                </mat-select-trigger>
                <mat-option [value]="employee" *ngFor="let employee of employeeList">{{ employee }}</mat-option>
              </mat-select>
              <mat-icon
                matSuffix
                class="closeIcon"
                *ngIf="includedEmployeeRange.length > 0"
                (click)="clearItems($event, 'includedEmployeeRange')"
                >close</mat-icon
              >
            </mat-form-field>
          </div>
        </mat-expansion-panel>
        <!-- Employee range end -->
        <!-- Department panel -->

        <mat-expansion-panel [disabled]="searchQuota <= 0" #panelDepartment hideToggle>
          <mat-expansion-panel-header
            [ngClass]="panelDepartment.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Department</div>
              <div class="sub-header" *ngIf="includedDepartmentList.length == 0 && !panelDepartment.expanded">
                Select department
              </div>
              <div class="selected" *ngIf="includedDepartmentList.length > 0">
                <mat-chip-list #includeSpecialityList aria-label="Included department">
                  <mat-basic-chip *ngIf="includedDepartmentList?.length > 0" [ngClass]="'selected'">
                    {{ includedDepartmentList[0] }}
                  </mat-basic-chip>
                  <span *ngIf="includedDepartmentList?.length > 1" class="additional-selection">
                    + {{ includedDepartmentList.length - 1 }} more
                  </span>
                </mat-chip-list>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelDepartment.expanded ? 'remove' : 'add'
              }}</mat-icon>
              <mat-icon class="filter-title lock-icon" *ngIf="searchQuota <= 0">lock</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <div>
              <mat-form-field class="example-chip-list mt-3">
                <mat-chip-list #includedDepartment aria-label="includedDepartment">
                  <mat-basic-chip
                    *ngFor="let content of includedDepartmentList"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeDepartment(content)"
                    class="chip-list"
                  >
                    {{ content }}
                    <mat-icon matChipRemove *ngIf="removable" class="chip-list">close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="Department name"
                    #includeDepartmentInput
                    aria-label="Include Department"
                    matInput
                    [formControl]="departmentListControl"
                    [matAutocomplete]="includeDepartmentAuto"
                    [matChipInputFor]="includedDepartment"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="includedDepartmentList.length > 0"
                  (click)="clearItems($event, 'includedDepartmentList')"
                  >close</mat-icon
                >
                <mat-autocomplete #includeDepartmentAuto="matAutocomplete" class="industryOpt app-autocomplete">
                  <div *ngIf="recentDepartment.length > 0">
                    <mat-option [disabled]="true" style="color: #c2c2c2">RECENTLY USED</mat-option>
                    <mat-option [disabled]="true" *ngFor="let department of recentDepartment" [value]="department">
                      <mat-icon style="color: #c2c2c2">access_time</mat-icon>
                      <span (click)="checkDepartmentOption($event, department)">
                        {{ department }}
                      </span>
                    </mat-option>
                    <hr *ngIf="departmentList.length > 0" />
                  </div>
                  <mat-option [disabled]="true" *ngFor="let department of departmentList" [value]="department">
                    <div (click)="checkDepartmentOption($event, department)">
                      {{ department }}
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Department end -->

        <!-- Titles Expansion panel -->

        <mat-expansion-panel [disabled]="searchQuota <= 0" #panelTitle hideToggle>
          <mat-expansion-panel-header
            [ngClass]="panelTitle.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Title</div>
              <div class="sub-header" *ngIf="includedTitleList.length == 0 && !panelTitle.expanded">Select titles</div>

              <div class="selected" *ngIf="includedTitleList.length > 0">
                <mat-chip-list #includeSpecialityList aria-label="Included title">
                  <mat-basic-chip *ngIf="includedTitleList?.length > 0" [ngClass]="'selected'">
                    {{ includedTitleList[0] }}
                  </mat-basic-chip>
                  <span *ngIf="includedTitleList?.length > 1" class="additional-selection">
                    + {{ includedTitleList.length - 1 }} more
                  </span>
                </mat-chip-list>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelTitle.expanded ? 'remove' : 'add'
              }}</mat-icon>
              <mat-icon class="filter-title lock-icon" *ngIf="searchQuota <= 0">lock</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <div>
              <!-- <span class="form-label">Include Title</span> -->
              <mat-form-field>
                <mat-chip-list #includeTitleList aria-label="Include title">
                  <mat-basic-chip
                    *ngFor="let title of includedTitleList"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeTitle(title)"
                  >
                    {{ title }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="Include Title"
                    (focus)="titleList = []"
                    #includeTitleInput
                    aria-label="Include Title"
                    matInput
                    [formControl]="includeTitleControl"
                    [matAutocomplete]="includeTitleAuto"
                    [matChipInputFor]="includeTitleList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="includedTitleList.length > 0"
                  (click)="clearItems($event, 'includedTitleList')"
                  >close</mat-icon
                >
                <mat-autocomplete
                  #includeTitleAuto="matAutocomplete"
                  (optionSelected)="includeTitle($event, 'auto')"
                  class="app-autocomplete"
                >
                  <div *ngIf="recentIncludeTitle.length > 0">
                    <mat-option style="color: #c2c2c2">RECENTLY USED</mat-option>
                    <mat-option *ngFor="let title of recentIncludeTitle" [value]="title">
                      <mat-icon style="color: #c2c2c2">access_time</mat-icon>
                      {{ title }}
                    </mat-option>
                    <hr *ngIf="titleList.length > 0" />
                  </div>
                  <mat-option *ngFor="let spec of titleList" [value]="spec">
                    {{ spec }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-chip-list aria-label="Terms selection" #includedTerms>
                <mat-basic-chip
                  *ngFor="let st of similarTitleList"
                  class="suggested"
                  [selectable]="true"
                  value="st"
                  [selected]="st"
                >
                  <span class="chip-body"> {{ st }}</span>
                  <span class="chip-add"> +</span>
                  <span class="chip-remove">- </span>
                </mat-basic-chip>
              </mat-chip-list>

              <mat-form-field class="mt-3">
                <mat-chip-list #excludeTitleList aria-label="Exclude title">
                  <mat-basic-chip
                    *ngFor="let title of excludedTitleList"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeExcludeTitle(title)"
                  >
                    {{ title }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-basic-chip>

                  <input
                    placeholder="Exclude Title"
                    (focus)="titleList = []"
                    #excludeTitleInput
                    matInput
                    [matChipInputFor]="excludeTitleList"
                    [formControl]="excludeTitleControl"
                    [matAutocomplete]="excludeTitleAuto"
                    floatPlaceholder="never"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="excludedTitleList.length > 0"
                  (click)="clearItems($event, 'excludedTitleList')"
                  >close</mat-icon
                >
                <mat-autocomplete
                  #excludeTitleAuto="matAutocomplete"
                  (optionSelected)="excludeTitle($event, 'auto')"
                  class="app-autocomplete"
                >
                  <div *ngIf="recentExcludeTitle.length > 0">
                    <mat-option style="color: #c2c2c2">RECENTLY USED</mat-option>
                    <mat-option *ngFor="let title of recentExcludeTitle" [value]="title">
                      <mat-icon style="color: #c2c2c2">access_time</mat-icon>
                      {{ title }}
                    </mat-option>
                    <hr *ngIf="titleList.length > 0" />
                  </div>
                  <mat-option *ngFor="let title of titleList" [value]="title">
                    {{ title }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Title end -->

        <!-- Seniority panel -->

        <mat-expansion-panel [disabled]="searchQuota <= 0" #panelSeniority hideToggle>
          <mat-expansion-panel-header
            [ngClass]="panelSeniority.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Seniority</div>
              <div class="sub-header" *ngIf="includedSeniorityList.length == 0 && !panelSeniority.expanded">
                Select seniority level
              </div>
              <div class="selected" *ngIf="includedSeniorityList.length > 0">
                <mat-chip-list #includeSpecialityList aria-label="Included seniority">
                  <mat-basic-chip *ngIf="includedSeniorityList?.length > 0" [ngClass]="'selected'">
                    {{ includedSeniorityList[0] }}
                  </mat-basic-chip>
                  <span *ngIf="includedSeniorityList?.length > 1" class="additional-selection">
                    + {{ includedSeniorityList.length - 1 }} more
                  </span>
                </mat-chip-list>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelSeniority.expanded ? 'remove' : 'add'
              }}</mat-icon>
              <mat-icon class="filter-title lock-icon" *ngIf="searchQuota <= 0">lock</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <div>
              <div>
                <mat-form-field class="example-chip-list mt-3">
                  <mat-select
                    placeholder="Select Seniority"
                    multiple
                    (selectionChange)="omitChanges()"
                    [(ngModel)]="includedSeniorityList"
                    [compareWith]="compareObjects"
                  >
                    <mat-select-trigger>
                      <mat-basic-chip
                        (removed)="removeSeniority(item)"
                        *ngFor="let item of includedSeniorityList; index as i"
                        class="chip-list"
                      >
                        {{ item }}
                        <mat-icon matChipRemove class="chip-list">close</mat-icon>
                      </mat-basic-chip>
                    </mat-select-trigger>
                    <mat-option [value]="seniority" *ngFor="let seniority of seniorityList">{{ seniority }}</mat-option>
                  </mat-select>
                  <mat-icon
                    matSuffix
                    class="closeIcon"
                    *ngIf="includedSeniorityList.length > 0"
                    (click)="clearItems($event, 'includedSeniorityList')"
                    >close</mat-icon
                  >
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Seniority end -->
        <!-- Skill panel -->

        <mat-expansion-panel [disabled]="searchQuota <= 0" #panelSkill hideToggle>
          <mat-expansion-panel-header
            [ngClass]="panelSkill.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Skill</div>
              <div class="sub-header" *ngIf="includedSkillList.length == 0 && !panelSkill.expanded">Select skill</div>
              <div class="selected" *ngIf="includedSkillList.length > 0">
                <mat-chip-list #includeSkillList aria-label="Included Skill">
                  <mat-basic-chip *ngIf="includedSkillList?.length > 0" [ngClass]="'selected'">
                    {{ includedSkillList[0] }}
                  </mat-basic-chip>
                  <span *ngIf="includedSkillList?.length > 1" class="additional-selection">
                    + {{ includedSkillList.length - 1 }} more
                  </span>
                </mat-chip-list>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelSkill.expanded ? 'remove' : 'add'
              }}</mat-icon>
              <mat-icon class="filter-title lock-icon" *ngIf="searchQuota <= 0">lock</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <div>
              <mat-form-field class="example-chip-list mt-3">
                <mat-chip-list #includedSkill aria-label="includedSkill">
                  <mat-basic-chip
                    *ngFor="let content of includedSkillList"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeSkill(content)"
                    class="chip-list"
                  >
                    {{ content }}
                    <mat-icon matChipRemove *ngIf="removable" class="chip-list">close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="Include Skill"
                    #includeSkillInput
                    aria-label="Include Skill"
                    matInput
                    [formControl]="skillListControl"
                    [matAutocomplete]="includeSkillAuto"
                    [matChipInputFor]="includedSkill"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="includedSkillList.length > 0"
                  (click)="clearItems($event, 'includedSkillList')"
                  >close</mat-icon
                >
                <mat-autocomplete
                  #includeSkillAuto="matAutocomplete"
                  (optionSelected)="includeSkill($event, 'auto')"
                  class="app-autocomplete"
                >
                  <div *ngIf="recentIncludeSkill.length > 0">
                    <mat-option style="color: #c2c2c2">RECENTLY USED</mat-option>
                    <mat-option *ngFor="let skill of recentIncludeSkill" [value]="skill">
                      <mat-icon style="color: #c2c2c2">access_time</mat-icon>
                      {{ skill }}
                    </mat-option>
                    <hr *ngIf="skillList.length > 0" />
                  </div>
                  <mat-option *ngFor="let spec of skillList" [value]="spec">
                    {{ spec }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field class="example-chip-list mt-3">
                <mat-chip-list #excludedSkill aria-label="excludedSkill">
                  <mat-basic-chip
                    *ngFor="let content of excludedSkillList"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeExcludeSkill(content)"
                    class="chip-list"
                  >
                    {{ content }}
                    <mat-icon matChipRemove *ngIf="removable" class="chip-list">close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="Exclude Skill"
                    #excludeSkillInput
                    aria-label="Exclude Skill"
                    matInput
                    [formControl]="excludeSkillListControl"
                    [matAutocomplete]="excludeSkillAuto"
                    [matChipInputFor]="excludedSkill"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                  <mat-icon
                    matSuffix
                    class="closeIcon"
                    *ngIf="excludedSkillList.length > 0"
                    (click)="clearItems($event, 'excludedSkillList')"
                    >close</mat-icon
                  >
                </mat-chip-list>
                <mat-autocomplete
                  #excludeSkillAuto="matAutocomplete"
                  (optionSelected)="excludeSkill($event, 'auto')"
                  class="app-autocomplete"
                >
                  <div *ngIf="recentExcludeSkill.length > 0">
                    <mat-option style="color: #c2c2c2">RECENTLY USED</mat-option>
                    <mat-option *ngFor="let skill of recentExcludeSkill" [value]="skill">
                      <mat-icon style="color: #c2c2c2">access_time</mat-icon>
                      {{ skill }}
                    </mat-option>
                    <hr *ngIf="skillList.length > 0" />
                  </div>
                  <mat-option *ngFor="let spec of skillList" [value]="spec">
                    {{ spec }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Skill end -->

        <!-- Full name -->

        <mat-expansion-panel [disabled]="searchQuota <= 0" #panelName hideToggle>
          <mat-expansion-panel-header
            [ngClass]="panelName.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Full Name</div>
              <div class="sub-header" *ngIf="includedContactsList.length == 0 && !panelName.expanded">Contact name</div>
              <div class="selected" *ngIf="includedContactsList.length > 0">
                <mat-chip-list #includeSpecialityList aria-label="Included speciality">
                  <mat-basic-chip *ngIf="includedContactsList?.length > 0" [ngClass]="'selected'">
                    {{ includedContactsList[0] }}
                  </mat-basic-chip>
                  <span *ngIf="includedContactsList?.length > 1" class="additional-selection">
                    + {{ includedContactsList.length - 1 }} more
                  </span>
                </mat-chip-list>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelName.expanded ? 'remove' : 'add'
              }}</mat-icon>
              <mat-icon class="filter-title lock-icon" *ngIf="searchQuota <= 0">lock</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <div>
              <mat-form-field class="example-chip-list mt-3">
                <mat-chip-list #includedContact aria-label="includedContact">
                  <mat-basic-chip
                    *ngFor="let content of includedContactsList"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeContact(content)"
                    class="chip-list"
                  >
                    {{ content }}
                    <mat-icon matChipRemove *ngIf="removable" class="chip-list">close</mat-icon>
                  </mat-basic-chip>
                  <input
                    placeholder="Full Name"
                    #includeContactInput
                    aria-label="Include Contact"
                    matInput
                    [formControl]="contactListControl"
                    [matAutocomplete]="includeContactAuto"
                    [matChipInputFor]="includedContact"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="selectedContact($event)"
                  />
                </mat-chip-list>
                <mat-icon
                  matSuffix
                  class="closeIcon"
                  *ngIf="includedContactsList.length > 0"
                  (click)="clearItems($event, 'includedContactsList')"
                  >close</mat-icon
                >
                <mat-autocomplete
                  #includeContactAuto="matAutocomplete"
                  (optionSelected)="selectedContact($event, 'auto')"
                  class="app-autocomplete"
                >
                  <mat-option style="color: #c2c2c2" *ngIf="recentContactsList.length > 0">RECENTLY USED</mat-option>
                  <mat-option *ngFor="let contact of recentContactsList" [value]="contact">
                    <mat-icon style="color: #c2c2c2">access_time</mat-icon>
                    {{ contact }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Full name end -->
        <!-- Revenue panel -->

        <!-- <mat-expansion-panel
          [disabled]="searchQuota <= 0"
          #panelRevenue
          hideToggle
        >
          <mat-expansion-panel-header
            [ngClass]="panelRevenue.expanded ? 'expanded' : ''"
            [collapsedHeight]="'70px'"
            [expandedHeight]="'70px'"
          >
            <mat-panel-title>
              <div class="mat-people-title">Revenue</div>
              <div
                class="sub-header"
                *ngIf="includedRevenueRange.length == 0 && !panelRevenue.expanded"
              >
                Select revenue range
              </div>
              <div
                class="selected"
                *ngIf="
                  includedRevenueRange.length > 0 && includedRevenueRange != null
                "
              >
                <mat-chip-list
                  #includeEmployeeList
                  aria-label="Included Revenue"
                >
                  <mat-basic-chip
                    *ngIf="includedRevenueRange.length > 0"
                    [ngClass]="'selected'"
                  >
                    {{ includedRevenueRange[0] }}
                  </mat-basic-chip>
                   <span
                    *ngIf="includedRevenueRange?.length > 1"
                    class="additional-selection"
                  >
                    + {{ includedRevenueRange.length - 1 }} more
                  </span>
                </mat-chip-list>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon *ngIf="searchQuota > 0" class="matrl-plus">{{
                panelRevenue.expanded ? "remove" : "add"
              }}</mat-icon>
              <mat-icon
                class="filter-title lock-icon"
                *ngIf="searchQuota <= 0"
                >lock</mat-icon
              >
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <mat-form-field class="example-chip-list mt-3">
              <mat-select
                placeholder="Selecte Revenue Range"
                multiple
                (selectionChange)="omitChanges()"
                [(ngModel)]="includedRevenueRange"
              >
                <mat-select-trigger>
                  
                  <mat-basic-chip
                    *ngFor="let item of includedRevenueRange; index as i"
                    (removed)="removeRevenue(item)"
                    class="chip-list"
                  >
                    {{ item }}
                    <mat-icon
                      *ngIf="includedRevenueRange.length > 0"
                      matChipRemove
                      class="chip-list"
                      >close</mat-icon
                    >
                  </mat-basic-chip>
                </mat-select-trigger>
                <mat-option
                  [value]="revenue"
                  *ngFor="let revenue of revenueList"
                  >{{ revenue }}</mat-option
                >
              </mat-select>
              <mat-icon
                matSuffix
                class="closeIcon"
                *ngIf="includedRevenueRange.length > 0"
                (click)="clearItems($event, 'includedRevenueRange', 'array')"
                >close</mat-icon
              >
            </mat-form-field>
          </div> -->
        <!-- <div class="chipContainerRevenue"></div> -->
        <!-- <mat-card class="revenueCard">
          <div class="flex-row">
            <mat-form-field appearance="outline" class="matFormField">
              <mat-select
              class="revenueSelect"
                (selectionChange)="omitChanges()"
                [(value)]="includedRevenueRange"
                placeholder="$MIN"
              >
                <mat-option
                  [value]="revenue"
                  *ngFor="let revenue of revenueList"
                  >{{ revenue }}</mat-option
                >
              </mat-select>
             
            </mat-form-field>
            <div>To</div>
            <mat-form-field appearance="outline" class="matFormField">
              <mat-select
              class="revenueSelect"
                (selectionChange)="omitChanges()"
                [(value)]="includedRevenueRange"
                placeholder="$MIN"
              >
                <mat-option
                  [value]="revenue"
                  *ngFor="let revenue of revenueList"
                  >{{ revenue }}</mat-option
                >
              </mat-select>
             
            </mat-form-field>
          </div>
          <div style="text-align: center;" class="mt-5">
          <button class="applyButton activeApplyButton">Apply</button>
          </div>
        </mat-card> -->
        <!-- </mat-expansion-panel> -->
      </mat-accordion>
    </div>
  </div>
</div>
<app-save-search-list
  (searchSaved)="handleSearchSave()"
  [searchCred]="searchContactInput"
  [searchListDrawer]="searchListDrawer"
  (cancelBtnClick)="cancelBtnClick($event)"
  *ngIf="searchListDrawer"
  (cancelBtnClick)="cancelBtnClick($event)"
>
</app-save-search-list>
