<div class="npi-list">
  <header>
    <span>Custom data enrichment services available! </span>
    <span
      >Let us complete and correct all your records for you — so you always get
      the right data for the right person, now Talk to us
    </span>
    <div class="btn_enrich" (click)="onButtonClick()">
      Book a session now to enrich your data
    </div>
    <img src="/assets/img/doctor.png" />
  </header>
</div>
