import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { PayorService } from '../../service/payor.service';

@Component({
	selector: 'app-clinic-not-correct',
	templateUrl: './clinic-not-correct.component.html',
	styleUrls: ['./clinic-not-correct.component.css'],
	animations: [
		trigger('enterAnimation', [
			transition(':enter', [
				style({ transform: 'translateX(100%)', opacity: 0 }),
				animate('300ms', style({ transform: 'translateX(0)', opacity: 1 }))
			]),
			transition(':leave', [
				style({ transform: 'translateX(0)', opacity: 1 }),
				animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 }))
			])
		])
	]
})
export class ClinicNotCorrectComponent implements OnInit {
	showOtherTextField: boolean = false;
	@Input() notCorrectReasons: Array<any> = ['Clinic Name', 'Location', 'Phone', 'Web Address'];
	selectedReason: string = '';
	@Input() imagingId: any = '';
	@Input() notCorrectType: any = 'clinic';
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
	constructor(
		private payorService: PayorService,
		private loaderServe: LoaderService,
		private messageService: MessageService
	) {}
	ngOnInit() {}

	otherClicked(event) {
		event.stopPropagation();
		this.showOtherTextField = true;
		this.selectedReason = '';
	}
	matMenuOpened() {
		this.showOtherTextField = false;
	}
	selectReason(reason: any) {
		if (reason !== '') {
			this.menuTrigger.closeMenu();
			this.loaderServe.display(true);
			this.selectedReason = reason;
			if (this.notCorrectType === 'clinic-executive') {
				this.reportDataNotCorrectForExecutive(reason);
			} else {
				this.reportDataNotCorrectForImaging(reason);
			}
		}
	}

	reportDataNotCorrectForImaging(reason) {
		const body = {
			incorrectDataList: [reason],
			clinicId: this.imagingId
		};
		this.payorService.reportDataNotCorrectForClinic(body).subscribe(
			(res) => {
				this.loaderServe.display(false);
				this.messageService.display(true, 'Updated the information');
			},
			(err) => {
				this.loaderServe.display(false);
				this.messageService.displayError(true, 'Error');
			}
		);
	}

	reportDataNotCorrectForExecutive(reason) {
		const body = {
			incorrectDataList: [reason],
			clinicExecutiveId: this.imagingId
		};
		this.payorService.reportDataNotCorrectForClinicExecutive(body).subscribe(
			(res) => {
				this.loaderServe.display(false);
				this.messageService.display(true, 'Updated the information');
			},
			(err) => {
				this.loaderServe.display(false);
				this.messageService.displayError(true, 'Error');
			}
		);
	}
}
