<div class="card column filter-component" style="width: 23rem">
	<div class="flex-row header">
		<div>
			<h6 class="card-title titleCardStyle">Showing results for</h6>
		</div>

		<span (click)="clearAll()" class="clearAll" style="cursor: pointer">Clear</span>
	</div>
	<mat-accordion>
		<app-expand-item title="Name" placeholder="Person name" [selectedData]="name" [isPaid]="false">
			<mat-form-field>
				<input
					id="physicianNameInput"
					#physicianNameInput
					matInput
					value="{{ filterData.fullName }}"
					(keyup)="addPersonName($event)"
				/>
			</mat-form-field>
		</app-expand-item>
		<app-expand-item
			class="dialog-flex-container"
			title="Hospital"
			placeholder="Hospital name, Firm type, Hospital classification"
			[selectedData]="HospitalExpandData"
			[isPaid]="isPaid"
		>
			<app-keyword-input
				title="Hospital Name"
				[storageKey]="'hospitalNameList_executive_Page_filterData'"
				[(selectedData)]="filterData.hospitalNameList"
				(onItemAdd)="omitChange()"
			></app-keyword-input>
			<app-signle-select-drop-down
				title="Select Firm Type"
				[(list)]="firmListData"
				[(selectedData)]="filterData.firmType"
				(onItemSelect)="onFirmChange()"
			></app-signle-select-drop-down>
			<app-select-drop-down
				title="Select Hospital Classification"
				[list]="getListForHospitalClasification"
				[(selectedData)]="filterData.hospitalClassification"
				(onItemSelect)="omitChange()"
			></app-select-drop-down>
			<app-select-drop-down
				title="Select Number of Beds"
				[(list)]="noOfBedList"
				[(selectedData)]="filterData.bedsrange"
				(onItemSelect)="omitChange()"
			></app-select-drop-down>
		</app-expand-item>
		<!-- <app-expand-item
			title="Title"
			placeholder="Enter Title"
			[selectedData]="filterData.titleInclude"
			[isPaid]="isPaid"
		>
			<app-auto-complete-drop-drow
				title="Include title"
				[(list)]="titleListData"
				[(selectedData)]="filterData.titleInclude"
				(onItemSelect)="omitChange()"
				(onChange)="getTitleList($event)"
			></app-auto-complete-drop-drow>
		</app-expand-item> -->

		<app-expand-item
			title="Keyword/Title"
			placeholder="Enter Keywords/Title"
			[isPaid]="isPaid"
			[selectedData]="filterData.keywords"
		>
			<app-keyword-input
				title="Keywords/Title"
				[storageKey]="'keyword_executive_Page_filterData'"
				[(selectedData)]="filterData.keywords"
				(onItemAdd)="omitChange()"
			></app-keyword-input>
		</app-expand-item>
		<app-expand-item
			title="Level"
			[selectedData]="filterData.levelID"
			itemValue="id"
			itemName="level"
			[isPaid]="isPaid"
			placeholder="Select Level"
		>
			<app-select-drop-down
				title="Select Level"
				[(list)]="levelListData"
				[(selectedData)]="filterData.levelID"
				(onItemSelect)="omitChange()"
				[itemName]="'level'"
				[itemValue]="'id'"
				[returnObject]="true"
			></app-select-drop-down>
		</app-expand-item>
		<app-expand-item
			title="Department"
			[selectedData]="filterData.department"
			[isPaid]="isPaid"
			placeholder="Select Department"
		>
			<app-select-drop-down
				title="Select Department"
				[(list)]="departmentListData"
				[(selectedData)]="filterData.department"
				(onItemSelect)="omitChange()"
			></app-select-drop-down>
		</app-expand-item>
		<app-expand-item title="Social Profiles" [isPaid]="isPaid" placeholder="Executives with Socials">
			<div class="toggle-container d-flex align-items-center justify-content-between mt-1">
				<div class="text-grey font-13">LinkedIn</div>
				<div class="toggle-switch">
					<input
						class="toggle-input"
						id="linkedInToggle"
						type="checkbox"
						[(ngModel)]="filterData.linkedinURL"
						(ngModelChange)="omitChange()"
					/>
					<label class="toggle-label" for="linkedInToggle"></label>
				</div>
			</div>
		</app-expand-item>
		<div class="location">
			<app-individual-location-filter
				[countryList]="[]"
				(locationValueChanged)="omitChange()"
				[(selectedStatesFromFilter)]="filterData.stateList"
				[(selectedCitiesFromFilter)]="filterData.city"
				[disabled]="isPaid"
			>
			</app-individual-location-filter>
		</div>
		<app-more-fliter-button
			[isPaid]="!isSubscribed"
			(openMoreFilter)="openMoreFilter()"
		></app-more-fliter-button>
	</mat-accordion>

	<!-- added by Rupesh end-->
</div>
<div class="upgrade-card" (click)="requestPricing()" *ngIf="isPaid">
	<div>
		<div class="blt-background">
			<mat-icon class="mt-ico">bolt</mat-icon>
		</div>
	</div>
	<div style="padding-left: 10px">
		<div class="upgrd-to-unlock">Request to unlock more filters</div>
		<div class="improve-search">Improve your search result with additional filters</div>
	</div>
</div>
<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="recordCountLoader"
		[totalRecords]="moreFilterRecordCount.value"
		(clear)="moreFilterAccordian.closeAll(); clearFilter()"
		(applyFilter)="applyMoreFilters()"
		(closeFilter)="closeDialogBox('mainDialog')"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<app-more-filter-expand-item
					title="Name"
					class="dialog-flex-container"
					[selectedData]="tempName"
				>
					<mat-form-field>
						<input
							id="tempPhysicianNameInput"
							#tempPhysicianNameInput
							matInput
							value="{{ tempDialogFilters.fullName }}"
							(keyup)="addTempPersonName($event)"
						/>
					</mat-form-field>
				</app-more-filter-expand-item>

				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Hospital"
					class="dialog-flex-container"
					[selectedData]="tempHospitalExpandData"
				>
					<app-keyword-input
						title="Hospital Name"
						[storageKey]="'hospitalNameList_executive_Page'"
						[(selectedData)]="tempDialogFilters.hospitalNameList"
						(onItemAdd)="getRecordCount()"
					></app-keyword-input>
					<app-signle-select-drop-down
						title="Select Firm Type"
						[(list)]="firmListData"
						[(selectedData)]="tempDialogFilters.firmType"
						(onItemSelect)="onTempFirmChange()"
					></app-signle-select-drop-down>
					<app-select-drop-down
						title="Select Hospital Classification"
						[list]="getListForHospitalClasification"
						[(selectedData)]="tempDialogFilters.hospitalClassification"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
					<app-select-drop-down
						title="Select Number of Beds"
						[(list)]="noOfBedList"
						[(selectedData)]="tempDialogFilters.bedsrange"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.hospitalNameList"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.firmType"
							(onChange)="getRecordCount()"
							[isSingle]="true"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.hospitalClassification"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.bedsrange"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<!-- <app-more-filter-expand-item
					class="dialog-flex-container"
					title="Title"
					[(selectedData)]="tempDialogFilters.titleInclude"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Include title"
						[(list)]="titleListData"
						[(selectedData)]="tempDialogFilters.titleInclude"
						(onItemSelect)="getRecordCount()"
						(onChange)="getTitleList($event)"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item> -->
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Keywords/Title"
					[(selectedData)]="tempDialogFilters.keywords"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-keyword-input
						title="Keywords/Title"
						[storageKey]="'keyword_executive_Page'"
						[(selectedData)]="tempDialogFilters.keywords"
						(onItemAdd)="getRecordCount()"
					></app-keyword-input>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Level"
					[(selectedData)]="tempDialogFilters.levelID"
					(clear)="clear('levels')"
					[single]="true"
					itemValue="id"
					itemName="level"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Level"
						[(list)]="levelListData"
						[(selectedData)]="tempDialogFilters.levelID"
						(onItemSelect)="getRecordCount()"
						[itemName]="'level'"
						[itemValue]="'id'"
						[returnObject]="true"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Department"
					[(selectedData)]="tempDialogFilters.department"
					(clear)="clear('department')"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Department"
						[(list)]="departmentListData"
						[(selectedData)]="tempDialogFilters.department"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>

				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Social Profiles"
					(omitChange)="getRecordCount()"
				>
					<div class="toggle-container d-flex align-items-center justify-content-between mt-1">
						<div class="text-grey font-13">LinkedIn</div>
						<div class="toggle-switch">
							<input
								class="toggle-input"
								id="linkedInToggle1"
								type="checkbox"
								[(ngModel)]="tempDialogFilters.linkedinURL"
								(ngModelChange)="getRecordCount()"
							/>
							<label class="toggle-label" for="linkedInToggle1"></label>
						</div>
					</div>
				</app-more-filter-expand-item>

				<app-location-filter
					class="dialog-flex-container"
					[countryList]="[]"
					(locationValueChanged)="getRecordCount()"
					[(selectedStatesFromFilter)]="tempDialogFilters.stateList"
					[(selectedCitiesFromFilter)]="tempDialogFilters.city"
				>
				</app-location-filter>
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<app-more-fliter-close-dialog
		[(showDialog)]="showConfirmDialog"
		(closeFilter)="closeDialogBox('mainDialog', true)"
	></app-more-fliter-close-dialog>
</ng-template>
