<ng-container>
	<div class="physican_container card-animate" *ngFor="let item of hospitalList">
		<div class="container">
			<div class="row align-items-center flex-container ml-2">
				<div class="col">
					<h3 class="hospital_name">{{ item?.hospitalName }} </h3>
				</div>

				<!-- <div class="col-3 d-flex align-items-center black-text">
					<i class="fa fa-phone mr-2"></i>
					<span>{{ item?.hospitalPhone[0] }}</span>
				</div> -->

				<div class="col-3 d-flex align-items-center black-text">
					<i class="fa fa-map-marker mr-2" aria-hidden="true"></i>
					<span>{{ item?.city }}, {{ item.state }}</span>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<div class="w-full d-flex justify-content-center py-2" *ngIf="showViewMoreBtn">
	<button class="view-more-btn" (click)="updateOffset()">Show More</button>
</div>
