import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
	selector: 'app-hc-save-search',
	templateUrl: './hc-save-search.component.html',
	styleUrls: ['./hc-save-search.component.css']
})
export class HcSaveSearchComponent implements OnInit {
	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
	searchName: string = '';
	@Output() onSaveSearch: EventEmitter<any> = new EventEmitter();
	constructor() {}

	ngOnInit(): void {
		// document.getElementById('input').focus();
	}
	closeMenu() {
		this.trigger.closeMenu();
	}
	saveSearch() {
		if (this.searchName === '') return;
		this.onSaveSearch.emit(this.searchName);
		this.trigger.closeMenu();
		this.searchName = '';
	}
}
