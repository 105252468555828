<!-- 
<pre>Form values: {{myForm.value | json}}</pre>
<pre>Form values: {{myForm1.value | json}}</pre>
<form [formGroup]="bioSection" (ngSubmit)="callingFunction()">
   <div *ngIf="test" formGroupName="stackDetails">
    <h3>Bio Details
</h3>
<div class="form-group col-md-4">
  <input type="text" class="form"  placeholder="Enter Keyword for filter result" [(ngModel)]="searchedKeyword" [ngModelOptions]="{standalone: true}" >
</div>
	<div  *ngFor="let data of emails | filter:searchedKeyword">
    
        <div class="input-container" (click)="onChange2(data.email)">
		<input type="checkbox" [checked]="data.checked"  [(ngModel)]="data.checked" [ngModelOptions]="{standalone: true}" (change)="onChange(data.email, $event.target.checked)"> 
        <label for="Accounting"  ><span>{{data.email}}</span></label>
        </div>
    </div>
  <br>
  <button (click)="showloc()"> next </button>
   </div>
<div *ngIf="location" formGroupName="stackDetails">
    <h3>Stack Details</h3>
    <div  *ngFor="let data of emails1">
		<input type="checkbox" [(ngModel)]="data.checked" [ngModelOptions]="{standalone: true}" (change)="onChange1(data.email1, $event.target.checked)"> {{data.email1}}<br>
	</div>
      <button (click)="prevtest()"> prev </button>
  <button (click)="showindus()"> next </button>

  </div>
<div *ngIf="industry"  formGroupName="address">
    <h3>Address</h3>
    <label>
      Country:
      <input type="text" formControlName="country">
    </label> <br>
    <label>
      City:
      <input type="text" formControlName="city">
    </label>
  </div>
<button type="submit">Submit Application</button>
</form> -->
<!-- ============================================================== -->
<!-- Start right Content here -->
<!-- ============================================================== -->
<div id="wrapper">
	<app-header [user]="user" style="width: 100%" [elementName]="'idealprofile'"></app-header>{{ user }}
	<app-header *ngIf="!subscribed" [header-data]="headerData" [elementName]="'idealprofile'"></app-header>
	<!-- <app-sidemenu elementName="idealprofile"></app-sidemenu> -->

	<div class="content-page">
		<!-- Start content -->
		<div class="content row">
			<!-- <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div class="card intro-card col d-flex justify-content-center">
                    <span>
                        Lorem Ipsum Lorep Ipsum Lorem Ipsum Lorem Ipsum Lorep Ipsum Lorem Ipsum Lorem Ipsum Lorep Ipsum
                        Lorem IpsumLorem Ipsum Lorep Ipsum Lorem Ipsum
                    </span>
                </div>
                <div class="pull-right">
                    <img src="/assets/images/Custmer-care.png">
                </div>
            </div>
            <div class="col-md-4"></div>
        </div> -->
			<div class="container-fluid">
				<div id="loadme" *ngIf="spinner"></div>

				<!-- <div id="loadme" class="text-center" style="display:block; margin: 0 auto !important;" *ngIf="spinner">
                    <img src="../assets/img/Loader3.gif" />
                </div> -->
				<!-- <div class="centered">
                    <div class="spinner-border text-primary text-center" role="status" *ngIf="spinner">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div> -->
				<!--  BEGIN CONTENT AREA  -->

				<div class="container" *ngIf="filterSelect">
					<div class="layout-top-spacing" id="cancel-row">
						<div id="" class="main-content">
							<div class="onboarding__home__guide-header flex-auto flex flex-row">
								<div class="flex-auto">
									<div id="ember673" class="ember-view">
										<div class="onboarding__home__guide-assistant__circle-backdrop__right o__large">
											<div class="onboarding__home__guide-assistant__speech-bubble__right">
												<div class="t__light-text u__padb__5">Lakshmanan from Ampliz</div>
												<div id="ember675" class="ember-view animated-container">
													<div class="t__h2">
														Hi, <strong>{{ name }}!</strong>
														I’m Lakshmanan from Ampliz.
													</div>

													<div class="t__h2">I’m here to help you find</div>

													<div class="t__h2">your Ideal Customer Profiles. Let's go!</div>
												</div>
											</div>
											<img class="onboarding__home__guide-assistant__asset o__large" src="/assets/images/Lakshman.png" />
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-12 layout-spacing padd-img">
								<div class="statbox bx-shadow widget box box-shadow">
									<section class="02">
										<div class="row no-gutters">
											<div class="col-lg-12 col-md-12 border-icp">
												<div class="pull-left icp-text mb-3">
													<h6>
														<!-- <i class="fa fa-user-circle-o mr-2 icp-fa-icon"
                                                            aria-hidden="true"></i> -->
														Tell us about your Ideal Customer Profile
													</h6>
												</div>
												<div class="pull-right icp-step">
													<p *ngIf="level === false">
														<span class="fnt-wt"
															>{{ remainStep }}
															<!-- <span *ngIf !='deptmnt'>steps</span>  -->
															<span *ngIf="deptmnt === true">step</span>
															<!-- <span [ngClass]="{'my-class': deptmnt===true, 'my-class2':deptmnt === false }">steps/step</span> -->
															<span *ngIf="deptmnt === false">steps</span>
															left </span
														><span class="ml-2">About 2 mins</span>
													</p>
													<p *ngIf="level === true" class="fnt-wt">0 steps left</p>
												</div>
											</div>
											<div class="col-md-12">
												<!-- <progress-bar [progress]=progresValue [color]="'#1bb157'">
                                                </progress-bar> -->
												<mat-progress-bar mode="determinate" [value]="progresValue"></mat-progress-bar>
											</div>

											<div class="col-lg-4 col-md-4">
												<div class="wizard-content-left justify-content-center align-items-center">
													<div
														class="no-resp-image"
														*ngIf="
															ind_data.length == 0 &&
															dept_data.length == 0 &&
															lev_data.length == 0 &&
															emp_data.length == 0 &&
															loc_data.length == 0 &&
															rev_data.length == 0
														"
													>
														<img src="/assets/images/filter.png" class="img-responsive" />
													</div>
													<div
														class="widget-content widget-content-area tags-content"
														[ngClass]="{
															icp_scroll:
																loc_data.length > 0 ||
																ind_data.length > 0 ||
																emp_data.length > 0 ||
																dept_data.length > 0 ||
																lev_data.length > 0
														}"
														id="style-6"
													>
														<h6 *ngIf="loc_data.length > 0" class="mt-2">Location</h6>
														<span *ngFor="let location of loc_data">
															<button class="chips">
																{{ location.name }}<i class="fa fa-times close_icon" (click)="cancelLocation(location)"></i>
															</button>
														</span>

														<h6 *ngIf="ind_data.length > 0">Industry</h6>
														<span *ngFor="let industries of ind_data" class="selected_button">
															<button class="chips">
																{{ industries.name
																}}<i class="fa fa-times close_icon" (click)="cancelIndustry(industries)"></i>
															</button>
														</span>

														<h6 *ngIf="emp_data.length > 0" class="mt-2">Employee Size</h6>
														<span *ngFor="let emp of emp_data">
															<button class="chips">
																{{ emp.name }}<i class="fa fa-times close_icon" (click)="cancelEmp(emp)"></i>
															</button>
														</span>

														<h6 *ngIf="dept_data.length > 0" class="mt-2">Department</h6>
														<span *ngFor="let dept of dept_data">
															<button class="chips">
																{{ dept.name }}<i class="fa fa-times close_icon" (click)="cancelDept(dept)"></i>
															</button>
														</span>

														<!-- <mat-form-field class="example-chip-list">
                                                            <h6 *ngIf="ind_data.length>0">Industry</h6>
                                                            <mat-chip-list #chipList>
                                                                <mat-chip *ngFor="let industries of ind_data"
                                                                    [selectable]="selectable" [removable]="removable"
                                                                    (removed)="removeInd(industries)">
                                                                    {{industries.name}}
                                                                    <mat-icon matChipRemove *ngIf="removable"
                                                                        class="close-icon">close
                                                                    </mat-icon>
                                                                </mat-chip>
                                                            </mat-chip-list>
                                                        </mat-form-field> -->
														<!-- <mat-form-field class="example-chip-list">
                                                            <h6 *ngIf="dept_data.length>0">Department</h6>
                                                            <mat-chip-list #chipList>
                                                                <mat-chip *ngFor="let fruit of dept_data"
                                                                    [selectable]="selectable" [removable]="removable"
                                                                    (removed)="removeDep(fruit)">
                                                                    {{fruit.name}}
                                                                    <mat-icon matChipRemove *ngIf="removable"
                                                                        class="close-icon">close</mat-icon>
                                                                </mat-chip>
                                                            </mat-chip-list>
                                                        </mat-form-field> -->
														<!-- <mat-form-field class="example-chip-list">
                                                            <h6 *ngIf="rev_data.length>0">Revenue </h6>
                                                            <mat-chip-list #chipList>
                                                                <mat-chip *ngFor="let fruit of rev_data"
                                                                    [selectable]="selectable" [removable]="removable"
                                                                    (removed)="removeRev(fruit)">
                                                                    {{fruit.name}}
                                                                    <mat-icon matChipRemove *ngIf="removable"
                                                                        class="close-icon">close</mat-icon>
                                                                </mat-chip>
                                                            </mat-chip-list>
                                                        </mat-form-field> -->
														<h6 *ngIf="lev_data.length > 0" class="mt-2">Seniority</h6>
														<span *ngFor="let seniority of lev_data">
															<button class="chips">
																{{ seniority.name
																}}<i class="fa fa-times close_icon" (click)="cancelSeniority(seniority)"></i>
															</button>
														</span>

														<!-- <mat-form-field class="example-chip-list">
                                                            <h6 *ngIf="lev_data.length>0"> Seniority</h6>
                                                            <mat-chip-list #chipList>
                                                                <mat-chip *ngFor="let fruit of lev_data"
                                                                    [selectable]="selectable" [removable]="removable"
                                                                    (removed)="removeLev(fruit)">
                                                                    {{fruit.name}}
                                                                    <mat-icon matChipRemove *ngIf="removable"
                                                                        class="close-icon">close</mat-icon>
                                                                </mat-chip>
                                                            </mat-chip-list>
                                                        </mat-form-field> -->
														<!-- REVENUE IMPORTANT START -->
														<!-- <h6 *ngIf="rev_data.length>0" class="mt-2">Revenue </h6>
                                                        <span *ngFor="let reven of rev_data">
                                                            <button class="chips">{{reven.name}}<i
                                                                    class="fa fa-times close_icon"
                                                                    (click)="cancelRevenue(reven)"></i></button>
                                                        </span> -->
														<!-- REVENUE IMPORTANT END -->
														<!-- <mat-form-field class="example-chip-list">
                                                            <h6 *ngIf="loc_data.length>0"> Location</h6>
                                                            <mat-chip-list #chipList>
                                                                <mat-chip *ngFor="let fruit of loc_data"
                                                                    [selectable]="selectable" [removable]="removable"
                                                                    (removed)="removeLoc(fruit)">
                                                                    {{fruit.name}}
                                                                    <mat-icon matChipRemove *ngIf="removable"
                                                                        class="close-icon">close</mat-icon>
                                                                </mat-chip>
                                                            </mat-chip-list>
                                                        </mat-form-field> -->
														<!-- <mat-form-field class="example-chip-list">
                                                            <h6 *ngIf="emp_data.length>0">Employee Size</h6>
                                                            <mat-chip-list #chipList>
                                                                <mat-chip *ngFor="let fruit of emp_data"
                                                                    [selectable]="selectable" [removable]="removable"
                                                                    (removed)="removeEmp(fruit)">
                                                                    {{fruit.name}}
                                                                    <mat-icon matChipRemove *ngIf="removable"
                                                                        class="close-icon">close</mat-icon>
                                                                </mat-chip>
                                                            </mat-chip-list>
                                                        </mat-form-field> -->
													</div>
												</div>
											</div>
											<div class="col-lg-8 col-md-8">
												<div class="form-wizard">
													<div *ngIf="level">
														<div
															class="alert alert-danger alert-dismissible fade show"
															role="alert"
															*ngIf="showError && this.levs1.length == 0"
														>
															<button type="button" class="close" data-dismiss="alert" aria-label="Close">
																<span aria-hidden="true">×</span>
															</button>
															<strong><i class="fa fa-info-circle"></i></strong>
															Required
														</div>
													</div>
													<form [formGroup]="bioSection" role="form">
														<div *ngIf="location">
															<fieldset class="wizard-fieldset show">
																<div>
																	<span class="pb-1 text-size">Choose Location</span>
																	<div class="search-input-group-style input-group pull-right mb-3" style="width: 60%">
																		<div class="input-group-prepend">
																			<span class="input-group-text" id="basic-addon1"
																				><svg
																					xmlns="http://www.w3.org/2000/svg"
																					width="24"
																					height="24"
																					viewBox="0
                                          0
                                          24
                                          24"
																					fill="none"
																					stroke="currentColor"
																					stroke-width="2"
																					stroke-linecap="round"
																					stroke-linejoin="round"
																					class="feather feather-search"
																				>
																					<circle cx="11" cy="11" r="8"></circle>
																					<line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg
																			></span>
																		</div>
																		<input
																			type="text"
																			class="form-control"
																			placeholder="Search
                                      Location"
																			aria-label="Username"
																			aria-describedby="basic-addon1"
																			[(ngModel)]="searchedKeyword"
																			[ngModelOptions]="{ standalone: true }"
																		/>
																	</div>
																</div>
																<!-- <p class="pb-2">Number of contacts you want to see a per
                                                                    the Location</p> -->
																<div class="scrollbar" id="style-4">
																	<span *ngFor="let data of location_data | filter : searchedKeyword">
																		<div class="input-container" (click)="onChange7(data.name)">
																			<input
																				type="checkbox"
																				[checked]="data.checked"
																				[(ngModel)]="data.checked"
																				[ngModelOptions]="{ standalone: true }"
																				(change)="onChange(data.email, $event.target.checked)"
																			/>
																			<label
																				><span> <img [src]="data.flag" width="22" height="22" /></span> {{ data.name }}</label
																			>
																		</div>
																	</span>
																</div>
																<button (click)="showindus()" class="form-wizard-next-btn float-right mb-3">Next</button>
																<!-- <div class="form-group">
                                                                    <span (click)="submitData();"
                                                                        class="form-wizard-next-btn float-right mb-3 ml-2">Submit</span>
                                                                    <span (click)="showLocation()"
                                                                        class="form-wizard-previous-btn float-right mb-3">Previous</span>
                                                                </div> -->
																<!-- LOCATION BUTTONS IMPORTANT END -->
																<!-- <div class="form-group">
                                                                    <button (click)="showrev()"
                                                                        [ngStyle]="{'background-color':loc_data.length===0?'#91bef5':'#2d8cff'}"
                                                                        class="form-wizard-next-btn float-right mb-3 ml-2"
                                                                        [disabled]="loc_data.length===0">Next</button>
                                                                    <span (click)="showEmployee();"
                                                                        class="form-wizard-previous-btn float-right mb-3">Previous</span>
                                                                </div> -->
																<!-- LOCATION BUTTONS IMPORTANT END -->
															</fieldset>
														</div>

														<div *ngIf="industry">
															<fieldset class="wizard-fieldset show">
																<div>
																	<span class="pb-1 text-size">Choose Industry</span>
																	<div class="search-input-group-style input-group pull-right mb-3" style="width: 60%">
																		<div class="input-group-prepend">
																			<span class="input-group-text" id="basic-addon1"
																				><svg
																					xmlns="http://www.w3.org/2000/svg"
																					width="24"
																					height="24"
																					viewBox="0
                                          0
                                          24
                                          24"
																					fill="none"
																					stroke="currentColor"
																					stroke-width="2"
																					stroke-linecap="round"
																					stroke-linejoin="round"
																					class="feather feather-search"
																				>
																					<circle cx="11" cy="11" r="8"></circle>
																					<line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg
																			></span>
																		</div>
																		<input
																			type="text"
																			class="form-control"
																			placeholder="Search
                                      Industry"
																			aria-label="Username"
																			aria-describedby="basic-addon1"
																			[(ngModel)]="searchedKeyword"
																			[ngModelOptions]="{ standalone: true }"
																		/>
																	</div>
																</div>

																<!-- <p class="pb-2">Number of contacts you want to see a per
                                                                    the Industry</p> -->
																<div class="scrollbar" id="style-4">
																	<span *ngFor="let data of industry_data | filter : searchedKeyword">
																		<div class="input-container" (click)="onChange2(data.name)">
																			<input
																				type="checkbox"
																				[checked]="data.checked"
																				[(ngModel)]="data.checked"
																				[ngModelOptions]="{ standalone: true }"
																				(change)="onChange(data.email, $event.target.checked)"
																			/>
																			<label
																				><span>{{ data.name }}</span></label
																			>
																		</div>
																	</span>
																</div>

																<a (click)="showempEmptyValues()" class="skip_sec float-left mb-3">Skip</a>
																<button
																	(click)="showemp()"
																	[ngStyle]="{
																		'background-color': ind_data.length === 0 ? '#91bef5' : '#2d8cff'
																	}"
																	class="form-wizard-next-btn float-right mb-3 ml-2"
																	[disabled]="ind_data.length === 0"
																>
																	Next
																</button>
																<span (click)="showloc()" class="form-wizard-previous-btn float-right mb-3">Previous</span>
															</fieldset>
														</div>

														<div *ngIf="emp">
															<fieldset class="wizard-fieldset show">
																<h5 class="pb-1">Choose Employee Size</h5>
																<!-- <p class="pb-2">Number of contacts you want to see a per
                                                                    the Employee Size</p> -->
																<!-- <div class="search-input-group-style input-group pull-right mb-3"
                                                                    style="width:60%;">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"
                                                                            id="basic-addon1"><svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                class="feather feather-search">
                                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                                <line x1="21" y1="21" x2="16.65"
                                                                                    y2="16.65"></line>
                                                                            </svg></span>
                                                                    </div>
                                                                    <input type="text" class="form-control"
                                                                        placeholder="Search Employee Size"
                                                                        aria-label="Username"
                                                                        aria-describedby="basic-addon1"
                                                                        [(ngModel)]="searchedKeyword"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                </div> -->
																<div class="scrollbar" id="style-4">
																	<span *ngFor="let data of employee_data">
																		<div class="input-container" (click)="onChange5(data.name)">
																			<input
																				type="checkbox"
																				[checked]="data.checked"
																				[(ngModel)]="data.checked"
																				[ngModelOptions]="{ standalone: true }"
																				(change)="onChange(data.email, $event.target.checked)"
																			/>
																			<label
																				><span>{{ data.name }}</span></label
																			>
																		</div>
																	</span>
																</div>
																<div class="form-group">
																	<a (click)="showdepEmptyValues()" class="skip_sec float-left mb-3">Skip</a>
																	<button
																		(click)="showdep()"
																		[ngStyle]="{
																			'background-color': emp_data.length === 0 ? '#91bef5' : '#2d8cff'
																		}"
																		class="form-wizard-next-btn float-right mb-3 ml-2"
																		[disabled]="emp_data.length === 0"
																	>
																		Next
																	</button>
																	<span (click)="showindus()" class="form-wizard-previous-btn float-right mb-3">Previous</span>
																</div>
																<p class="text-center blockquote">" Looks like we have just what you need! "</p>
															</fieldset>
														</div>

														<div *ngIf="deptmnt">
															<fieldset class="wizard-fieldset show">
																<div>
																	<span class="pb-1 text-size">Choose Department</span>
																	<div class="search-input-group-style input-group pull-right mb-3" style="width: 60%">
																		<div class="input-group-prepend">
																			<span class="input-group-text" id="basic-addon1"
																				><svg
																					xmlns="http://www.w3.org/2000/svg"
																					width="24"
																					height="24"
																					viewBox="0
                                          0
                                          24
                                          24"
																					fill="none"
																					stroke="currentColor"
																					stroke-width="2"
																					stroke-linecap="round"
																					stroke-linejoin="round"
																					class="feather feather-search"
																				>
																					<circle cx="11" cy="11" r="8"></circle>
																					<line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg
																			></span>
																		</div>
																		<input
																			type="text"
																			class="form-control"
																			placeholder="Search
                                      Department"
																			aria-label="Username"
																			aria-describedby="basic-addon1"
																			[(ngModel)]="searchedKeyword"
																			[ngModelOptions]="{ standalone: true }"
																		/>
																	</div>
																</div>

																<!-- <p class="pb-2">Number of contacts you want to see a per
                                                                    the Department</p> -->
																<div class="scrollbar" id="style-4">
																	<span *ngFor="let data of deptartment_data | filter : searchedKeyword">
																		<div class="input-container" (click)="onChange3(data.name)">
																			<input
																				type="checkbox"
																				[checked]="data.checked"
																				[(ngModel)]="data.checked"
																				[ngModelOptions]="{ standalone: true }"
																				(change)="onChange(data.email, $event.target.checked)"
																			/>
																			<label
																				><span>{{ data.name }}</span></label
																			>
																		</div>
																	</span>
																</div>
																<div class="form-group">
																	<a (click)="showlevelEmptyValues()" class="skip_sec float-left mb-3">Skip</a>
																	<button
																		(click)="showlevel()"
																		[ngStyle]="{
																			'background-color': dept_data.length === 0 ? '#91bef5' : '#2d8cff'
																		}"
																		class="form-wizard-next-btn float-right mb-3 ml-2"
																		[disabled]="dept_data.length === 0"
																	>
																		Next
																	</button>
																	<span (click)="showemp()" class="form-wizard-previous-btn float-right mb-3">Previous</span>
																</div>
																<p class="text-center blockquote">" Reach your ideal customer... FASTER "</p>
															</fieldset>
														</div>

														<div *ngIf="level">
															<fieldset class="wizard-fieldset show">
																<h5 class="pb-1">
																	Choose Seniority
																	<strong class="text-danger">*</strong>
																</h5>
																<!-- <p class="pb-2">Number of contacts you want to see a per
                                                                    the Seniority</p> -->
																<!-- <div class="search-input-group-style input-group pull-right mb-3"
                                                                    style="width:60%;">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"
                                                                            id="basic-addon1"><svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                stroke="currentColor" stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                class="feather feather-search">
                                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                                <line x1="21" y1="21" x2="16.65"
                                                                                    y2="16.65"></line>
                                                                            </svg></span>
                                                                    </div>
                                                                    <input type="text" class="form-control"
                                                                        placeholder="Search Seniority"
                                                                        aria-label="Username"
                                                                        aria-describedby="basic-addon1"
                                                                        [(ngModel)]="searchedKeyword"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                </div> -->
																<div class="scrollbar" id="style-4">
																	<span *ngFor="let data of level_data | filter : searchedKeyword">
																		<div class="input-container" (click)="onChange4(data.name)">
																			<input
																				type="checkbox"
																				[checked]="data.checked"
																				[(ngModel)]="data.checked"
																				[ngModelOptions]="{ standalone: true }"
																				(change)="onChange(data.email, $event.target.checked)"
																			/>
																			<label
																				><span>{{ data.name }}</span></label
																			>
																		</div>
																	</span>
																</div>
																<div class="form-group">
																	<!-- <a (click)="showemp()"
                                                                        class="skip_sec float-left mb-3">skip</a> -->
																	<!-- <button (click)="showemp();"
                                                                        [ngStyle]="{'background-color':lev_data.length===0?'#91bef5':'#2d8cff'}"
                                                                        class="form-wizard-next-btn float-right mb-3 ml-2"
                                                                        [disabled]="lev_data.length===0">Next</button>
                                                                    <span (click)="showdep();"
                                                                        class="form-wizard-previous-btn float-right mb-3">Previous</span> -->
																	<div class="form-group">
																		<span (click)="submitData()" class="form-wizard-next-btn float-right mb-3 ml-2"
																			>Submit</span
																		>
																		<span (click)="showdep()" class="form-wizard-previous-btn float-right mb-3">Previous</span>
																	</div>
																</div>
															</fieldset>
														</div>

														<!-- <div *ngIf="rev">
                                                            <fieldset class="wizard-fieldset  show">
                                                                <h5 class="pb-1">Choose Revenue</h5>
                                                                <p class="pb-2">Number of contacts you want to see a per
                                                                    the Revenue</p>
                                                               
                                                                <div class="scrollbar" id="style-4">
                                                                    <span *ngFor="let data of revenue_data">
                                                                        <div class="input-container"
                                                                            (click)="onChange6(data.name)">
                                                                            <input type="checkbox"
                                                                                [checked]="data.checked"
                                                                                [(ngModel)]="data.checked"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                (change)="onChange(data.email, $event.target.checked)">
                                                                            <label><span>{{data.name}}</span></label>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <div class="form-group">
                                                                    <span (click)="submitData();"
                                                                        class="form-wizard-next-btn float-right mb-3 ml-2">Submit</span>
                                                                    <span (click)="showLocation()"
                                                                        class="form-wizard-previous-btn float-right mb-3">Previous</span>

                                                                      </div>
                                                            </fieldset>
                                                        </div> -->
													</form>
												</div>
											</div>
										</div>
									</section>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="layout-px-spacing" *ngIf="resultDash && finalreport">
					<div class="row layout-top-spacing" *ngIf="finalreport.company_group_by_industry">
						<!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing "> -->
						<!-- <span class="pull-left report-text">Report on the basis of your selected criteria</span> -->
						<div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 mb-1">
							<p>
								<span
									class="fontstyle"
									type="text"
									data-toggle="collapse"
									data-target="#collapseExample"
									aria-expanded="false"
									aria-controls="collapseExample"
								>
									Breakdown on the basis of your<span class="text-primary selectedCriteria" (click)="showPanel()"
										>selected criteria</span
									>
								</span>
							</p>

							<!-- <mat-accordion>
                                    <mat-expansion-panel (opened)="panelOpenState = true"
                                        (closed)="panelOpenState = false">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Report on the basis of your<span class="selectedCriteria">selected criteria</span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                       <span *ngIf="loc1.length>0"><strong>Location :
                                            </strong><span class="badge-filter" *ngFor="let location of loc_data">{{location.name}}</span></span>
                                       <span *ngIf="inds1.length>0"><strong class="ml-2">Industry
                                                :</strong><span class="badge-filter" *ngFor="let industries of ind_data">{{industries.name}}</span></span>
                                        <span *ngIf="emp1.length>0" > <strong class="ml-2">Employee Range
                                                :</strong><span class="badge-filter">{{payload.EmployeeRange}}</span></span>
                                       <span *ngIf="dept1.length>0"><strong class="ml-2">Department
                                                :</strong><span class="badge-filter">{{payload.department}} </span></span>
                                        <strong class="ml-2 mt-2">Seniority :</strong><span class="badge-filter">{{final_level}}</span>
                                    </mat-expansion-panel>
                                </mat-accordion> -->
						</div>
						<div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
							<a class="pull-right refine-criteria" (click)="refineFilters()"
								><i class="fa fa-sliders" aria-hidden="true"></i> Refine Criteria</a
							>
						</div>

						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 clps-screen">
							<div class="collapse" id="collapseExample" *ngIf="resultDash && isCollapse">
								<div class="card card-body align-sle-filter">
									<i class="fa fa-close pull-right panel-icon" (click)="closepanel()"></i>
									<p *ngIf="loc1.length > 0">
										<strong class="mt-2">Location : </strong
										><span class="badge-filter" *ngFor="let location of loc_data">{{ location.name }}</span>
									</p>
									<p *ngIf="inds1.length > 0">
										<strong class="mt-2">Industry :</strong
										><span class="badge-filter" *ngFor="let industries of ind_data">{{ industries.name }}</span>
									</p>
									<p *ngIf="emp1.length > 0">
										<strong class="mt-2">Employee Range :</strong
										><span class="badge-filter" *ngFor="let emp of emp_data">{{ emp.name }}</span>
									</p>
									<p *ngIf="dept1.length > 0">
										<strong class="mt-2">Department :</strong
										><span class="badge-filter" *ngFor="let dept of dept_data">{{ dept.name }} </span>
									</p>
									<p *ngIf="levs1.length > 0">
										<strong class="mt-2">Seniority : </strong
										><span class="badge-filter" *ngFor="let seniority of lev_data">{{ seniority.name }}</span>
									</p>
								</div>
							</div>
						</div>
						<div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing" *ngIf="finalreport">
							<div class="widget widget-card-four">
								<div class="widget-content">
									<div class="w-content">
										<div class="w-info">
											<h6 class="pt-3">Contacts</h6>
											<p class="value pt-3" *ngIf="finalreport.contact_counts > 0">
												{{ finalreport.contact_counts | number }}
											</p>
											<p class="pt-3 contacts_nodata" *ngIf="finalreport.contact_counts === 0">No Contacts Found</p>
										</div>
										<div class="">
											<div class="w-icon">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="40"
													height="55"
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
													class="feather feather-mail"
												>
													<path
														d="M4 4h16c1.1 0 2 .9 2
                            2v12c0 1.1-.9 2-2
                            2H4c-1.1
                            0-2-.9-2-2V6c0-1.1.9-2
                            2-2z"
													></path>
													<polyline
														points="22,6 12,13
                            2,6"
													></polyline>
												</svg>
											</div>
										</div>
									</div>
									<!-- <div class="progress">
                                            <div class="progress-bar bg-gradient-secondary" role="progressbar"
                                                style="width: 99%" aria-valuenow="22" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div> -->
								</div>
							</div>
						</div>
						<div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing" *ngIf="finalreport">
							<div class="widget widget-card-four">
								<div class="widget-content">
									<div class="w-content">
										<div class="w-info">
											<h6 class="pt-3">Companies</h6>
											<p class="value pt-3" *ngIf="finalreport.company_counts > 0">
												{{ finalreport.company_counts | number }}
											</p>
											<p class="pt-3 contacts_nodata" *ngIf="finalreport.company_counts === 0">No Companies Found</p>
										</div>
										<div class="">
											<div class="w-icon">
												<div class="icon-container">
													<i class="fa fa-building-o fnt_company"></i>
													<!-- <svg viewBox="0 0 24 24" width="40" height="55"
                                                            stroke="currentColor" stroke-width="2" fill="none"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            class="css-i6dzq1">
                                                            <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                                            <polyline points="2 17 12 22 22 17"></polyline>
                                                            <polyline points="2 12 12 17 22 12"></polyline>
                                                        </svg> -->
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="progress">
                                            <div class="progress-bar bg-gradient-red" role="progressbar"
                                                style="width: 57%" aria-valuenow="57" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div> -->
								</div>
							</div>
						</div>
						<div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing" *ngIf="finalreport">
							<div class="widget widget-account-invoice-two">
								<div class="widget-content">
									<div class="account-box">
										<div class="info">
											<h5 class="">Get Custom Data Intelligence</h5>
											<!-- <p class="inv-balance">$10,344</p> -->
										</div>
										<div class="acc-action">
											<button
												type="button"
												*ngIf="finalreport.contact_counts > 50"
												class="btn btn-outline-primary waves-effect btn-lg"
												(click)="icpFilterDataRequest()"
											>
												Get 5 Contacts
											</button>
											<button
												type="button"
												*ngIf="finalreport.contact_counts < 50 || finalreport.contact_counts == 50"
												class="btn btn-outline-primary waves-effect btn-lg"
												(click)="
													bookDemo('www.ampliz.com/get-more-prospects?utm_source=ampliz&utm_medium=icp&utm_campaign=icp')
												"
											>
												Talk to us
											</button>
											<!-- <p *ngIf="requestSampletext" class="sucees_msg">
                                                    <strong class="reques-success-msg">
                                                        Thanks for asking! We will get back to you in 24 hours </strong>
                                                </p> -->
											<!-- <div class="succes-message"  *ngIf="!requestSample">
                                                        <div class="alert alert-success alert-dismissible fade show" role="alert" >
                                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                            Data Saved Successfully!
                                                        </div>
                                                    </div> -->
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                            <div class="widget widget-card-four">
                                <div class="widget-content">
                                    <div class="w-content">
                                        <div class="col-lg-9">
                                            <div class="media">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-database"><ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path></svg>
                                                <div class="media-body">
                                                    <h5>Get access for full data platform</h5>
                                                    <p class="pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.consectetur adipisicing </p>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-3 brtn ">
                                            <a (click)="getDataAccess()" class="ml-5 mt-2 pull-right">Get Full Data Access</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> -->
						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
							<div class="widget widget-card-four bkt-clr">
								<div class="widget-content">
									<div class="w-content">
										<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
											<div class="text-center pb-3">
												<h6 class="pb-4 pt-2">GET ACCESS TO MOST ACCURATE AND COMPREHENSIVE BUSINESS DATABASE</h6>
												<div class="media-body innerDiv">
													<div class="row">
														<div class="col-lg-4 col-md-4 col-sm-6 text-center">
															<div class="counter">
																<h6><span>200 M+</span></h6>
																<p class="pt-2">Direct Emails</p>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-6 text-center">
															<div class="counter">
																<h6><span>10 M+</span></h6>
																<p class="pt-2">Company Profiles</p>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-6 text-center">
															<div class="counter padding50">
																<h6><span>4.6 M+</span></h6>
																<p class="pt-2">Healthcare Physicians</p>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-xl-12 text-center pt-3 pb-3 col-lg-12 col-md-12 col-sm-12 col-12 brtn">
												<a (click)="getDataAccess()" class="">Get Full Data Access</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing" *ngIf="finalreport">
							<div class="widget widget-table-one">
								<div class="widget-heading">
									<h5 class="">Contacts by Department</h5>
								</div>
								<div class="col-lg-12 col-12 layout-spacing">
									<div class="statbox department-card box box-shadow">
										<div
											class="widget-content widget-content-area animated-underline-content scrollbar_market"
											id="style-5"
										>
											<!-- <ul class="nav nav-tabs  mb-3" id="animateLine" role="tablist">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" id="animated-underline-home-tab"
                                                            data-toggle="tab" href="#animated-underline-home" role="tab"
                                                            aria-controls="animated-underline-home"
                                                            aria-selected="false"> Contact</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link " id="animated-underline-profile-tab"
                                                            data-toggle="tab" href="#animated-underline-profile"
                                                            role="tab" aria-controls="animated-underline-profile"
                                                            aria-selected="true"> Company</a>
                                                    </li>
                                                </ul> -->
											<div class="tab-content" id="animateLineContent-4">
												<div *ngIf="finalreport.contact_group_by_dept.length === 0" class="text-center">
													<img src="/assets/images/new.png" />
												</div>
												<div
													class="tab-pane fade active show"
													id="animated-underline-home"
													role="tabpanel"
													aria-labelledby="animated-underline-home-tab"
												>
													<div *ngFor="let item of finalreport.contact_group_by_dept" class="widget-content">
														<div class="transactions-list" *ngIf="item.Department != null">
															<div class="t-item">
																<div class="t-company-name">
																	<div class="t-icon">
																		<div class="avatar avatar-xl">
																			<!-- <span>{{deptartment_data.icon}}</span> -->
																			<span class="avatar-title rounded-circle" *ngIf="item.Department">{{
																				item.Department | shortName
																			}}</span>
																		</div>
																	</div>
																	<div class="t-name">
																		<h4>{{ item.Department }}</h4>
																	</div>
																</div>
																<div class="t-rate rate-inc">
																	<p>
																		<span>{{ item.counts | number }}</span>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<!-- <div class="tab-pane fade " id="animated-underline-profile"
                                                        role="tabpanel"
                                                        aria-labelledby="animated-underline-profile-tab">
                                                        <div class="widget-content">
                                                            <div class="transactions-list">
                                                                <div class="t-item">
                                                                    <div class="t-company-name">
                                                                        <div class="t-icon">
                                                                            <div class="avatar avatar-xl">
                                                                                <span
                                                                                    class="avatar-title rounded-circle">A</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="t-name">
                                                                            <h4>Accounting</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="t-rate rate-inc">
                                                                        <p><span>16,567,87</span> </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="transactions-list">
                                                                <div class="t-item">
                                                                    <div class="t-company-name">
                                                                        <div class="t-icon">
                                                                            <div class="avatar avatar-xl">
                                                                                <span
                                                                                    class="avatar-title rounded-circle">AA</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="t-name">
                                                                            <h4>Airlines and Aviation</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="t-rate rate-inc">
                                                                        <p><span>18,567,87</span> </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="transactions-list">
                                                                <div class="t-item">
                                                                    <div class="t-company-name">
                                                                        <div class="t-icon">
                                                                            <div class="avatar avatar-xl">
                                                                                <span
                                                                                    class="avatar-title rounded-circle">A</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="t-name">
                                                                            <h4>Animation</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="t-rate rate-inc">
                                                                        <p><span>11,567,87</span> </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing" *ngIf="finalreport">
							<div class="widget widget-chart-two">
								<div class="widget-heading seniority-padd">
									<h5 class="">Contacts by Seniority</h5>
								</div>
								<div class="widget-content seniority-chart">
									<div *ngIf="finalreport.contact_group_by_level.length === 0" class="text-center seniority-img">
										<img src="/assets/images/new.png" class="" />
									</div>
									<div id="chart" *ngIf="finalreport.contact_group_by_level.length > 0">
										<apx-chart
											[series]="chartOptions.series"
											[chart]="chartOptions.chart"
											[labels]="chartOptions.labels"
											[fill]="chartOptions.fill"
											[dataLabels]="chartOptions.dataLabels"
											[responsive]="chartOptions.responsive"
										></apx-chart>
									</div>
									<div id="chart-2" class=""></div>
								</div>
							</div>
						</div>

						<!-- REVENUE IMAPORTANT END -->

						<!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing ">
                                <div class="widget-four ">
                                    <div class="widget-heading ">
                                        <h5 class=" "> Revenue</h5>
                                    </div>
                                    <div class="widget-content " *ngIf="finalreport">
                                        <div class="vistorsBrowser ">
                                            <div class="browser-list ">

                                                <div class="w-browser-details ">

                                                    <div class="w-browser-info ">
                                                        <h6>0-1M</h6>
                                                        <p class="browser-count ">
                                                            {{finalreport.company_group_by_revenue.One_M}}</p>
                                                    </div>

                                                    <div class="w-browser-stats ">
                                                        <div class="progress ">
                                                            <div class="progress-bar bg-gradient-warning "
                                                                role="progressbar " style="width: 75% "
                                                                aria-valuenow="75 " aria-valuemin="0 "
                                                                aria-valuemax="100 "></div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="browser-list ">

                                                <div class="w-browser-details ">

                                                    <div class="w-browser-info ">
                                                        <h6>1M-5M</h6>
                                                        <p class="browser-count ">
                                                            {{finalreport.company_group_by_revenue.Five_M}}</p>
                                                    </div>

                                                    <div class="w-browser-stats ">
                                                        <div class="progress ">
                                                            <div class="progress-bar bg-gradient-warning "
                                                                role="progressbar " style="width: 65% "
                                                                aria-valuenow="65 " aria-valuemin="0 "
                                                                aria-valuemax="100 "></div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="browser-list ">

                                                <div class="w-browser-details ">

                                                    <div class="w-browser-info ">
                                                        <h6>5M-25M</h6>
                                                        <p class="browser-count ">
                                                            {{finalreport.company_group_by_revenue.TwentyFive_M}}</p>
                                                    </div>

                                                    <div class="w-browser-stats ">
                                                        <div class="progress ">
                                                            <div class="progress-bar bg-gradient-warning "
                                                                role="progressbar " style="width: 85% "
                                                                aria-valuenow="85 " aria-valuemin="0 "
                                                                aria-valuemax="100 "></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="w-browser-details ">

                                                    <div class="w-browser-info ">
                                                        <h6>25M-100M</h6>
                                                        <p class="browser-count ">
                                                            {{finalreport.company_group_by_revenue.One_Hundred_M}}</p>
                                                    </div>

                                                    <div class="w-browser-stats ">
                                                        <div class="progress ">
                                                            <div class="progress-bar bg-gradient-warning "
                                                                role="progressbar " style="width: 85% "
                                                                aria-valuenow="85 " aria-valuemin="0 "
                                                                aria-valuemax="100 "></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="w-browser-details ">

                                                    <div class="w-browser-info ">
                                                        <h6> >1B </h6>
                                                        <p class="browser-count ">
                                                            {{finalreport.company_group_by_revenue.One_B}}</p>
                                                    </div>

                                                    <div class="w-browser-stats ">
                                                        <div class="progress ">
                                                            <div class="progress-bar bg-gradient-warning "
                                                                role="progressbar " style="width: 85% "
                                                                aria-valuenow="85 " aria-valuemin="0 "
                                                                aria-valuemax="100 "></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div> -->
						<!-- REVENUE IMAPORTANT END -->

						<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing" *ngIf="finalreport">
							<div class="widget widget-table-one">
								<div class="widget-heading">
									<h5 class="">Employee Count</h5>
								</div>
								<div class="col-lg-12 col-12 layout-spacing">
									<!-- <p *ngIf="finalreport.company_group_by_employee_count==0"
                                            class="text-center mt-3">Data Not Available
                                        </p> -->
									<div class="statbox box box-shadow">
										<div
											class="widget-content widget-content-area animated-underline-content scrollbar_market"
											id="style-5"
										>
											<div *ngIf="finalreport.company_group_by_employee_count.length === 0" class="text-center">
												<img src="/assets/images/new.png" />
											</div>
											<div class="tab-content" id="animateLineContent-4">
												<div
													class="tab-pane fade active show"
													id="animated-underline-home"
													role="tabpanel"
													aria-labelledby="animated-underline-home-tab"
												>
													<div
														*ngFor="let item of finalreport.company_group_by_employee_count; let i = index"
														class="widget-content"
													>
														<div class="transactions-list" *ngIf="item.EmployeeRange != null">
															<div class="t-item">
																<div class="t-company-name">
																	<div class="t-icon">
																		<div class="avatar avatar-xl">
																			<span class="avatar-title rounded-circle"
																				><i class="fa fa-users" aria-hidden="true"></i
																			></span>
																		</div>
																	</div>
																	<div class="t-name">
																		<h4>{{ item.EmployeeRange }}</h4>
																	</div>
																</div>
																<div class="t-rate rate-inc">
																	<p>
																		<span>{{ item.counts | number }}</span>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="widget-four  ">
                                    <div class="widget-heading ">
                                        <h5 class=" "> Employee Count</h5>
                                    </div>
                                    <div *ngIf="finalreport.company_group_by_industry.length==0">
                                        <p>No data Available</p>
                                    </div>
                                    <div class="scrollbar_progress" id="style-5">
                                        <div class="widget-content ">
                                            <div *ngFor="let item of finalreport.company_group_by_employee_count ; let i = index"
                                                class="vistorsBrowser ">
                                                <div class="browser-list ">

                                                    <div class="w-browser-details ">

                                                        <div class="w-browser-info ">
                                                            <h6>{{item.EmployeeRange}}</h6>
                                                            <p class="browser-count ">{{item.counts}}</p>
                                                        </div>

                                                        <div class="w-browser-stats ">
                                                            <div class="progress ">
                                                                <div class="progress-bar bg-gradient-danger "
                                                                    role="progressbar " style="width: 75% "
                                                                    aria-valuenow="75 " aria-valuemin="0 "
                                                                    aria-valuemax="100 "></div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div> -->
						</div>
						<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing" *ngIf="finalreport">
							<div class="widget widget-table-one">
								<div class="widget-heading">
									<h5 class="">Industry</h5>
								</div>

								<!-- <p class="text-center mt-3" *ngIf="finalreport.company_group_by_industry.length==0">
                                        Data Not Available </p> -->
								<div class="col-lg-12 col-12 layout-spacing">
									<div class="statbox box box-shadow">
										<div
											class="widget-content widget-content-area animated-underline-content scrollbar_market"
											id="style-5"
										>
											<div *ngIf="finalreport.company_group_by_industry.length === 0" class="text-center">
												<img src="/assets/images/new.png" />
											</div>
											<div class="tab-content" id="animateLineContent-4">
												<div
													class="tab-pane fade active show"
													id="animated-underline-home"
													role="tabpanel"
													aria-labelledby="animated-underline-home-tab"
												>
													<div *ngFor="let item of finalreport.company_group_by_industry" class="widget-content">
														<div class="transactions-list" *ngIf="item.Industry != null">
															<div class="t-item">
																<div class="t-company-name">
																	<div class="t-icon">
																		<div class="avatar avatar-xl">
																			<span class="avatar-title rounded-circle" *ngIf="item.Industry">{{
																				item.Industry | shortName
																			}}</span>
																		</div>
																	</div>
																	<div class="t-name">
																		<h4>{{ item.Industry }}</h4>
																	</div>
																</div>
																<div class="t-rate rate-inc">
																	<p>
																		<span>{{ item.counts | number }}</span>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- <div class="statbox  box box-shadow">
                                    <div class="widget-content widget-content-area animated-underline-content">

                                <div class="widget-four  ">
                                    <div class="widget-heading ">
                                        <h5 class=" ">Industry</h5>
                                    </div>
                                    <div *ngIf="finalreport.company_group_by_industry.length==0">
                                        <p>No data Available</p>
                                    </div>
                                    <div class="scrollbar_progress" id="style-5">
                                        <div class="widget-content ">
                                                                                    
                                           <div class="tab-content" id="animateLineContent-4">
                                                                <div class="tab-pane fade active show" id="animated-underline-home"
                                                                    role="tabpanel" aria-labelledby="animated-underline-home-tab">
                                                                    <div *ngFor="let item of finalreport.company_group_by_industry"
                                                                        class="widget-content">
                                                                        <div class="transactions-list">
                                                                            <div class="t-item">
                                                                                <div class="t-company-name">
                                                                                    <div class="t-icon">
                                                                                        <div class="avatar avatar-xl">
                                                                                            <span
                                                                                                class="avatar-title rounded-circle"
                                                                                                *ngIf="item.Industry">{{item.Industry | shortName}}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="t-name">
                                                                                        <h4>{{item.Industry}}</h4>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="t-rate rate-inc">
                                                                                    <p><span>{{item.counts | number}}</span> </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                        </div>
                                    </div>

                                </div>
                                </div>
                                </div> -->
							<!-- <div class="widget widget-table-one">
                                                <div class="widget-heading">
                                                    <h5 class="">Industry</h5>
                                                </div>
                                                <div class="col-lg-12 col-12 layout-spacing" id="">
                                        <p *ngIf="finalreport.company_group_by_industry==0" class="text-center mt-3">Data Not Available for {{payload.Industry}}</p>

                                                    <div class="statbox  box box-shadow" >
                                                        <div class="widget-content widget-content-area animated-underline-content">
            

                                            <div id="industry-chart">
                                                <apx-chart
                                                  [series]="indChartOptions.series"
                                                  [chart]="indChartOptions.chart"
                                                  [dataLabels]="indChartOptions.dataLabels"
                                                  [plotOptions]="indChartOptions.plotOptions"
                                                  [xaxis]="indChartOptions.xaxis"
                                                ></apx-chart>
                                              </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
							<!-- <div *ngFor="let item of finalreport.company_group_by_industry ; let i = index"
                                                class="vistorsBrowser ">
                                                <div class="browser-list ">

                                                    <div class="w-browser-details ">

                                                        <div class="w-browser-info ">
                                                            <h6>{{item.Industry}}</h6>
                                                            <p class="browser-count ">{{item.counts}}</p>
                                                        </div>

                                                        <div class="w-browser-stats ">
                                                            <div class="progress ">
                                                                <div class="progress-bar bg-gradient-primary "
                                                                    role="progressbar " style="width: 75% "
                                                                    aria-valuenow="75 " aria-valuemin="0 "
                                                                    aria-valuemax="100 "></div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div> -->
							<!-- </div>
                                    </div> -->
						</div>

						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing" *ngIf="finalreport">
							<div class="widget-four">
								<div class="widget-heading">
									<h5 class="">Location</h5>
								</div>
								<div *ngIf="finalreport.company_group_by_location.length === 0" class="text-center">
									<img src="/assets/images/new.png" />
								</div>
								<div *ngIf="finalreport.company_group_by_location.length > 0">
									<!-- HIGHCHART START -->
									<highcharts-chart
										id="container"
										[Highcharts]="Highcharts"
										[constructorType]="chartConstructor"
										[options]="chartOption"
										style="width: 100%; height: 600px; display: block"
									>
									</highcharts-chart>
									<!-- HIGHCHART End -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- container -->
		</div>
		<!-- content -->
	</div>

	<!-- ============================================================== -->
	<!-- End Right content here -->
	<!-- ============================================================== -->

	<!-- <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog">
          
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title"></h4>
                </div>
                <div class="modal-body">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <textarea type="text" formControlName="message" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required">Add your custom requirement</div>
                            </div>
                        </div>
                       
                        <input type="submit" value="Send">
                    </form>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div> -->
	<app-footer></app-footer>
</div>
