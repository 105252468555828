import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
	Renderer2,
	TemplateRef
} from '@angular/core';
import { BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { FilterStorageService } from 'src/app/modules/B2B/services/filter-storage.service';
import { SearchImagingExecutivesModel } from '../../../models/SearchImagingExecutivesModel';
import { ImagingDataService } from '../../../services/imaging-data.service';
import { ImagingService } from '../../../services/imaging.service';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';

@Component({
	selector: 'app-filter-imaging-center-executives',
	templateUrl: './filter-imaging-center-executives.component.html',
	styleUrls: ['./filter-imaging-center-executives.component.css']
})
export class FilterImagingCenterExecutivesComponent implements OnInit {
	@Output() onFilterChange = new EventEmitter<any>();
	@Input() isSubscribed: boolean = false;
	filterData: SearchImagingExecutivesModel = new SearchImagingExecutivesModel();
	tempDialogFilters: SearchImagingExecutivesModel = new SearchImagingExecutivesModel();
	dialogRef: any;
	dialogState: boolean = false;
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;
	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showConfirmDialog: boolean = false;
	@Input() isPaid: boolean = false;
	@ViewChild('imagingEqupInput', { static: false })
	imagingEqupInput: ElementRef<HTMLInputElement>;
	selectable = true;
	removable = true;

	//company Variables
	companyList: Array<any> = [];

	//title
	titleList: Array<any> = [];

	// industry

	industryList: Array<any> = [];

	// seniority
	seniorityList: Array<any> = [];

	//department

	departmentList: Array<any> = [
		'Business Development',
		'Engineering',
		'Finance',
		'Human Resources',
		'IT',
		'Laboratory',
		'Marketing/Sales',
		'Medical',
		'Nursing',
		'Operations',
		'Others',
		'Purchasing',
		'R&D',
		'Sub Level Management',
		'Top Level Management'
	];

	//contacts

	//Country Variables
	countryList: Array<any> = [];
	selectedCountry: Array<any> = [];

	//State Variables
	stateList: Array<any> = [];
	selectedStates: Array<any> = [];

	//City Variables

	//Search List variables
	cityList: Array<any> = [];
	selectedCities: Array<any> = [];
	imagingEquipmentControl = new FormControl();

	// Revenue Variables
	revenueList: Array<any> = [];
	includedRevenueRange: any = [];
	subscription: Subscription;

	//Imaging equipments

	imagingNumber: any = null;

	selectedImagingNumber: any = [];
	imagingEqupmentsList: Array<any> = [];
	//
	@ViewChild('cptCodeInput', { static: false })
	cptCodeInput: ElementRef<HTMLInputElement>;
	cptControl = new FormControl();
	cptCodeList: any = [];
	hscpcCodeList: any = [];
	codeClassificationControl = new FormControl();
	codeControl = new FormControl();

	@ViewChild('codeClassificationInput', { static: false })
	codeClassificationInput: ElementRef<HTMLInputElement>;
	@ViewChild('codeInput', { static: false })
	codeInput: ElementRef<HTMLInputElement>;
	icdLoader: boolean = false;
	separatorKeysCodes: number[] = [ENTER, COMMA];

	get selectedHSCPCCode() {
		return this.filterData.hcpcsCodes;
	}
	get selectedCPTCode() {
		return this.filterData.cptCodes;
	}
	constructor(
		private imagingService: ImagingService,
		private filterStorageService: FilterStorageService,
		private dataService: ImagingDataService,
		private cdr: ChangeDetectorRef,
		private renderer: Renderer2,
		private amplizService: AmplizService,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService
	) {}

	ngOnInit() {
		this.getPersistData();
		this.getSeniorityList();
		// this.getRevenueList();
		this.getAllListData();
		this.getImagingCenterEquipment();
		this.getHSCPCCodes();
		this.getCPTCodes();
		this.subscription = this.dataService.imagingSearchData.subscribe((res) => {
			if (res.fromSearch) {
				this.filterData = res.data;
				this.omitChanges();
			}
		});
	}
	getAllListData() {
		this.imagingEquipmentControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getImagingCenterEquipment(hv);
			}
		});
		this.cptControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getHSCPCCodes(hv);
			}
		});
		this.codeClassificationControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getCPTCodes(hv);
			}
		});
	}

	get numberOfImagingEquipmentList() {
		const numberOfImagingEquipmentList = [];
		for (let i = 0; i <= 25; i++) {
			numberOfImagingEquipmentList.push(i + '');
		}
		return numberOfImagingEquipmentList;
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
		this.dataService.passSearchImagingInput(this.filterData, false);
	}
	getSeniorityList() {
		this.imagingService.getSeniorityList('').subscribe((res) => {
			this.seniorityList = res.seniorityList;
		});
	}

	// getRevenueList() {
	// 	this.filterData.fullName;
	// 	this.imagingService.getRevenueList().subscribe((res) => {
	// 		this.revenueList = res.revenueList;
	// 	});
	// }

	// Company functions

	companyValueChanges(item) {
		if (item && item.length > 1) {
			this.imagingService.getCompanyList(item).subscribe((res) => {
				this.companyList = res.companyListImagingCenter;
			});
		}
	}
	// TITLE FUNCTIONS......................

	titleValueChanges(item) {
		if (item && item.length > 1) {
			const body = {
				searchPhrase: item,
				previousSearchedTitle: [...this.filterData.titleInclude, ...this.filterData.titleExclude]
			};
			this.imagingService.getImagingTitlesList(body).subscribe((res) => {
				this.titleList = res.titleAll;
			});
		} else {
			this.titleList = [];
		}
	}
	// INDUSTRY FUNCTIONS......................

	industryValueChanges(item) {
		if (item && item.length > 1) {
			const body = {
				searchPhrase: item
			};
			this.imagingService.getIndustryList(body).subscribe((res) => {
				this.industryList = res.industryListImagingCenter;
			});
		} else {
			this.industryList = [];
		}
	}
	// SKILL FUNCTIONS......................

	hanldeLocationValueChange(locationBody: any) {
		this.omitChanges();
	}

	// Imaging functions
	imagingNumberSelected() {
		this.selectedImagingNumber[0] = this.imagingNumber;
		this.filterData.numberOfImagingEquipments = this.imagingNumber;
		this.storeFilterData();
		this.omitChanges();
	}
	removeImagingNumber() {
		this.selectedImagingNumber = [];
		this.imagingNumber = null;
		this.filterData.numberOfImagingEquipments = null;
		this.omitChanges();
	}

	getPersistData() {
		const newFilterData = this.filterStorageService.get('imaging_filter_data');
		Object.keys(newFilterData).map((el) => {
			this.filterData[el] = newFilterData[el];
		});
		if (this.filterData.numberOfImagingEquipments) {
			this.selectedImagingNumber[0] = this.filterData.numberOfImagingEquipments;
			this.imagingNumber = this.filterData.numberOfImagingEquipments;
		}
		this.cdr.detectChanges();
		this.omitChanges();
		this.getRecordCount();
	}
	get selectedImagingEquipments() {
		return this.filterData.imagingEquipments;
	}

	storeFilterData() {
		this.filterStorageService.set('imaging_filter_data', this.filterData);
	}

	omitChanges() {
		this.onFilterChange.emit(this.filterData.toJson());
		this.storeFilterData();
	}

	clearFilter() {
		const isValid = this.filterData.validateImagingSearch();
		if (isValid) {
			this.filterData = new SearchImagingExecutivesModel();
			this.omitChanges();
		}
	}
	getImagingCenterEquipment(searchPhase = '') {
		this.imagingService.getImagingCenterEquipment(searchPhase, 'Executive').subscribe((res) => {
			this.imagingEqupmentsList = res.icEquipmentList;
		});
	}
	removeImagingEquipmentCode(code: string) {
		this.filterData.imagingEquipments = this.filterData.imagingEquipments.filter((el) => el !== code);
		this.getImagingCenterEquipment();
		this.omitChanges();
		this.storeFilterData();
	}
	onImagingEquipmentSelect(event: MatAutocompleteSelectedEvent) {
		this.filterData.imagingEquipments = [
			...new Set([...this.filterData.imagingEquipments, event.option.value])
		];
		setTimeout(() => {
			this.getImagingCenterEquipment();
		}, 500);
		this.omitChanges();
		this.storeFilterData();
	}
	//
	triggerAutoFocus(eleId: string) {
		const element = this.renderer.selectRootElement(eleId);
		setTimeout(() => element.focus(), 100);
	}
	removeCode(code: string) {
		this.filterData.hcpcsCodes = this.filterData.hcpcsCodes.filter((el) => el !== code);
		this.getHSCPCCodes();
		this.omitChanges();
		this.storeFilterData();
	}
	getHSCPCCodes(searchPhrase: string = '') {
		this.amplizService.getHcpcsCodesForImage(searchPhrase).subscribe((res: any) => {
			this.hscpcCodeList = res.hcpcsCodes.map((el) => ({ ...el, id: `${el.hcpcsCode} - ${el.description}` }));
		});
	}

	removeCPTCode(code: string) {
		this.filterData.cptCodes = this.filterData.cptCodes.filter((el) => el !== code);
		this.getCPTCodes();
		this.omitChanges();
		this.storeFilterData();
	}
	getCPTCodes(searchPhrase: string = '') {
		this.amplizService.getImagingCptCodes(searchPhrase).subscribe((res: any) => {
			this.cptCodeList = res.cptCodes.map((el) => ({ ...el, id: `${el.cptCode} - ${el.description}` }));
		});
	}
	addCodeClassification(event): void {
		this.filterData.cptCodes = [...new Set([...this.filterData.cptCodes, event.option.value])];

		this.codeClassificationInput.nativeElement.value = '';
		setTimeout(() => {
			this.getCPTCodes();
		}, 500);

		this.omitChanges();
		this.storeFilterData();
	}
	onCodeSelect(event: MatAutocompleteSelectedEvent) {
		this.filterData.hcpcsCodes = [...new Set([...this.filterData.hcpcsCodes, event.option.value])];
		this.cptCodeInput.nativeElement.value = '';
		setTimeout(() => {
			this.getHSCPCCodes();
		}, 500);
		this.omitChanges();
		this.storeFilterData();
	}
	get selectedCodes() {
		return [...this.filterData.cptCodes, ...this.filterData.hcpcsCodes];
	}
	clearTempFilter() {
		const isValid = this.tempDialogFilters.validateImagingSearch();
		if (isValid) {
			this.tempDialogFilters = new SearchImagingExecutivesModel();
			this.getRecordCount();
		}
	}
	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		Object.keys(this.filterData).map((el) => {
			this.tempDialogFilters[el] = this.filterData[el];
		});
		this.getRecordCount();
		this.dialogState = true;
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}
	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, cloneDeep(this.filterData));
	}
	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearTempFilter();
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}
	getRecordCount() {
		this.recordCountLoader.next(true);
		this.imagingService.searchImagingExecutives(this.tempDialogFilters.toJson()).subscribe(
			(res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				this.recordCountLoader.next(false);
			},
			(err) => {
				this.recordCountLoader.next(false);
			}
		);
	}
	applyMoreFilters() {
		Object.keys(this.tempDialogFilters).map((el) => {
			this.filterData[el] = this.tempDialogFilters[el];
		});
		this.omitChanges();
		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}
	get tempTitleData() {
		return [...this.tempDialogFilters.titleInclude, ...this.tempDialogFilters.titleExclude].filter(Boolean);
	}
	get tempImagingExpandData() {
		const data: any = [...this.tempDialogFilters.imagingEquipments];
		if (this.tempDialogFilters.numberOfImagingEquipments) {
			data.push(this.tempDialogFilters.numberOfImagingEquipments);
		}
		return data;
	}
	get ImagingExpandData() {
		const data: any = [...this.filterData.imagingEquipments];
		if (this.filterData.numberOfImagingEquipments) {
			data.push(this.filterData.numberOfImagingEquipments);
		}
		return data;
	}
	get tempProcedureCodes() {
		return [...this.tempDialogFilters.hcpcsCodes, ...this.tempDialogFilters.cptCodes];
	}
	get ProcedureCodes() {
		return [...this.filterData.hcpcsCodes, ...this.filterData.cptCodes];
	}
}
