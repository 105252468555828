<button
	type="button"
	(click)="btnClickedHandler()"
	class="btn btn-primary btn-outline-light pr-3 pl-3"
	*ngIf="params.data.noOfLeads > 0"
	[disabled]="isDisabled"
	[matTooltip]="isDisabled ? 'Enable salesforce to export': ''"
	[matTooltipPosition]="'above'"
	matTooltipClass="tool-tip"
>
	<img src="../../../../../assets/images/salesforce.svg" height="15px" />
	Salesforce Export
</button>
