import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { DataService } from '../../services/data.service';
import * as Highcharts from 'highcharts/highmaps';

@Component({
	selector: 'app-hospital-overview',
	templateUrl: './hospital-overview.component.html',
	styleUrls: ['./hospital-overview.component.css']
})
export class HospitalOverviewComponent implements OnInit, AfterViewInit {
	hospitalId1: any;
	hospitalId2: string = '';

	hospitalOverview: any = [];
	hospitalOverviewDetails: any = [];
	hospitalOverviewSocials: any = [];

	subscriptions = [];
	subscribed: boolean;
	headerData: string = 'Request Pricing';

	notCorrectDrawer: boolean = false;
	noResult: boolean = false;

	loading: boolean = false;

	attributes = [
		{
			name: 'Overview',
			active: true
		},
		{
			name: 'HCPs',
			active: false
		},
		{
			name: 'Executives',
			active: false
		},
		{
			name: 'Finance',
			active: false
		},
		{
			name: 'Specification',
			active: false
		},
		{
			name: 'Hospital Stats',
			active: false
		},
		{
			name: 'Alliances',
			active: false
		},
		{
			name: 'Total Stats',
			active: false
		},
		{
			name: 'Value Stats',
			active: false
		},
		{
			name: 'Technology',
			active: false
		},
		{
			name: 'CPT Codes',
			active: false
		},
		{
			name: 'HCPCS Codes',
			active: false
		},
		{
			name: 'ICD 10',
			active: false
		}
	];

	selectedHcpStats = 'Specialty';
	hcpStatsOptions = ['Specialty', 'Genders'];

	selectedExecutiveStat = 'Department';
	executiveStatsOptions = ['Department', 'Seniority'];

	chartOptions: any;

	mapUrl: any = this.domSanitizer.bypassSecurityTrustResourceUrl(
		`https://www.google.com/maps/embed/v1/place?key=AIzaSyA9MPVyBx9QI03grz7fgaUuLmwcJ8lwd9k&q=${this.location}`
	);

	// MapUrl: any;

	hcpStats = {
		specialty: {
			data: [],
			dataLabel: [],
			loader: false
		},
		state: {
			data: [],
			dataLabel: [],
			loader: false
		},
		gender: {
			data: [],
			dataLabel: [],
			loader: false
		}
	};

	executiveStats = {
		department: {
			data: [],
			dataLabel: [],
			loader: false
		},
		state: {
			data: [],
			dataLabel: [],
			loader: false
		},
		seniority: {
			data: [],
			dataLabel: [],
			loader: false
		}
	};

	specialtyChart = {
		series: [{ data: this.hcpStats.specialty.data, name: '' }],
		xaxis: {
			categories: this.hcpStats.specialty.dataLabel
		}
	};
	genderChart = {
		series: [{ data: this.hcpStats.gender.data, name: '' }],
		xaxis: {
			categories: this.hcpStats.gender.dataLabel
		}
	};
	stateChart = {
		series: [{ data: this.hcpStats.state.data, name: '' }],
		xaxis: {
			categories: this.hcpStats.state.dataLabel
		}
	};

	departmentChart = {
		series: [{ data: this.executiveStats.department.data, name: '' }],
		xaxis: {
			categories: this.executiveStats.department.dataLabel
		}
	};
	seniorityChart = {
		series: [{ data: this.executiveStats.seniority.data, name: '' }],
		xaxis: {
			categories: this.executiveStats.seniority.dataLabel
		}
	};
	executiveStateChart = {
		series: [{ data: this.executiveStats.state.data, name: '' }],
		xaxis: {
			categories: this.executiveStats.state.dataLabel
		}
	};

	constructor(
		private activatedRoute: ActivatedRoute,
		public amplizService: AmplizService,
		private loaderService: LoaderService,
		private domSanitizer: DomSanitizer,
		private dataService: DataService,
		private router: Router
	) {
		this.chartOptions = {
			chart: {
				type: 'bar',
				height: 300,
				toolbar: false
			},
			colors: {
				luminosity: 'light',
				hue: '#2d8cff`',
				count: 30
			},
			plotOptions: {
				bar: {
					horizontal: true
				}
			},
			dataLabels: {
				enabled: true
			},
			grid: {
				show: false,
				borderColor: '#90A4AE',
				strokeDashArray: 0,
				position: 'back',
				xaxis: {
					lines: {
						show: false
					}
				},
				yaxis: {
					lines: {
						show: false
					}
				}
			}
		};
	}

	get hospitalData() {
		return this.hospitalOverviewDetails?.hospitalInfoData || this.hospitalOverviewDetails;
	}

	get getActiveFilter() {
		return this.attributes.find((filter) => filter.active) || null;
	}

	ngOnInit() {
		// this.MapUrl =
	}

	get location() {
		return this.hospitalOverview?.Address1 + this.hospitalOverview?.Location;
	}

	ngAfterViewInit() {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.hospitalId1 = params.hospitalId1;
		});

		// this.hospitalId1 = this.activatedRoute.snapshot.params['hospitalId'];

		this.getHospitalOverviewData();
		this.getDashboardDetails();

		this.getPhysicianSpecialityStats();
		this.getExecutiveDepartmentStats();
	}

	cancelBtnClick(value: boolean) {
		this.notCorrectDrawer = value;
	}

	getHospitalOverviewData() {
		this.loaderService.display(true);

		this.amplizService.hospitalOverviewDetails(this.hospitalId1).subscribe((res) => {
			this.hospitalOverview = res.hospitalOverview;
			this.mapUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
				`https://www.google.com/maps/embed/v1/place?key=AIzaSyA9MPVyBx9QI03grz7fgaUuLmwcJ8lwd9k&q=${
					this.hospitalOverview?.Address1 + this.hospitalOverview?.Location
				}`
			);

			this.hospitalOverviewDetails = res.hospitalOverviewDetails;
			this.hospitalOverviewSocials = res.hospitalSocials;
			this.hospitalId2 = this.hospitalOverviewDetails['Hospital Id'];
			delete this.hospitalOverviewDetails['Cpt Codes'];
			delete this.hospitalOverviewDetails['Hcpcs Codes'];
			delete this.hospitalOverviewDetails['Hospital Id'];
			delete this.hospitalOverviewDetails['Icdten Codes'];
			delete this.hospitalOverviewDetails['Latitude'];
			delete this.hospitalOverviewDetails['Longitude'];
			delete this.hospitalOverviewDetails['Phone Exists'];
			// delete this.hospitalOverviewDetails['Noofexecutives'];
			// delete this.hospitalOverviewDetails['No Of Physician'];

			this.loaderService.display(false);
		});
	}

	async getDashboardDetails() {
		const authToken = await localStorage.getItem('auth_token');
		const refreshToken = await localStorage.getItem('refresh_token');
		if (authToken !== null && refreshToken !== null) {
			this.amplizService.getDashboardDetails().subscribe(
				(res) => {
					this.subscriptions = res.Subscriptions;
					if (this.subscriptions[0].SubscriptionType == 'Free') {
						localStorage.setItem('SubscriptionisActive', 'false');
						this.subscribed = false;

						this.headerData = 'Request Pricing';
					}
					if (this.subscriptions[0].SubscriptionType == 'Paid') {
						localStorage.setItem('SubscriptionisActive', 'true');
						this.subscribed = true;
					}
				},
				(error) => {
					if (error.status === 401) {
						this.amplizService.logout();
					}
				}
			);
		} else {
			this.amplizService.logout();
		}
	}

	openSocial(link: any) {
		window.open(link, '_blank').focus();
	}

	activateFilter(filterName) {
		this.attributes = this.attributes.map((filter) => ({
			...filter,
			active: filter?.name === filterName
		}));
	}

	navigateToExecutive() {
		this.dataService.passHospitalForExecutives(this.hospitalOverview['Hospital Name']);
		this.router.navigate(['executive'], { queryParamsHandling: 'preserve' });
	}

	downloadPDF() {
		if (!this.subscribed) return;
		this.loading = true;
		this.amplizService.downloadHospitalPdf(this.hospitalId2).subscribe(
			(res) => {
				const blob = new Blob([res.body], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${this.hospitalOverview['Hospital Name']}_Details.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setTimeout(() => {
					this.loading = false;
				}, 100);
			},
			(err) => {
				this.loading = false;
				console.log('ERR', err);
			}
		);
	}

	onHcpChange(stats: any) {
		this.getPhysicianGenderStats();
		// stats.value === 'States' ? this.getPhysicianStateStats() : this.getPhysicianGenderStats();
	}

	onExecutiveStatChange(stats: any) {
		this.getExecutiveSeniorityStats();
		// stats.value === 'States' ? this.getExecutiveStateStats() : this.getExecutiveSeniorityStats();
	}

	getPhysicianSpecialityStats() {
		if (this.hcpStats.specialty.data.length > 0) return;
		this.hcpStats.specialty.loader = true;
		this.amplizService
			.getPhysicianSpecialtyStatsForHospital(this.hospitalId1)
			.subscribe((res: any) => {
				if (res.length <= 0) return;
				if (res[0].top10.length > 0) {
					res[0].top10?.forEach((item) => {
						this.hcpStats.specialty.data.push(item.count);
						this.hcpStats.specialty.dataLabel.push(item.name === null ? '' : item.name);
					});
				}

				if (res[0].others.length > 0) {
					this.hcpStats.specialty.data.push(res[0].others[0]?.count);
					this.hcpStats.specialty.dataLabel.push(res[0].others[0]?.name);
				}

				// if (res[0].entries.length > 0) {
				// 	const entries = res[0].entries.splice(0, 10);
				// 	entries.forEach((item) => {
				// 		this.hcpStats.specialty.data.push(item.count);
				// 		this.hcpStats.specialty.dataLabel.push(item.name !== null && item.name);
				// 	});
				// }
				this.hcpStats.specialty.loader = false;
			});
	}

	getPhysicianGenderStats() {
		if (this.hcpStats.gender.data.length > 0) return;
		this.hcpStats.gender.loader = true;
		this.amplizService.getPhysicianGenderStatsForHospital(this.hospitalId1).subscribe((res: any) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.hcpStats.gender.data.push(item.count);
					this.hcpStats.gender.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.hcpStats.gender.data.push(res[0].others[0]?.count);
				this.hcpStats.gender.dataLabel.push(res[0].others[0]?.name);
			}

			// if (res[0].entries.length > 0) {
			// 	const entries = res[0].entries.splice(0, 10);
			// 	entries.forEach((item) => {
			// 		this.hcpStats.gender.data.push(item.count);
			// 		this.hcpStats.gender.dataLabel.push(item.name !== null && item.name);
			// 	});
			// }
			this.hcpStats.gender.loader = false;
		});
	}

	// getPhysicianStateStats() {
	// 	if (this.hcpStats.state.data.length > 0) return;
	// 	this.hcpStats.state.loader = true;
	// 	this.amplizService.getPhysicianStateStatsForHospital(this.hospitalId1).subscribe((res: any) => {
	// 		if (res[0].entries.length > 0) {
	// 			const entries = res[0].entries.splice(0, 10);
	// 			entries.forEach((item) => {
	// 				this.hcpStats.state.data.push(item.count);
	// 				this.hcpStats.state.dataLabel.push(item.name !== null && item.name);
	// 			});
	// 		}
	// 		this.hcpStats.state.loader = false;
	// 	});
	// }

	getExecutiveDepartmentStats() {
		if (this.executiveStats.department.data.length > 0) return;
		this.executiveStats.department.loader = true;
		this.amplizService
			.getExecutiveDepartmentStatsForHospital(this.hospitalId1)
			.subscribe((res: any) => {
				if (res.length <= 0) return;
				if (res[0].top10.length > 0) {
					res[0].top10?.forEach((item) => {
						this.executiveStats.department.data.push(item.count);
						this.executiveStats.department.dataLabel.push(item.name === null ? '' : item.name);
					});
				}

				if (res[0].others.length > 0) {
					this.executiveStats.department.data.push(res[0].others[0]?.count);
					this.executiveStats.department.dataLabel.push(res[0].others[0]?.name);
				}

				console.log('EXEC', this.executiveStats.department);

				// if (res[0].entries.length > 0) {
				// 	const entries = res[0].entries.splice(0, 10);
				// 	entries.forEach((item) => {
				// 		this.executiveStats.department.data.push(item.count);
				// 		this.executiveStats.department.dataLabel.push(item.name !== null && item.name);
				// 	});
				// }
				this.executiveStats.department.loader = false;
			});
	}

	getExecutiveSeniorityStats() {
		if (this.executiveStats.seniority.data.length > 0) return;
		this.executiveStats.seniority.loader = true;
		this.amplizService
			.getExecutiveSeniorityStatsForHospital(this.hospitalId1)
			.subscribe((res: any) => {
				if (res.length <= 0) return;
				if (res[0].top10.length > 0) {
					res[0].top10?.forEach((item) => {
						this.executiveStats.seniority.data.push(item.count);
						this.executiveStats.seniority.dataLabel.push(item.name === null ? '' : item.name);
					});
				}

				if (res[0].others.length > 0) {
					this.executiveStats.seniority.data.push(res[0].others[0]?.count);
					this.executiveStats.seniority.dataLabel.push(res[0].others[0]?.name);
				}

				// if (res[0].entries.length > 0) {
				// 	const entries = res[0].entries.splice(0, 10);
				// 	entries.forEach((item) => {
				// 		this.executiveStats.seniority.data.push(item.count);
				// 		this.executiveStats.seniority.dataLabel.push(item.name !== null && item.name);
				// 	});
				// }
				this.executiveStats.seniority.loader = false;
			});
	}

	// getExecutiveStateStats() {
	// 	if (this.executiveStats.state.data.length > 0) return;
	// 	this.executiveStats.state.loader = true;
	// 	this.amplizService.getExecutiveStateStatsForHospital(this.hospitalId1).subscribe((res: any) => {
	// 		if (res[0].entries.length > 0) {
	// 			const entries = res[0].entries.splice(0, 10);
	// 			entries.forEach((item) => {
	// 				this.executiveStats.state.data.push(item.count);
	// 				this.executiveStats.state.dataLabel.push(item.name !== null && item.name);
	// 			});
	// 		}
	// 		this.executiveStats.state.loader = false;
	// 	});
	// }
}
