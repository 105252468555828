import { Component, OnInit, Input } from '@angular/core';
import { AmplizService } from '../../../services/ampliz.service';

@Component({
	selector: 'app-hospital-specification',
	templateUrl: './hospital-specification.component.html',
	styleUrls: ['./hospital-specification.component.css']
})
export class HospitalSpecificationComponent implements OnInit {
	@Input() hospitalID: string = '';
	@Input() hospitalName: string = '';
	@Input() subscribed: boolean;

	loading: boolean = false;
	constructor(private amplizService: AmplizService) {}
	specificationList: any = [];

	ngOnInit(): void {
		this.getHospitalSpecification();
	}

	getHospitalSpecification() {
		if (JSON.parse(sessionStorage.getItem('specificationList')) == null) {
			this.amplizService.getHospitalSpecification(this.hospitalID).subscribe((res: any) => {
				this.specificationList = res.hospitalSpecification;
				delete this.specificationList['IDN Parent ID'];
				delete this.specificationList['IDN ID'];
				delete this.specificationList['HospitalID'];
				sessionStorage.setItem('specificationList', JSON.stringify(this.specificationList));
			});
		} else {
			this.specificationList = JSON.parse(sessionStorage.getItem('specificationList'));
		}
	}

	downloadPdf() {
		if (!this.subscribed) return;
		this.loading = true;
		this.amplizService.hospitalSpecificationPdfDownload(this.hospitalID).subscribe(
			(res) => {
				const blob = new Blob([res.body], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${this.hospitalName}_Specification.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setTimeout(() => {
					this.loading = false;
				}, 100);
			},
			(err) => {
				this.loading = false;
				console.log('ERR', err);
			}
		);
	}
}
