
<mat-chip-list>
	<ng-container *ngIf="!isSingle">
		<mat-basic-chip *ngFor="let item of chipData" [removable]="removable" (removed)="remove(item)">
			{{ itemName ? item[itemName] : item }}
			<mat-icon matChipRemove>close</mat-icon>
		</mat-basic-chip>
	</ng-container>

	<ng-container *ngIf="isSingle">
		<mat-basic-chip *ngIf="data !== ''" [removable]="removable" (removed)="remove(data)">
			{{ data }}
			<mat-icon matChipRemove>close</mat-icon>
		</mat-basic-chip>
	</ng-container>
</mat-chip-list>
