<div class="landingDashboardCard card">
    <div class="commonCardTitle">
        APAC LOCATION
    </div>
    
    <app-contacts-company-tab></app-contacts-company-tab>
    <!-- <figure id="apacLocation"></figure> -->
    <div class="landingCardContainer">
    <svg id="apacLocation" width="400" height="300"></svg>
</div>


</div>