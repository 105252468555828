<div id="wrapper">
  <app-header [user]="user" [elementName]="'b2b-list'" style="width: 100%"></app-header>{{ user }}
  <!-- <app-header *ngIf="!subscribed" [header-data]="headerData"></app-header> -->
  <!-- <app-sidemenu elementName="b2b-list"></app-sidemenu> -->

  <app-b2b-create-list
    [createListDiv]="createDrawer"
    (cancelBtnClick)="cancelBtnClick($event)"
    *ngIf="createDrawer"
  ></app-b2b-create-list>
  <!-- <app-save-search-list></app-save-search-list> -->
  <!-- <app-footer></app-footer> -->

  <div class="content-page enlarged">
    <!-- Start content -->

    <div class="content mx-auto">
      <!-- container -->
      <div class="row">
        <div class="col">
          <!-- <app-alert-box
          alertBox="alert"
          *ngIf="alert"
          item-name="Amazon"
          listName="test"
          >
          </app-alert-box> -->
          <div class="float-start">
            <div class="mt-3 title-header">
              Lists
              <span *ngIf="totalSize" class="total-count">{{ totalSize }}</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="float-end mt-3">
            <button
              type="button"
              class="btn btn-lg create-list pull-right"
              (click)="createDrawer = true"
            >
              <span class="iconfont-size">+</span> Create list
            </button>
          </div>
        </div>
      </div>
      <!-- Begin page -->
      <div id="wrapper" style="margin-top: 20px">
        <!-- Start content -->
        <div class="container-fluid">
          <!-- Start Edit profile row -->
          <div class="row">
            <div class="table-wrapper">
              <div class="search_pagination_wrapper">
                <nav mat-tab-nav-bar class="row search-header align-items-center">
                  <div class="col-md-5 col-sm-12" style="padding: 10px 0px">
                    
                    <!-- <span *ngIf="!showLoader" class="icon-content">{{ tabItems[0].name }}</span> -->
                    <!-- <nav> -->
                      <a
                        mat-tab-link
                        *ngFor="let tab of tabItems; index as i"
                        (click)="activeLink = tab; setPage(1)"
                        class="tab-anchor"
                        [active]="activeLink == tab"
                        >
                        <span [class.active]="activeLink == tab" style="color: black">
                        {{tab}}
                        </span>
                        <!-- <span class="icon-content">{{ tab.name }}</span> -->
                        <!-- <span class="placeholder-item"> </span> -->
                      </a>
                    <!-- </nav> -->
                  </div>

                  <div class="row col-md-7 col-sm-12 align-items-center">
                    <div class="col-md-8 col-sm-12">
                      <input
                        type="text"
                        id="filter-text-box"
                        (keyup)="onFilterTextBoxChanged($event)"
                        class="form-control form-control-lg list-search-bar"
                        placeholder="Search"
                        [(ngModel)]="searchString"
                      />
                    </div>

                    <div class="col-md-4 col-sm-12">
                      <div class="paginationWrapper">
                        <div
                          class="paginationWrapper row align-items-center justify-content-end"
                          *ngIf="totalSize > 0"
                        >
                          <div class="paginationInfo">
                            {{ offset + 1 }}-{{
                              offset + count > totalSize
                                ? totalSize
                                : count + offset
                            }}
                            of {{ totalSize }}
                          </div>
                          <ul
                            *ngIf="pager.pages && pager.pages.length"
                            class="pagination"
                          >
                            <!-- <li [ngClass]="{disabled:pager.currentPage === 1}">
                                                    <a *ngIf="pager.currentPage === 1"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                                                    <a (click)="setPage(1)" *ngIf="pager.currentPage !== 1"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                                                  </li> -->
                            <li
                              [ngClass]="{ disabled: pager.currentPage === 1 }"
                            >
                              <a
                                *ngIf="pager.currentPage === 1"
                                style="border-radius: 5px 0 0 5px !important"
                                ><i
                                  class="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i
                              ></a>
                              <a
                                *ngIf="pager.currentPage !== 1"
                                (click)="setPage(pager.currentPage - 1)"
                                style="border-radius: 5px 0 0 5px !important"
                                ><i
                                  class="fa fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i
                              ></a>
                            </li>
                            <!-- <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                                                    <a (click)="setPage(page)">{{page}}</a>
                                                  </li> -->
                            <li
                              [ngClass]="{
                                disabled: pager.currentPage === pager.totalPages
                              }"
                            >
                              <a
                                *ngIf="pager.currentPage === pager.totalPages"
                                style="border-radius: 0 5px 5px 0 !important"
                                ><i
                                  class="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i
                              ></a>
                              <a
                                *ngIf="pager.currentPage !== pager.totalPages"
                                (click)="setPage(pager.currentPage + 1)"
                                style="border-radius: 0 5px 5px 0 !important"
                                ><i
                                  class="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i
                              ></a>
                            </li>
                            <!-- <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                                      <a *ngIf="pager.currentPage === pager.totalPages"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                                                    <a  *ngIf="pager.currentPage !== pager.totalPages" (click)="setPage(pager.totalPages)"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                                                  </li> -->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
                <div style="display: flex; flex-direction: row; height: 100%">
                  <div style="overflow: hidden; flex-grow: 1">
                    <ag-grid-angular
                      style="width: 100%; height: 100%"
                      class="ag-theme-material list1"
                      (gridReady)="onGridReady($event)"
                      #agGrid
                      [defaultColDef]="defaultColDef"
                      [columnDefs]="columnDefs"
                      [animateRows]="true"
                      (cellClicked)="onCellClicked($event)"
                      [sortingOrder]="sortingOrders"
                      (firstDataRendered)="onFirstDataRendered($event)"
                      [paginationPageSize]="paginationPageSize"
                      [frameworkComponents]="frameworkComponents"
                      [context]="context"
                      [rowHeight]="55"
                      [domLayout]="domLayout"
                      [headerHeight]="45"
                      [overlayNoRowsTemplate]="noRowsTemplate"
                      (cellValueChanged)="onCellValueChanged($event)"
                      >
                      <!-- [tooltipShowDelay]="0" -->
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
              <!-- <div style="display: flex; flex-direction: row; height: 100%">
                <div style="overflow: hidden; flex-grow: 1">
                  <ag-grid-angular
                    style="width: 100%; height: calc(100vh - 350px)"
                    class="ag-theme-material"
                    (gridReady)="onGridReady($event)"
                    #agGrid
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs"
                    [animateRows]="true"
                    (cellClicked)="onCellClicked($event)"
                    [sortingOrder]="sortingOrders"
                    (firstDataRendered)="onFirstDataRendered($event)"
                    [paginationPageSize]="paginationPageSize"
                    (columnResized)="onColumnResized($event)"
                  >
                    >
                  </ag-grid-angular>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #anotherToolTipTemplate let-row="row">
  <div class="custom-another-tooltip common">
    <label>{{ row.listName }}</label>
  </div>
</ng-template>
</div>
