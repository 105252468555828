<div id="wrapper" style="height: 100%">
	<app-header [user]="" style="width: 100%"></app-header>

	<div class="content-page" style="height: 100%">
		<div class="enlarged">
			<nav class="header">
				<div
					class="d-flex justify-content-between align-center"
					style="gap: 15px"
					*ngIf="!showLoader"
				>
					<button [matMenuTriggerFor]="menu" class="primary-button ml-0 d-flex align-center">
						<i class="fa fa-filter mr-2"></i>Sort
					</button>
					<div class="paginationWrapper d-flex align-center justify-content-end" *ngIf="totalCount > 0">
						<div class="pull-right paginationInfo mr-2 mt-2">
							{{ offset }}-{{ count }} of
							{{ totalCount }}
						</div>
						<ul class="pagination">
							<li
								[ngClass]="{
									disabled: pager.currentPage === 1
								}"
							>
								<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
									><i class="fa fa-chevron-left" aria-hidden="true"></i
								></a>
								<a
									*ngIf="pager.currentPage !== 1"
									(click)="onPaginationClick(pager.currentPage - 1)"
									style="border-radius: 5px 0 0 5px !important"
									><i class="fa fa fa-chevron-left" aria-hidden="true"></i
								></a>
							</li>
							<li
								[ngClass]="{
									disabled: pager.currentPage === pager.totalPages
								}"
							>
								<a
									*ngIf="pager.currentPage === pager.totalPages"
									style="border-radius: 0 5px 5px 0 !important"
									><i class="fa fa-chevron-right" aria-hidden="true"></i
								></a>
								<a
									*ngIf="pager.currentPage !== pager.totalPages"
									(click)="onPaginationClick(pager.currentPage + 1)"
									style="border-radius: 0 5px 5px 0 !important"
									><i class="fa fa-chevron-right" aria-hidden="true"></i
								></a>
							</li>
						</ul>
						
					</div>
					<mat-menu #menu="matMenu">
						<button mat-menu-item (click)="setFilter('')">
							<span>Requested + In Progress</span>
						</button>
						<button mat-menu-item (click)="setFilter('Requested')">
							<span>Requested</span>
						</button>
						<button mat-menu-item (click)="setFilter('Inprogress')">
							<span>In Progress</span>
						</button>
						<button mat-menu-item (click)="setFilter('Completed')">
							<span>Completed</span>
						</button>
					</mat-menu>
				</div>
			</nav>

			<div class="main">
				<ng-container *ngIf="requestList.length > 0 && !showLoader">
					<ng-container *ngFor="let request of requestList">
						<div class="card">
							<div class="d-flex flex-column">
								<span class="text-small">User Email</span>
								<span class="text-large">{{ request.createdByEmail }}</span>
							</div>
							<div class="d-flex flex-column">
								<span class="text-small">List Name</span>
								<span class="text-large">{{ request.listname }}</span>
							</div>
							<div class="d-flex flex-column">
								<span class="text-small">Mobile Request Count</span>
								<span class="text-large">{{ request.mobileRequestCount }}</span>
							</div>
							<div class="d-flex flex-column">
								<span class="text-small">Mobile Credit</span>
								<span class="text-large">{{ request.mobileCredit }}</span>
							</div>
							<div class="d-flex flex-column">
								<span class="text-small">Status</span>
								<div
									class="status-chip"
									[ngClass]="
										request.mobileRequestStatus == 'Completed'
											? 'completed'
											: request.mobileRequestStatus == 'Requested'
											? 'requested'
											: 'inProgress'
									"
								>
									<span>{{
										request.mobileRequestStatus == 'Inprogress' ? 'In Progress' : request.mobileRequestStatus
									}}</span>
								</div>
							</div>
							<div>
								<button class="primary-button secondary-button" (click)="onFileDownload(request.listId)">
									<i class="fa fa-download mr-2"></i>Download
								</button>
							</div>
							<div class="button-container">
								<input
									hidden
									type="file"
									#uploader
									(change)="onFileUpload($event, request.listId, request.user_id)"
								/>
								<button
									class="primary-button"
									[class.disabled-button]="request.mobileRequestStatus == 'Requested'"
									(click)="uploader.click()"
									[disabled]="request.mobileRequestStatus == 'Requested'"
								>
									<span> <i class="fa fa-upload mr-2"></i>Upload </span>
								</button>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="requestList.length <= 0 && !showLoader">
					<div class="no-data-container">
						<span>No Data</span>
					</div>
				</ng-container>

				<ng-container *ngIf="showLoader">
					<div class="no-data-container">
						<app-circle-loader [radius]="30" *ngIf="showLoader"></app-circle-loader>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
