import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { PayorService } from '../../service/payor.service';

@Component({
	selector: 'app-clinical-overview',
	templateUrl: './clinical-overview.component.html',
	styleUrls: ['./clinical-overview.component.css']
})
export class ClinicalOverviewComponent implements OnInit {
	executiveTableHeader: any = [
		{
			key: 'fullname',
			name: 'Executive Name',
			type: 'text',
			isLink: true
		},
		{
			key: 'title',
			name: 'Title',
			type: 'text'
		},
		{
			key: 'email',
			name: 'Email Address',
			type: 'text'
		}
	];
	loadExecutive: boolean = false;
	executiveList: any = [];
	paramsData: any;
	showButtonLoader: boolean = false;
	clinicDetails: any = {
		imagingCenterId: '',
		centerName: '',
		address: '',
		city: '',
		state: '',
		country: '',
		phoneNumber: [],
		web_Address: '',
		noOfExecutive: 0
	};
	imagingOverviewData: any;
	overviewData: any = {};
	imagingMoreInfoData: any;
	isOverviewAvailable: boolean = false;
	isMoreInfoAvailable: boolean = true;
	showSaveButton: boolean = false;
	saveDrawer: boolean = false;
	notCorrectDrawer: boolean = false;
	notCorrectReasons: Array<any> = ['Center name', 'Imaging equipments', 'Location'];
	DataResult: Array<any> = [];
	currentCredit: any = 0;
	requiredDetailsList = {
		accreditationagency: 'Accreditation agency',
		address: 'Address',
		cbsacode: 'CBSA Code',
		cbsapopulationestmostrecent: 'CBSA Population EST most recent',
		state: 'State',
		country: 'Country',
		facilitytype: 'Facility Type',
		firmtype: 'Firm Type',
		geoclassification: 'Geo Classification',
		hospitalparentname: 'Hospital Parent Name',
		hospitalparentpath: 'Hospital Parent Path',
		latitude: 'Latitude',
		longitude: 'Longitude',
		npinumber: 'NPI Number',
		numberofaffiliatedphysicians: 'Number of Affiliated Physicians',
		providernumber: 'Provider Number',
		zipcode: 'Zipcode'
	};

	constructor(
		private activatedRoute: ActivatedRoute,
		private imagingService: PayorService,
		private messageService: MessageService,
		private loaderService: LoaderService,
		private amplizService: AmplizService,
		private router: Router
	) {}

	ngOnInit() {
		this.paramsData = this.activatedRoute.snapshot.params['clinicId'];
		this.getImaginCenterDetails();
		this.getImagingCenterOverviewData();
		this.getExecutives();
	}
	getExecutives() {}
	get getWebAddress() {
		if (this.clinicDetails.webaddress) {
			return this.clinicDetails.webaddress.includes('http')
				? this.clinicDetails.webaddress
				: 'https://' + this.clinicDetails.webaddress;
		}
		return '';
	}
	getImaginCenterDetails() {
		this.imagingService.getClinicDetails(this.paramsData).subscribe(
			(res) => {
				this.loaderService.display(false);
				this.clinicDetails = res.clinicInfoList;
				this.clinicDetails.address = this.clinicDetails.address + ' ,' + this.clinicDetails.address1;
				Object.entries(this.clinicDetails).map(([key, value]) => {
					if (Object.keys(this.requiredDetailsList).includes(key)) {
						this.overviewData[this.requiredDetailsList[key]] = value || '---';
					}
				});
			},
			(err) => {
				this.loaderService.display(false);
			}
		);
	}
	getImagingCenterOverviewData() {}
	getImagingMoreInfoData() {}
	get saveButtonText() {
		return this.clinicDetails.leadSaveStatus == 'NotSaved' ? 'View' : 'Save';
	}
	viewImagingCenterFromList() {
		const body = {
			mcoExecutiveId: this.clinicDetails.mcoExecutiveId
		};
		this.showButtonLoader = true;
		this.imagingService.viewPayourFromList(body).subscribe(
			(res) => {
				this.messageService.display(true, 'Successfully added to the list');
				this.imagingService.getPayourCenterDetails(this.clinicDetails.mcoExecutiveId).subscribe(
					(overview) => {
						this.showButtonLoader = false;
						this.clinicDetails = overview.mcoExecutiveInfo;
						if (overview.mcoExecutiveInfo.leadSaveStatus !== 'Saved') {
							this.showSaveButton = true;
						}
					},
					(err) => {
						this.showButtonLoader = false;
					}
				);
			},
			(err) => {
				this.showButtonLoader = false;
			}
		);
	}
	handleSaveButtonPress() {
		const leadSaveStatus = this.clinicDetails.leadSaveStatus;
		if (leadSaveStatus == 'NotSaved') {
			this.viewImagingCenterFromList();
		} else {
			this.saveDrawer = true;
		}
	}
	cancelBtnClick(value: boolean) {
		this.saveDrawer = value;
		this.notCorrectDrawer = value;
	}
	refreshedData(ev: any) {
		if (ev === true) {
			this.loaderService.display(true);
			setTimeout(() => {
				this.getImaginCenterDetails();
			}, 200);
		}
	}
	request(request: any) {
		this.loaderService.display(true);
		const body = {
			comid: '0',
			url: window.location.href,
			intentrequest: request
		};
		this.amplizService.request_access(body).subscribe(
			(res) => {
				this.loaderService.display(false);
				this.messageService.display(true, res.msg);
			},
			(err) => {
				this.loaderService.display(true);
			}
		);
	}
	tabClick(event) {}
	get location() {
		let loc = [];
		if (this.clinicDetails.address != '') {
			loc.push(this.clinicDetails.address);
		}
		if (this.clinicDetails.city != '') {
			loc.push(this.clinicDetails.city);
		}
		if (this.clinicDetails.state != '') {
			loc.push(this.clinicDetails.state);
		}
		if (this.clinicDetails.country != '') {
			loc.push(this.clinicDetails.country);
		}
		if (this.clinicDetails.zipcode != '') {
			loc.push(this.clinicDetails.zipcode);
		}
		return loc.join(', ');
	}

	executiveNamePressed(event: any = {}) {
		const mcoExecutiveId = event.value.mcoExecutiveId;
		this.router.navigate([]).then((result) => {
			window.open(`payor/${mcoExecutiveId}`, '_blank');
		});
	}
}
