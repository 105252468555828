import { Component, OnInit, ElementRef, Input, ViewChild } from '@angular/core';
import { SearchPhysicianModel } from '../../models/searchPhysicianModel';
import { AmplizService } from '../../services/ampliz.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { DataService } from '../../services/data.service';
@Component({
	selector: 'app-hc-save-search-card',
	templateUrl: './hc-save-search-card.component.html',
	styleUrls: ['./hc-save-search-card.component.css'],
	animations: [
		trigger('inputAnimation', [
			transition(':enter', [
				style({ transform: 'translateX(100%)', opacity: 0 }),
				animate('200ms', style({ transform: 'translateX(0)', opacity: 1 }))
			])
		]),
		trigger('nameAnimation', [
			transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))])
		])
	]
})
export class HcSaveSearchCardComponent implements OnInit {
	@Input() searchData: any;
	searchName: string = '';
	showInput: boolean = false;
	searchContactInput: SearchPhysicianModel = new SearchPhysicianModel();
	@ViewChild('name') searchElement: ElementRef;
	constructor(
		private amplizService: AmplizService, // private dataService: DataService,
		private messageService: MessageService,
		private dataService: DataService
	) {}

	ngOnInit() {
		this.searchName = this.searchData.searchName;
	}

	handleRenameClick() {
		this.showInput = true;
		setTimeout(() => {
			this.searchElement.nativeElement.focus();
		}, 0);
	}
	handleInputCancel() {
		this.showInput = false;
		this.searchName = this.searchData.searchName || '';
	}

	handleChangeNameClicked(event) {
		this.showInput = false;
		this.updateName();
	}

	updateName() {
		if (this.searchName !== this.searchData.searchName && this.searchName !== '') {
			this.amplizService.renameSavedSearch(this.searchData.savedSearchId, this.searchName).subscribe(
				(res) => {
					this.searchData.searchName = this.searchName;
					this.messageService.display(true, 'Successfully Renamed');
				},
				(err) => {
					this.searchName = this.searchData.searchName;
					this.messageService.displayError(true, 'Error while renaming');
				}
			);
		} else {
			this.searchName = this.searchData.searchName;
		}
	}

	makeSearch() {
		this.amplizService.getFiltersForSaveSearch(this.searchData.savedSearchId).subscribe((res) => {
			const filtered = res.savedPhysicianSearchDetails;
			delete filtered.searchName;
			delete filtered.savedSearchType;
			delete filtered.savedDateTime;
			delete filtered.savedSearchId;

			this.dataService.passSaveSearchFilter(filtered);

			this.dataService.saveSearchPanelVisible.next(false);
		});
		// this.amplizService
		// 	.renameSavedSearch(this.searchData.savedSearchId, this.searchName)
		// 	.subscribe((res) => {
		// 		console.log('RES', res);
		// 	});
		// console.log(this.searchData.savedSearchType);
		// this.dataService.searchOrRecentTapped(true);
		// if (this.searchData.savedSearchType == 'Contact') {
		// 	const contactObj = this.searchContactInput.fromJson(this.searchData);
		// 	this.dataService.passSearchContactInput(contactObj);
		// 	this.dataService.changeSelectedTab(0);
		// } else {
		// 	const companyObj = this.searchCompanyInput.fromJson(this.searchData);
		// 	this.dataService.passSearchCompanyInput(companyObj);
		// 	this.dataService.changeSelectedTab(1);
		// }
		// this.dataService.makeSavesearchVisible(false);
		// this.dataService.makeRecentsearchVisible(false);
	}
}
