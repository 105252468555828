<span class="form-label" *ngIf="title">{{ title }}</span>
<mat-form-field>
	<mat-select [(value)]="selectedData">
		<mat-select-trigger>
			<mat-chip-list>
				<mat-basic-chip
					*ngIf="selectedData?.length > 0"
					[selectable]="selectable"
					[removable]="removable"
					(removed)="remove()"
				>
					{{ itemName && returnObject ? selectedData[itemName] : selectedData }}
					<mat-icon matChipRemove>close</mat-icon>
				</mat-basic-chip>
			</mat-chip-list>
		</mat-select-trigger>

		<mat-option
			*ngFor="let item of list"
			[value]="itemValue ? item[itemValue] : item"
			(click)="selectItem(item)"
		>
			{{ itemName ? item[itemName] : item }}
		</mat-option>
	</mat-select>
</mat-form-field>
