<div class="card column filter-component" style="width: 23rem">
	<div class="image-filter">
		<div class="flex-row filter-header">
			<div>
				<h6 class="card-title titleCardStyle">Showing results for</h6>
			</div>
			<div
				class="searchButton primary"
				[ngClass]="filterData.validateImagingSearch() ? 'primaryBtn' : ''"
				(click)="clearFilter()"
			>
				<span> Clear </span>
			</div>
		</div>
		<mat-accordion>
			<app-individual-filter
				#companyAuto
				(valueChanges)="companyValueChanges($event)"
				[totalItems]="companyList"
				[(includedItems)]="filterData.centerName"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Company"
				title="Company name"
			></app-individual-filter>

			<app-individual-filter
				#noOfImgEqListMulti
				type="singleselectnumber"
				[totalItems]="numberOfImagingEquipmentList"
				[(includedItems)]="filterData.industry"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="MCO type"
				title="MCO"
				[disabled]="!isSubscribed"
			>
			</app-individual-filter>

			<div class="location">
				<app-individual-location-filter
					[countryList]="countryList"
					(locationValueChanged)="hanldeLocationValueChange($event)"
					[disabled]="!isSubscribed"
					[(selectedStatesFromFilter)]="filterData.stateList"
					[(selectedCitiesFromFilter)]="filterData.cityList"
				>
				</app-individual-location-filter>
			</div>
			<app-more-fliter-button
				[isPaid]="!isSubscribed"
				(openMoreFilter)="openMoreFilter()"
			></app-more-fliter-button>
		</mat-accordion>
	</div>
	<div class="upgrade-card" (click)="requestPricing()" *ngIf="isPaid">
		<div>
			<div class="blt-background">
				<mat-icon class="mt-ico">bolt</mat-icon>
			</div>
		</div>
		<div style="padding-left: 10px">
			<div class="upgrd-to-unlock">Request to unlock more filters</div>
			<div class="improve-search">Improve your search result with additional filters</div>
		</div>
	</div>
</div>

<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="recordCountLoader"
		[totalRecords]="moreFilterRecordCount.value"
		(clear)="moreFilterAccordian.closeAll(); clearTempFilter()"
		(applyFilter)="applyMoreFilters()"
		(closeFilter)="closeDialogBox('mainDialog')"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Company"
					[(selectedData)]="tempDialogFilters.centerName"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Company Name"
						[(list)]="companyList"
						[(selectedData)]="tempDialogFilters.centerName"
						(onItemSelect)="getRecordCount()"
						(onChange)="companyValueChanges($event)"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="MCO"
					[(selectedData)]="tempDialogFilters.industry"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-signle-select-drop-down
						title="MCO Type"
						[list]="numberOfImagingEquipmentList"
						[(selectedData)]="tempDialogFilters.industry"
						(onItemSelect)="getRecordCount()"
					></app-signle-select-drop-down>
				</app-more-filter-expand-item>
				<app-location-filter
					class="dialog-flex-container"
					[countryList]="[]"
					(locationValueChanged)="getRecordCount()"
					[(selectedStatesFromFilter)]="tempDialogFilters.stateList"
					[(selectedCitiesFromFilter)]="tempDialogFilters.cityList"
				>
				</app-location-filter>
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<app-more-fliter-close-dialog
		[(showDialog)]="showConfirmDialog"
		(closeFilter)="closeDialogBox('mainDialog', true)"
	></app-more-fliter-close-dialog>
</ng-template>
