import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-request-mobile',
	template: `
		<ng-container *ngIf="mobileRequestStatus == 'Inprogress' || mobileRequestStatus == 'Requested'">
			<span
				class="status-chip"
				[ngClass]="mobileRequestStatus == 'Inprogress' ? 'inProgress' : 'requested'"
			>
				{{ mobileRequestStatus == 'Inprogress' ? 'In Progress' : mobileRequestStatus }}
			</span>
		</ng-container>

		<ng-container *ngIf="mobileRequestStatus == 'Completed' || mobileRequestStatus == ''">
			<button
				type="button"
				(click)="btnClickedHandler()"
				class="btn btn-primary btn-outline-light pr-3 pl-3"
				style="width: 100%; border-radius: 4px !important"
				*ngIf="params.data.noOfLeads > 0"
			>
				{{
					mobileRequestStatus == 'Completed' ? 'Request Mobile Numbers Again' : 'Request Mobile Numbers'
				}}
			</button>
		</ng-container>
	`,
	styleUrls: ['./request-mobile.component.css']
})
export class RequestMobileComponent implements ICellRendererAngularComp {
	constructor() {}
	public params: any;
	mobileRequestStatus: string = '';

	agInit(params: any): void {
		this.params = params;
		this.mobileRequestStatus = params.data.mobileRequestStatus;
		//
	}
	refresh(params?: any): boolean {
		return true;
	}

	btnClickedHandler() {
		this.params.clicked(this.params);
	}
	ngOnInit(): void {}
}
