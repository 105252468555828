export class SearchHospital {
	constructor(
		public hospitalName: any[] = [],
		public hospitalType: any[] = [],
		public numberOFBeds: any[] = [],
		public icdTenCodes: any[] = [],
		public cptCodes: any[] = [],
		public hcpcsCodes: any[] = [],
		public city: any[] = [],
		public stateList: any[] = [],
		public ownerType: any[] = [],
		public geoclassification: any[] = [],
		public entity_type: any[] = [],
		public hospital_status: any[] = []
	) {}
	public toJson(): object {
		return {
			city: this.city.map((el) => el?.city || el),
			stateList: this.stateList.map((el) => el?.state || el),
			hospitalName: this.hospitalName,
			hospitalType: this.hospitalType,
			numberOFBeds: this.numberOFBeds,
			icdTenCodes: this.icdTenCodes,
			cptCodes: this.cptCodes,
			hcpcsCodes: this.hcpcsCodes,
			ownerType: this.ownerType,
			geoclassification: this.geoclassification,
			entity_type: this.entity_type,
			hospital_status: this.hospital_status
		};
	}

	validateImagingSearch() {
		return (
			this.city.length > 0 ||
			this.stateList.length > 0 ||
			this.hospitalName.length > 0 ||
			this.hospitalType.length > 0 ||
			this.numberOFBeds.length > 0 ||
			this.icdTenCodes.length > 0 ||
			this.cptCodes.length > 0 ||
			this.hcpcsCodes.length > 0
		);
	}
}
