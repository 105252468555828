<div class="container">
	<app-no-member-limit *ngIf="remainingLicence <= 0"> </app-no-member-limit>

	<div class="card" *ngIf="remainingLicence > 0">
		<!-- Header 1 starts -->
		<div class="header">
			<span class="header-text"
				>Total member left <b>{{ remainingLicence }}</b></span
			>
		</div>
		<!-- Header 1 ends -->

		<!-- Email Text area start -->
		<div class="email-container row justify-content-center">
			<textarea
				class="col-11"
				name=""
				id=""
				cols="30"
				rows="5"
				placeholder="Add multiple email address separated by commas"
				[(ngModel)]="params.emails"
				(keypress)="invalidEmails = [] && (invalidDomains = [] && (alreadyExistEmails = []))"
			></textarea>
			<div class="col-11 text-left disclaimer-text">
				<span *ngIf="invalidEmails.length <= 0 && invalidDomains.length <= 0 && alreadyExistEmails.length <= 0"
					>Users will join with non-admin permissions.</span
				>
				<span class="error-text" *ngIf="invalidEmails.length > 0">
					&#8226; Please check this email: <b>{{ invalidEmails.toString().replaceAll(',', ', ') }}</b>
				</span>

				<span class="error-text" *ngIf="invalidDomains.length > 0">
					&#8226; This email's domain doesn't match with your domain ({{ this.domain }}) :
					<b>{{ invalidDomains.toString().replaceAll(',', ', ') }}</b>
				</span>

				<span class="error-text" *ngIf="alreadyExistEmails.length > 0">
					&#8226; This email already exists in the team:
					<b>{{ alreadyExistEmails.toString().replaceAll(',', ', ') }}</b>
				</span>
			</div>
			<div class="role-container col-11">
				<span>Role</span>
				<div class="d-flex mt-3">
					<button
						type="button"
						class="role-btn"
						*ngFor="let item of roleList; let i = index"
						(click)="activeRole = i; params.role = roleList[i]"
						[class.role-active]="activeRole === i"
					>
						{{ item }}
					</button>
				</div>
			</div>
		</div>
		<!-- Email Text area ends -->

		<!-- Header 2 starts -->
		<div class="header d-flex align-items-center justify-content-start" [class.header-error]="headerError">
			<span class="header-text col-5">
				Your total credit <b>{{ remainingCredit }}</b>
			</span>
			<span class="header-text col-3">
				Your mobile credit
				<b>
					{{ remainingMobileCredit }}
				</b>
			</span>

			<div class="justify-content-end low-credit-cta" *ngIf="headerError">
				<span (click)="upgrade()">Upgrade Credits</span>
			</div>
		</div>
		<!-- Header 2 ends -->

		<div class="content-section d-flex flex-column">
			<div class="input-container row pb-2">
				<div class="credit-container col-6 flex-column">
					<div class="input-header">
						<span>Credits</span>
					</div>
					<div class="input-group">
						<div class="input-group-prepend">
							<button
								class="btn range-button"
								type="button"
								(click)="decrement(true)"
								[class.range-error]="!isCreditCorrect()"
							>
								<i class="fa fa-minus" [class.icon-error]="!isCreditCorrect()"></i>
							</button>
						</div>
						<input
							type="number"
							min="1"
							class="form-control"
							value="1"
							[(ngModel)]="params.dailyCredit"
							(keypress)="
								isCreditCorrect();
								(($event.charCode != 8 && $event.charCode == 0) || ($event.charCode >= 48 && $event.charCode <= 57))
							"
							[class.input-error]="!isCreditCorrect()"
							step="1"
						/>
						<div class="input-group-append">
							<button
								class="btn range-button"
								type="button"
								(click)="increment(true)"
								[class.range-error]="!isCreditCorrect()"
							>
								<i class="fa fa-plus" [class.icon-error]="!isCreditCorrect()"></i>
							</button>
						</div>
					</div>
				</div>

				<div class="mobile-credit-container col-6 flex-column">
					<div class="input-header">
						<span class>Mobile credits</span>
					</div>
					<div class="input-group">
						<div class="input-group-prepend">
							<button
								class="btn range-button"
								type="button"
								(click)="decrement()"
								[class.range-error]="!isMobileCreditCorrect()"
							>
								<i class="fa fa-minus" [class.icon-error]="!isMobileCreditCorrect()"></i>
							</button>
						</div>
						<input
							type="number"
							min="1"
							class="form-control"
							value="1"
							step="1"
							[(ngModel)]="params.mobileCredit"
							(keypress)="
								isMobileCreditCorrect();
								(($event.charCode != 8 && $event.charCode == 0) || ($event.charCode >= 48 && $event.charCode <= 57))
							"
							[class.input-error]="!isMobileCreditCorrect()"
						/>
						<div class="input-group-append">
							<button
								class="btn range-button"
								type="button"
								(click)="increment()"
								[class.range-error]="!isMobileCreditCorrect()"
							>
								<i class="fa fa-plus" [class.icon-error]="!isMobileCreditCorrect()"></i>
							</button>
						</div>
					</div>
					<!-- <span *ngIf="!isMobileCreditCorrect()">
						Mobile credits exceeding your total mobile credits</span
					> -->
				</div>
			</div>
		</div>

		<!-- Permissions  -->
		<div class="header d-flex align-items-center justify-content-start">
			<span class="header-text"> Permissions </span>
		</div>

		<div class="flex-container">
			<div class="title-col">
				<span>Download List</span>
				<p>Has an ability to download list</p>
			</div>

			<div class="action-col row align-items-center">
				<input type="checkbox" id="switch" [(ngModel)]="params.isDownload" /><label for="switch">Toggle</label>
			</div>
		</div>

		<!-- Permissions end -->

		<!-- action button starts -->
		<div class="action-section">
			<div
				class="action-button-container d-flex align-items-center justify-content-end gap-3"
				*ngIf="showConfirmEmails.length == ''"
			>
				<!-- <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. </span> -->
				<span class="canel-btn" (click)="handleCancel()">Cancel</span>
				<button
					[class.disable-btn]="!enableBtn"
					class="save-btn"
					type="button"
					(click)="handleSubmit()"
					*ngIf="!loader"
				>
					Send Invite
				</button>
				<svg class="loader-svg" viewBox="25 25 50 50" *ngIf="loader">
					<circle r="10" cy="50" cx="50"></circle>
				</svg>
			</div>

			<!-- action button ends -->

			<!-- Confirm box starts -->
			<div class="action-button-container mt-3 gap-3 message-container" *ngIf="showConfirmEmails !== ''">
				<div>
					<svg
						viewBox="0 0 24 24"
						width="24"
						height="24"
						stroke="currentColor"
						stroke-width="2"
						fill="none"
						stroke-linecap="round"
						stroke-linejoin="round"
						class="css-i6dzq1 align-self-start"
					>
						<circle cx="12" cy="12" r="10"></circle>
						<line x1="12" y1="16" x2="12" y2="12"></line>
						<line x1="12" y1="8" x2="12.01" y2="8"></line>
					</svg>
				</div>
				<div style="flex: 1">
					<span class="confirm-text">
						We've noticed that this email address is already associated with an Ampliz account. <br />
						Would you like to continue to add this account to your organization's account? <br />
						<b style="text-decoration: underline">{{ showConfirmEmails }}</b>
					</span>
				</div>

				<div>
					<span class="canel-btn mr-4" (click)="handleConfirmation(false)">No</span>
					<button class="save-btn" type="button" (click)="handleConfirmation(true)">Yes</button>
				</div>
			</div>
			<!-- Confirm box ends -->
		</div>
	</div>
</div>
