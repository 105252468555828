import { Component, OnInit, Input } from '@angular/core';
import { AmplizService } from '../../../services/ampliz.service';

@Component({
	selector: 'app-hospital-techiq',
	templateUrl: './hospital-techiq.component.html',
	styleUrls: ['./hospital-techiq.component.css']
})
export class HospitalTechiqComponent implements OnInit {
	constructor(private amplizService: AmplizService) {}
	@Input() hospitalID: any;
	@Input() hospitalName: any;
	@Input() subscribed: boolean;

	hospitalTechIQList: any = [];
	loading: boolean = false;

	ngOnInit(): void {
		this.getHospitalTechIQ();
	}

	getHospitalTechIQ() {
		if (JSON.parse(sessionStorage.getItem('hospitalTechIQList')) == null) {
			this.amplizService.getHospitalTechIQ(this.hospitalID).subscribe((res: any) => {
				this.hospitalTechIQList = res.hospitalTechIQ;
				delete this.hospitalTechIQList['IDN Parent ID'];
				delete this.hospitalTechIQList['IDN ID'];
				delete this.hospitalTechIQList['HospitalID'];
				sessionStorage.setItem('hospitalTechIQList', JSON.stringify(this.hospitalTechIQList));
			});
		} else {
			this.hospitalTechIQList = JSON.parse(sessionStorage.getItem('hospitalTechIQList'));
		}
	}

	downloadPdf() {
		if (!this.subscribed) return;
		this.loading = true;
		this.amplizService.hospitalTechIqPdfDownload(this.hospitalID).subscribe(
			(res) => {
				const blob = new Blob([res.body], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${this.hospitalName}_TechIQ.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setTimeout(() => {
					this.loading = false;
				}, 100);
			},
			(err) => {
				this.loading = false;
				console.log('ERR', err);
			}
		);
	}
}
