
<div class="d-flex justify-content-center align-items-center h-100 border-bottom" style="min-height:400px">
<div class="row">
    <div class="col-6 text-right" ><img src="assets/images/Upgrade-illus.png" width="50%" class="text-right"></div>
    <div class="col-6 mt-3">
        <div class="head1"><img src="assets/images/star.png" width="30" /> Upgrade for more features</div>
        <div class="head2">To see all, Upgrade to professional plan</div>
        <div class="head3"><button type="button"  class="btn btn-primary-locked  rounded-btn" (click)="upgradeBtn()" >Upgrade</button></div>
    </div>
</div>
</div>


  