import { Component, OnInit, Input } from '@angular/core';
import { CustomTooltipComponent } from 'src/app/modules/basic/component/custom-tooltip/custom-tooltip.component';
import { CellValueChangedEvent } from 'ag-grid-community';
import { AmplizService } from '../../../services/ampliz.service';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-hospital-hscpc-details',
	templateUrl: './hospital-hscpc-details.component.html',
	styleUrls: ['./hospital-hscpc-details.component.css']
})
export class HospitalHscpcDetailsComponent implements OnInit {
	@Input() physicianId: string;
	alert: boolean = false;
	columnDefs: any;
	searchString: string = '';
	paginationPageSize: number;
	sortingOrders: any;
	gridApi: any;
	gridColumnApi: any;
	paramsData: any;
	datasource: any;
	defaultColDef: any;
	pager: any = { currentPage: 1, offset: 10, totalPages: 1 };
	hcpcsControl = new FormControl();
	totalCount = 0;
	public overlayLoadingTemplate =
		'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
	public noRowsTemplate = `"<span">no rows to show</span>"`;

	context: any;
	public domLayout = 'autoHeight';
	constructor(public amplizService: AmplizService) {}

	ngOnInit(): void {
		this.getHcpcsDetails();
		this.getHCPCSDetailsBySearch();
		this.context = {
			componentParent: this
		};
		this.columnDefs = [
			{ tooltipField: 'col1' },
			{
				headerName: 'Code',
				field: 'hcpcsCode',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				autoHeight: true,
				sortable: true,
				lockPosition: true,
				width: 120,
				suppressSizeToFit: true,
				cellStyle: { fontWeight: '500' }
			},
			{
				headerName: 'Description',
				field: 'description',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				autoHeight: true,
				lockPosition: true,
				width: 400,
				cellStyle: { 'white-space': 'normal', lineHeight: '20px' }
			},
			{
				headerName: 'Beneficiaries',
				field: 'beneficiaries',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				autoHeight: true,
				lockPosition: true,
				sortable: true,
				width: 180,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			},
			{
				headerName: 'Services Count',
				field: 'servicesCount',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				lockPosition: true,
				autoHeight: true,
				sortable: true,
				width: 180,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			},
			{
				headerName: 'Number of Beneficiary',
				field: 'numberOfBeneficiaryOrPerDayServices',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				wrapText: true,
				lockPosition: true,
				autoHeight: true,
				sortable: true,
				width: 180,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			},
			{
				headerName: 'Avg Submitted Charge Amount',
				field: 'avgSubmittedChargeAmount',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				wrapText: true,
				lockPosition: true,
				autoHeight: true,
				sortable: true,
				width: 180,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			},
			{
				headerName: 'Avg Medicare Allowed Amount',
				field: 'avgMedicareAllowedAmount',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				wrapText: true,
				lockPosition: true,
				autoHeight: true,
				sortable: true,
				width: 180,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			},
			{
				headerName: 'Avg Medicare Payment Amount',
				field: 'avgMedicarePaymentAmount',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				wrapText: true,
				lockPosition: true,
				autoHeight: true,
				sortable: true,
				width: 180,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			},
			{
				headerName: 'Avg Medicare Standardized Payment Amount',
				field: 'avgMedicareStandardizedPaymentAmount',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				wrapText: true,
				lockPosition: true,
				autoHeight: true,
				sortable: true,
				width: 180,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			}
		];
		this.sortingOrders = ['desc', 'asc', null];
		this.paginationPageSize = 10;
		this.defaultColDef = {
			resizable: true,
			tooltipComponent: CustomTooltipComponent
		};
	}
	onGridReady(params, dataSource?: any) {
		this.paramsData = params;
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.paramsData.api.setRowData(dataSource);

		// const allColumnIds: string[] = [];
		// this.gridApi.getColumns()!.forEach((column) => {
		// 	allColumnIds.push(column.getId());
		// });
		// this.gridApi.autoSizeColumns(allColumnIds, false);
	}

	getHcpcsDetails() {
		const offset = (this.pager.currentPage - 1) * this.pager.offset;
		this.amplizService
			.getHospitalHcpcsDetails({
				hospitalId: this.physicianId,
				offset,
				count: this.pager.offset,
				hcpcsCode: this.searchString.toUpperCase()
			})
			.subscribe((res: any) => {
				this.paramsData.api.setRowData(res.hcpcsCodeDetailList);
				this.totalCount = res.totalCount;
				this.pager = {
					...this.pager,
					totalPages: Math.ceil(this.totalCount / this.pager.offset)
				};
			});
	}
	onPaginationClick(currentPage) {
		this.pager = { ...this.pager, currentPage };
		this.getHcpcsDetails();
	}

	get offset() {
		const total = this.pager.offset * (this.pager.currentPage - 1);
		return +total + 1;
	}

	get count() {
		const pageCount = this.pager.currentPage * this.pager.offset;
		return this.pager.currentPage === this.pager.totalPages
			? this.totalCount
			: pageCount > this.totalCount
			? this.totalCount
			: pageCount;
	}

	getHCPCSDetailsBySearch() {
		this.hcpcsControl.valueChanges.debounceTime(300).subscribe((value) => {
			let hv = value !== null ? value : '';

			this.searchString = hv;
			this.getHcpcsDetails();
		});
	}
}
