<div class="content-contianer d-flex flex-column">
	<!-- Header starts -->
	<div class="header-container">
		<!-- Info container starts -->
		<div class="" *ngIf="!addUser && !noResultFound">
			<div class="info-container row align-items-center" *ngIf="!loader">
				<div class="col">
					<h2>
						{{ userInfo.fullName }}
						<span *ngIf="isAdmin">(Admin)</span>
					</h2>
					<p>{{ userInfo.email }}</p>
					<span class="status-text" *ngIf="!isAdmin">
						<span></span>
					</span>
				</div>
				<div class="deactivate-btn col text-right" *ngIf="!isAdmin">
					<span (click)="handleAction()"
						>{{ getStatusCta }}
						<span *ngIf="getStatusCta == 'Resend Invitation?' ? false : true">{{ userInfo.fullName }}?</span></span
					>
				</div>
			</div>

			<div class="info-container row align-items-center loader-container" *ngIf="loader">
				<div class="loader-details col">
					<span class="name-loader"></span>
					<span class="about-loader"></span>
					<span class="status-loader"></span>
				</div>
				<div class="loader-details loader-btn col-2">
					<span class="deactivate-btn-loader"></span>
				</div>
			</div>
		</div>

		<!-- <div
			class="loader-container info-container row align-items-center"
			*ngIf="!addUser && loader"
		></div> -->

		<div class="info-container row align-items-center" *ngIf="addUser">
			<h2 class="col-12">Add New Members</h2>
			<p class="col-12">Invite new users to your organisation!</p>
		</div>

		<!-- Info container ends -->

		<!-- Tab navigation starts -->
		<div
			class="tab-container row"
			*ngIf="!addUser && !noResultFound"
			[style.padding-top]="isAdmin && !loader ? '22px' : ''"
		>
			<div class="tab col-12">
				<a
					class="tab-btns text-uppercase"
					*ngFor="let tab of tabItems; let i = index"
					[class.tab-active]="activeTab === i"
					(click)="changeTab(i)"
				>
					{{ tab.name }}
				</a>
			</div>
		</div>
		<!-- Tab navigation starts -->
	</div>
	<!-- Header Ends -->

	<!-- Action panel starts -->
	<div class="action-container" *ngIf="!noResultFound">
		<div class="add-new-container" *ngIf="addUser">
			<app-add-team-member></app-add-team-member>
		</div>
		<div class="team-action-container" *ngIf="!addUser && !loader">
			<app-team-credits *ngIf="activeTab === 0"></app-team-credits>
			<app-member-list *ngIf="activeTab === 1" [userInfo]="userInfo" [isAdmin]="isAdmin"></app-member-list>
		</div>
	</div>
	<!-- Action panel ends -->

	<!-- No Result Panel Starts -->

	<div class="no-result-container" *ngIf="noResultFound && !addUser">
		<div>
			<img src="assets/images/noresult.png" alt="no-result" />
			<span> We couldn't find any matching records.</span>
		</div>
	</div>

	<!-- No Result Panel ends -->
</div>
<!-- [show]="!isAdmin" -->
<app-confirm-panel
	(cancel)="showPanel = false"
	[show]="showPanel"
	[userInfo]="userInfo"
	[activate]="getStatusCta === 'Activate' ? true : false"
	*ngIf="showPanel"
>
</app-confirm-panel>
