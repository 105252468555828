<div id="wrapper">
    <!-- Top Bar Start -->

    <!-- <app-header [user]="user" style="width:100%"></app-header>{{user}} -->
    <app-sidemenu elementName="Payment"></app-sidemenu>
    <div class="content-page">
        <!-- Start content -->
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <h4 class="header-title m-t-0 m-b-20">Your Billing History</h4>
                    </div>
                    <div class="col-md-12">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table m-t-30">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Transaction</th>
                                                    <th>Amount</th>
                                                    <th class="text-right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        <b class="color-blue">TRAN000001</b> <br> Yearly Plan (100 Credits/Month) <br> Thu ,August 10,2020 ,12:30 PM
                                                    </td>
                                                    <td><b>$480</b> </td>
                                                    <td class="text-right"><button type="button" class="btn btn-default">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>
                                                        <b class="color-blue">TRAN000001</b> <br> Yearly Plan (100 Credits/Month) <br> Thu ,July 10,2020 ,12:30 PM
                                                    </td>
                                                    <td><b>$480</b></td>
                                                    <td class="text-right"><button type="button" class="btn btn-default">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>
                                                        <b class="color-blue">TRAN000003</b> <br> Monthly Plan (100 Credits/Month)<br> Thu ,June 10,2020 ,12:30 PM
                                                    </td>

                                                    <td><b>$50</b></td>
                                                    <td class="text-right"><button type="button" class="btn btn-default">View</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-10 center-page">
                        <div class="row ">
                            <div class="col-sm-12">
                                <div class="text-center m-t-30">
                                    <h3 class="m-b-30 m-t-20">Invoice infromation</h3>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 SucccessBox">
                                <div class="hero_image_success">
                                    <img src="assets/img/success.png" class="card-img-top" alt="success thumbs up image" width="100%">
                                </div>
                                <div>
                                    <p class="text_success">Thank you for your payment. An automated payment receipt will be sent to your registered email.</p>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 SucccessBox1">
                                <div class="card">
                                    <div class="card-header">
                                        <strong>Download the receipt</strong>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table class="table text_table_center">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Serial No.</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">File</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>12.02.2020</td>
                                                        <td>
                                                            <a href="#" download> <i class="  mdi mdi-download fa-lg"></i> Download Invoice</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">2</th>
                                                        <td>12.02.2020</td>
                                                        <td>
                                                            <a href="#" download> <i class="  mdi mdi-download fa-lg"></i> Download Invoice</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">3</th>
                                                        <td>12.02.2020</td>
                                                        <td>
                                                            <a href="#" download> <i class="  mdi mdi-download fa-lg"></i> Download Invoice</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- end col -->
                    </div>
                </div>
                <!-- container -->
            </div>
            <!-- content -->
        </div>
        <app-footer></app-footer>
    </div>


    <!-- main app container -->