<!-- Created by Mehbub -->
<div class="footer text-center">
    <div>
        <strong>Ampliz Sales Buddy</strong> - Copyright © 2020
        <!-- <span style="float: right;">
                                <a id="my_custom_link" style="cursor: pointer;"><span class="  footer-text ">Talk to
                                        a human &nbsp; &nbsp;<img class=""
                                        src="assets/img/icons/support2.png" /> </span></a>
                                </span> -->
    </div>

</div>