export class SearchClinicalModal {
	constructor(
		public nameList: Array<any> = [],
		public cityList: Array<any> = [],
		public stateList: Array<any> = [],
		public npiNumbers: Array<any> = [],
		public offset: number = 0,
		public count: number = 5,
		public clientIp: string = '',
		public firmTypes: Array<any> = []
	) {}

	public toJson(): object {
		return {
			nameList: this.nameList,
			cityList: this.cityList.map((el) => el?.city || el),
			stateList: this.stateList.map((el) => el?.state || el),
			npiNumbers: this.npiNumbers,
			offset: this.offset,
			count: this.count,
			firmTypes: this.firmTypes,
			clientIp: this.clientIp
		};
	}

	public fromJson(obj: any): SearchClinicalModal {
		return new SearchClinicalModal(
			obj.nameList,
			obj.cityList,
			obj.stateList,
			obj.npiNumbers,
			obj.offset,
			obj.count,
			obj.firmTypes,
			obj.clientIp
		);
	}

	validateImagingSearch() {
		return (
			this.nameList.length > 0 ||
			this.cityList.length > 0 ||
			this.stateList.length > 0 ||
			this.firmTypes.length > 0 ||
			this.npiNumbers.length > 0
		);
	}
}
