import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { SearchImagingExecutivesModel } from 'src/app/modules/ImagingCenter/models/SearchImagingExecutivesModel';
import { SearchImagingModel } from 'src/app/modules/ImagingCenter/models/SearchImagingModel';
import { ImagingDataService } from 'src/app/modules/ImagingCenter/services/imaging-data.service';
import { ImagingService } from 'src/app/modules/ImagingCenter/services/imaging.service';
import { SearchPayorExecutive } from '../../models/search-payor-executive.model';
import { DataService } from '../../service/data.service';
import { PayorService } from '../../service/payor.service';
import { ClinicalExecutivesSearch } from '../../models/clinical-model/clinical-executives-search';
import { ClinicalExecutives } from '../../models/clinical-model/clinical-executives';

@Component({
	selector: 'app-clinical-executive-card',
	templateUrl: './clinical-executive-card.component.html',
	styleUrls: ['./clinical-executive-card.component.css']
})
export class ClinicalExecutiveCardComponent implements OnInit {
	@Input() imagingInfo: ClinicalExecutives;
	b2bSaveDrawer: boolean = false;
	showMore: boolean = true;
	sliceLength: number = 5;
	industryAndSkillset: Array<string> = [];
	@Input() checkboxSelected: boolean = false;
	@Input() isSubscribed: boolean = false;
	@Output() checkboxChange = new EventEmitter();
	@Input() checkboxDisabled: boolean = false;
	notCorrectReasons: string[] = ['Name', 'Clinic Name', 'Location', 'Phone', 'Email'];
	constructor(
		private dataService: DataService,
		private router: Router,
		private payorService: PayorService,
		private loaderService: LoaderService,
		private messageService: MessageService,
		private amplizService: AmplizService
	) {}

	ngOnInit() {}

	get showSaveButton() {
		return this.imagingInfo.leadSaveStatus !== 'Saved';
	}
	get isSaveButton() {
		return this.imagingInfo.leadSaveStatus == 'Viewed';
	}
	get showRequest() {
		return this.imagingInfo.emailaddress.length === 0 && this.imagingInfo.phone.length === 0;
	}

	request(request: any) {
		this.loaderService.display(true);
		const body = {
			comid: '0',
			url: window.location.href,
			intentrequest: request
		};
		this.amplizService.request_access(body).subscribe(
			(res) => {
				this.loaderService.display(false);
				this.messageService.display(true, res.msg);
			},
			(err) => {
				this.loaderService.display(true);
			}
		);
	}

	resetSliceLength() {
		if (window.screen.availWidth < 1300) {
			this.sliceLength = 3;
		} else {
			this.sliceLength = 5;
		}
	}
	getIndustryAndSkillset() {
		// const industryList = this.imagingInfo.industryList || [];
		// const skillList = this.imagingInfo.skillList || [];
		// this.industryAndSkillset = [...industryList, ...skillList];
	}
	invokeSaveList() {
		// console.log("save clicked")
		this.b2bSaveDrawer = true;
		// console.log(this.b2bSaveDrawer )
	}

	handleShowMore() {
		// if (this.showMore == true) {
		//   this.sliceLength = this.imagingInfo.skillList.length;
		// } else {
		//   this.resetSliceLength();
		// }
		// this.showMore = !this.showMore;
	}

	openUrl(type) {
		const url = this.imagingInfo[type];
		if (url !== '') {
			window.open('https://' + url, 'popUpWindow');
		}
	}
	onLtcNameClicked() {
		if (this.imagingInfo.clinicExecutiveId) {
			this.router.navigate([]).then((result) => {
				window.open(`clinicalExecutiveOverview/${this.imagingInfo.clinicExecutiveId}`, '_blank');
			});
		}
	}

	cancelBtnClick(value: boolean) {
		this.b2bSaveDrawer = value;
	}

	handleCheckboxChange(event) {
		this.checkboxChange.emit(this.checkboxSelected);
	}
	doSearchImaging(key: string, skill: any) {
		const imagingObj: ClinicalExecutivesSearch = new ClinicalExecutivesSearch();
		imagingObj[key] = [skill];
		this.dataService.passSearchClinicalInput(imagingObj);
	}
	onImagingNameClicked(iv) {
		if (iv.clinicExecutiveId !== null && iv.clinicExecutiveId !== undefined) {
			if (this.imagingInfo.leadSaveStatus === 'NotSaved') {
				this.viewContactAndOpen(iv.clinicExecutiveId);
			} else {
				// this.router.navigate([]).then((result) => {
				//   window.open(`payor/${iv.clinicExecutiveId}`, "_blank");
				// });
			}
		}
	}

	viewContactAndOpen(clinicExecutiveId: any) {
		const body = {
			clinicExecutiveId
		};
		this.loaderService.display(true);
		this.payorService.viewClinicalExecutiveFromList(body).subscribe(
			(res) => {
				this.loaderService.display(false);
				this.payorService.getPayourDetails(clinicExecutiveId).subscribe((overview) => {
					this.dataService.addToSavedClinicalExecutives([overview.clinicExecutiveInfo]);
					// this.router.navigate([]).then((result) => {
					//   window.open(`payor/${clinicExecutiveId}`, "_blank");
					// });
				});
			},
			(err) => {
				this.loaderService.display(false);
			}
		);
	}
	get location() {
		let loc = [];
		if (this.imagingInfo.city != '') {
			loc.push(this.imagingInfo.city);
		}
		if (this.imagingInfo.state != '') {
			loc.push(this.imagingInfo.state);
		}
		return loc.join(', ');
	}
}
