import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-more-fliter-button',
	templateUrl: './more-fliter-button.component.html',
	styleUrls: ['./more-fliter-button.component.css']
})
export class MoreFliterButtonComponent implements OnInit {
	@Output() openMoreFilter = new EventEmitter();
	@Input() isPaid = true;
	constructor() {}

	ngOnInit(): void {}
	clickFilter() {
		this.openMoreFilter.emit();
	}
}
