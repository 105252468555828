import { Component, OnInit, ViewChild, ElementRef, Input, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/modules/basic/helper/must-match.validator';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { Router } from '@angular/router';
import { MessageService } from '../../../B2B/services/message.service';
declare var paypal;
@Component({
	selector: 'app-pricing',
	templateUrl: './hc-payment.component.html',
	styleUrls: ['./hc-payment.component.css']
})
export class HcPaymentComponent implements OnInit {
	@ViewChild('paypal', { static: true }) paypalElement: ElementRef;
	@ViewChild('month', { static: true }) monthly;
	@ViewChild('year', { static: true }) yrly;
	myorderStatus = '';
	plan = '';
	product = {
		price: 39.99,
		description: 'B2B Intelligence you need to get Customers. Personalized.',
		// img: 'assets/couch.jpg',
		img: 'https://www.ampliz.com/img/sales-intelligence-tool.gif'
	};
	public headerData;
	subscribed: boolean;
	paidFor = false;
	showLoader: boolean;
	showError = false;
	loading = false;
	showMsg: boolean;
	response = '';
	responseStatus = '';
	successStatus = false;
	errorStatus = false;
	plan_id = '';
	dtOptions: DataTables.Settings = {};
	dtOptions1: DataTables.Settings = {};
	subscription: boolean = false;
	contactDetails = [];
	subDetails = [];
	subcriptionDetails = '';
	subcriptionName = '';
	planId: any;
	SubscriptionName = '';
	planSelected: string = '';
	currentPlan: string = '';
	public user = null;
	selectedTab = 'monthly';
	priceDetails: any = {
		standardPlan: { price: 199, credits: 100 },
		proPlan: { price: 899, credits: 1000 }
	};

	pricingPlans: any = [
		{
			name: 'Starter',
			plan: 'Starter',
			highlightPoint: '5,000 email credits/month',
			highlight: false,
			features: [
				"5.3 Million Provider's data",
				'Upto 3 users',
				'Monthly updates',
				'Advanced Filters (Specialty, Job title, Intent data, location, NPI, ailment types, procedure codes, EHR/EMR, Hospitals/Affiliation, 20 + other filters)',
				'Data usable with All Email Providers',
				'Personal onboarding and initial training'
			]
		},
		{
			name: 'Growth',
			plan: 'Growth',
			highlightPoint: '10,000 email credits/month',
			highlight: true,
			features: [
				'1000 mobile phone credits/month',
				'Upto 5 users',
				"5.3 Million Provider's data",
				'1.7 Million HC Executives access',
				'Monthly updates',
				'API/CRM integration',
				'Advanced Filters (Specialty, Job title, Intent data, location, NPI, ailment types, procedure codes, EHR/EMR, Hospitals/Affiliation, 20 + other filters)',
				'Data Enrichment',
				'Data usable with All Email Providers',
				'Personal onboarding and initial training'
			]
		},
		{
			name: 'Enterprise',
			plan: 'Enterprise',
			highlightPoint: '25,000 email credits/month',
			highlight: false,
			features: [
				'2500 mobile phone credits/month',
				'Upto 8 users',
				"5.3 Million Provider's data",
				'1.7 Million HC Executives access',
				'Hospital insights + Physicians groups',
				'Monthly updates',
				'API/CRM Integration',
				'Advanced Filters (Specialty, Job title, Intent data, location, NPI, ailment types, procedure codes, EHR/EMR, Hospitals/Affiliation, 20 + other filters)',
				'Data Enrichment',
				'Data usable with All Email Providers',
				'Track website visitors (up to 500 visitors monthly)',
				'Personal onboarding and initial training'
			]
		}
	];

	constructor(
		public amplizService: AmplizService,
		public router: Router,
		private loaderService: LoaderService,
		private ngZone: NgZone,
		private messageService: MessageService
	) {
		this.plan = localStorage.getItem('plan');
		//
		// if (this.plan === "Basic") {
		//   this.planId = "P-8GB39286FL8521414L4EHNHQ";
		// }
		if (this.plan === 'Yearly') {
			this.planId = 'P-6PX16313UE1888801MGLWYBY';
		}
		if (this.plan === 'Monthly') {
			this.planId = 'P-6S33019312084464HMGLVYNI';
		}
	}
	ngOnInit() {
		// const url = this.router.url;
		// // this.selectedPlan = url.split("/").pop();
		// this.dtOptions = {
		// 	pagingType: 'full_numbers',
		// 	pageLength: 7
		// };
		// this.dtOptions1 = {
		// 	pagingType: 'full_numbers',
		// 	pageLength: 5,
		// 	scrollX: true,
		// 	autoWidth: false,
		// 	columnDefs: [{ width: '40%', targets: 5 }]
		// };

		this.getDashboardDetails();

		this.checkSubscription();
		//

		// this.planId = '';  //Default Plan Id
		// paypal
		//   .Buttons({
		//     createSubscription: function (data, actions) {
		//       return actions.subscription.create({
		//         plan_id: self.planId,
		//       });
		//     },
		//     onApprove: async (data, actions) => {
		//       //
		//       // alert('You have successfully created subscription ' + data.subscriptionID);
		//       // self.getSubcriptionDetails(data.subscriptionID);
		//       this.pass(data);
		//       this.paidFor = true;
		//     },
		//     onCancel: function (data) {
		//       // Show a cancel page, or return to cart
		//       //
		//     },
		//     onError: function (err) {
		//       // Show an error page here, when an error occurs
		//       //
		//     },
		//   })
		//   .render(this.paypalElement.nativeElement);

		// if(this.selectedPlan == "monthly"){
		//   paypal.Buttons({
		//     createSubscription: function(data, actions) {
		//       return actions.subscription.create({
		//         'plan_id': 'P-62167583NX339741GL3KHZGI'
		//         // 'plan_id': 'P-18934381P4557442KL3INRII'
		//       });
		//     },
		//     onApprove: async (data, actions) =>{
		//

		//       // alert('You have successfully created subscription ' + data.subscriptionID);
		//       this.pass(data);
		//       this.paidFor = true;
		//     },
		//         onError: err => {
		//
		//         }
		//   }).render(this.paypalElement.nativeElement);  }
		// else if(this.selectedPlan =="yearly"){
		//   paypal.Buttons({
		//     createSubscription: function(data, actions) {
		//       return actions.subscription.create({
		//         'plan_id': 'P-0XB02880VG245713XL3IP72Y'
		//         // 'plan_id': 'P-18934381P4557442KL3INRII'
		//       });
		//     },
		//     onApprove: async (data, actions) =>{
		//
		//       // alert('You have successfully created subscription ' + data.subscriptionID);
		//       this.pass(data);
		//       this.paidFor = true;
		//     },
		//         onError: err => {
		//
		//         }
		//   }).render(this.paypalElement.nativeElement);  }
		// else{
		//   this.router.navigate(['pricing'])  }
	}

	visibleIndex = -1;
	showSubItem(ind) {
		//
		if (this.visibleIndex === ind) {
			this.visibleIndex = -1;
		} else {
			this.visibleIndex = ind;
		}
	}
	async checkSubscription() {
		const authToken = await localStorage.getItem('auth_token');
		// const userId = await localStorage.getItem('user_id');
		const refreshToken = await localStorage.getItem('refresh_token');
		//
		if (authToken !== null && refreshToken !== null) {
			this.amplizService.checkSubscriptionStatus().subscribe(
				(res) => {
					if (res[0].Subscriptions[0].isActive) {
						this.subscription = true;
						this.subcriptionDetails = res[0].Subscriptions[0];
					} else {
						this.subscription = false;
					}
				},
				(error) => {
					if (error.status === 401) {
						this.amplizService.logout();
					}
					//
				}
			);
		} else {
			this.amplizService.logout();
		}
	}
	async getDashboardDetails() {
		const authToken = await localStorage.getItem('auth_token');
		// const userId = await localStorage.getItem('user_id');
		const refreshToken = await localStorage.getItem('refresh_token');
		//
		if (authToken !== null && refreshToken !== null) {
			this.plan = localStorage.getItem('plan');
			//

			if (this.plan === 'standard') {
				this.planSelected = 'standard';
				this.currentPlan = 'standard';
			}
			if (this.plan === 'pro') {
				this.planSelected = 'pro';
				this.currentPlan = 'pro';
			}

			this.amplizService.checkSubscriptionStatus().subscribe(
				(res) => {
					if (res[0].Subscriptions[0].SubscriptionName == 'Subscription1') {
						localStorage.setItem('plan', 'Basic');
						this.SubscriptionName = 'Basic';
					} else {
						localStorage.setItem('plan', res[0].Subscriptions[0].SubscriptionName);
						this.SubscriptionName = res[0].Subscriptions[0].SubscriptionName;
					}
					if (res[0].Subscriptions[0].SubscriptionType == 'Free') {
						localStorage.setItem('SubscriptionisActive', 'false');
						this.subscribed = false;
						//
						// this.headerData = "Upgrade";
					}
					if (res[0].Subscriptions[0].SubscriptionType == 'Paid') {
						//
						localStorage.setItem('SubscriptionisActive', 'true');
						this.subscribed = true;
					}
				},
				(error) => {
					if (error.status === 401) {
						this.amplizService.logout();
					}
					//
				}
			);
		} else {
			this.amplizService.logout();
		}
	}

	async requestPricing(plan: string) {
		const emailId = await localStorage.getItem('email_id');
		this.loaderService.display(true);
		const body = { package: plan, email: emailId };
		this.amplizService.getPrice(body).subscribe(
			(res) => {
				this.loaderService.display(false);

				this.messageService.display(true, 'Thanks for asking, will get back to you in 24 hrs');
			},
			(error) => {
				this.loaderService.display(false);
				this.messageService.displayError(true, error.error.msg ? error.error.msg : 'Server Error !!!');
			}
		);
	}

	onTalkToUs() {
		window.open('https://www.ampliz.com/book-your-demo', '_blank');
	}
}
