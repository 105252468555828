<div class="card column filter-component" style="width: 23rem">
	<div class="image-filter">
		<div class="flex-row filter-header">
			<div>
				<h6 class="card-title titleCardStyle">Showing results for</h6>
			</div>
			<div
				class="searchButton primary"
				[ngClass]="filterData.validateImagingSearch() ? 'primaryBtn' : ''"
				(click)="clearFilter()"
			>
				<span> Clear </span>
			</div>
		</div>
		<mat-accordion>
			<app-individual-filter
				#companyAuto
				(valueChanges)="companyValueChanges($event)"
				[totalItems]="companyList"
				[(includedItems)]="filterData.company"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Center name"
				title="Center"
			></app-individual-filter>

			<app-individual-filter
				#fullnameText
				type="text"
				[totalItems]="[]"
				[(includedItems)]="filterData.fullName"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Contact name"
				title="Full Name"
				[disabled]="!isSubscribed"
			>
			</app-individual-filter>

			<app-individual-filter
				#titleAuto
				(valueChanges)="titleValueChanges($event)"
				[totalItems]="titleList"
				[(includedItems)]="filterData.titleInclude"
				[(excludedItems)]="filterData.titleExclude"
				includePlaceholder="Include titles"
				title="Title"
				excludePlaceholder="Exclude title"
				(includedItemsChange)="omitChanges()"
				(excludedItemsChange)="omitChanges()"
				[disabled]="!isSubscribed"
			>
			</app-individual-filter>

			<!-- <app-individual-filter #industryAuto (valueChanges)="industryValueChanges($event)"
                [totalItems]="industryList" [(includedItems)]="filterData.industryInclude"
                [(excludedItems)]="filterData.industryExclude" (includedItemsChange)="omitChanges()"
                (excludedItemsChange)="omitChanges()" includePlaceholder="Include industry" title="Industry"
                excludePlaceholder="Exclude industry"
                [disabled]="!isSubscribed"
                ></app-individual-filter> -->

			<app-individual-filter
				#departmentMulti
				type="multiselect"
				[totalItems]="departmentList"
				[(includedItems)]="filterData.department"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Select Department"
				title="Department"
				[disabled]="!isSubscribed"
			>
			</app-individual-filter>

			<app-individual-filter
				#seniorityMulti
				type="multiselect"
				[totalItems]="seniorityList"
				[(includedItems)]="filterData.seniority"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Select seniority level"
				title="Seniority"
				[disabled]="!isSubscribed"
			>
			</app-individual-filter>
			<!-- <app-individual-filter #noOfImgEqListMulti type="singleselectnumber" [totalItems]="numberOfImagingEquipmentList"
                [(includedItems)]="filterData.numberOfImagingEquipments" (includedItemsChange)="omitChanges()"
                includePlaceholder="No. of imaging equipments" title="Imaging equipments" 
                [disabled]="!isSubscribed"
                >
            </app-individual-filter> -->

			<!-- Imaging equipment starts -->

			<app-expand-item
				[selectedData]="ImagingExpandData"
				[isPaid]="!isSubscribed"
				title="Imaging Equipments"
				placeholder="Select imaging equipments"
			>
				<app-signle-select-drop-down
					title="Number of equipments"
					[list]="numberOfImagingEquipmentList"
					[(selectedData)]="filterData.numberOfImagingEquipments"
					(onItemSelect)="omitChanges()"
				></app-signle-select-drop-down>
				<app-auto-complete-drop-drow
					title="Name of the equipments"
					[(list)]="imagingEqupmentsList"
					[(selectedData)]="filterData.imagingEquipments"
					(onItemSelect)="omitChanges()"
					(onChange)="getImagingCenterEquipment($event)"
				></app-auto-complete-drop-drow>
			</app-expand-item>
			<div class="location">
				<app-individual-location-filter
					[countryList]="[]"
					(locationValueChanged)="omitChanges()"
					[(selectedStatesFromFilter)]="filterData.stateList"
					[(selectedCitiesFromFilter)]="filterData.cityList"
					[disabled]="!isSubscribed"
				>
				</app-individual-location-filter>
			</div>
			<app-more-fliter-button
				[isPaid]="!isSubscribed"
				(openMoreFilter)="openMoreFilter()"
			></app-more-fliter-button>
		</mat-accordion>
	</div>
</div>

<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="recordCountLoader"
		[totalRecords]="moreFilterRecordCount.value"
		(clear)="moreFilterAccordian.closeAll(); clearTempFilter()"
		(applyFilter)="applyMoreFilters()"
		(closeFilter)="closeDialogBox('mainDialog')"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Center"
					[(selectedData)]="tempDialogFilters.company"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Center Name"
						[(list)]="companyList"
						[(selectedData)]="tempDialogFilters.company"
						(onItemSelect)="getRecordCount()"
						(onChange)="companyValueChanges($event)"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Full Name"
					[(selectedData)]="tempDialogFilters.fullName"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-keyword-input
						title="Contact name"
						[(selectedData)]="tempDialogFilters.fullName"
						storageKey="imaging_executive_full_name"
						(onItemAdd)="getRecordCount()"
					>
					</app-keyword-input>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Title"
					[selectedData]="tempTitleData"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Title Included"
						[(list)]="titleList"
						[selectedData]="tempDialogFilters.titleInclude"
						(onItemSelect)="getRecordCount()"
						(onChange)="titleValueChanges($event)"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="Title Excluded"
						[(list)]="titleList"
						[(selectedData)]="tempDialogFilters.titleExclude"
						(onItemSelect)="getRecordCount()"
						(onChange)="titleValueChanges($event)"
					></app-auto-complete-drop-drow>
					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.titleInclude"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.titleExclude"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Department"
					[(selectedData)]="tempDialogFilters.department"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Department"
						[list]="departmentList"
						[(selectedData)]="tempDialogFilters.department"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Seniority"
					[(selectedData)]="tempDialogFilters.seniority"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Seniority"
						[list]="seniorityList"
						[(selectedData)]="tempDialogFilters.seniority"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Imaging Equipments"
					[selectedData]="tempImagingExpandData"
				>
					<app-signle-select-drop-down
						title="Number of equipments"
						[list]="numberOfImagingEquipmentList"
						[(selectedData)]="tempDialogFilters.numberOfImagingEquipments"
						(onItemSelect)="getRecordCount()"
					></app-signle-select-drop-down>
					<app-auto-complete-drop-drow
						title="Name of the equipments"
						[(list)]="imagingEqupmentsList"
						[(selectedData)]="tempDialogFilters.imagingEquipments"
						(onItemSelect)="getRecordCount()"
						(onChange)="getImagingCenterEquipment($event)"
					></app-auto-complete-drop-drow>
					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.numberOfImagingEquipments"
							(onChange)="getRecordCount()"
							[isSingle]="true"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.imagingEquipments"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Procedure Codes"
					[selectedData]="tempProcedureCodes"
				>
					<app-auto-complete-drop-drow
						title="CPT"
						[(list)]="cptCodeList"
						[(selectedData)]="tempDialogFilters.cptCodes"
						(onItemSelect)="getRecordCount()"
						(onChange)="getCPTCodes($event)"
						itemName="id"
						itemValue="cptCode"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="HCPCS"
						[(list)]="hscpcCodeList"
						[(selectedData)]="tempDialogFilters.hcpcsCodes"
						(onItemSelect)="getRecordCount()"
						(onChange)="getHSCPCCodes($event)"
						itemName="id"
						itemValue="hcpcsCode"
					></app-auto-complete-drop-drow>
					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.cptCodes"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.hcpcsCodes"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<app-location-filter
					class="dialog-flex-container"
					[countryList]="[]"
					(locationValueChanged)="getRecordCount()"
					[(selectedStatesFromFilter)]="tempDialogFilters.stateList"
					[(selectedCitiesFromFilter)]="tempDialogFilters.cityList"
				>
				</app-location-filter>
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<app-more-fliter-close-dialog
		[(showDialog)]="showConfirmDialog"
		(closeFilter)="closeDialogBox('mainDialog', true)"
	></app-more-fliter-close-dialog>
</ng-template>
