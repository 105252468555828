<button class="saveSearchBtn" [mat-menu-trigger-for]="menu">Save Search</button>
<mat-menu #menu="matMenu">
	<div
		class="row px-2 py-4 align-items-center justify-content-end"
		(click)="$event.stopPropagation()"
	>
		<div class="col-12">
			<h3 class="title">Save your search</h3>
			<p class="paragraph">
				Give your search a name. Search name will allow you to easily find it and continue where you
				left off.
			</p>
		</div>
		<div class="col-12 mt-2">
			<input id="input" placeholder="Enter Search Name" [(ngModel)]="searchName" class="searchInput p-2" />
		</div>

		<div class="d-flex justify-content-end col-12 mt-4">
			<button class="cancel-btn action-btn mr-2" (click)="closeMenu()">Cancel</button>
			<button class="save-btn action-btn" (click)="saveSearch()">Save</button>
		</div>
	</div>
</mat-menu>
