export class ClinicalExecutives {
  constructor(
    public clinicExecutiveId: string = '',
    public hospitalname: string = '',
    public hospitalId: string = '',
    public fullname: string = '',
    public title: string = '',
    public standardtitle: string = '',
    public state: string = '',
    public city: string = '',
    public firmtype: string = '',
    public emailaddress: string = '',
    public phone: string = '',
    public leadSaveStatus: string = ''
  ) {}
}

export class ClinicalExecutivesList {
  constructor(public clinicalExecutivesList: Array<ClinicalExecutives> = []) {}

  updateImagingssListFromSavedList(savedList: Array<ClinicalExecutives>) {
    savedList.map((savedClinical: ClinicalExecutives) => {
      const index = this.clinicalExecutivesList.findIndex(
        (imagingItem) =>
          savedClinical.clinicExecutiveId == imagingItem.clinicExecutiveId
      );
      if (index !== -1) {
        this.clinicalExecutivesList[index] = savedClinical;
      }
    });
  }
}
