<div class="physican_container card-animate">
	<div class="container">
		<div class="row">
			<div class="col-sm-12" [ngClass]="smallResolution ? 'col-md-8' : 'col-md-9'">
				<div class="physician_card">
					<div class="physician_content ml-3">
						<h5 class="physician_name" (click)="onImagingNameClicked(clinicalData)">
							{{ clinicalData.name }}
						</h5>
						<div class="physician_info">
							<span class="physican_location"
								><i class="fa fa-map-marker pr-1" aria-hidden="true"></i>{{ location }}</span
							>
						</div>
						<!------------>
					</div>
				</div>
			</div>
			<div class="col-sm-12" [ngClass]="smallResolution ? 'col-md-4' : 'col-md-3'">
				<div class="physican_additionInfo">
					<ng-container *ngIf="getWebAddress">
						<span>
							<!-- <i class="fa fa-globe" aria-hidden="true"></i> -->
							<i-feather name="Globe" class="imgCardIcon mr-2"></i-feather>
							<a [href]="getWebAddress" class="physican_email" target="_blank"> View Website </a>
						</span>
					</ng-container>

					<ng-container *ngIf="clinicalData.phone && clinicalData.phone != null">
						<span class="physican_phone">
							<i class="ml-1 fa fa-phone" aria-hidden="true"></i>
							{{ clinicalData.phone }}</span
						>
					</ng-container>

					<div class="flex1" style="flex: 1; display: flex"></div>
					<div class="saveBtn_wrp imgCenterCard">
						<app-clinic-not-correct [imagingId]="clinicalData.clinicId"></app-clinic-not-correct>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
