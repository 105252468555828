<div class="more-filter-container">
	<button
		class="more-filter-button"
		(click)="clickFilter()"
		[class.diasble-more-filter]="isPaid"
		[disabled]="isPaid"
	>
		More Filters <i class="fa fa-external-link ml-2" aria-hidden="true" *ngIf="!isPaid"> </i>
		<mat-icon style="margin-left: 6px; margin-top: 3px" class="filter-title" *ngIf="isPaid">lock</mat-icon>
	</button>
</div>
