<div id="wrapper">
	<app-header [user]="user" style="width: 100%" [elementName]="'pricing'"></app-header>{{ user }}
	<!-- <app-header *ngIf="router.url != '/payment'" [header-data]="headerData"></app-header> -->

	<!-- <app-sidemenu elementName="pricing"></app-sidemenu> -->
	<!-- ============================================================== -->
	<div class="content-page">
		<!-- Start content -->
		<div class="content">
			<div class="container-fluid">
				<div class="d-flex flex-column">
					<div class="banner">
						<header>
							<div class="text-section d-flex flex-column">
								<span>Not sure which plan is best for you?</span>
								<span>Get a customised product demo and identify the plan that best fits your needs. </span>
							</div>
							<div class="btn-bordred btn-rounded request-btn highlight-btn" (click)="onTalkToUs()">
								Talk to us
							</div>
							<!-- <img src="/assets/img/doctor.png" /> -->
						</header>
					</div>
					<div class="card__wrap--outer">
						<ng-container *ngFor="let plan of pricingPlans">
							<div class="card__wrap--inner">
								<div class="card" [class.highlight]="plan.highlight">
									<div
										class="card__item card__header d-flex justify-content-between align-items-center"
										[class.highlight]="plan.highlight"
									>
										<div class="d-flex flex-column align-items-start">
											<h2>{{ plan.name }}</h2>
											<p class="highlight-text">{{ plan.highlightPoint }}</p>
										</div>
										<button
											class="btn-bordred btn-rounded request-btn"
											[class.highlight-btn]="plan.highlight"
											(click)="requestPricing(plan.plan)"
										>
											Request Pricing
										</button>
									</div>

									<div class="flexible card__item">
										<ul>
											<li *ngFor="let feature of plan.features">{{ feature | titlecase }}</li>
										</ul>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>

				<!-- container -->
			</div>
			<!-- content -->
		</div>
	</div>
</div>
