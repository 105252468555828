<app-right-panel
	[show]="show"
	title="CRM Integrations"
	(closePanel)="panelClosed.emit(false)"
	[enableCloseIcon]="true"
>
	<div>
		<div class="integration-card-container p-3">
			<div class="integration-card mb-2" *ngFor="let integration of integrationList">
				<div class="integration-logo-container">
					<img [src]="integration.icon" class="integration-logo rounded" />
				</div>

				<div class="detail-container flex-grow-1">
					<span class="integration-name">{{integration.name}}</span>
					<div
						class="integration-status integration-active-status"
						[class.integration-active-status]="findActivatedIntegration(integration.type)"
					>
						<span>{{findActivatedIntegration(integration.type) === true ? 'Active': 'Inactive'}} </span>
					</div>
				</div>

				<div>
					<button class="integration-btn" (click)="openPortal(integration.type)">
						{{findActivatedIntegration(integration.type) === true ? 'Manage' : 'Enable'}}
					</button>
				</div>
			</div>
		</div>
	</div>
</app-right-panel>
