// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	prodGlobalB2BApi: 'https://test.ampliz.com',
	prodAdbApi: 'https://go.ampliz.com',
	// prodB2BApi: 'http://119.8.182.178:9000',
	// prodB2BApi: 'https://test.ampliz.com',

	// Prod API
	// prodHcApi: 'https://hcla15.ampliz.com',

	// Stage API
	prodHcApi: 'https://test.ampliz.com',

	// Old API
	// prodHcApi: 'https://hcapi.ampliz.com',

	// Paragon {CRM Integration }
	paragonApiKey: 'api_key_uiEX9PVCTW-t9IShwGoCpibKhq1DTvudlLlcjtjG',
	paragonProjectKey: 'ed42fc7b-de2e-456d-9cc3-3340ba524c16',
	paragonBaseUrl: 'https://zeus.useparagon.com/projects',

	//Zipcode API (Third Party) {Zipcode Radius API}
	zipCodeApiKey: 'js-k8EpWDX78xoCNyfmtFHZ9afblQi7L72oLsn58KyAFU02qJV4l3y0tGHMCvEaM5m3'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
