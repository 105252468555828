<div class="confirm-dialog">
	<div class="confirm-dialog-overlay" [class.show-confirm-dialog]="showDialog">
		<div class="confirm-dialog-content">
			<div class="header-content">
				<span>Caution</span>
				<mat-icon class="close-icon" (click)="handleCloseDialogBox()">close</mat-icon>
			</div>

			<div class="main-content">
				<span
					>Modifications made to your filters are not saved. Leaving now will discard these changes.<br />Do you
					wish to continue?</span
				>
			</div>

			<div class="action-content">
				<button class="cancel-btn" (click)="handleCloseDialogBox()">Cancel</button>
				<button class="save-btn ml-2" (click)="handleCloseFilter()">Yes</button>
			</div>
		</div>
	</div>
</div>
