<div style="display: flex; flex-direction: row; height: 100%">
  <div style="overflow: hidden; flex-grow: 1">
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-material list1"
      (gridReady)="onGridReady($event)"
      #agGrid
      [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs"
      [animateRows]="true"
      (cellClicked)="onCellClicked($event)"
      [sortingOrder]="sortingOrders"
      (firstDataRendered)="onFirstDataRendered($event)"
      [paginationPageSize]="paginationPageSize"
      [frameworkComponents]="frameworkComponents"
      [context]="context"
      [rowHeight]="55"
      [domLayout]="domLayout"
      [headerHeight]="45"
      [overlayNoRowsTemplate]="noRowsTemplate">
    </ag-grid-angular>
  </div>
</div>
