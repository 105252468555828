import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
	selector: 'app-backend-data-upload',
	templateUrl: './backend-data-upload.component.html',
	styleUrls: ['./backend-data-upload.component.css']
})
export class BackendDataUploadComponent implements OnInit {
	constructor(private messageService: MessageService, private amplizService: AmplizService) {}
	@ViewChild('paginator', { static: true }) paginator: MatPaginator;
	showLoader: boolean = false;
	requestList: any = [];
	totalCount: number = 0;
	searchFilter: string = '';

	pager: any = { currentPage: 1, offset: 5, totalPages: 1 };

	ngOnInit() {
		this.getRequestedList();
	}
	get offset() {
		const total = this.pager.offset * (this.pager.currentPage - 1);
		return +total + 1;
	}
	get count() {
		const pageCount = this.pager.currentPage * this.pager.offset;
		return this.pager.currentPage === this.pager.totalPages
			? this.totalCount
			: pageCount > this.totalCount
			? this.totalCount
			: pageCount;
	}

	onPaginationClick(currentPage) {
		this.pager = { ...this.pager, currentPage };
		this.getRequestedList();
	}

	getRequestedList() {
		this.showLoader = true;
		const skip = (this.pager.currentPage - 1) * this.pager.offset;
		this.amplizService
			.backend_getRequestedMobileList(this.searchFilter, skip, this.pager.offset)
			.subscribe(
				(res) => {
					this.totalCount = res.total_count;
					this.pager = {
						...this.pager,
						totalPages: Math.ceil(this.totalCount / this.pager.offset)
					};
					this.requestList = res.mobile_requested_list;
					this.showLoader = false;
				},
				(err) => {
					this.showLoader = false;
				}
			);
	}

	onFileUpload(event, listId, userId) {
		const file: File = event.target.files[0];
		this.showLoader = true;
		if (file && file.type === 'text/csv') {
			const formData = new FormData();
			formData.append('listId', listId);
			formData.append('userid', userId);
			formData.append('file', file);

			this.amplizService.backend_requestedMobileFileUpload(formData).subscribe(
				(res) => {
					this.messageService.display(true, 'File Submitted Successfully');
					this.showLoader = false;
					setTimeout(() => {
						this.getRequestedList();
					}, 100);
				},
				(err) => {
					this.showLoader = false;
				}
			);
		} else {
			this.showLoader = false;
			this.messageService.displayError(true, 'Please upload csv file');
		}
	}

	onFileDownload(listId: string) {
		this.amplizService.backend_downloadMobileRequestFile(listId).subscribe((el) => {
			const a = document.createElement('a');
			const blob = new Blob([el.body], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);

			a.href = url;
			a.download = listId + '.csv';
			a.click();
			window.URL.revokeObjectURL(url);
			a.remove();

			setTimeout(() => {
				this.getRequestedList();
			}, 100);
		});
	}

	setFilter(searchFilter: string) {
		this.searchFilter = searchFilter;
		this.getRequestedList();
	}
}
