<div id="wrapper">
  <app-header style="width: 100%" [elementName]="'clinical'"></app-header>
  <div class="content-page">
    <div class="row col imaging-wrapper">
      <app-clinical-executive-filter
        (onFilterChange)="handleFilterChanged($event)"
        [isSubscribed]="subscribed">
      </app-clinical-executive-filter>
      <div class="b2b-content" style="position: relative">
        <div class="b2b-content-main">
          <div class="other-border">
            <app-hc-top-tab
              [allSelected]="allSelected"
              [partiallySelected]="isPartiallySelected"
              [showLoader]="showLoader"
              [haveCheckbox]="
                savedImagings.length < selectedFilter.limit &&
                subscribed &&
                clinicalList.clinicalExecutivesList.length > 0
              "
              [tabItems]="tabItems"
              (selectAllChanged)="handleSelectVisible($event)"
              [isPrimary]="true"
              (tabChanged)="handleTabChange($event)">
              <div class="flex1 flex-row">
                <div style="flex: 1"></div>

                <div class="opt selectVal">
                  <mat-form-field
                    style="max-width: 50px; border: none; padding-top: 10px">
                    <mat-select
                      [disabled]="!subscribed"
                      [(ngModel)]="selectedFilter.limit"
                      (selectionChange)="handleRecordsChange()">
                      <mat-option
                        [value]="opt"
                        *ngFor="let opt of pageNumberOptions"
                        >{{ opt }}
                      </mat-option>
                    </mat-select>
                    <div
                      *ngIf="!isSubscribed"
                      class="my-1 selectDisabled"></div>
                  </mat-form-field>
                </div>

                <!-- pagination part start -->
                <div class="paginationText">
                  {{ offset + 1 }}
                  -
                  {{ paginationEndCount }} of
                  {{
                    selectedFilter.searchType == 'NETNEW'
                      ? (netNewCount | numberWithCommas)
                      : (totalItemCount | numberWithCommas)
                  }}
                  executives
                </div>
                <mat-icon
                  (click)="decrementPage()"
                  class="leftIcon"
                  aria-hidden="false"
                  aria-label="Example home icon"
                  [ngClass]="decrementEnabled ? '' : 'disabled'">
                  keyboard_arrow_left</mat-icon
                >
                <mat-icon
                  (click)="incrementPage()"
                  class="rightIcon"
                  aria-hidden="false"
                  aria-label="Example home icon"
                  [ngClass]="incrementEnabled ? '' : 'disabled'">
                  keyboard_arrow_right</mat-icon
                >
              </div>
            </app-hc-top-tab>
            <app-bulk-save-clinic-executive-card
              *ngIf="selectedImagings.length > 0"
              [selectedImagings]="selectedImagings"
              [selectedImagingsInCurrentPage]="selectedImagingsInCurrentPage"
              (successfullyAdded)="handleImagingAddList()"
              [totalItemCount]="totalItemCount"
              (clearPress)="clearSave()"
              [selectedFilter]="selectedFilter"
              [allImagingsSelected]="false">
            </app-bulk-save-clinic-executive-card>
            <div *ngIf="!showUpgradeCard && !showLoader">
              <app-clinical-executive-card
                *ngFor="
                  let imaging of clinicalList.clinicalExecutivesList;
                  index as i
                "
                [imagingInfo]="imaging"
                (checkboxChange)="handleCheckboxChange($event, imaging)"
                [isSubscribed]="subscribed"
                [checkboxSelected]="
                  selectedImagings.includes(imaging.clinicExecutiveId)
                "
                [checkboxDisabled]="
                  peopleCheckboxDisabled(imaging)
                "></app-clinical-executive-card>
            </div>

            <div class="h-100" *ngIf="showUpgradeCard">
              <app-upgrade></app-upgrade>
            </div>
            <div class="layout h-100" *ngIf="noResult && !showLoader">
              <div
                class="d-flex justify-content-center align-items-center align-self-center h-100 flex-column">
                <div class="text-center">
                  <img src="assets/img/Physician.png" width="30%" />
                </div>
                <h5 class="text-center pa-4">
                  No results found, Try to search again
                </h5>
              </div>
            </div>
            <app-b2b-loader *ngIf="showLoader" [loopCount]="5"></app-b2b-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
