<div class="mat-dialog-title">
	<div class="modal-header d-flex justify-content-between">
		<div class="dialog-title d-flex align-items-center">
			<span>Filters</span>

			<div class="ml-2">
				<button class="main-clear-more-filter" (click)="handleClear()">
					<span class="clear-more-filter-text">Clear</span>
				</button>
			</div>
		</div>

		<div class="dialog-action-container d-flex align-items-center">
			<div class="record-container">
				<!-- loader -->
				<div *ngIf="loader | async; else recordCount" class="py-2 mr-2">
					<div class="bars"></div>
				</div>
				<!-- Record count starts -->
				<ng-template #recordCount>
					<span class="record-count"> {{ totalRecords | numberWithCommas }} Records</span>
				</ng-template>
				<!-- Record count ends -->
			</div>

			<button
				class="ml-2 dialog-action-button"
				[disabled]="countLoader"
				[class.disable-action-btn]="countLoader"
				(click)="applyMoreFilters()"
			>
				Apply Filter
			</button>

			<button class="ml-2 dialog-close-button d-flex">
				<mat-icon class="close-dialog-icon" (click)="closeDialogBox()">close</mat-icon>
			</button>
		</div>
	</div>
</div>
