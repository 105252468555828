<div class="card column filter-component" style="width: 23rem">
	<div class="image-filter">
		<div class="flex-row filter-header">
			<div>
				<h6 class="card-title titleCardStyle">Showing results for</h6>
			</div>
			<div
				class="searchButton primary"
				[ngClass]="filterData.validateImagingSearch() ? 'primaryBtn' : ''"
				(click)="clearFilter()"
			>
				<span> Clear </span>
			</div>
		</div>
		<mat-accordion>
			<app-individual-filter
				#fullnameText
				(valueChanges)="nameListChange($event)"
				[totalItems]="nameList"
				[labelKey]="'nameKey'"
				[valueKey]="'name'"
				[(includedItems)]="filterData.hospitalnameList"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Enter the name of the clinic"
				title="Clinic Name"
			>
			</app-individual-filter>
			<app-individual-filter
				#fullnameText
				type="text"
				[totalItems]="[]"
				[(includedItems)]="filterData.fullnameList"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Full name"
				title="Name"
				[disabled]="!isSubscribed"
				filterType="clinical"
			>
			</app-individual-filter>
			<app-expand-item title="Title" [selectedData]="filterData.titleList" [isPaid]="!isSubscribed" placeholder="Title">
				<app-keyword-input
					title="Title"
					[storageKey]="'titleList_clinic_executive_Page_filterData'"
					[(selectedData)]="filterData.titleList"
					(onItemAdd)="omitChanges()"
				></app-keyword-input>
			</app-expand-item>
			<app-individual-filter
				#firmTypes
				type="multiselect"
				[totalItems]="firmTypeList"
				[(includedItems)]="filterData.firmTypes"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Firm Types"
				title="Firm Types"
				[disabled]="!isSubscribed"
			>
			</app-individual-filter>

			<div class="location">
				<app-individual-location-filter
					[countryList]="countryList"
					(locationValueChanged)="hanldeLocationValueChange($event)"
					[(selectedStatesFromFilter)]="filterData.stateList"
					[(selectedCitiesFromFilter)]="filterData.cityList"
					[disabled]="!isSubscribed"
				>
				</app-individual-location-filter>
			</div>
			<app-more-fliter-button
				[isPaid]="!isSubscribed"
				(openMoreFilter)="openMoreFilter()"
			></app-more-fliter-button>
		</mat-accordion>
	</div>
	<div class="upgrade-card" (click)="requestPricing()" *ngIf="!isSubscribed">
		<div>
			<div class="blt-background">
				<mat-icon class="mt-ico">bolt</mat-icon>
			</div>
		</div>
		<div style="padding-left: 10px">
			<div class="upgrd-to-unlock">Request to unlock more filters</div>
			<div class="improve-search">Improve your search result with additional filters</div>
		</div>
	</div>
</div>
<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="recordCountLoader"
		[totalRecords]="moreFilterRecordCount.value"
		(clear)="moreFilterAccordian.closeAll(); clearTempFilter()"
		(applyFilter)="applyMoreFilters()"
		(closeFilter)="closeDialogBox('mainDialog')"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Clinic Name"
					[(selectedData)]="tempDialogFilters.hospitalnameList"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Enter the name of the clinic"
						[(list)]="nameList"
						itemName="nameKey"
						itemValue="name"
						[(selectedData)]="tempDialogFilters.hospitalnameList"
						(onItemSelect)="getRecordCount()"
						(onChange)="nameListChange($event)"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Name"
					[(selectedData)]="tempDialogFilters.fullnameList"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-keyword-input
						title="Full name"
						[storageKey]="'fullnameList_clinic_executive_Page'"
						[(selectedData)]="filterData.fullnameList"
						(onItemAdd)="getRecordCount()"
					></app-keyword-input>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Title"
					[(selectedData)]="tempDialogFilters.titleList"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-keyword-input
						title="Title"
						[storageKey]="'titleList_clinic_executive_Page'"
						[(selectedData)]="filterData.titleList"
						(onItemAdd)="getRecordCount()"
					></app-keyword-input>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Firm Types"
					[(selectedData)]="tempDialogFilters.firmTypes"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Firm Types"
						[list]="firmTypeList"
						[(selectedData)]="tempDialogFilters.firmTypes"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-location-filter
					class="dialog-flex-container"
					[countryList]="[]"
					(locationValueChanged)="getRecordCount()"
					[(selectedStatesFromFilter)]="tempDialogFilters.stateList"
					[(selectedCitiesFromFilter)]="tempDialogFilters.cityList"
				>
				</app-location-filter>
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<app-more-fliter-close-dialog
		[(showDialog)]="showConfirmDialog"
		(closeFilter)="closeDialogBox('mainDialog', true)"
	></app-more-fliter-close-dialog>
</ng-template>
