<div id="wrapper">
	<app-header [user]="" style="width: 100%" [elementName]="'hospital'"></app-header>
	<!-- <app-header *ngIf="!subscribed" [header-data]="headerData"></app-header> -->
	<!-- <app-sidemenu elementName="hospital"></app-sidemenu> -->

	<div class="content-page">
		<!-- Start content -->
		<div class="content row">
			<app-filter-hospital
				(onFilterChange)="filterChanged($event)"
				[isSubscribed]="subscribed"
			></app-filter-hospital>
			<div class="physician-content" style="position: relative">
				<app-skeleton-loader *ngIf="showLoader" [loopCount]="10"></app-skeleton-loader>
				<div *ngIf="noResult === false">
					<div class="physician-content-header" *ngIf="totalSize > 0">
						<div class="toggle-container">
							<div class="toggle" [ngClass]="tab === 1 ? 'active' : ''">
								<span class="sm-txt">{{ totalCount | numberWithCommas }}</span>
								<span style="font-size: 14px">Total Result</span>
							</div>
							<!-- <div
                class="toggle"
                [ngClass]="tab === 2 ? 'active' : ''"
                (click)="ngTab(2)"
              >
                <span class="big-txt">{{ netNewCount }}</span>
                <span class="sm-txt">Net New</span>
              </div> -->
						</div>
					</div>

					<div class="" *ngIf="unLockDiv == false">
						<div class="physician-content-main" *ngIf="tab == 1">
							<div class="totalCount > 0">
								<div *ngFor="let item of totalSearchResult; let i = index">
									<app-hospitalcard [hospitalData]="item" [dataIndex]="i"></app-hospitalcard>
								</div>
							</div>
						</div>
					</div>
					<div class="h-100" *ngIf="unLockDiv">
						<app-upgrade></app-upgrade>
					</div>

					<div class="search_pagination_wrapper">
						<div class="row">
							<div class="col-md-6 col-sm-12">
								<div class="paginationWrapper" *ngIf="totalSize > 0">
									<div class="pull-left paginationInfo" *ngIf="totalSize > 0">
										{{ offset + 1 | numberWithCommas }}-{{
											(offset + limit > totalSize ? totalSize : limit + offset) | numberWithCommas
										}}
										of {{ totalSize | numberWithCommas }}
									</div>
									<ul *ngIf="pager.pages && pager.pages.length" class="pagination" style="float: left !important">
										<!-- <li [ngClass]="{disabled:pager.currentPage === 1}">
                            <a *ngIf="pager.currentPage === 1"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                            <a (click)="setPage(1)" *ngIf="pager.currentPage !== 1"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                          </li> -->
										<li [ngClass]="{ disabled: pager.currentPage === 1 }">
											<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
												><i class="fa fa-chevron-left" aria-hidden="true"></i
											></a>
											<a
												*ngIf="pager.currentPage !== 1"
												(click)="setPage(pager.currentPage - 1)"
												style="border-radius: 5px 0 0 5px !important"
												><i class="fa fa fa-chevron-left" aria-hidden="true"></i
											></a>
										</li>
										<!-- <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                            <a (click)="setPage(page)">{{page}}</a>
                          </li> -->
										<li
											[ngClass]="{
												disabled:
													pager.currentPage === pager.totalPages || (pager.currentPage === 2 && subscribed === false)
											}"
										>
											<a *ngIf="pager.currentPage === pager.totalPages" style="border-radius: 0 5px 5px 0 !important"
												><i class="fa fa-chevron-right" aria-hidden="true"></i
											></a>
											<a
												*ngIf="pager.currentPage !== pager.totalPages"
												(click)="setPage(pager.currentPage + 1)"
												style="border-radius: 0 5px 5px 0 !important"
												><i class="fa fa-chevron-right" aria-hidden="true"></i
											></a>
										</li>
										<!-- <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                              <a *ngIf="pager.currentPage === pager.totalPages"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                            <a  *ngIf="pager.currentPage !== pager.totalPages" (click)="setPage(pager.totalPages)"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                          </li> -->
									</ul>
								</div>
							</div>
							<div class="col-md-6 col-sm-12 p-0 m-0 pb-2 pt-3"></div>
						</div>
					</div>
				</div>
				<div class="layout h-100" *ngIf="noResult">
					<div class="d-flex justify-content-center align-items-center align-self-center h-100 flex-column">
						<div class="text-center">
							<img src="assets/img/Hospital.png" width="30%" />
						</div>
						<h5 class="text-center pa-4">No results found, Try to search again</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
