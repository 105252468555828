export class ClinicalExecutivesSearch {
	constructor(
		public hospitalnameList: string[] = [],
		public fullnameList: string[] = [],
		public titleList: string[] = [],
		public cityList: any[] = [],
		public stateList: any[] = [],
		public firmTypes: string[] = [],
		public searchType: string = 'TOTAL',
		public clientIp: string = '',
		public savedListOffset: number = 0,
		public offset: number = 0,
		public limit: number = 5
	) {}

	public toJson(): object {
		return {
			hospitalnameList: this.hospitalnameList,
			fullnameList: this.fullnameList,
			titleList: this.titleList,
			cityList: this.cityList.map((el) => el?.city || el),
			stateList: this.stateList.map((el) => el?.state || el),
			firmTypes: this.firmTypes,
			searchType: this.searchType,
			clientIp: this.clientIp,
			savedListOffset: this.savedListOffset,
			offset: this.offset,
			limit: this.limit
		};
	}

	public fromJson(obj: any): ClinicalExecutivesSearch {
		return new ClinicalExecutivesSearch(
			obj.hospitalnameList,
			obj.fullnameList,
			obj.titleList,
			obj.cityList,
			obj.stateList,
			obj.firmTypes,
			obj.searchType,
			obj.clientIp,
			obj.savedListOffset,
			obj.offset,
			obj.limit
		);
	}
	validateImagingSearch() {
		return (
			this.hospitalnameList.length > 0 ||
			this.fullnameList.length > 0 ||
			this.titleList.length > 0 ||
			this.cityList.length > 0 ||
			this.stateList.length > 0 ||
			this.firmTypes.length > 0
		);
	}
}
