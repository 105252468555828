import { COMMA, ENTER, T } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilterStorageService } from 'src/app/modules/healthcare/services/filter-storage.service';

@Component({
	selector: 'app-keyword-input',
	templateUrl: './keyword-input.component.html',
	styleUrls: ['./keyword-input.component.css']
})
export class KeywordInputComponent implements OnInit {
	@Input() title: string = '';
	@Input() placeholder: any = '';
	separatorKeysCodes: number[] = [ENTER, COMMA];
	excludeControl = new UntypedFormControl();
	@ViewChild('keywordInput')
	keywordInput: ElementRef<HTMLInputElement>;
	@Output() selectedDataChange = new EventEmitter();
	@Input() selectedData: Array<any> = [];
	@Output() onItemAdd = new EventEmitter();
	@Input() storageKey: any = '';
	@Input() numberOnly: boolean = false;
	@Input() isSingle: boolean = false;
	@Input() selectedSingleData: string = '';
	@Output() selectedSingleDataChange = new EventEmitter();
	@Input() itemName: string = ''; //For Object
	selectable = true;

	constructor(private filterStorageService: FilterStorageService) {}

	ngOnInit(): void {
		this.handleValue();
	}
	handleValue() {
		this.excludeControl.valueChanges.subscribe((value: string) => {
			// this.valueChanges.emit(value);
		});
	}
	public reset() {
		this.keywordInput.nativeElement.value = '';
		this.excludeControl.setValue('', { emitEvent: true });
	}
	selectText(item, selectionType = 'auto') {
		let val: any = '';
		if (selectionType == 'auto') {
			val = item.value;
		} else {
			val = item.option.value;
		}
		const found = this.selectedData.indexOf(val);
		if (found == -1) {
			this.selectedData.push(this.itemName ? { [this.itemName]: val } : val);
		}
		this.setLocalStorageValues(val);
		this.reset();
		this.omitChange();
	}
	selectSingleText(item) {
		if (this.selectedSingleData !== item.value) {
			this.selectedSingleData = item.value;
			this.reset();
			this.selectedSingleDataChange.emit(this.selectedSingleData);
		}
	}
	clear() {
		this.isSingle ? (this.selectedSingleData = '') : (this.selectedData = []);
		this.omitChange();
	}
	remove(item?) {
		if (this.isSingle) {
			this.selectedSingleData = '';
		} else {
			this.selectedData = this.selectedData.filter((el) => {
				if (this.itemName) {
					return el[this.itemName] !== item[this.itemName];
				}
				el !== item;
			});
		}
		this.omitChange();
	}
	omitChange() {
		this.isSingle
			? this.selectedSingleDataChange.emit(this.selectedSingleData)
			: this.selectedDataChange.emit(this.selectedData);
		this.onItemAdd.emit();
	}
	get recentSelectedValues() {
		return this.filterStorageService.get(this.storageKey) || [];
	}
	async setLocalStorageValues(value) {
		if (this.itemName) return; //If object don't save the value
		const data = [...this.recentSelectedValues, value];
		this.filterStorageService.set(this.storageKey, [...new Set(data)]);
	}
}
