<div class="container col" *ngIf="!isLoader">
	<!-- Credit summary card starts -->
	<div class="card-section">
		<div class="action-card">
			<app-credit-action-cards
				(cancel)="cancelAction($event)"
				[edit]="isEdit"
				*ngIf="isAction && !isAdmin"
				[userCredits]="userCredits"
				[adminCredits]="adminCredits"
				[userId]="userInfo.userId"
			></app-credit-action-cards>
		</div>
		<div class="credit-summary-card">
			<app-credit-summary
				*ngIf="!isAction && userCredits !== undefined"
				(trigger)="handleTrigger($event)"
				[isAdmin]="isAdmin"
				[userCredits]="userCredits"
				[userInfo]="userInfo"
				[nextBillingDate]="nextBillingDate"
			></app-credit-summary>
		</div>

		<!-- TEMP PERMISSIONS -->
		<div class="credit-summary-card" style="margin-top: 30px" *ngIf="!isAdmin && !isAction">
			<app-permissions></app-permissions>
		</div>
	</div>
	<!-- Credit summary card ends -->
</div>
<div class="loader-container d-flex justify-content-center align-items-center" *ngIf="isLoader">
	<svg class="spinner-svg" *ngIf="isLoader" viewBox="25 25 50 50">
		<circle r="10" cy="50" cx="50"></circle>
	</svg>
</div>
