import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	Output,
	EventEmitter,
	Renderer2,
	Input,
	TemplateRef
} from '@angular/core';
import { BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { FilterStorageService } from 'src/app/modules/B2B/services/filter-storage.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { ImagingDataService } from 'src/app/modules/ImagingCenter/services/imaging-data.service';
import { ImagingService } from 'src/app/modules/ImagingCenter/services/imaging.service';
import { SearchPhysicianExecutive } from '../../models/search-physician-executive';
import { DataService } from '../../service/data.service';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { PayorService } from '../../service/payor.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';

@Component({
	selector: 'app-physician-group-executive-filter',
	templateUrl: './physician-group-executive-filter.component.html',
	styleUrls: ['./physician-group-executive-filter.component.css']
})
export class PhysicianGroupExecutiveFilterComponent implements OnInit {
	@Output() onFilterChange = new EventEmitter<any>();
	@Input() isSubscribed: boolean = false;
	@Input() isPaid: boolean = false;
	selectable = true;
	filterData: SearchPhysicianExecutive = new SearchPhysicianExecutive();
	tempDialogFilters: SearchPhysicianExecutive = new SearchPhysicianExecutive();
	dialogRef: any;
	dialogState: boolean = false;
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;
	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showConfirmDialog: boolean = false;
	//company Variables
	companyList: Array<any> = [];
	exculdedSpecialityPlaceholder: string = '';
	separatorKeysCodes: number[] = [ENTER, COMMA];

	//title
	titleList: Array<any> = [];
	totalItemsExcludeControl = new UntypedFormControl();
	@Input() recentIncludeItems: Array<any> = [];

	// industry

	industryList: Array<any> = [];

	// seniority
	seniorityList: Array<any> = [];

	//department

	departmentList: Array<any> = [
		'Business Development',
		'Engineering',
		'Finance',
		'Human Resources',
		'IT',
		'Laboratory',
		'Marketing/Sales',
		'Medical',
		'Nursing',
		'Operations',
		'Others',
		'Purchasing',
		'R&D',
		'Respiratory, Developmental, Rehabilitative & Restorative',
		'Sub Level Management',
		'Top Level Management'
	];
	@Output() valueChanges = new EventEmitter();

	@ViewChild('keywordInput')
	keywordInput: ElementRef<HTMLInputElement>;
	//contacts

	//Country Variables
	countryList: Array<any> = [];
	selectedCountry: Array<any> = [];

	excludedTitles: any = [];
	//State Variables
	stateList: Array<any> = [];
	selectedStates: Array<any> = [];

	//City Variables

	//Search List variables
	cityList: Array<any> = [];
	selectedCities: Array<any> = [];

	// Revenue Variables
	revenueList: Array<any> = [];
	includedRevenueRange: any = [];
	subscription: Subscription;
	mapingValue = {
		'Payors/health Insurance': 0,
		'Preferred Provider Organization': 1,
		'Point Of Service': 2,
		'Exclusive Provider Organization': 3,
		'Health Maintenance Organization': 4
	};

	//Imaging equipments
	@ViewChild('codeClassificationInput', { static: false })
	codeClassificationInput: ElementRef<HTMLInputElement>;
	codeClassificationControl = new FormControl();
	@ViewChild('vendorNameInput', { static: false })
	vendorNameInput: ElementRef<HTMLInputElement>;
	vendorNameControl = new FormControl();
	@ViewChild('insuranceInput', { static: false })
	insuranceInput: ElementRef<HTMLInputElement>;
	insuranceControl = new FormControl();
	productNameList: string[] = [];
	vendorList: string[] = [];
	insuranceList: string[] = [];
	removable = true;

	numberOfImagingEquipmentList: string[] = [];
	groupPracticeNumber: string[] = ['1-10', '11-25', '26-50', '50-100', '100-250', '250-500', '500+'];

	constructor(
		private b2bService: ImagingService,
		private filterStorageService: FilterStorageService,
		private dataService: DataService,
		private payourService: PayorService,
		private loaderService: LoaderService,
		private messageService: MessageService,
		private amplizService: AmplizService,
		private renderer: Renderer2,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService
	) {}
	getRecentValues() {
		const includeRecentKey = `recentPhysicGroupExcludedSpeciality_excludeSpeciality`;
		this.recentIncludeItems = this.filterStorageService.get(includeRecentKey) || [];
	}
	ngOnInit() {
		this.getPersistData();
		this.getSeniorityList();
		// this.getRevenueList();
		this.makeImagingEqipmentList();
		this.totalItemsExcludeControl.valueChanges.subscribe((value: string) => {
			this.valueChanges.emit(value);
		});
		this.subscription = this.dataService.physicianSearchExecutiveData.subscribe((res) => {
			if (res.fromSearch) {
				this.filterData = res.data;
				this.omitChanges();
			}
		});
		this.codeClassificationControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getCPTCodes(hv);
			}
		});
		this.vendorNameControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getVendorList(hv);
			}
		});
		this.insuranceControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getInsuranceList(hv);
			}
		});
	}
	removeItem(content, type = 'include') {
		const index = this.filterData.keywords.indexOf(content);
		this.filterData.keywords.splice(index, 1);
		this.omitChanges();
		this.storeFilterData();
	}
	public reset() {
		this.keywordInput.nativeElement.value = '';
		this.totalItemsExcludeControl.setValue('', { emitEvent: true });
	}
	triggerAutoFocus(eleId: string) {
		// console.log(eleId);
		const element = this.renderer.selectRootElement(eleId);
		setTimeout(() => element.focus(), 100);
	}
	selectExculededText(item, selectionType = 'auto') {
		let val = '';
		if (selectionType == 'auto') {
			val = item.value;
		} else {
			val = item.option.value;
		}
		const found = this.filterData.keywords.indexOf(val);
		if (found == -1) {
			this.filterData.keywords.push(val);
			this.setLocalStorageValues(val, 'exclude');
		}
		this.reset();
		this.omitChanges();
		this.storeFilterData();
	}
	get selectedCodes() {
		return [
			...this.filterData.ehremrproductname,
			...this.filterData.ehremrvendorname,
			...this.filterData.insurance
		];
	}
	async setLocalStorageValues(value, type = 'include') {
		const recentKey = `recentPhysicGroupExcludedSpeciality_excludeSpeciality`;
		const data: Array<any> = (await this.filterStorageService.get(recentKey)) || [];
		let valueIndex = 0;
		if (typeof value == 'object') {
			//At some cases we are adding the values as object.So we are taking the first key of the object
			const firstKey = Object.keys(value)[0];
			valueIndex = data.findIndex((itm) => itm[firstKey] == value[firstKey]);
		} else {
			valueIndex = data.indexOf(value);
		}
		if (valueIndex === -1) {
			await this.filterStorageService.set(recentKey, [...data.splice(-2), value]);
			this.getRecentValues();
		}
	}

	clearItems() {
		this.filterData.keywords = [];
		this.keywordInput.nativeElement.value = '';
		this.totalItemsExcludeControl.setValue('', { emitEvent: true });
		this.omitChanges();
		this.storeFilterData();
	}
	makeImagingEqipmentList() {
		// this.numberOfImagingEquipmentList = [];
		// for(let i=0;i<25;i++) {
		//   this.numberOfImagingEquipmentList.push(i+1);
		// }
		this.numberOfImagingEquipmentList = [
			'Single/Multi-Specialty Physician Group',
			'Independent Practice Association',
			'Academic/Faculty Practice'
		];
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
		this.dataService.passSearchPayourInput(this.filterData, false);
	}
	getSeniorityList() {
		this.b2bService.getSeniorityList('').subscribe((res) => {
			this.seniorityList = res.seniorityList;
		});
	}

	// getRevenueList() {
	// 	this.filterData.fullName;
	// 	this.b2bService.getRevenueList().subscribe((res) => {
	// 		this.revenueList = res.revenueList;
	// 	});
	// }

	// Company functions

	companyValueChanges(item) {
		if (item && item.length > 1) {
			this.payourService.getPhysicianCompanyList(item).subscribe((res) => {
				this.companyList = res.companyListPhysicianGroup;
			});
		}
	}
	// TITLE FUNCTIONS......................

	titleValueChanges(item) {
		if (item && item.length > 1) {
			const body = {
				searchPhrase: item,
				previousSearchedTitle: [...this.filterData.titleInclude, ...this.filterData.titleExclude]
			};
			this.payourService.getPhysicianGroupTitlesList(body).subscribe((res) => {
				this.titleList = res.titleAll;
			});
		} else {
			this.titleList = [];
		}
	}
	// INDUSTRY FUNCTIONS......................

	industryValueChanges(item) {
		if (item && item.length > 1) {
			const body = {
				searchPhrase: item
				// previouslySearchedTerm: [
				//   ...this.filterData.industryInclude,
				//   ...this.filterData.industryExclude,
				// ],
			};
			this.b2bService.getIndustryList(body).subscribe((res) => {
				this.industryList = res.industryListImagingCenter;
			});
		} else {
			this.industryList = [];
		}
	}
	// SKILL FUNCTIONS......................

	hanldeLocationValueChange(locationBody: any) {
		this.omitChanges();
	}

	getPersistData() {
		const newFilterData = this.filterStorageService.get('Physician_group_executive_filter');
		Object.keys(newFilterData).map((el) => {
			this.filterData[el] = newFilterData[el];
		});
		this.omitChanges();
		this.getRecordCount();
	}

	storeFilterData() {
		this.filterStorageService.set('Physician_group_executive_filter', this.filterData);
	}

	omitChanges() {
		this.onFilterChange.emit(this.filterData.toJson());
		this.storeFilterData();
	}

	clearFilter() {
		const isValid = this.filterData.validateImagingSearch();
		if (isValid) {
			this.filterData = new SearchPhysicianExecutive();
			this.omitChanges();
		}
	}
	ngOnChanges() {
		this.isPaid = !this.isSubscribed;
		// this.isPaid = false;
		//
	}
	async requestPricing() {
		const emailId = await localStorage.getItem('email_id');
		this.loaderService.display(true);
		const body = { package: 'Enterprise', email: emailId };
		this.amplizService.getPrice(body).subscribe(
			(res) => {
				this.loaderService.display(false);

				this.messageService.display(true, 'Thanks for asking, will get back to you in 24 hrs');
			},
			(error) => {
				this.loaderService.display(false);
				this.messageService.displayError(true, error.error.msg ? error.error.msg : 'Server Error !!!');
			}
		);
	}
	getCPTCodes(search = '') {
		this.payourService.getProductName(search).subscribe((res) => {
			this.productNameList = res?.ehremrproductnameList || [];
		});
	}
	getVendorList(search = '') {
		this.payourService.getVendorList(search).subscribe((res) => {
			this.vendorList = res?.ehremrvendornameList || [];
		});
	}
	getInsuranceList(search = '') {
		this.payourService.getInsuranceList(search).subscribe((res) => {
			this.insuranceList = res?.insuranceList || [];
			// console.log(this.insuranceList);
		});
	}
	addProductName(event): void {
		this.filterData.ehremrproductname = [
			...new Set([...this.filterData.ehremrproductname, event.option.value])
		];

		this.codeClassificationInput.nativeElement.value = '';
		setTimeout(() => {
			this.getCPTCodes();
		}, 500);

		this.omitChanges();
		this.storeFilterData();
	}
	addVendorName(event): void {
		this.filterData.ehremrvendorname = [...new Set([...this.filterData.ehremrvendorname, event.option.value])];

		this.vendorNameInput.nativeElement.value = '';
		setTimeout(() => {
			this.getVendorList();
		}, 500);

		this.omitChanges();
		this.storeFilterData();
	}
	addInsurance(event): void {
		this.filterData.insurance = [...new Set([...this.filterData.insurance, event.option.value])];

		this.insuranceInput.nativeElement.value = '';
		setTimeout(() => {
			this.getInsuranceList();
		}, 500);

		this.omitChanges();
		this.storeFilterData();
	}
	removeProductName(code: string) {
		this.filterData.ehremrproductname = this.filterData.ehremrproductname.filter((el) => el !== code);
		this.getCPTCodes();
		this.omitChanges();
		this.storeFilterData();
	}
	removeVendorName(code: string) {
		this.filterData.ehremrvendorname = this.filterData.ehremrvendorname.filter((el) => el !== code);
		this.getVendorList();
		this.omitChanges();
		this.storeFilterData();
	}
	removeInsurance(code: string) {
		this.filterData.insurance = this.filterData.insurance.filter((el) => el !== code);
		this.getInsuranceList();
		this.omitChanges();
		this.storeFilterData();
	}
	clearTempFilter() {
		const isValid = this.tempDialogFilters.validateImagingSearch();
		if (isValid) {
			this.tempDialogFilters = new SearchPhysicianExecutive();
			this.getRecordCount();
		}
	}
	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		Object.keys(this.filterData).map((el) => {
			this.tempDialogFilters[el] = this.filterData[el];
		});
		this.getRecordCount();
		this.dialogState = true;
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}
	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, cloneDeep(this.filterData));
	}
	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearTempFilter();
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}
	getRecordCount() {
		this.recordCountLoader.next(true);
		this.payourService.searchPhysicianGroupExecutives(this.tempDialogFilters.toJson()).subscribe(
			(res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				this.recordCountLoader.next(false);
			},
			(err) => {
				this.recordCountLoader.next(false);
			}
		);
	}
	applyMoreFilters() {
		Object.keys(this.tempDialogFilters).map((el) => {
			this.filterData[el] = this.tempDialogFilters[el];
		});
		this.omitChanges();
		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}
	get insuranceData() {
		return [
			...this.tempDialogFilters.ehremrproductname,
			...this.tempDialogFilters.ehremrvendorname,
			...this.tempDialogFilters.insurance
		];
	}
}
