<div id="wrapper">
	<app-header [user]="" style="width: 100%" [elementName]="'physician'"></app-header>
	<!-- <app-header *ngIf="!subscribed" [header-data]="headerData"></app-header> -->
	<!-- <app-sidemenu elementName="physician"></app-sidemenu> -->

	<div class="content-page" *ngIf="physicianOverviewResult !== undefined">
		<div class="content">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="row justify-content-between align-items-center">
							<div class="col-md-8">
								<div class="row" style="gap: 34px">
									<!-- <div>
										<img
											class="doctor_image"
											[src]="
												gender === 'Male'
													? 'assets/images/doctor-M.png'
													: gender === 'Female'
													? 'assets/images/Doctor-F.png'
													: 'assets/img/Physician-hc.png'
											"
										/>
									</div> -->
									<div>
										<h3 class="physician_name">
											{{ physicianOverviewResult.physicianInfoData.fullName }}
										</h3>
										<p class="physician_hospital">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="18"
												height="18"
												viewBox="0 0 18 18"
												fill="none"
											>
												<path
													d="M9 1.5L11.3175 6.195L16.5 6.9525L12.75 10.605L13.635 15.765L9 13.3275L4.365 15.765L5.25 10.605L1.5 6.9525L6.6825 6.195L9 1.5Z"
													fill="#EBAD10"
													stroke="#EBAD10"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>

											{{ physicianOverviewResult.physicianInfoData.specialty }}
										</p>
										<section class="physician_hospital">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="18"
												height="18"
												viewBox="0 0 18 18"
												fill="none"
											>
												<path
													d="M11.25 5.25C11.0511 5.25 10.8603 5.32902 10.7197 5.46967C10.579 5.61032 10.5 5.80109 10.5 6V8.25H7.5V6C7.5 5.80109 7.42099 5.61032 7.28033 5.46967C7.13968 5.32902 6.94892 5.25 6.75 5.25C6.55109 5.25 6.36033 5.32902 6.21967 5.46967C6.07902 5.61032 6 5.80109 6 6V12C6 12.1989 6.07902 12.3897 6.21967 12.5303C6.36033 12.671 6.55109 12.75 6.75 12.75C6.94892 12.75 7.13968 12.671 7.28033 12.5303C7.42099 12.3897 7.5 12.1989 7.5 12V9.75H10.5V12C10.5 12.1989 10.579 12.3897 10.7197 12.5303C10.8603 12.671 11.0511 12.75 11.25 12.75C11.4489 12.75 11.6397 12.671 11.7803 12.5303C11.921 12.3897 12 12.1989 12 12V6C12 5.80109 11.921 5.61032 11.7803 5.46967C11.6397 5.32902 11.4489 5.25 11.25 5.25ZM9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35472 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.4978 7.01155 15.7069 5.10516 14.3009 3.69911C12.8948 2.29306 10.9885 1.50218 9 1.5ZM9 15C7.81332 15 6.65328 14.6481 5.66658 13.9888C4.67989 13.3295 3.91085 12.3925 3.45673 11.2961C3.0026 10.1997 2.88378 8.99334 3.11529 7.82946C3.3468 6.66557 3.91825 5.59647 4.75736 4.75736C5.59648 3.91824 6.66558 3.3468 7.82946 3.11529C8.99335 2.88378 10.1997 3.0026 11.2961 3.45672C12.3925 3.91085 13.3295 4.67988 13.9888 5.66658C14.6481 6.65327 15 7.81331 15 9C14.998 10.5907 14.3652 12.1157 13.2405 13.2404C12.1157 14.3652 10.5907 14.998 9 15Z"
													fill="#FF1709"
												/>
											</svg>
											{{ physicianOverviewResult.physicianInfoData.hospitalName }}
										</section>
										<section class="physician_general">
											<p *ngIf="gender"><span class="dot"></span>{{ gender }}</p>
											<p *ngIf="languages">
												<span class="dot"></span>
												{{ languages }}
											</p>
											<p *ngIf="physicianOverviewResult.physicianInfoData.age">
												<span class="dot"></span>
												{{ physicianOverviewResult.physicianInfoData.age }}
											</p>
										</section>
									</div>
								</div>
							</div>
							<div class="icon_container">
								<section
									class="icon_contact_info"
									*ngIf="physicianOverviewResult.physicianInfoData.emailExists"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
									>
										<g id="mail">
											<path
												id="Vector"
												d="M2.5 2.5H12.5C13.1875 2.5 13.75 3.0625 13.75 3.75V11.25C13.75 11.9375 13.1875 12.5 12.5 12.5H2.5C1.8125 12.5 1.25 11.9375 1.25 11.25V3.75C1.25 3.0625 1.8125 2.5 2.5 2.5Z"
												stroke="#FF6C63"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												id="Vector_2"
												d="M13.75 3.75L7.5 8.125L1.25 3.75"
												stroke="#FF6C63"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</g>
									</svg>
									<span
										(click)="viewPhysicianEmail()"
										*ngIf="!physicianOverviewResult.physicianInfoData.emailViewed; else viewed"
										>Email</span
									>

									<ng-template #viewed>
										<span
											class="viewed_style_email"
											*ngIf="
												physicianOverviewResult?.physicianInfoData?.emailAddress &&
													physicianOverviewResult?.physicianInfoData?.emailAddress?.length !== 0;
												else viewRequest
											"
										>
											<a
												*ngFor="let email of physicianOverviewResult.physicianInfoData.emailAddress"
												class="viewed_style_email"
												[href]="'mailto:' + email"
												>{{ email }}</a
											>
										</span>
										<ng-template #viewRequest>
											<span (click)="request('Request Email Access')">Request Email</span></ng-template
										>
									</ng-template>
								</section>
								<section
									class="icon_contact_info"
									*ngIf="physicianOverviewResult.physicianInfoData.phoneViewed"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
									>
										<g clip-path="url(#clip0_145_207)">
											<path
												d="M9.08632 2.5C9.69678 2.6191 10.2578 2.91766 10.6976 3.35746C11.1374 3.79726 11.436 4.35829 11.5551 4.96875M9.08632 0C10.3546 0.140898 11.5373 0.708859 12.4402 1.61063C13.3431 2.5124 13.9126 3.69438 14.0551 4.9625M13.4301 9.95V11.825C13.4308 11.9991 13.3951 12.1714 13.3254 12.3308C13.2557 12.4903 13.1534 12.6335 13.0251 12.7512C12.8969 12.8688 12.7454 12.9584 12.5805 13.0142C12.4157 13.07 12.2409 13.0907 12.0676 13.075C10.1443 12.866 8.29695 12.2088 6.67382 11.1563C5.16372 10.1967 3.88341 8.91636 2.92382 7.40625C1.86756 5.77575 1.21022 3.91937 1.00507 1.9875C0.989455 1.81467 1.00999 1.64048 1.06539 1.47602C1.12078 1.31156 1.2098 1.16043 1.3268 1.03226C1.4438 0.904096 1.5862 0.801693 1.74494 0.731576C1.90368 0.661459 2.07529 0.625163 2.24882 0.625H4.12382C4.42714 0.622015 4.72119 0.729424 4.95117 0.927208C5.18116 1.12499 5.33137 1.39965 5.37382 1.7C5.45296 2.30004 5.59973 2.8892 5.81132 3.45625C5.89541 3.67995 5.91361 3.92307 5.86376 4.1568C5.81392 4.39053 5.69811 4.60507 5.53007 4.775L4.73632 5.56875C5.62605 7.13347 6.92161 8.42903 8.48632 9.31875L9.28007 8.525C9.45 8.35696 9.66454 8.24116 9.89827 8.19131C10.132 8.14146 10.3751 8.15966 10.5988 8.24375C11.1659 8.45534 11.755 8.60211 12.3551 8.68125C12.6587 8.72408 12.9359 8.877 13.1342 9.11093C13.3324 9.34486 13.4377 9.64348 13.4301 9.95Z"
												stroke="#FF6C63"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</g>
										<defs>
											<clipPath id="clip0_145_207">
												<rect width="15" height="15" fill="white" />
											</clipPath>
										</defs>
									</svg>
									<span
										*ngIf="
											physicianOverviewResult?.physicianInfoData?.phoneNumber &&
												physicianOverviewResult?.physicianInfoData?.phoneNumber?.length !== 0;
											else viewRequest
										"
									>
										<span
											class="viewed_style"
											*ngFor="let phone of physicianOverviewResult.physicianInfoData.phoneNumber"
										>
											{{ phone }}
										</span>
									</span>
									<ng-template #viewRequest>
										<span (click)="request('Request Phone Number Access')"
											>Request Phone Number</span
										></ng-template
									>
								</section>
								<!-- <section class="icon_contact_info" *ngIf="physicianOverviewResult.physicianInfoData.isMobile">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
										<g id="smartphone">
											<path
												id="Vector"
												d="M11.623 1.25H5.37305C4.68269 1.25 4.12305 1.80964 4.12305 2.5V12.5C4.12305 13.1904 4.68269 13.75 5.37305 13.75H11.623C12.3134 13.75 12.873 13.1904 12.873 12.5V2.5C12.873 1.80964 12.3134 1.25 11.623 1.25Z"
												stroke="#FF6C63"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												id="Vector_2"
												d="M8.49805 11.25H8.50305"
												stroke="#FF6C63"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</g>
									</svg>
									<span
										(click)="viewPhysicianMobileNumber()"
										*ngIf="!physicianOverviewResult.physicianInfoData.mobileViewed; else viewed"
										>Direct Dial</span
									>
									<ng-template #viewed>
										<span
											class="viewed_style"
											*ngIf="physicianOverviewResult.physicianInfoData.mobileNumber; else viewRequest"
										>
											{{ physicianOverviewResult.physicianInfoData.mobileNumber }}</span
										>
										<ng-template #viewRequest>
											<span (click)="request('Request mobile number Access')">Request Mobile Number</span></ng-template
										>
									</ng-template>
								</section> -->
								<div class="row">
									<div>
										<button
											class="btn-blue-bordered rounded"
											(click)="handleSaveButtonPress()"
											*ngIf="showSaveButton && physicianOverviewResult.physicianInfoData.emailExists"
										>
											<span *ngIf="!showButtonLoader">
												{{ saveButtonText }}
											</span>
											<app-circle-loader [radius]="13" *ngIf="showButtonLoader"></app-circle-loader>
										</button>
									</div>
									<div>
										<app-physician-not-correct
											[physicianId]="physicianOverviewResult.physicianInfoData.physicianId"
										></app-physician-not-correct>
									</div>
								</div>
							</div>
						</div>
						<section class="tab_container">
							<p [ngClass]="{ selected: selectedTab === 1 }" (click)="tabselected(1)">SUMMARY</p>
							<p [ngClass]="{ selected: selectedTab === 2 }" (click)="tabselected(2)">
								HOSPITAL INFORMATION
							</p>
							<p [ngClass]="{ selected: selectedTab === 3 }" (click)="tabselected(3)">AILMENT</p>
							<p [ngClass]="{ selected: selectedTab === 4 }" (click)="tabselected(4)">
								PHYSICIAN PRACTICE LOCATION
							</p>
							<p [ngClass]="{ selected: selectedTab === 5 }" (click)="tabselected(5)">AFFILIATION</p>
							<!-- <p [ngClass]="{ selected: selectedTab === 6 }" (click)="tabselected(6)">RX-DATA</p> -->
							<p [ngClass]="{ selected: selectedTab === 7 }" (click)="tabselected(7)">SIMILAR PHYSICIAN</p>
							<p [ngClass]="{ selected: selectedTab === 8 }" (click)="tabselected(8)">CPT Codes</p>
							<p [ngClass]="{ selected: selectedTab === 9 }" (click)="tabselected(9)">HCPCS Codes</p>
						</section>
					</div>
				</div>
				<div class="col-sm-12 marginTop">
					<div class="card" style="padding-bottom: 42px">
						<section *ngIf="selectedTab === 1">
							<p class="bio_content">
								{{ physicianOverviewResult.physicianInfoData.providerBio }}
							</p>
							<div class="npi_content">
								<p>
									Location: <span>{{ hospitalAddress }}</span>
								</p>
							</div>
							<div class="npi_content">
								<p *ngIf="physicianOverviewResult.physicianInfoData?.npiNumber">
									NPI Number :
									<span>{{ physicianOverviewResult.physicianInfoData.npiNumber }}</span>
								</p>
								<p *ngIf="physicianOverviewResult.physicianInfoData?.state_License_Number">
									State Licence Number :
									<span>{{ physicianOverviewResult.physicianInfoData.state_License_Number }}</span>
								</p>
								<p *ngIf="physicianOverviewResult?.physicianInfoData?.state_License_Code">
									State Licence Code :
									<span>{{ physicianOverviewResult.physicianInfoData.state_License_Code }}</span>
								</p>
							</div>
							<h4
								class="heading"
								*ngIf="
									physicianOverviewResult?.physicianInfoData?.specialties &&
									physicianOverviewResult?.physicianInfoData?.specialties?.length !== 0
								"
							>
								Specialties
							</h4>
							<div class="physician_speciality">
								<p *ngFor="let speciality of physicianOverviewResult.physicianInfoData.specialties">
									<span class="dot"></span>{{ speciality }}
								</p>
							</div>
						</section>
						<section *ngIf="selectedTab === 2">
							<div
								class="p-5 d-flex justify-content-center align-items-center align-self-center align-center"
								*ngIf="isBlankHospitalInfo; else hospitalContent"
							>
								<button (click)="request('Request Hospital Access')" class="btn btn-new">
									Request Hospital Info
								</button>
							</div>
							<ng-template #hospitalContent>
								<p class="bio_content" *ngIf="physicianOverviewResult.physicianInfoData.hospitalBio">
									{{ physicianOverviewResult.physicianInfoData.hospitalBio }}
								</p>
								<div class="hospital_content">
									<p *ngIf="physicianOverviewResult.physicianInfoData.webAddress">
										Web Address :
										<span
											><a [href]="physicianOverviewResult.physicianInfoData.webAddress">Web address</a></span
										>
									</p>
									<p *ngIf="physicianOverviewResult.physicianInfoData.address">
										Address :
										<span>{{ physicianOverviewResult.physicianInfoData.address }} </span>
									</p>
									<p *ngIf="physicianOverviewResult.physicianInfoData.city">
										City :
										<span>{{ physicianOverviewResult.physicianInfoData.city }}</span>
									</p>
									<p *ngIf="physicianOverviewResult.physicianInfoData.state">
										State :
										<span>{{ physicianOverviewResult.physicianInfoData.state }}</span>
									</p>
									<p *ngIf="physicianOverviewResult.physicianInfoData.country">
										Country :
										<span>{{ physicianOverviewResult.physicianInfoData.country }}</span>
									</p>
									<p *ngIf="physicianOverviewResult.physicianInfoData.zipCode">
										Zipcode :
										<span>{{ physicianOverviewResult.physicianInfoData.zipCode }}</span>
									</p>
									<p *ngIf="physicianOverviewResult.physicianInfoData.hospitalContact">
										Hospital Contact :
										<span>{{ physicianOverviewResult.physicianInfoData.hospitalContact }}</span>
									</p>
								</div>
								<h4
									*ngIf="
										physicianOverviewResult.physicianHospitalInfoData?.hospitalQualities &&
										physicianOverviewResult.physicianHospitalInfoData?.hospitalQualities.length !== 0
									"
									class="heading"
								>
									HOSPITAL QUALITIES
								</h4>
								<div class="physician_speciality">
									<p
										*ngFor="
											let qualities of physicianOverviewResult.physicianHospitalInfoData.hospitalQualities
										"
									>
										<span class="dot"></span>{{ qualities }}
									</p>
								</div>
							</ng-template>
						</section>
						<section *ngIf="selectedTab === 3">
							<h4
								*ngIf="
									physicianOverviewResult?.physicianInfoData?.experience &&
									physicianOverviewResult?.physicianInfoData?.experience?.length !== 0
								"
								class="heading"
								style="margin-top: 0"
							>
								EXPERIENCE
							</h4>
							<div class="physician_experience" *ngIf="experienceList.length !== 0; else notFound">
								<p class="experience_container" *ngFor="let speciality of experienceList">
									{{ speciality }}
								</p>
							</div>
							<ng-template #notFound>
								<div class="notFound">
									<p>No Experience Found</p>
								</div>
							</ng-template>
						</section>
						<section *ngIf="selectedTab === 4">
							<app-maps-selection
								[mapsData]="physicianPractiseHospitals"
								name="Physician Practise Hospital"
								[physicianOverviewResult]="physicianOverviewResult"
							></app-maps-selection>
						</section>
						<section *ngIf="selectedTab === 5">
							<app-maps-selection
								[hideMore]="true"
								[mapsData]="affiliatedHospitals"
								name="Affiliation"
							></app-maps-selection>
						</section>
						<section *ngIf="selectedTab === 6">
							<app-rx-data></app-rx-data>
						</section>
						<section *ngIf="selectedTab === 8">
							<app-cpt-details [physicianId]="paramsData"></app-cpt-details>
						</section>
						<section *ngIf="selectedTab === 9">
							<app-hscpc-details [physicianId]="paramsData"></app-hscpc-details>
						</section>
						<section *ngIf="selectedTab === 7">
							<div
								class="similar_physician_container"
								*ngIf="similarPhysician.length !== 0; else notFound"
							>
								<div *ngFor="let hospital of similarPhysician">
									<app-similar-physician
										[name]="hospital.fullName"
										[hospital]="hospital.hospitalName"
										[specialty]="hospital.specialty"
										[physicianId]="hospital.physicianId"
									></app-similar-physician>
								</div>
								<div class="dummy_card" *ngFor="let i of [0, 1, 2, 3]"></div>
							</div>
							<ng-template #notFound>
								<div class="notFound">
									<p>No Similar Physician Found</p>
								</div>
							</ng-template>
						</section>
					</div>
				</div>
			</div>
		</div>
	</div>
	<app-savelist
		[saveListDiv]="saveDrawer"
		[physicianId]="physicianOverviewResult?.physicianInfoData?.physicianId"
		[executiveId]="physicianOverviewResult?.physicianInfoData?.executiveId"
		(cancelBtnClick)="cancelBtnClick($event)"
		(refreshedData)="refreshedData($event)"
		[currentCredit]="currentCredit || 0"
		*ngIf="saveDrawer"
	></app-savelist>
	<app-notcorrect
		[notCorrectDiv]="notCorrectDrawer"
		[physicianId]="physicianOverviewResult?.physicianInfoData?.physicianId"
		[physicianName]="physicianOverviewResult?.physicianInfoData?.fullName"
		[executiveId]="physicianOverviewResult?.physicianInfoData?.executiveId"
		(cancelBtnClick)="cancelBtnClick($event)"
		*ngIf="notCorrectDrawer"
	></app-notcorrect>
</div>
