export class ClinicalModel {
	constructor(
		public clinicId: string = '',
		public name: string = '',
		public hospital_id: number = 0,
		public firmtype: string = '',
		public firmtypefull: string | null = null,
		public address: string = '',
		public address1: string = '',
		public city: string = '',
		public state: string = '',
		public zipcode: string = '',
		public latitude: string = '',
		public longitude: string = '',
		public webaddress: string = '',
		public phone: string = '',
		public ownership: string | null = null,
		public datefiscalyearend: string | null = null,
		public npinumber: string = '',
		public providernumber: number = 0,
		public dateEntered: string | null = null,
		public accreditationagency: string | null = null,
		public facilitytype: string | null = null,
		public topmedschoolaffiliate: string | null = null,
		public numberofaffiliatedphysicians: number = 0,
		public numberofaffiliatedphysicianssecondary: number = 0,
		public networkid: number = 0,
		public networkname: string | null = null,
		public networkpath: string | null = null,
		public networkparentid: number = 0,
		public networkparentname: string | null = null,
		public networkparentpath: string | null = null,
		public hospitalparentid: number = 0,
		public hospitalparentname: string | null = null,
		public hospitalparentpath: string | null = null,
		public pgparentid: number = 0,
		public pgparentname: string | null = null,
		public pgparentpath: string | null = null,
		public idnintegrationlevel: string | null = null,
		public idnintegrationleveldescription: string | null = null,
		public country: string | null = null,
		public geoclassification: string | null = null,
		public cbsacode: string = '',
		public cbsapopulationestmostrecent: string = ''
	) {}
	public toJson(): object {
		return {
			clinicId: this.clinicId,
			name: this.name,
			hospital_id: this.hospital_id,
			firmtype: this.firmtype,
			firmtypefull: this.firmtypefull,
			address: this.address,
			address1: this.address1,
			city: this.city,
			state: this.state,
			zipcode: this.zipcode,
			latitude: this.latitude,
			longitude: this.longitude,
			webaddress: this.webaddress,
			phone: this.phone,
			ownership: this.ownership,
			datefiscalyearend: this.datefiscalyearend,
			npinumber: this.npinumber,
			providernumber: this.providernumber,
			dateEntered: this.dateEntered,
			accreditationagency: this.accreditationagency,
			facilitytype: this.facilitytype,
			topmedschoolaffiliate: this.topmedschoolaffiliate,
			numberofaffiliatedphysicians: this.numberofaffiliatedphysicians,
			numberofaffiliatedphysicianssecondary: this.numberofaffiliatedphysicianssecondary,
			networkid: this.networkid,
			networkname: this.networkname,
			networkpath: this.networkpath,
			networkparentid: this.networkparentid,
			networkparentname: this.networkparentname,
			networkparentpath: this.networkparentpath,
			hospitalparentid: this.hospitalparentid,
			hospitalparentname: this.hospitalparentname,
			hospitalparentpath: this.hospitalparentpath,
			pgparentid: this.pgparentid,
			pgparentname: this.pgparentname,
			pgparentpath: this.pgparentpath,
			idnintegrationlevel: this.idnintegrationlevel,
			idnintegrationleveldescription: this.idnintegrationleveldescription,
			country: this.country,
			geoclassification: this.geoclassification,
			cbsacode: this.cbsacode,
			cbsapopulationestmostrecent: this.cbsapopulationestmostrecent
		};
	}

	public fromJson(obj: any): ClinicalModel {
		return new ClinicalModel(
			obj.clinicId,
			obj.name,
			obj.hospital_id,
			obj.firmtype,
			obj.firmtypefull,
			obj.address,
			obj.address1,
			obj.city,
			obj.state,
			obj.zipcode,
			obj.latitude,
			obj.longitude,
			obj.webaddress,
			obj.phone,
			obj.ownership,
			obj.datefiscalyearend,
			obj.npinumber,
			obj.providernumber,
			obj.dateEntered,
			obj.accreditationagency,
			obj.facilitytype,
			obj.topmedschoolaffiliate,
			obj.numberofaffiliatedphysicians,
			obj.numberofaffiliatedphysicianssecondary,
			obj.networkid,
			obj.networkname,
			obj.networkpath,
			obj.networkparentid,
			obj.networkparentname,
			obj.networkparentpath,
			obj.hospitalparentid,
			obj.hospitalparentname,
			obj.hospitalparentpath,
			obj.pgparentid,
			obj.pgparentname,
			obj.pgparentpath,
			obj.idnintegrationlevel,
			obj.idnintegrationleveldescription,
			obj.country,
			obj.geoclassification,
			obj.cbsacode,
			obj.cbsapopulationestmostrecent
		);
	}
}

export class ClinicalModelList {
	constructor(public clinicalList: Array<ClinicalModel> = []) {}

	updateClinicalListFromSavedList(savedList: Array<ClinicalModel>) {
		savedList.map((savedClinicalList: ClinicalModel) => {
			const index = this.clinicalList.findIndex(
				(clinicalItem) => savedClinicalList.clinicId == clinicalItem.clinicId
			);
			if (index !== -1) {
				this.clinicalList[index] = savedClinicalList;
			}
		});
	}
}
