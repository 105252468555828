<div class="content-container d-flex flex-column">
	<div class="header-container d-flex flex-column">
		<div class="heading mb-2">
			<h2>
				Team Members
				<span *ngIf="adminDetails" style="font-weight: 400"
					>({{ adminDetails.consumedMemberLimit }}/{{ adminDetails.teamMemberLimit }})</span
				>
			</h2>
			<p>Invite or manage your organisation member</p>
		</div>
		<div class="row align-items-center justify-content-between">
			<!-- Search-input  -->
			<div class="col-6">
				<div class="inputWithIcon">
					<input
						type="text"
						placeholder="Search member"
						class="search-input"
						[(ngModel)]="searchMember.searchName"
						(keyup)="handleSearch()"
					/>
					<i class="fa fa-search" aria-hidden="true"></i>
				</div>
			</div>
			<!-- Filter dropdown -->
			<span
				class="col-1 filter-dropdown"
				data-toggle="dropdown"
				aria-haspopup="true"
				[class.active-filter]="filterCount > 0"
			>
				<div class="filter-count" *ngIf="filterCount > 0">{{ filterCount }}</div>
				<svg width="14" height="14" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M11.9167 1.625H1.08337L5.41671 6.74917V10.2917L7.58337 11.375V6.74917L11.9167 1.625Z"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<div class="dropdown-menu" onclick="event.stopPropagation()">
					<!-- <div class="clear-all-container d-flex justify-content-end">
						<span class="clear-all-text">Clear All</span>
					</div> -->
					<div class="form-check" *ngFor="let item of filterItems | slice : 0 : 4; let i = index">
						<div class="form-check form-check-inline">
							<input
								class="form-check-input checkbox-input"
								type="checkbox"
								[id]="item.name"
								[(ngModel)]="item.checked"
								(change)="handleFilter(item)"
							/>
							<!-- [checked]="item.checked" -->

							<label class="form-check-label" [for]="item.name">{{ item.name }}</label>
						</div>
					</div>
					<hr />
					<div class="form-check" *ngFor="let item of filterItems | slice : 4 : 8; let i = index">
						<div class="form-check form-check-inline">
							<input
								class="form-check-input checkbox-input"
								type="checkbox"
								[id]="item.name"
								[(ngModel)]="item.checked"
								(change)="handleFilter(item)"
							/>
							<!-- [checked]="item.checked" -->

							<label class="form-check-label" [for]="item.name">{{ item.name }}</label>
						</div>
					</div>
				</div>
			</span>
			<!-- Add member button -->
			<button class="col-4 add-member-btn" (click)="addUser()" >
				<i class="fa fa-plus"></i>
				<span class="ml-2">Add Member</span>
			</button>
		</div>
	</div>

	<div class="scroll-container mt-2 pb-3" id="scrollContainer">
		<div class="list-container">
			<div
				class="card-container"
				[class.active-card]="adminActive == true"
				(click)="openAdmin()"
				*ngIf="showAdmin"
			>
				<app-member-card [userInfo]="adminDetails" [isAdmin]="true" *ngIf="adminDetails"></app-member-card>
			</div>

			<div
				class="card-container"
				*ngFor="let user of userList; let i = index"
				(click)="handleClick(user, i)"
				[class.active-card]="i === activeCard"
			>
				<app-member-card [userInfo]="user" [isAdmin]="false" *ngIf="user"> </app-member-card>
			</div>
			<div class="loader-container text-center" *ngIf="showMore">
				<span *ngIf="!listLoader" (click)="handleShowMore()">Show More</span>
				<svg viewBox="25 25 50 50" *ngIf="listLoader">
					<circle r="10" cy="50" cx="50"></circle>
				</svg>
			</div>
		</div>
	</div>
</div>
