import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { tap, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
@Injectable({
	providedIn: 'root'
})
export class PayorService {
	public authToken = localStorage.getItem('auth_token');
	public cook = document.cookie;
	domainName = '';

	constructor(
		private http: HttpClient,
		private router: Router,
		private cookieService: CookieService
	) {}
	get organizationId() {
		return localStorage.getItem('organizationId');
	}

	searchMCOExecutives(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url = environment.prodHcApi + '/amplizhcreadapi/searchmco/search-m-c-o-executives';
		// console.log(body, 'searchMCOExecutives');
		return this.http.post(
			url,
			{ searchInputMCOExecutives: body, organizationId: this.organizationId },
			{ headers }
		);
	}
	searchClinicalExecutives(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url = environment.prodHcApi + '/amplizhcreadapi/searchclinic/search-clinic-executive';
		return this.http.post(
			url,
			{ searchInputClinicExecutive: body, organizationId: this.organizationId },
			{ headers }
		);
	}
	searchPhysicianGroupExecutives(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url =
			environment.prodHcApi +
			'/amplizhcreadapi/searchphysiciangroup/search-physician-group-executives';
		return this.http.post(
			url,
			{
				searchInputPhysicianGroupExecutives: body,
				organizationId: this.organizationId
			},
			{ headers }
		);
	}
	searchMCOExecutivesCount(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url =
			environment.prodHcApi + '/amplizhcreadapi/searchmco/get-m-c-o-executives-net-new-count';
		return this.http.post(
			url,
			{ searchInputMCOExecutives: body, organizationId: this.organizationId },
			{ headers }
		);
	}
	searchClinicalExecutivesCount(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url =
			environment.prodHcApi + '/amplizhcreadapi/searchclinic/get-clinic-executives-net-new-count';
		return this.http.post(
			url,
			{ searchInputClinicExecutive: body, organizationId: this.organizationId },
			{ headers }
		);
	}
	searchPhysicianGroupExecutivesCount(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url =
			environment.prodHcApi +
			'/amplizhcreadapi/searchphysiciangroup/get-physician-group-executives-net-new-count';
		return this.http.post(
			url,
			{
				searchInputPhysicianGroupExecutives: body,
				organizationId: this.organizationId
			},
			{ headers }
		);
	}
	searchMCOCount(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url = environment.prodHcApi + '/amplizhcreadapi/searchmco/get-m-c-o-net-new-count';
		return this.http.post(
			url,
			{ searchInputMCO: body, organizationId: this.organizationId },
			{ headers }
		);
	}
	searchMCO(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url = environment.prodHcApi + '/amplizhcreadapi/searchmco/search-m-c-o';
		return this.http.post(
			url,
			{ searchInputMCO: body, organizationId: this.organizationId },
			{ headers }
		);
	}
	searchPhysicianGroup(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url =
			environment.prodHcApi + '/amplizhcreadapi/searchphysiciangroup/search-physician-group';
		return this.http.post(
			url,
			{ searchInputPhysicianGroup: body, organizationId: this.organizationId },
			{ headers }
		);
	}
	searchClinical(body: any): Observable<any> {
		const headers = new HttpHeaders({});
		const url = environment.prodHcApi + '/amplizhcreadapi/searchclinic/search-clinic';
		return this.http.post(
			url,
			{ searchInputClinic: body, organizationId: this.organizationId },
			{ headers }
		);
	}
	createList(body: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcwriteapi/savedlist/create-list';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}
	savePayourAsPerCount(data: any): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcwriteapi/savedlist/save-m-c-o-executives-records-as-per-count';
		const response = this.http.post(url, {
			...data,
			organizationId: this.organizationId
		});
		return response;
	}
	saveClinicExecutiveAsPerCount(data: any): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcwriteapi/savedlist/save-clinic-executive-records-as-per-count';
		const response = this.http.post(url, {
			...data,
			organizationId: this.organizationId
		});
		return response;
	}
	savePhysicianGroupAsPerCount(data: any): Observable<any> {
		const url =
			environment.prodHcApi +
			'/amplizhcwriteapi/savedlist/save-physician-group-executives-records-as-per-count';
		const response = this.http.post(url, {
			...data,
			organizationId: this.organizationId
		});
		return response;
	}
	getLists(
		offset: any,
		count: any,
		autoCreated: any = false,
		listType: string = 'Mylist'
	): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcreadapi/list/get-all-list';
		const body = {
			offset: offset,
			count: count,
			autoCreated: autoCreated,
			listType
		};
		const response = this.http.get(url, {
			params: { ...body, organizationId: this.organizationId }
		});
		return response;
	}
	viewPayourFromList(body: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcwriteapi/savedlist/add-m-c-o-executives-to-list';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}
	viewClinicalExecutiveFromList(body: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcwriteapi/savedlist/add-clinic-executives-to-list';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}
	viewPhysicianGroupFromList(body: any): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcwriteapi/savedlist/add-physician-group-executives-to-list';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}
	getPayourDetails(mcoExecutiveId: any): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcreadapi/searchmco/get-m-c-o-executive-and-company-detail';
		const response = this.http.get(url, {
			params: { mcoExecutiveId, organizationId: this.organizationId }
		});
		return response;
	}
	getPhysicianGroupDetails(phyGroupExecutiveId: any): Observable<any> {
		const url =
			environment.prodHcApi +
			'/amplizhcreadapi/searchphysiciangroup/get-physician-group-executive-and-company-detail';
		const response = this.http.get(url, {
			params: { phyGroupExecutiveId, organizationId: this.organizationId }
		});
		return response;
	}
	saveViewedPayour(body: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcwriteapi/savedlist/save-viewed-lead';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}
	saveViewedClinicalExecutive(body: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcwriteapi/savedlist/save-viewed-lead';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}
	getCompanyList(searchString: string): Observable<any> {
		// console.log(searchString);
		const url =
			environment.prodHcApi +
			'/amplizhcreadapi/general/get-company-list-mco-company-auto-suggest?searchPhrase=' +
			searchString;
		const response = this.http.get(url, {
			params: { organizationId: this.organizationId }
		});
		return response;
	}
	getPhysicianCompanyList(searchString: string): Observable<any> {
		// console.log(searchString);
		const url =
			environment.prodHcApi +
			'/amplizhcreadapi/general/get-company-list-physician-group-auto-suggest?searchPhrase=' +
			searchString;
		const response = this.http.get(url, {
			params: { organizationId: this.organizationId }
		});
		return response;
	}
	getClinicalNameList(searchString: string): Observable<any> {
		// console.log(searchString);
		const url =
			environment.prodHcApi +
			'/amplizhcreadapi/general/get-clinic-name-list?searchPhrase=' +
			searchString;
		const response = this.http.get(url, {
			params: { organizationId: this.organizationId }
		});
		return response;
	}
	getClinicNameList(searchString: string): Observable<any> {
		// console.log(searchString);
		const url =
			environment.prodHcApi +
			'/amplizhcreadapi/general/get-clinic-name-list-for-executive?searchPhrase=' +
			searchString;
		const response = this.http.get(url, {
			params: { organizationId: this.organizationId }
		});
		return response;
	}
	getClinicalNPIList(searchString: string): Observable<any> {
		// console.log(searchString);
		const url =
			environment.prodHcApi +
			'/amplizhcreadapi/general/get-clinic-npi-number-list?searchPhrase=' +
			searchString;
		const response = this.http.get(url, {
			params: { organizationId: this.organizationId }
		});
		return response;
	}

	getTitlesList(params: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcreadapi/general/get-mco-title-all-auto-suggest';
		const response = this.http.post(url, {
			...params,
			organizationId: this.organizationId
		});
		return response;
	}
	getPhysicianGroupTitlesList(params: any): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcreadapi/general/get-physician-group-title-all-auto-suggest';
		const response = this.http.post(url, {
			...params,
			organizationId: this.organizationId
		});
		return response;
	}
	reportDataNotCorrectForPayor(body): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcwriteapi/customervoice/report-incorrect-m-c-o-company-data';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}
	reportDataNotCorrectForPhysicianGroup(body): Observable<any> {
		const url =
			environment.prodHcApi +
			'/amplizhcwriteapi/customervoice/report-incorrect-physician-group-company-data';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}

	reportDataNotCorrectForExecutivePayor(body): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcwriteapi/customervoice/report-incorrect-m-c-o-executive-data';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}
	reportDataNotCorrectForExecutivePhysicianGRoup(body): Observable<any> {
		const url =
			environment.prodHcApi +
			'/amplizhcwriteapi/customervoice/report-incorrect-physician-group-executive-data';
		const response = this.http.post(url, {
			...body,
			organizationId: this.organizationId
		});
		return response;
	}
	getPayourCenterDetails(mcoCompanyId: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcreadapi/searchmco/get-m-c-o-detail';
		const response = this.http.get(url, {
			params: { mcoCompanyId, organizationId: this.organizationId }
		});
		return response;
	}
	getClinicDetails(clinicId: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcreadapi/searchclinic/get-clinic-details';
		const response = this.http.get(url, {
			params: { clinicId, organizationId: this.organizationId }
		});
		return response;
	}
	getClinicExecutiveDetails(clinicExecutiveId: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcreadapi/searchclinic/get-clinic-executives-details';
		const response = this.http.get(url, {
			params: { clinicExecutiveId, organizationId: this.organizationId }
		});
		return response;
	}
	getPhysicianGroupCenterDetails(phyGroupCompanyId: any): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcreadapi/searchphysiciangroup/get-physician-group-detail';
		const response = this.http.get(url, {
			params: { phyGroupCompanyId, organizationId: this.organizationId }
		});
		return response;
	}
	getExecutivesOfPayor(mcoCompanyId: any, offset: any, count: any): Observable<any> {
		const url = environment.prodHcApi + '/amplizhcreadapi/searchmco/get-m-c-o-executives';

		const response = this.http.get(url, {
			params: {
				offset: offset,
				count: count,
				mcoCompanyId: mcoCompanyId,
				organizationId: this.organizationId
			}
		});
		return response;
	}
	getExecutivesOfPhysicianGroup(phyGroupCompanyId: any, offset: any, count: any): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcreadapi/searchphysiciangroup/get-physician-group-executives';

		const response = this.http.get(url, {
			params: {
				offset: offset,
				count: count,
				phyGroupCompanyId: phyGroupCompanyId,
				organizationId: this.organizationId
			}
		});
		return response;
	}

	reportDataNotCorrectForClinic(body): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcwriteapi/customervoice/report-incorrect-clinic-data';
		const response = this.http.post(url, body);
		return response;
	}
	reportDataNotCorrectForClinicExecutive(body): Observable<any> {
		const url =
			environment.prodHcApi + '/amplizhcwriteapi/customervoice/report-incorrect-clinic-executive-data';
		const response = this.http.post(url, body);
		return response;
	}
	getProductName(search): Observable<any> {
		const url =
			environment.prodHcApi +
			`/amplizhcreadapi/general/get-phy-group-exec-ehremrproductname-list?searchPhrase=${search}`;
		const response = this.http.get(url);
		return response;
	}
	getVendorList(search): Observable<any> {
		const url =
			environment.prodHcApi +
			`/amplizhcreadapi/general/get-phy-group-exec-ehremrvendorname-list?searchPhrase=${search}`;
		const response = this.http.get(url);
		return response;
	}
	getInsuranceList(search): Observable<any> {
		const url =
			environment.prodHcApi +
			`/amplizhcreadapi/general/get-phy-group-exec-insurance-list?searchPhrase=${search}`;
		const response = this.http.get(url);
		return response;
	}

	getPhysiciansForPhysicianGroup(phyOrgId: any, offset: any, count: any): Observable<any> {
		const url =
			environment.prodHcApi +
			'/amplizhcreadapi/searchphysiciangroup/physicians-for-physician-group-organization';

		const response = this.http.post(url, {
			offset: offset,
			count: count,
			phyGroupOrgId: phyOrgId
		});
		return response;
	}
}
