import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	Output,
	EventEmitter,
	Input,
	OnChanges,
	AfterViewInit,
	NgZone,
	Renderer2,
	TemplateRef
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FilterStorageService } from '../../../services/filter-storage.service';
import { Router } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { ImagingService } from 'src/app/modules/ImagingCenter/services/imaging.service';
import { SearchHospital } from '../../../models/search-hospital';
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';
@Component({
	selector: 'app-filter-hospital',
	templateUrl: './filter-hospital.component.html',
	styleUrls: ['./filter-hospital.component.css']
})
export class FilterHospitalComponent implements OnInit, OnChanges, AfterViewInit {
	typeSettings = {};
	hospitalLoader = false;
	filterData: SearchHospital = new SearchHospital();
	tempDialogFilters: SearchHospital = new SearchHospital();
	dialogRef: any;
	dialogState: boolean = false;
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;
	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showConfirmDialog: boolean = false;

	@Output() onFilterChange = new EventEmitter<any>();
	@Input() isSubscribed: boolean;
	selectable = true;
	removable = true;
	@ViewChild('hospitalNameInput', { static: false })
	hospitalNameInput: ElementRef<HTMLInputElement>;
	@ViewChild('codeInput', { static: false })
	codeInput: ElementRef<HTMLInputElement>;
	@ViewChild('stateInput', { static: false })
	stateInput: ElementRef<HTMLInputElement>;
	@ViewChild('cityInput', { static: false })
	cityInput: ElementRef<HTMLInputElement>;
	@ViewChild('input', { static: true }) triggerHospital: MatAutocompleteTrigger;
	@ViewChild('codeClassificationInput', { static: false })
	codeClassificationInput: ElementRef<HTMLInputElement>;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	hospitalControl = new FormControl();
	codeControl = new FormControl();
	stateControl = new FormControl();
	cityControl = new FormControl();
	hideHospitalNamePlaceholder: boolean = false;
	toppingsControl = new FormControl([]);
	isPaid: boolean = false;
	selectedCode: any[] = [];
	selectedImagingNumber: any = [];
	imagingNumber: any = 0;
	numberOfImagingEquipmentList: string[] = [];
	imagingEqupmentsList: Array<any> = [];
	selectedImagingEquipments: any = [];
	@ViewChild('cptCodeInput', { static: false })
	cptCodeInput: ElementRef<HTMLInputElement>;
	cptControl = new FormControl();
	cptCodeList: any = [];
	hscpcCodeList: any = [];
	selectedCPTCode: string[] = [];
	selectedHSCPCCode: string[] = [];
	codeClassificationControl = new FormControl();

	// Data for filter component
	filteredHospitals: Observable<string[]>;
	filteredStates: Observable<string[]>;
	filteredCities: any = [];
	icptCodeList: any = [];
	hospitalTypeList = [];
	noOfBedList = [];
	// Variable to be used for filter API call
	selectedHospitalTypes: any = [];
	hospitalNames: any = [];
	selectedStates: any = [];
	selectedCities: any = [];
	searchCity: any = [];
	noOfBeds: any = [];
	icdLoader: boolean = false;
	dropdownSettings = {
		enableCheckAll: false,
		singleSelection: false,
		idField: 'id',
		textField: 'itemName',
		itemsShowLimit: 3,
		allowSearchFilter: true
	};
	subscription: Subscription;
	constructor(
		public router: Router,
		private amplizService: AmplizService,
		private filterStorageService: FilterStorageService,
		private ngZone: NgZone,
		private healthCareDataService: DataService,
		private loaderService: LoaderService,
		private messageService: MessageService,
		private renderer: Renderer2,
		private b2bService: ImagingService,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService
	) {}
	ngOnInit() {
		this.subscription = this.healthCareDataService.searchHospitalData.subscribe((res) => {
			// console.log(res);
			if (res.fromSearch) {
				this.filterData = res.data;
				this.omitChange();
			}
		});
		this.makeImagingEqipmentList();
		this.getHSCPCCodes();
		this.getCPTCodes();
		this.getAllListData();
		this.getCpdtList();
		this.getPersistData();
		this.getImagingCenterEquipment();
	}
	ngAfterViewInit() {}
	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
		this.healthCareDataService.PassHospitalData(this.filterData, false);
	}
	get selectedCodes() {
		return [...this.selectedCPTCode, ...this.selectedHSCPCCode, ...this.selectedCode];
	}
	getImagingCenterEquipment(searchPhase = '') {
		this.b2bService.getImagingCenterEquipment(searchPhase, 'Company').subscribe((res) => {
			this.imagingEqupmentsList = res.icEquipmentList;
		});
	}
	removeImagingEquiment(code: string) {
		this.selectedImagingEquipments = this.selectedImagingEquipments.filter((el) => el !== code);
		this.getImagingCenterEquipment();
		this.omitChange();
		this.storeFilterData();
	}
	makeImagingEqipmentList() {
		this.numberOfImagingEquipmentList = [];
		for (let i = 0; i < 25; i++) {
			this.numberOfImagingEquipmentList.push(i + 1 + '');
		}
	}
	ngOnChanges() {
		// this.isPaid = false;
		this.isPaid = !this.isSubscribed;
	}
	getPersistData() {
		const newFilterData = this.filterStorageService.get('hospital_filter_data');
		if (newFilterData == null) return;
		Object.keys(newFilterData).map((el) => {
			this.filterData[el] = newFilterData[el];
		});
		this.omitChange();
		this.getRecordCount();
	}
	clearAll() {
		this.filterData = new SearchHospital();
		this.getCPTCodes();
		this.getHSCPCCodes();
		this.omitChange();
		this.storeFilterData();
	}
	storeFilterData() {
		this.filterStorageService.set('hospital_filter_data', this.filterData);
	}
	addHospitalName(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		const found = this.hospitalNames.indexOf(value);
		// Add our fruit
		if (value && found === -1) {
			this.hospitalNames.push(value);
		}
		event.input.value = '';

		this.hospitalControl.setValue(null);
		this.omitChange();
		this.storeFilterData();
	}
	omitChange() {
		this.healthCareDataService.changePhysicainSearchData({
			requestType: 'Hospital',
			...this.filterData.toJson()
		});
		this.onFilterChange.emit(this.filterData);
	}

	selectedHospitalName(event: MatAutocompleteSelectedEvent): void {
		if (this.hospitalNames.findIndex((hospital) => hospital === event.option.viewValue) === -1) {
			this.hospitalNames.push(event.option.viewValue);
			this.hospitalNameInput.nativeElement.value = '';
			this.hospitalControl.setValue(null);
			this.omitChange();
			this.storeFilterData();
		}
	}
	selectCode(event: MatAutocompleteSelectedEvent): void {
		if (this.selectedCode.findIndex((hospital) => hospital === event.option.viewValue) === -1) {
			this.selectedCode.push(event.option.value);
			this.codeInput.nativeElement.value = '';
			this.codeControl.setValue(null);
			this.getCpdtList();
			this.omitChange();
			this.storeFilterData();
		}
	}
	onHTOptionClick(HT: any) {
		const found = this.selectedHospitalTypes.findIndex((ele) => ele === HT);
		//
		if (found !== -1) {
			this.selectedHospitalTypes = this.selectedHospitalTypes.filter((hType) => hType !== HT);
		} else {
			this.selectedHospitalTypes.push(HT);
		}
		this.omitChange();
		this.storeFilterData();
	}
	onHospitalTypeSelect(hospitalType: any) {
		this.selectedHospitalTypes.push(hospitalType.value);
		this.omitChange();
		//
	}
	onHospitalTypeDeselect(hospitalType: any) {
		//
		this.selectedHospitalTypes = this.selectedHospitalTypes.filter((hType) => hType !== hospitalType);
		this.omitChange();
		this.storeFilterData();
	}
	onNoOfBedsSelect(bed: any) {
		//
		const found = this.noOfBeds.findIndex((ele) => ele === bed);
		if (found !== -1) {
			this.noOfBeds = this.noOfBeds.filter((b) => b !== bed);
		} else {
			this.noOfBeds.push(bed);
		}
		this.omitChange();
		this.storeFilterData();
	}
	onNoOfBedsDeselect(bed: any) {
		this.noOfBeds = this.noOfBeds.filter((b) => b !== bed);
		this.omitChange();
		this.storeFilterData();
	}
	selectStates(event: MatAutocompleteSelectedEvent): void {
		const receivedState = event.option.value;
		const found = this.selectedStates.findIndex((ele) => ele.stateId === receivedState.stateId);
		if (found === -1) {
			this.selectedStates.push(receivedState);
			this.stateInput.nativeElement.value = '';
			this.stateControl.setValue(null);
			//
			// this.addCitiesInList(receivedState);
			this.omitChange();
			this.storeFilterData();
		}
	}
	selectCity(event: MatAutocompleteSelectedEvent): void {
		this.selectedCities.push(event.option.value);
		this.cityInput.nativeElement.value = '';
		this.cityControl.setValue(null);
		this.omitChange();
		this.storeFilterData();
		//
	}
	addCitiesInList(state: any) {
		//
		this.amplizService.getCityListForState(state.stateId).subscribe((response) => {
			this.filteredCities = [...this.filteredCities, ...response.cityDataList];
			this.searchCity = this.filteredCities;
			// this.filteredCities.push(response.cityDataList);
			//
		});
	}
	removeState(inState: any) {
		this.selectedStates = this.selectedStates.filter((state) => inState.stateId !== state.stateId);
		// this.filteredCities = this.filteredCities.filter(
		//   (city) => city.stateId !== inState.stateId
		// );
		// this.searchCity = this.filteredCities;
		this.omitChange();
		this.storeFilterData();
		//
		//
	}
	onCityKeyUp(citySearchPhrase: any) {
		this.searchCity = this.filteredCities.filter((ele) =>
			ele.city.toLocaleLowerCase().includes(citySearchPhrase.toLowerCase())
		);
	}

	displayFn(selectedState: any): string {
		return selectedState.stateId;
	}
	getAllListData() {
		this.cptControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getHSCPCCodes(hv);
			}
		});
		this.codeClassificationControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getCPTCodes(hv);
			}
		});
		// change control for hospital name
		this.hospitalControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
			} else {
				const tempHospital: any = [];
				this.filteredHospitals = tempHospital;
			}
		});
		this.codeControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			this.getCpdtList(hv);
		});
		// change control for states
		this.stateControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 2) {
				this.amplizService.getStateList({ searchPhase: value }).subscribe((response) => {
					this.filteredStates = response.stateDataList;
				});
			} else {
				const tempStates: any = [];
				this.filteredStates = tempStates;
			}
		});
		// get hospital type list
		this.amplizService.getHospitalTypeList('').subscribe((res) => {
			this.hospitalTypeList = res.hospitalTypeList;
		});
		// get number of bed  list
		this.amplizService.getNumberOfBedList('').subscribe((res) => {
			this.noOfBedList = res.bedRangeList;
		});
		// city input change
		this.cityControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				// event.cityList.map((city) => city.city);
				var tStates = this.selectedStates.map((state) => state.stateId);
				var params = { stateId: tStates, searchPhase: value };
				this.amplizService.searchCitiesForStates(params).subscribe((response) => {
					this.filteredCities = [...response.cityDataList];
					this.searchCity = this.filteredCities;
					// this.filteredCities.push(response.cityDataList);
				});
			} else {
				const temp: any = [];
				this.searchCity = temp;
			}
		});
	}
	getCpdtList(searchPhrase = '') {
		if (searchPhrase.length >= 3) {
			this.icdLoader = true;
			this.amplizService.getAllicdtenCodes({ searchPhrase }).subscribe((response: any) => {
				this.icptCodeList = response.icdtenCodes?.map((el) => ({ ...el, id: `${el.code} - ${el.description}` }));
				this.icdLoader = false;
			});
		}
	}
	getHospitalName(searchPhase) {
		this.hospitalLoader = true;
		this.amplizService.getPhysicianHospitalList({ searchPhase }).subscribe((response) => {
			this.filteredHospitals = response.hospitalDataList;
			this.hospitalLoader = false;
		});
	}
	onKey(value) {
		this.searchCity = [];
		this.selectSearchCity(value);
	}
	selectSearchCity(value: string) {
		let filter = value.toLowerCase();
		this.searchCity = this.filteredCities.filter((i) => i.name.toLowerCase().indexOf(filter) >= 0);
		this.omitChange();
		this.storeFilterData();
	}
	public openItem(path: string): void {
		this.ngZone.run(() => this.router.navigateByUrl(path)).then();
	}
	async requestPricing() {
		const emailId = await localStorage.getItem('email_id');
		this.loaderService.display(true);
		const body = { package: 'Enterprise', email: emailId };
		this.amplizService.getPrice(body).subscribe(
			(res) => {
				this.loaderService.display(false);

				this.messageService.display(true, 'Thanks for asking, will get back to you in 24 hrs');
			},
			(error) => {
				this.loaderService.display(false);
				this.messageService.displayError(true, error.error.msg ? error.error.msg : 'Server Error !!!');
			}
		);
	}
	triggerAutoFocus(eleId: string) {
		const element = this.renderer.selectRootElement(eleId);
		setTimeout(() => element.focus(), 100);
	}
	onCodeSelect(event: MatAutocompleteSelectedEvent) {
		this.selectedHSCPCCode = [...new Set([...this.selectedHSCPCCode, event.option.value])];
		this.cptCodeInput.nativeElement.value = '';
		setTimeout(() => {
			this.getHSCPCCodes();
		}, 500);
		this.omitChange();
		this.storeFilterData();
	}
	addCodeClassification(event): void {
		this.selectedCPTCode = [...new Set([...this.selectedCPTCode, event.option.value])];

		this.codeClassificationInput.nativeElement.value = '';
		setTimeout(() => {
			this.getCPTCodes();
		}, 500);

		this.omitChange();
		this.storeFilterData();
	}
	removeCode(code: string) {
		this.selectedHSCPCCode = this.selectedHSCPCCode.filter((el) => el !== code);
		this.getHSCPCCodes();
		this.omitChange();
		this.storeFilterData();
	}
	removeICDCode(code: string) {
		this.selectedCode = this.selectedCode.filter((el) => el != code);
		this.omitChange();
		this.storeFilterData();
	}
	removeCPTCode(code: string) {
		this.selectedCPTCode = this.selectedCPTCode.filter((el) => el !== code);
		this.getCPTCodes();
		this.omitChange();
		this.storeFilterData();
	}
	getCPTCodes(searchPhrase: string = '') {
		this.amplizService.getCptCodes(searchPhrase).subscribe((res: any) => {
			this.cptCodeList = res.cptCodes.map((el) => ({ ...el, id: `${el.cptCode} - ${el.description}` }));
		});
	}
	getHSCPCCodes(searchPhrase: string = '') {
		this.amplizService.getHcpcsCodes(searchPhrase).subscribe((res: any) => {
			this.hscpcCodeList = res.hcpcsCodes.map((el) => ({ ...el, id: `${el.hcpcsCode} - ${el.description}` }));
		});
	}
	clearTempFilter() {
		const isValid = this.tempDialogFilters.validateImagingSearch();
		if (isValid) {
			this.tempDialogFilters = new SearchHospital();
			this.getRecordCount();
		}
	}
	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		Object.keys(this.filterData).map((el) => {
			this.tempDialogFilters[el] = this.filterData[el];
		});
		this.getRecordCount();
		this.dialogState = true;
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}
	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, cloneDeep(this.filterData));
	}
	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearTempFilter();
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}
	getRecordCount() {
		this.recordCountLoader.next(true);
		this.amplizService.searchHospital({ offset: 5, limit: 5 }, this.tempDialogFilters.toJson()).subscribe(
			(res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				this.recordCountLoader.next(false);
			},
			(err) => {
				this.recordCountLoader.next(false);
			}
		);
	}
	applyMoreFilters() {
		Object.keys(this.tempDialogFilters).map((el) => {
			this.filterData[el] = this.tempDialogFilters[el];
		});
		this.omitChange();
		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}
	get tempProcedureCodes() {
		return [
			...this.tempDialogFilters.cptCodes,
			...this.tempDialogFilters.hcpcsCodes,
			...this.tempDialogFilters.icdTenCodes
		];
	}
	get ProcedureCodes() {
		return [...this.filterData.cptCodes, ...this.filterData.hcpcsCodes, ...this.filterData.icdTenCodes];
	}
}
