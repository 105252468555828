import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { B2bService } from 'src/app/modules/B2B/services/b2b.service';
import { FilterStorageService } from 'src/app/modules/B2B/services/filter-storage.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { SearchImagingModel } from '../../../ImagingCenter/models/SearchImagingModel';
import { ImagingDataService } from '../../../ImagingCenter/services/imaging-data.service';
import { ImagingService } from '../../../ImagingCenter/services/imaging.service';
import { PayorService } from '../../service/payor.service';
import { SearchClinicalModal } from '../../models/clinical-model/search-clinical.modal';
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';

@Component({
	selector: 'app-clinical-filter',
	templateUrl: './clinical-filter.component.html',
	styleUrls: ['./clinical-filter.component.css']
})
export class ClinicalFilterComponent implements OnInit {
	@Output() onFilterChange = new EventEmitter<any>();
	@Input() isSubscribed: boolean;
	@Input() isPaid: boolean = false;

	filterData: SearchClinicalModal = new SearchClinicalModal();
	tempDialogFilters: SearchClinicalModal = new SearchClinicalModal();
	dialogRef: any;
	dialogState: boolean = false;
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;
	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showConfirmDialog: boolean = false;
	nameList: Array<any> = [];
	npiNumberList: Array<any> = [];
	countryList: Array<any> = [];
	selectedCountry: Array<any> = [];
	stateList: Array<any> = [];
	selectedStates: Array<any> = [];
	cityList: Array<any> = [];
	selectedCities: Array<any> = [];
	revenueList: Array<any> = [];
	includedRevenueRange: any = [];
	subscription: Subscription;
	mapingValue = {
		'Payors/health Insurance': 0,
		'Preferred Provider Organization': 1,
		'Point Of Service': 2,
		'Exclusive Provider Organization': 3,
		'Health Maintenance Organization': 4
	};

	//Imaging equipments

	constructor(
		private b2bService: ImagingService,
		private filterStorageService: FilterStorageService,
		private dataService: ImagingDataService,
		private payourService: PayorService,
		private loaderService: LoaderService,
		private messageService: MessageService,
		private amplizService: AmplizService,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService
	) {}

	ngOnInit() {
		this.getPersistData();
	}
	get firmTypeList() {
		return [
			'Federally Qualified Health Center',
			'Renal Dialysis Facility',
			'Retail Clinic',
			'Rural Health Clinic',
			'Urgent Care Clinic'
		];
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
		// this.dataService.passSearchImagingInput(this.filterData, false);
	}
	ngOnChanges() {
		this.isPaid = !this.isSubscribed;
		// this.isPaid = false;
		//
	}

	nameListChange(item) {
		if (item && item.length > 1) {
			this.payourService.getClinicalNameList(item).subscribe((res) => {
				this.nameList =
					res?.ClinicDataList?.map((el) => ({ ...el, nameKey: `${el.name} (${el.firmtype})` })) || [];
			});
		}
	}
	handleClinicChange() {
		// console.log('changing');
	}

	npiNumberChange(item) {
		if (item && item.length > 1) {
			this.payourService.getClinicalNPIList(item).subscribe((res) => {
				this.npiNumberList = res.npiNumberList;
			});
		}
	}
	getPersistData() {
		// let that = this;
		setTimeout(() => {
			const newFilterData = this.filterStorageService.get('clinical_group_filter');
			Object.keys(newFilterData).map((el) => {
				this.filterData[el] = newFilterData[el];
			});

			this.omitChanges();
		});
		this.getRecordCount();
	}
	async requestPricing() {
		const emailId = await localStorage.getItem('email_id');
		this.loaderService.display(true);
		const body = { package: 'Enterprise', email: emailId };
		this.amplizService.getPrice(body).subscribe(
			(res) => {
				this.loaderService.display(false);

				this.messageService.display(true, 'Thanks for asking, will get back to you in 24 hrs');
			},
			(error) => {
				this.loaderService.display(false);
				this.messageService.displayError(true, error.error.msg ? error.error.msg : 'Server Error !!!');
			}
		);
	}

	storeFilterData() {
		this.filterStorageService.set('clinical_group_filter', this.filterData);
	}

	omitChanges() {
		// console.log('filterData', this.filterData);
		this.onFilterChange.emit(this.filterData.toJson());
		this.storeFilterData();
	}

	clearFilter() {
		const isValid = this.filterData.validateImagingSearch();
		if (isValid) {
			this.filterData = new SearchClinicalModal();
			this.omitChanges();
		}
	}
	hanldeLocationValueChange(locationBody: any) {
		this.omitChanges();
	}
	clearTempFilter() {
		const isValid = this.tempDialogFilters.validateImagingSearch();
		if (isValid) {
			this.tempDialogFilters = new SearchClinicalModal();
			this.getRecordCount();
		}
	}
	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		Object.keys(this.filterData).map((el) => {
			this.tempDialogFilters[el] = this.filterData[el];
		});
		this.getRecordCount();
		this.dialogState = true;
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}
	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, cloneDeep(this.filterData));
	}
	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearTempFilter();
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}
	getRecordCount() {
		this.recordCountLoader.next(true);
		this.payourService.searchClinical(this.tempDialogFilters.toJson()).subscribe(
			(res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				this.recordCountLoader.next(false);
			},
			(err) => {
				this.recordCountLoader.next(false);
			}
		);
	}
	applyMoreFilters() {
		Object.keys(this.tempDialogFilters).map((el) => {
			this.filterData[el] = this.tempDialogFilters[el];
		});
		this.omitChanges();
		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}
}
