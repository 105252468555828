import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { SearchHospital } from '../models/search-hospital';

@Injectable({
	providedIn: 'root'
})
export class DataService {
	private physicianSearchData = new BehaviorSubject({});
	private moreFilterData = new BehaviorSubject({});
	public savedPhysicians: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
	public savedExecutives: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
	// observable for searchDatas
	physicianSearch = this.physicianSearchData.asObservable();

	storedMoreFilterData: any = this.moreFilterData.asObservable();
	public searchHospitalData = new BehaviorSubject({
		data: new SearchHospital(),
		fromSearch: false
	});

	constructor() {}

	changePhysicainSearchData(data: any) {
		this.physicianSearchData.next(data);
	}

	addToSavedPhysician(value: Array<any>) {
		this.savedPhysicians.next(value);
	}
	addToSavedExecutive(value: Array<any>) {
		this.savedExecutives.next(value);
	}

	storeMoreFilterData(data: any) {
		this.storedMoreFilterData.next(data);
	}
	PassHospitalData(data: SearchHospital, fromSearch: boolean = true) {
		this.searchHospitalData.next({ data, fromSearch: fromSearch });
	}
}
