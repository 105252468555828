import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { SearchHospital } from '../models/search-hospital';
import { SearchPhysicianModel } from '../models/searchPhysicianModel';

@Injectable({
	providedIn: 'root'
})
export class DataService {
	private physicianSearchData = new BehaviorSubject({});
	private moreFilterData = new BehaviorSubject({});
	public savedPhysicians: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
	public savedExecutives: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
	public saveSearchFilter: BehaviorSubject<{}> = new BehaviorSubject({});
	public saveSearchPanelVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public physicianSearchFromPhysicianGroup = new BehaviorSubject('');
	public hospitalSearchForExecutive = new BehaviorSubject('');
	public physicianSearchFromGlobalSearch = new BehaviorSubject('');
	// observable for searchDatas
	physicianSearch = this.physicianSearchData.asObservable();

	storedMoreFilterData: any = this.moreFilterData.asObservable();
	public searchHospitalData = new BehaviorSubject({
		data: new SearchHospital(),
		fromSearch: false
	});

	constructor() {}

	changePhysicainSearchData(data: any) {
		this.physicianSearchData.next(data);
	}

	addToSavedPhysician(value: Array<any>) {
		this.savedPhysicians.next(value);
	}
	addToSavedExecutive(value: Array<any>) {
		this.savedExecutives.next(value);
	}

	storeMoreFilterData(data: any) {
		this.storedMoreFilterData.next(data);
	}
	PassHospitalData(data: SearchHospital, fromSearch: boolean = true) {
		this.searchHospitalData.next({ data, fromSearch: fromSearch });
	}

	passSaveSearchFilter(data: SearchPhysicianModel) {
		this.saveSearchFilter.next(data);
	}

	closeSaveSearchPanel(visible) {
		this.saveSearchPanelVisible.next(visible);
	}

	passPhysicianFromPhysicianGroup(value: any) {
		this.physicianSearchFromPhysicianGroup.next(value);
	}

	passSpecialtyFromGlobalSearch(value: any) {
		this.physicianSearchFromGlobalSearch.next(value);
	}

	passHospitalFromExecutive(value: any) {
		this.hospitalSearchForExecutive.next(value);
	}
}
