import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FilterStorageService } from 'src/app/modules/B2B/services/filter-storage.service';
import { SearchLTCModel } from '../../../models/SearchLTCModel';
import { LTCDataService } from '../../../services/ltc-data.service';
import { LTCService } from '../../../services/ltc.service';
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';

@Component({
	selector: 'app-filter-ltc',
	templateUrl: './filter-ltc.component.html',
	styleUrls: ['./filter-ltc.component.css']
})
export class FilterLtcComponent implements OnInit, OnDestroy {
	@Output() onFilterChange = new EventEmitter<any>();
	@Input() isSubscribed: boolean = false;
	filterData: SearchLTCModel = new SearchLTCModel();
	tempDialogFilters: SearchLTCModel = new SearchLTCModel();
	dialogRef: any;
	dialogState: boolean = false;
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;
	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showConfirmDialog: boolean = false;
	companyList: Array<any> = [];
	titleList: Array<any> = [];
	ltcTypeList: Array<any> = [];
	seniorityList: Array<any> = [];
	departmentList: Array<any> = [
		'Business Development',
		'Engineering',
		'Finance',
		'Human Resources',
		'IT',
		'Laboratory',
		'Marketing/Sales',
		'Medical',
		'Nursing',
		'Operations',
		'Others',
		'Purchasing',
		'R&D',
		'Sub Level Management',
		'Top Level Management'
	];
	countryList: Array<any> = [];
	selectedCountry: Array<any> = [];
	stateList: Array<any> = [];
	selectedStates: Array<any> = [];
	cityList: Array<any> = [];
	selectedCities: Array<any> = [];
	revenueList: Array<any> = [];
	includedRevenueRange: any = [];
	subscription: Subscription;

	constructor(
		private b2bService: LTCService,
		private filterStorageService: FilterStorageService,
		private dataService: LTCDataService,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService
	) {}

	ngOnInit() {
		this.getPersistData();
		this.getSeniorityList();
		// this.getRevenueList();
		this.getLtcTypeList();
		this.subscription = this.dataService.ltcSearchData.subscribe((res) => {
			if (res.fromSearch) {
				this.filterData = res.data;
				this.omitChanges();
			}
		});
	}

	getLtcTypeList() {
		const body = {
			searchPhrase: ''
			// previouslySearchedTerm: [
			//   ...this.filterData.industryInclude,
			//   ...this.filterData.industryExclude,
			// ],
		};
		this.b2bService.getLTCTypeAutoSuggest(body).subscribe((res) => {
			this.ltcTypeList = res.ltcTypeInfoList?.map((el) => el.LTCType);
		});
	}
	ngOnDestroy(): void {
		if (this.subscription) this.subscription.unsubscribe();
		this.dataService.passSearchLTCInput(this.filterData, false);
	}

	getSeniorityList() {
		this.b2bService.getSeniorityList('').subscribe((res) => {
			this.seniorityList = res.seniorityList;
		});
	}

	// getRevenueList() {
	// 	this.b2bService.getRevenueList().subscribe((res) => {
	// 		this.revenueList = res.revenueList;
	// 	});
	// }

	// Company functions

	companyValueChanges(item) {
		if (item && item.length > 1) {
			this.b2bService.getCompanyList(item).subscribe((res) => {
				this.companyList = res.companyListLtc;
			});
		}
	}
	// TITLE FUNCTIONS......................

	titleValueChanges(item) {
		if (item && item.length > 1) {
			const body = {
				searchPhrase: item,
				previousSearchedTitle: [...this.filterData.titleInclude, ...this.filterData.titleExclude]
			};
			this.b2bService.getTitlesList(body).subscribe((res) => {
				this.titleList = res.titleAll;
			});
		} else {
			this.titleList = [];
		}
	}
	// INDUSTRY FUNCTIONS......................

	ltcTypeValueChanges(item) {
		if (item && item.length > 1) {
			const body = {
				searchPhrase: item
				// previouslySearchedTerm: [
				//   ...this.filterData.industryInclude,
				//   ...this.filterData.industryExclude,
				// ],
			};
			this.b2bService.getLTCTypeAutoSuggest(body).subscribe((res) => {
				this.ltcTypeList = res.ltcTypeInfoList;
			});
		} else {
			this.ltcTypeList = [];
		}
	}
	// SKILL FUNCTIONS......................

	hanldeLocationValueChange(locationBody: any) {
		this.omitChanges();
	}

	getPersistData() {
		const newFilterData = this.filterStorageService.get('ltc_filter_data') || {};
		Object.keys(newFilterData).map((el) => {
			this.filterData[el] = newFilterData[el];
		});
		this.omitChanges();
		this.getRecordCount();
	}

	storeFilterData() {
		this.filterStorageService.set('ltc_filter_data', this.filterData);
	}

	omitChanges() {
		this.onFilterChange.emit(this.filterData.toJson());
		this.storeFilterData();
	}

	clearFilter() {
		this.filterData = new SearchLTCModel();
		this.omitChanges();
	}
	clearTempFilter() {
		this.tempDialogFilters = new SearchLTCModel();
		this.getRecordCount();
	}
	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		Object.keys(this.filterData).map((el) => {
			this.tempDialogFilters[el] = this.filterData[el];
		});
		this.getRecordCount();
		this.dialogState = true;
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}
	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, cloneDeep(this.filterData));
	}
	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearTempFilter();
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}
	getRecordCount() {
		this.recordCountLoader.next(true);
		const body: any = this.tempDialogFilters.toJson();
		this.b2bService.searchLTC(body).subscribe(
			(res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				this.recordCountLoader.next(false);
			},
			(err) => {
				this.recordCountLoader.next(false);
			}
		);
	}
	applyMoreFilters() {
		Object.keys(this.tempDialogFilters).map((el) => {
			this.filterData[el] = this.tempDialogFilters[el];
		});
		this.omitChanges();
		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}
}
