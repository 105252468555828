<div class="dialog-flex-item">
	<div class="expansion-body">
		<mat-expansion-panel [disabled]="disabled" #panelLoc hideToggle>
			<mat-expansion-panel-header
				[ngClass]="panelLoc.expanded ? 'expanded' : ''"
				[collapsedHeight]="'70px'"
				[expandedHeight]="'70px'"
			>
				<mat-panel-title>
					<div class="d-flex align-items-center">
						Location

						<div
							class="ml-2 clear-more-filter"
							*ngIf="selectedStatesFromFilter.length > 0 || selectedCitiesFromFilter.length > 0"
						>
							<button (click)="clearState(); $event.stopPropagation()">
								<mat-icon class="clear-more-filter-icon">close</mat-icon>
								<span class="clear-more-filter-text">
									{{ selectedStatesFromFilter.length + selectedCitiesFromFilter.length }}
								</span>
							</button>
						</div>
					</div>
				</mat-panel-title>
				<mat-panel-description>
					<mat-icon class="matrl-plus">{{ panelLoc.expanded ? 'remove' : 'add' }}</mat-icon>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<div class="dialog-expansion-content">
				<span class="form-label">State</span>
				<app-auto-complete-drop-drow
					[(list)]="stateList"
					[(selectedData)]="selectedStatesFromFilter"
					(onItemSelect)="omitChanges()"
					(onChange)="getStateList($event)"
					[itemName]="'stateFullName'"
					[returnObject]="true"
				></app-auto-complete-drop-drow>
				<span class="form-label">City</span>
				<app-auto-complete-drop-drow
					[(list)]="cityList"
					[(selectedData)]="selectedCitiesFromFilter"
					(onItemSelect)="omitChanges()"
					(onChange)="getCityList($event)"
					[itemName]="'city'"
					[returnObject]="true"
				></app-auto-complete-drop-drow>
			</div>
		</mat-expansion-panel>
		<div
			class="second-header d-flex"
			*ngIf="(selectedCitiesFromFilter.length > 0 || selectedStatesFromFilter.length > 0) && !panelLoc.expanded"
		>
			<mat-chip-list #includeSpecialityList>
				<ng-container *ngIf="selectedStatesFromFilter.length > 0">
					<mat-basic-chip
						*ngFor="let state of selectedStatesFromFilter"
						[removable]="removable"
						(removed)="removeState(state)"
					>
						{{ state.stateFullName }}
						<mat-icon matChipRemove>close</mat-icon>
					</mat-basic-chip>
				</ng-container>

				<ng-container *ngIf="selectedCitiesFromFilter.length > 0">
					<mat-basic-chip
						*ngFor="let city of selectedCitiesFromFilter"
						[removable]="removable"
						(removed)="removeCity(city)"
					>
						{{ city.city }}
						<mat-icon matChipRemove>close</mat-icon>
					</mat-basic-chip>
				</ng-container>
			</mat-chip-list>
		</div>
	</div>
</div>
