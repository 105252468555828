import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-divider',
  templateUrl: './card-divider.component.html',
  styleUrls: ['./card-divider.component.css']
})
export class CardDividerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
