<app-right-panel
	[show]="visible"
	[enableCloseIcon]="true"
	[title]="'Your Saved Searches'"
	(closePanel)="closePanel()"
>
	<ng-container *ngIf="savedSearchList.length <= 0">
		<app-saved-no-data></app-saved-no-data>
	</ng-container>
	<ng-container *ngIf="savedSearchList.length > 0">
		<div class="card-container" *ngFor="let item of savedSearchList">
			<app-hc-save-search-card [searchData]="item"></app-hc-save-search-card>
		</div>

		<div class="w-full d-flex justify-content-center py-3">
			<button class="show-more-btn" *ngIf="isShowMore" (click)="showMoreClicked()">Show More</button>
		</div>
	</ng-container>
</app-right-panel>
