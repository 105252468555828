<div class="action">
  <div class="action-container" (click)="showAllClick()">
    {{ selectedName }}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
    >
      <path
        d="M1.25 0.875L4.5 4.125L7.75 0.875"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="drop-down" *ngIf="showMore">
    <p
      *ngFor="let option of options"
      (click)="onSelect(option.value)"
      [ngClass]="{
        option_item: true,
        selected_item: option.value === selected
      }"
    >
      {{ option.name }}
    </p>
  </div>
</div>
