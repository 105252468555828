<div class="main-container">
	<div class="member-summary">
		<!-- Consumed credits starts -->
		<div class="consumed-credits">
			<h6>Consumed credits</h6>
			<div class="card">
				<div class="info col-8">
					<!-- Total credit starts -->
					<div class="total-credit row align-items-center">
						<div class="col-1 text-right">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M10 20C11.9767 20 13.909 19.4142 15.5529 18.3166C17.1968 17.2189 18.4785 15.6587 19.2362 13.8331C19.9939 12.0074 20.1935 9.99809 19.8098 8.05902C19.4261 6.11995 18.4764 4.33808 17.0806 2.93849C15.6848 1.53889 13.9055 0.584358 11.9674 0.195458C10.0294 -0.193441 8.0196 0.000740645 6.19187 0.753475C4.36415 1.50621 2.8005 2.78373 1.69844 4.42466C0.596383 6.06559 0.00535548 7.99633 1.31447e-05 9.97298C-0.00211791 11.2881 0.254889 12.5907 0.756349 13.8064C1.25781 15.0222 1.9939 16.1272 2.92255 17.0584C3.85121 17.9895 4.95424 18.7286 6.16862 19.2333C7.383 19.7381 8.68493 19.9986 10 20ZM10 1.08109C11.764 1.08109 13.4884 1.60417 14.9551 2.58419C16.4218 3.56422 17.565 4.95716 18.24 6.58688C18.9151 8.21661 19.0917 10.0099 18.7476 11.74C18.4034 13.4701 17.554 15.0593 16.3067 16.3066C15.0593 17.554 13.4701 18.4034 11.74 18.7476C10.0099 19.0917 8.21662 18.9151 6.5869 18.24C4.95718 17.565 3.56423 16.4218 2.58421 14.9551C1.60418 13.4884 1.0811 11.764 1.0811 10C1.08396 7.63544 2.02454 5.36854 3.69655 3.69654C5.36855 2.02454 7.63545 1.08395 10 1.08109Z"
									fill="black"
								/>
								<path
									d="M7.19996 13.2162C7.0566 13.2162 6.91912 13.2732 6.81774 13.3746C6.71637 13.4759 6.65942 13.6134 6.65942 13.7568C6.65942 13.9001 6.71637 14.0376 6.81774 14.139C6.91912 14.2404 7.0566 14.2973 7.19996 14.2973H9.45943V15.6757C9.45943 15.8191 9.51638 15.9565 9.61775 16.0579C9.71912 16.1593 9.85661 16.2162 9.99997 16.2162C10.1433 16.2162 10.2808 16.1593 10.3822 16.0579C10.4836 15.9565 10.5405 15.8191 10.5405 15.6757V14.3243H11.4C12.4811 14.3243 13.3405 13.2433 13.3405 11.8919C13.3405 10.5406 12.4919 9.45947 11.4 9.45947H10.5405V6.75677H12.8C12.9433 6.75677 13.0808 6.69982 13.1822 6.59845C13.2836 6.49707 13.3405 6.35959 13.3405 6.21623C13.3405 6.07287 13.2836 5.93538 13.1822 5.834C13.0808 5.73263 12.9433 5.67568 12.8 5.67568H10.5405V4.32433C10.5405 4.18097 10.4836 4.04348 10.3822 3.94211C10.2808 3.84074 10.1433 3.78379 9.99997 3.78379C9.85661 3.78379 9.71912 3.84074 9.61775 3.94211C9.51638 4.04348 9.45943 4.18097 9.45943 4.32433V5.67568H8.59997C7.51888 5.67568 6.65942 6.75677 6.65942 8.10812C6.65942 9.45947 7.51348 10.5406 8.59997 10.5406H9.45943V13.2433L7.19996 13.2162ZM8.59997 9.43245C8.14591 9.43245 7.74051 8.80001 7.74051 8.08109C7.74051 7.36217 8.14591 6.72974 8.59997 6.72974H9.45943V9.43245H8.59997ZM10.5405 10.5135H11.4C11.8594 10.5135 12.2594 11.146 12.2594 11.8649C12.2594 12.5838 11.8594 13.2162 11.4 13.2162H10.5405V10.5135Z"
									fill="#0071EB"
								/>
							</svg>
						</div>
						<div class="col text-left">
							<span
								>The user has used
								<span class="credit-value">{{ userCredits.consumedCredit | numberWithCommas }}</span> credits of
								<span class="credit-value"> {{ userCredits.totalCredit | numberWithCommas }} </span>total
								credits</span
							>
							<p>Next refresh on {{ nextBillingDate }}</p>
						</div>
					</div>
					<!-- Total credit ends -->

					<!-- Mobile credit starts -->
					<div class="mobile-credit row align-items-center">
						<div class="col-1 text-right">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M14.1666 1.66667H5.83329C4.91282 1.66667 4.16663 2.41286 4.16663 3.33334V16.6667C4.16663 17.5871 4.91282 18.3333 5.83329 18.3333H14.1666C15.0871 18.3333 15.8333 17.5871 15.8333 16.6667V3.33334C15.8333 2.41286 15.0871 1.66667 14.1666 1.66667Z"
									stroke="black"
									stroke-width="1.2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M10 14H10.0083"
									stroke="#0071EB"
									stroke-width="3"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						<div class="col text-left">
							<span
								>The user has used <span class="credit-value">{{ userCredits.consumedMobileCredit }}</span> credits of
								<span class="credit-value"> {{ userCredits.totalMobileCredit }} </span>mobile credits</span
							>
						</div>
					</div>
					<!-- Mobile credit ends -->

					<!-- Member Limit starts -->
					<div class="mobile-credit row align-items-center" *ngIf="isAdmin">
						<div class="col-1 text-right">
							<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" style="fill: #040404">
								<path
									d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"
								/>
							</svg>
						</div>

						<div class="col text-left">
							<span
								>The user has used <span class="credit-value">{{ userInfo.consumedMemberLimit }}</span> limit of
								<span class="credit-value"> {{ userInfo.teamMemberLimit }} </span>member limit</span
							>
						</div>
					</div>
					<!-- Member Limit ends -->
				</div>

				<!-- Add more credit starts -->
				<div class="action-container col">
					<div class="add-more-credit">
						<button (click)="handleTrigger('add')" *ngIf="!isAdmin">Add more credits</button>
						<button (click)="handleTrigger('buy')" *ngIf="isAdmin">Buy more credits</button>
					</div>
				</div>
				<!-- Add more credit ends -->
			</div>
		</div>
		<!-- Consumed credits ends -->

		<!-- Assigned credits starts -->
		<div class="assigned-credits">
			<h6>Assigned credits</h6>
			<div class="card">
				<div class="info col-8">
					<!-- Total credit starts -->
					<div class="total-credit row align-items-center">
						<div class="col-1 text-right">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M10 20C11.9767 20 13.909 19.4142 15.5529 18.3166C17.1968 17.2189 18.4785 15.6587 19.2362 13.8331C19.9939 12.0074 20.1935 9.99809 19.8098 8.05902C19.4261 6.11995 18.4764 4.33808 17.0806 2.93849C15.6848 1.53889 13.9055 0.584358 11.9674 0.195458C10.0294 -0.193441 8.0196 0.000740645 6.19187 0.753475C4.36415 1.50621 2.8005 2.78373 1.69844 4.42466C0.596383 6.06559 0.00535548 7.99633 1.31447e-05 9.97298C-0.00211791 11.2881 0.254889 12.5907 0.756349 13.8064C1.25781 15.0222 1.9939 16.1272 2.92255 17.0584C3.85121 17.9895 4.95424 18.7286 6.16862 19.2333C7.383 19.7381 8.68493 19.9986 10 20ZM10 1.08109C11.764 1.08109 13.4884 1.60417 14.9551 2.58419C16.4218 3.56422 17.565 4.95716 18.24 6.58688C18.9151 8.21661 19.0917 10.0099 18.7476 11.74C18.4034 13.4701 17.554 15.0593 16.3067 16.3066C15.0593 17.554 13.4701 18.4034 11.74 18.7476C10.0099 19.0917 8.21662 18.9151 6.5869 18.24C4.95718 17.565 3.56423 16.4218 2.58421 14.9551C1.60418 13.4884 1.0811 11.764 1.0811 10C1.08396 7.63544 2.02454 5.36854 3.69655 3.69654C5.36855 2.02454 7.63545 1.08395 10 1.08109Z"
									fill="black"
								/>
								<path
									d="M7.19996 13.2162C7.0566 13.2162 6.91912 13.2732 6.81774 13.3746C6.71637 13.4759 6.65942 13.6134 6.65942 13.7568C6.65942 13.9001 6.71637 14.0376 6.81774 14.139C6.91912 14.2404 7.0566 14.2973 7.19996 14.2973H9.45943V15.6757C9.45943 15.8191 9.51638 15.9565 9.61775 16.0579C9.71912 16.1593 9.85661 16.2162 9.99997 16.2162C10.1433 16.2162 10.2808 16.1593 10.3822 16.0579C10.4836 15.9565 10.5405 15.8191 10.5405 15.6757V14.3243H11.4C12.4811 14.3243 13.3405 13.2433 13.3405 11.8919C13.3405 10.5406 12.4919 9.45947 11.4 9.45947H10.5405V6.75677H12.8C12.9433 6.75677 13.0808 6.69982 13.1822 6.59845C13.2836 6.49707 13.3405 6.35959 13.3405 6.21623C13.3405 6.07287 13.2836 5.93538 13.1822 5.834C13.0808 5.73263 12.9433 5.67568 12.8 5.67568H10.5405V4.32433C10.5405 4.18097 10.4836 4.04348 10.3822 3.94211C10.2808 3.84074 10.1433 3.78379 9.99997 3.78379C9.85661 3.78379 9.71912 3.84074 9.61775 3.94211C9.51638 4.04348 9.45943 4.18097 9.45943 4.32433V5.67568H8.59997C7.51888 5.67568 6.65942 6.75677 6.65942 8.10812C6.65942 9.45947 7.51348 10.5406 8.59997 10.5406H9.45943V13.2433L7.19996 13.2162ZM8.59997 9.43245C8.14591 9.43245 7.74051 8.80001 7.74051 8.08109C7.74051 7.36217 8.14591 6.72974 8.59997 6.72974H9.45943V9.43245H8.59997ZM10.5405 10.5135H11.4C11.8594 10.5135 12.2594 11.146 12.2594 11.8649C12.2594 12.5838 11.8594 13.2162 11.4 13.2162H10.5405V10.5135Z"
									fill="#0071EB"
								/>
							</svg>
						</div>
						<div class="col text-left">
							<span
								>Issued credit
								<span class="credit-value"> {{ userCredits.totalCredit | numberWithCommas }} </span></span
							>
							<p>Next refresh on {{ nextBillingDate }}</p>
						</div>
					</div>
					<!-- Total credit ends -->

					<!-- Mobile credit starts -->
					<div class="mobile-credit row align-items-center">
						<div class="col-1 text-right">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M14.1666 1.66667H5.83329C4.91282 1.66667 4.16663 2.41286 4.16663 3.33334V16.6667C4.16663 17.5871 4.91282 18.3333 5.83329 18.3333H14.1666C15.0871 18.3333 15.8333 17.5871 15.8333 16.6667V3.33334C15.8333 2.41286 15.0871 1.66667 14.1666 1.66667Z"
									stroke="black"
									stroke-width="1.2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M10 14H10.0083"
									stroke="#0071EB"
									stroke-width="3"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						<div class="col text-left">
							<span
								>Issued mobile credit <span class="credit-value"> {{ userCredits.totalMobileCredit }} </span></span
							>
						</div>
					</div>
					<!-- Mobile credit ends -->

					<!-- Member Limit starts -->
					<div class="mobile-credit row align-items-center" *ngIf="isAdmin">
						<div class="col-1 text-right">
							<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" style="fill: #040404">
								<path
									d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"
								/>
							</svg>
						</div>

						<div class="col text-left">
							<span
								>Issued member limit <span class="credit-value"> {{ userInfo.teamMemberLimit }} </span></span
							>
						</div>
					</div>
					<!-- Member Limit ends -->
				</div>

				<!-- Add more credit starts -->
				<div class="action-container col">
					<div class="add-more-credit">
						<button (click)="handleTrigger('edit')" *ngIf="!isAdmin">Edit credits</button>
						<button (click)="handleTrigger('buy')" *ngIf="isAdmin">Upgrade credits</button>
					</div>
				</div>
				<!-- Add more credit ends -->
			</div>
		</div>
		<!-- Assigned credits ends -->
	</div>
</div>
