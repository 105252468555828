import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	NgZone,
	ElementRef,
	ViewChild,
	TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ClinicalModel } from '../../models/clinical-model/clinical.model';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { PayorService } from '../../service/payor.service';
import { MatDialog } from '@angular/material/dialog';
import { DataService as HealthcareDataService } from 'src/app/modules/healthcare/services/data.service';
import { DataService } from '../../service/data.service';

@Component({
	selector: 'app-clinical-card',
	templateUrl: './clinical-card.component.html',
	styleUrls: ['./clinical-card.component.css']
})
export class ClinicalCardComponent implements OnInit {
	@Input() clinicalData: ClinicalModel;
	@Input() dataIndex: any;
	@Output() DataRefreshed: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('contactPanelTrigger') contactPanelTrigger: MatMenuTrigger;
	notCorrectDrawer: boolean = false;
	saveDrawer: boolean = false;
	smallResolution: boolean = false;

	@ViewChild('specialityDialog') specialityDialog = {} as TemplateRef<any>;
	dialogRef: any;
	constructor(
		private clipboard: Clipboard,
		private messageService: MessageService,
		private dialog: MatDialog,
		private dataService: DataService,
		private healthcareDataService: HealthcareDataService,
		private router: Router
	) {}
	get location() {
		return `${this.clinicalData.city}, ${this.clinicalData.state}`;
	}

	get firstSpecialities() {
		return this.clinicalData?.specialtyCounts.slice(0, 3);
	}

	ngOnInit() {
		if (window.innerWidth < 1100) {
			this.smallResolution = true;
		} else {
			this.smallResolution = false;
		}
	}

	cancelBtnClick(value: boolean) {
		this.saveDrawer = value;
		this.notCorrectDrawer = value;
		setTimeout(() => {
			this.DataRefreshed.emit(true);
		}, 1000);
	}

	gotoContacts(hospitalName: string) {}

	openSocial(link: any) {
		window.open(link, '_blank').focus();
	}

	handleAction(action: any, value?: any) {
		if (action === 'close') {
			this.contactPanelTrigger.closeMenu();
		} else {
			this.clipboard.copy(value);
			this.messageService.display(true, 'Copied!');
		}
	}
	get getWebAddress() {
		if (this.clinicalData.webaddress) {
			return this.clinicalData.webaddress.includes('http')
				? this.clinicalData.webaddress
				: 'https://' + this.clinicalData.webaddress;
		}
		return '';
	}
	onImagingNameClicked(iv) {
		if (iv.clinicId !== null && iv.clinicId !== undefined) {
			//     this.loaderService.display(true);
			//     const body = {
			//       clinicId: iv.clinicId,
			//     };
			//     this.payorService.viewImagingCenterFromList(body).subscribe(
			//       (res) => {
			//         this.loaderService.display(false);
			//         this.router.navigate([]).then((result) => {
			window.open(`clinicalOverview/${iv.clinicId}`, '_blank');
			//         });
			//       },
			//       (err) => {
			//         this.loaderService.display(false);
			//       }
			//     );
		}
	}
	openSpecialityDialog() {
		this.dialogRef = this.dialog.open(this.specialityDialog, {
			width: '900px',
			height: '600px'
		});
	}

	searchHospitalInHcps() {
		this.healthcareDataService.passHospitalForPhysician(this.clinicalData.name);
		this.router.navigate(['/physician']);
	}

	searchHospitalInExecutives() {
		this.dataService.passExecutiveFromClinic(this.clinicalData.name);
		this.dataService.changeSelectedTab(1);
	}
}
