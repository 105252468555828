<div class="physican_container card-animate">
	<div class="container">
		<div class="row">
			<div class="col-sm-12" [ngClass]="smallResolution ? 'col-md-8' : 'col-md-9'">
				<div class="physician_card">
					<div class="physician_content ml-3">
						<h5 class="physician_name" (click)="onImagingNameClicked(imagingInfo)">
							{{ imagingInfo?.centerName }}
						</h5>
						<div class="physician_info">
							<span class="physican_location"
								><i class="fa fa-map-marker pr-1" aria-hidden="true"></i>{{ location }}</span
							>
						</div>
						<div class="speciality-container">
							<ng-container *ngFor="let specialty of firstSpecialities">
								<span class="speciality-chip" (click)="searchSpecialty(specialty.specialty)"
									>{{ specialty.specialty }} ({{ specialty.count }})</span
								>
							</ng-container>
							<span
								*ngIf="imagingInfo.specialtyCounts.length > 3"
								class="speciality-chip blue-chip"
								(click)="openSpecialityDialog()"
								>+{{ imagingInfo.specialtyCounts.length - 3 }} Specialities</span
							>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12" [ngClass]="smallResolution ? 'col-md-4' : 'col-md-3'">
				<div class="physican_additionInfo">
					<ng-container *ngIf="imagingInfo.web_Address != null && imagingInfo.web_Address != ''">
						<span>
							<i-feather name="Globe" class="imgCardIcon mr-2"></i-feather>
							<a [href]="imagingInfo.web_Address" class="physican_email" target="_blank"> View Website </a>
						</span>
					</ng-container>

					<ng-container *ngIf="imagingInfo.noOfExecutive != null && imagingInfo.noOfExecutive">
						<span (click)="doSearchImaging('company', imagingInfo.centerName)">
							<i class="ml-1 fa fa-user" aria-hidden="true"></i>
							<a class="physican_email">
								{{ imagingInfo.noOfExecutive | numberWithCommas }} Executive{{
									imagingInfo.noOfExecutive <= 1 ? '' : 's'
								}}
							</a>
						</span>
					</ng-container>
					<ng-container *ngIf="imagingInfo.noOfPhysicians != null && imagingInfo.noOfPhysicians !== 0">
						<span (click)="searchPhysician(imagingInfo.centerName)">
							<i class="ml-1 fa fa-user-md" aria-hidden="true"></i>
							<a class="physican_email">
								{{ imagingInfo.noOfPhysicians | numberWithCommas }} Physician{{
									imagingInfo.noOfPhysicians <= 1 ? '' : 's'
								}}
							</a>
						</span>
					</ng-container>
					<ng-container *ngIf="imagingInfo.phoneNumber.length > 0 && imagingInfo.phoneNumber != null">
						<span class="physican_phone">
							<i class="ml-1 fa fa-phone" aria-hidden="true"></i>
							{{ imagingInfo.phoneNumber }}</span
						>
					</ng-container>

					<div class="flex1" style="flex: 1; display: flex"></div>
					<div class="saveBtn_wrp imgCenterCard">
						<app-physician-group-not-correct
							[notCorrectType]="'imaging-center'"
							[notCorrectReasons]="notCorrectReasons"
							[imagingId]="imagingInfo.phyGroupCompanyId"
						></app-physician-group-not-correct>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #specialityDialog>
	<div class="dialog-header mb-1">
		<span>Specialties</span>
		<mat-icon class="closeIcon" (click)="dialogRef.close()">close</mat-icon>
	</div>

	<div class="scroll-dialog-content">
		<div class="speciality-container">
			<ng-container *ngFor="let specialty of imagingInfo.specialtyCounts">
				<span class="speciality-chip" (click)="searchSpecialty(specialty.specialty, true)"
					>{{ specialty.specialty }} ({{ specialty.count }})</span
				>
			</ng-container>
		</div>
	</div>
</ng-template>

<!-- <div class="hospital_container card-animate mt-2">
	<div class="row">
		<div class="image-container col-1">
			<img src="assets/img/hospital-hc.png" *ngIf="!imagingInfo.iconLink" width="60%" height="60%" />
			<img [src]="imagingInfo.iconLink" *ngIf="imagingInfo.iconLink" width="60%" height="60%" />
		</div>

		<div class="name-container col-2">
			<a [matTooltip]="imagingInfo.centerName" [matTooltipPosition]="'above'" target="_blank">{{
				imagingInfo.centerName.length > 24
					? imagingInfo.centerName.substring(0, 24) + '...'
					: imagingInfo.centerName
			}}</a>
		</div>

		<div class="action-container col-2">
			<button
				*ngIf="imagingInfo.web_Address || imagingInfo.phoneNumber"
				[mat-menu-trigger-for]="contactPanel"
				#contactPanelTrigger="matMenuTrigger"
			>
				Contact information
			</button>
		</div>

		<div class="physician-count-container col-1">
			<span
				><i class="fa fa-user-circle-o mr-2" aria-hidden="true"></i>
				<a
					href="javascript:void(0)"
					[matTooltip]="imagingInfo.noOfExecutive + ' Physicians'"
					[matTooltipPosition]="'above'"
					(click)="doSearchImaging('company', imagingInfo.centerName)"
					*ngIf="imagingInfo.noOfExecutive > 0"
					>{{ imagingInfo.noOfExecutive | numberWithCommas }}
				</a>
				<span *ngIf="imagingInfo.noOfExecutive == 0"> 0 Executives</span>
			</span>
		</div>

		<div class="hospital-type-container col-2">
			<i class="fa fa-heartbeat mr-2" aria-hidden="true"></i>

			<span [matTooltip]="imagingInfo.hospitalclassification" [matTooltipPosition]="'above'">{{
				imagingInfo.hospitalclassification.length > 18
					? imagingInfo.hospitalclassification.substring(0, 18) + '...'
					: imagingInfo.hospitalclassification
			}}</span>
		</div>

		<div class="location-container col-2">
			<i class="fa fa-map-marker mr-2" aria-hidden="true"></i>

			<span [matTooltip]="location" [matTooltipPosition]="'above'">{{
				location.length > 18 ? location.substring(0, 18) + '...' : location
			}}</span>
		</div>

		<mat-menu #contactPanel="matMenu" xPosition="before">
			<div class="menu-div row" (click)="$event.stopPropagation()">
				<div class="col-12 d-flex justify-content-end p-0 m-0">
					<i
						class="fa fa-times close-icon"
						aria-hidden="true"
						matTooltipClass="tool-tip"
						(click)="handleAction('close')"
					></i>
				</div>
				<div class="col-12 d-flex flex-column align-items-start" *ngIf="phoneNumber">
					<span>Phone</span>
					<span class="menu-value mt-2" *ngFor="let phone of imagingInfo.phoneNumber">
						{{ phone }}
						<i
							[matTooltip]="'Copy phone number'"
							[matTooltipPosition]="'above'"
							class="fa fa-clone copy-icon ml-2"
							aria-hidden="true"
							matTooltipClass="tool-tip"
							(click)="handleAction('copy', phone)"
						></i>
					</span>
				</div>

				<div class="col-12 d-flex flex-column align-items-start" *ngIf="imagingInfo.webAddress">
					<span>Website</span>
					<span class="menu-value mt-2">
						<a href="{{ 'http://' + imagingInfo.webAddress }}" target="_blank" [title]="imagingInfo.webAddress">{{
							imagingInfo.webAddress
						}}</a>
					</span>
				</div>

				<div class="col-12 d-flex flex-column align-items-start">
					<span>Social</span>
					<div class="social_icon_wrap mt-2">
						<ul>
							<li *ngIf="hospitalData.linkedInLink">
								<img
									src="assets/img/socialicons/linkedin.png"
									width="15px"
									height="15px"
									(click)="openSocial(hospitalData.linkedInLink)"
									class="clickable"
								/>
							</li>
							<li *ngIf="hospitalData.twitterLink">
								<img
									src="assets/img/socialicons/twitter.png"
									width="15px"
									height="15px"
									(click)="openSocial(hospitalData.twitterLink)"
									class="clickable"
								/>
							</li>
							<li *ngIf="hospitalData.facebookLink">
								<img
									src="assets/img/socialicons/facebook.png"
									width="15px"
									height="15px"
									(click)="openSocial(hospitalData.facebookLink)"
									class="clickable"
								/>
							</li>
							<li *ngIf="hospitalData.instagramLink">
								<img
									src="assets/img/socialicons/instagram.png"
									width="15px"
									height="15px"
									(click)="openSocial(hospitalData.instagramLink)"
									class="clickable"
								/>
							</li>
							<li *ngIf="hospitalData.googleLink">
								<img
									src="assets/img/socialicons/search.png"
									width="15px"
									height="15px"
									(click)="openSocial(hospitalData.googleLink)"
									class="clickable"
								/>
							</li>
							<li *ngIf="hospitalData.pinterestLink">
								<img
									src="assets/img/socialicons/pinterest.png"
									width="15px"
									height="15px"
									(click)="openSocial(hospitalData.pinterestLink)"
									class="clickable"
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</mat-menu>
		<app-physician-group-not-correct
			[notCorrectType]="'imaging-center'"
			[notCorrectReasons]="notCorrectReasons"
			[imagingId]="imagingInfo.phyGroupCompanyId"
			*ngIf="notCorrectDrawer"
		></app-physician-group-not-correct>

		<app-hospital-notcorrect
			[notCorrectDiv]="notCorrectDrawer"
			(cancelBtnClick)="cancelBtnClick($event)"
			[hospitalId]="hospitalData.hospitalId"
			[hospitalName]="hospitalData.hospitalName"
			*ngIf="notCorrectDrawer"
		></app-hospital-notcorrect>
	</div>
</div> -->
