import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-right-panel',
	templateUrl: './right-panel.component.html',
	styleUrls: ['./right-panel.component.css']
})
export class RightPanelComponent implements OnInit {
	@Input() show: boolean = false;
	@Input() title: string = '';
	@Output() closePanel: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() enableCloseIcon?: boolean = false;
	constructor() {}

	ngOnInit(): void {}
}
