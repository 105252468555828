<div id="wrapper">
  <app-header style="width: 100%" [elementName]="'clinical'"></app-header>
  <!-- <app-sidemenu elementName="payor"></app-sidemenu> -->
  <div style="margin-top: 90px">
    <app-hc-top-tab
      [activeLink]="currentTab"
      [tabItems]="tabItems"
      [isPrimary]="true"
      (tabChanged)="handleTabChange($event)"
      [fontSize]="'14px'">
    </app-hc-top-tab>
  </div>
  <div *ngIf="currentTab == 0">
    <app-clinical-info></app-clinical-info>
  </div>
  <div *ngIf="currentTab == 1">
    <app-clinical-executive></app-clinical-executive>
  </div>
</div>
