<div id="wrapper">
  <app-header [user]="user" style="width: 100%"></app-header>{{ user }}
  <!-- <app-header *ngIf="router.url != '/payment'" [header-data]="headerData"></app-header> -->

  <app-sidemenu elementName="pricing"></app-sidemenu>
  <!-- ============================================================== -->
  <div class="content-page">
    <!-- Start content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="p-0 mt-3">
              <div class="member-card">
                <div class="col-sm-12">
                  <h5 class="m-t-0 m-b-20">Select a Plan</h5>
                  <button
                    class="btn-bordred btn-rounded no_plan_selected"
                    (click)="openItem('pricing')"
                    style="
                      margin-top: -46px;
                      color: #2076b4;
                      cursor: pointer;
                      float: right; /* padding-top: 8px; */
                    "
                    btn=""
                  >
                    Back
                  </button>
                </div>
                <div class="spinner" *ngIf="loading"></div>
                <!-- <nav class="breadcrumb-one" aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a style="color: #2076b4; cursor: pointer" (click)="openItem('pricing')"  >back</a></li>
                                    </ol>
                                </nav> -->
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
        <div class="">
          <div class="tab-content">
            <div class="tab-pane active" id="home-b1">
              <div class="row">
                <div class="col-md-8">
                  <!-- Personal-Information -->
                  <div class="row mt-2">
                    <!--Pricing Column-->
                    <article class="pricing-column col-md-4">
                      <div class="sele-paln" style="border: 1px solid #eee">
                        <div class="plan-header text-center">
                          <h3 class="plan-title">Free</h3>
                          <h2 class="plan-price">$0</h2>
                          <div class="plan-duration" style="visibility: hidden">
                            Per Month
                          </div>
                        </div>
                        <div class="text-center pb-4">
                          <span></span>
                          <button
                            disabled
                            class="btn-bordred btn-rounded disabled_class"
                          >
                            Basic
                          </button>
                        </div>
                        <ul class="plan-stats list-unstyled text-center">
                          <li><strong>10</strong> free credits</li>
                          <li>No credit Card required</li>
                        </ul>
                      </div>
                    </article>
                    <!--Pricing Column-->
                    <article class="pricing-column col-md-4">
                      <!-- <div class="ribbon"><span>POPULAR</span></div> -->
                      <div class="inner-box">
                        <div class="plan-header text-center">
                          <h3 class="plan-title">Yearly</h3>
                          <h2 class="plan-price">$480</h2>
                          <div class="plan-duration">Per Year</div>
                        </div>
                        <div class="text-center pb-4">
                          <button
                            *ngIf="!existedYearlyPlan"
                            #year
                            [ngClass]="
                              yearsel ? 'month_selected' : 'no_plan_selected'
                            "
                            class="btn-bordred btn-rounded"
                            t
                            type="button home-button"
                            (click)="
                              planId = 'P-8GB39286FL8521414L4EHNHQ';
                              changetoYear()
                            "
                            [disabled]="yearsel"
                            id="button1"
                          >
                            <span></span>{{ yearsel ? "Selected" : " Select" }}
                          </button>
                          <button
                            *ngIf="existedYearlyPlan"
                            #year
                            [ngClass]="
                              existedYearlyPlan
                                ? 'disabled_class'
                                : 'no_disabled_class'
                            "
                            class="btn-bordred btn-rounded"
                            t
                            type="button home-button"
                            (click)="
                              planId = 'P-8GB39286FL8521414L4EHNHQ';
                              changetoYear()
                            "
                            disabled
                            id="button1"
                          >
                            <span></span>Current Plan
                          </button>

                          <!-- <a href=2"#" class="btn btn-default btn-bordred btn-rounded waves-effect waves-light ">Select </a> -->
                        </div>
                        <ul class="plan-stats list-unstyled text-center">
                          <li><strong>100</strong> credits per month</li>
                          <li>All Features Access</li>
                        </ul>
                      </div>
                    </article>
                    <!--Pricing Column-->
                    <article class="pricing-column col-md-4">
                      <div class="inner-box">
                        <div class="plan-header text-center">
                          <h3 class="plan-title">Monthly</h3>
                          <h2 class="plan-price">$50</h2>
                          <div class="plan-duration">Per Month</div>
                        </div>

                        <div class="text-center pb-4">
                          <button
                            *ngIf="!existedMonthlyPlan"
                            #month
                            [ngClass]="
                              monthsel ? 'month_selected' : 'no_plan_selected'
                            "
                            class="btn-bordred btn-rounded"
                            type="button home-button"
                            (click)="
                              planId = 'P-79H33532CT024840UL4EHMSY';
                              changetoMonth()
                            "
                            id="button1"
                            [disabled]="monthsel"
                          >
                            <span>{{ monthsel ? "Selected" : " Select" }}</span>
                          </button>
                          <button
                            *ngIf="existedMonthlyPlan"
                            #month
                            [ngClass]="
                              existedYearlyPlan
                                ? 'disabled_class'
                                : 'no_disabled_class'
                            "
                            class="btn-bordred btn-rounded"
                            type="button home-button"
                            (click)="
                              planId = 'P-79H33532CT024840UL4EHMSY';
                              changetoMonth()
                            "
                            id="button1"
                            disabled
                          >
                            <span>{{ monthsel ? "Selected" : " Select" }}</span>
                          </button>
                        </div>
                        <ul class="plan-stats list-unstyled text-center">
                          <li><strong>100</strong> credits per month</li>
                          <li>All Features Access</li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  <!-- Personal-Information -->
                </div>
                <div class="col-md-4">
                  <!-- Personal-Information -->
                  <div class="panel panel-default panel-fill mt-2">
                    <div class="panel-heading">
                      <h3 class="panel-title">Billing Information</h3>
                    </div>
                    <div class="panel-body">
                      <div *ngIf="yearsel" class="m-b-20">
                        <b style="color: #000"
                          ><span class="pr-5">Yearly Plan:</span>
                          <span class="pl-5">$480</span>
                        </b>
                        <p class="mt-3"><span>1200 Credits/Year</span></p>

                        <hr />
                        <!-- <p class="pt-2">*When you exceed your limit, you incur additional charges of $14.99/mo per additional 50 Credits &nbsp;</p> -->
                      </div>
                      <div *ngIf="monthsel" class="m-b-20">
                        <b style="color: #000"
                          ><span class="pr-5">Monthly Plan:</span>
                          <span class="pl-5">$50</span>
                        </b>
                        <p class="mt-3"><span>100 Credits/Month</span></p>

                        <hr />
                        <!-- <p class="pt-2">*When you exceed your limit, you incur additional charges of $14.99/mo per additional 50 Credits &nbsp;</p> -->
                      </div>
                      <div class="col">
                        <div #paypal></div>
                      </div>
                      <!--
                                                    <div class="about-info-p m-b-0">
                                                        <a href="upgrade-plan.html" type="button" class="btn btn-primary m-t-10">Upgrade</a>
                                                    </div> -->
                    </div>
                  </div>

                  <!-- Personal-Information -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- container -->
    </div>
    <!-- content -->
  </div>
</div>
