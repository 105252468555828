import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import moment from 'moment';
import { Router } from '@angular/router';
import { PaginationService } from '../../services/pagination.service';
import 'rxjs/Rx';
import { ButtoncellrendererComponent } from '../../../basic/component/ag-grid/buttoncellrenderer/buttoncellrenderer.component';
import { MessageService } from '../../../B2B/services/message.service';
import { ParagonIntegrationService } from '../../services/paragon-integration.service';
import { SalesForceExportBtnComponent } from '../../../basic/component/ag-grid/sales-force-export-btn/sales-force-export-btn.component';
import { CellClickedEvent } from 'ag-grid-community';
import { MatMenuTrigger } from '@angular/material/menu';
import { RequestMobileComponent } from '../../../basic/component/ag-grid/request-mobile/request-mobile.component';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { RefreshDataDownloadComponent } from 'src/app/modules/basic/component/refresh-data-download/refresh-data-download.component';
import { CellRendererComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
@Component({
	selector: 'app-lists',
	templateUrl: './lists.component.html',
	styleUrls: ['./lists.component.css']
})
export class ListsComponent implements OnInit {
	@ViewChild('trigger') trigger: MatMenuTrigger;
	@ViewChild('closeBtn') closeBtn: ElementRef;
	@ViewChild('hideCancelOption')
	hideCancelOption: ElementRef;
	columnDefs: any;
	searchString: string;
	createDrawer: boolean = false;
	paginationPageSize: number;
	sortingOrders: any;
	gridApi: any;
	gridColumnApi: any;
	paramsData: any = {};
	datasource: any;
	offset: any = 0;
	count: any = 10;
	defaultColDef: any;
	allItems: any[];
	pager: any = {};
	pagedItems: any[];
	totalSize: number;
	clickedListId: any;
	apiUrl: any;
	leadName: string;
	headerData = '';
	public user = null;
	subscriptions = [];
	frameworkComponents: any;
	subscribed: boolean;
	isDownload: boolean = false;

	// Paragon Vars
	integrationBtn: string = '';
	isParaAuthenitcated: boolean = false;
	isParaIntegrationActive: boolean = false;
	crmExpanded: boolean = false;

	isIntegration: boolean = false;

	// Export SF Vars
	isCRMMenu: boolean = false;
	selectedListDetails: any = [];

	showConfirmDialog: boolean = false;
	dialogRef: any;
	@ViewChild('requestMobileDialog') requestMobileDialog = {} as TemplateRef<any>;
	requestMobileCount: number = 0;

	// return '<div class="listLink"><a href="javascript:void(0)">'+ params.value + '</a><br/><div class="leads">'+params.data.noOfLeads+' Leads</div></div>';
	constructor(
		public amplizService: AmplizService,
		private loaderService: LoaderService,
		private router: Router,
		private pagerservice: PaginationService,
		private messageService: MessageService,
		private paragonService: ParagonIntegrationService,
		private dialog: MatDialog
	) {
		// paragon funcs
		this.paragonService.authenticate();
		this.paragonService.btnStatus.subscribe((val) => {
			this.integrationBtn = val;
		});

		this.paragonService.isIntegrationActive.subscribe((val) => {
			this.isParaIntegrationActive = val;
		});
		this.paragonService.isAuthenticated.subscribe((val) => {
			this.isParaAuthenitcated = val;
		});

		this.frameworkComponents = {
			buttonRenderer: ButtoncellrendererComponent,
			salesforceExport: SalesForceExportBtnComponent,
			requestMobileContact: RequestMobileComponent,
			refreshDataDownload: RefreshDataDownloadComponent
		};
		this.columnDefs = [
			{
				headerName: 'Name',
				checkboxSelection: true,
				field: 'listName',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				autoHeight: true,
				sortable: true,
				width: 300,
				cellClass: 'cellClass',
				lockPosition: true,
				suppressSizeToFit: true,
				onCellClicked: (event: CellClickedEvent) => {
					this.onCellClicked(event, 'listDetails');
				},
				cellRenderer: function (params) {
					if (params.data.noOfLeads > 0) {
						if (params.data.show_refresh_link) {
							return `<div class="listLink d-flex flex-column"><a href="javascript:void(0)">${params.value}</a>
									<div class="leads" style="
									font-size: 14px;
									color: #4bb543;
									">${params.data.refresh_msg}</div>
									</div>`;
						} else {
							return `<div class="listLink py-1"><a href="javascript:void(0)">${params.value}</a></div>`;
						}
						// '<div class="listLink"><a href="javascript:void(0)">' +
						// params.value +
						// '</a><br/><div div class="leads" > ' +
						// params.data.noOfLeads +
						// ' Leads</div></div>'
					} else {
						return `<div class="listLink">${params.value}</div>`;
						// <br/><div class="leads">${params.data.noOfLeads} Leads</div>
					}
				}
			},
			{
				headerName: 'Number of Leads',
				field: 'noOfLeads',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				autoHeight: true,
				width: 130,
				lockPosition: true,
				sortable: true,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					return `${params.data.noOfLeads} Leads`;
				}
			},
			{
				headerName: 'Export List',
				field: 'export',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				sortable: false,
				width: 150,
				autoHeight: true,
				lockPosition: true,
				suppressSizeToFit: true,
				cellRenderer: 'buttonRenderer',
				cellRendererParams: {
					clicked: this.exportDownloadAll.bind(this)
				}
			},
			{
				headerName: 'Request Mobile Numbers',
				field: 'Request Mobile Numbers',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				sortable: false,
				width: '300',
				autoHeight: true,
				lockPosition: true,
				suppressSizeToFit: true,
				cellRenderer: 'requestMobileContact',
				cellRendererParams: {
					clicked: this.openRequestMobileContact.bind(this)
				}
			},
			{
				headerName: 'Download Updates',
				field: 'Download Updates',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				sortable: false,
				width: '280',
				autoHeight: true,
				lockPosition: true,
				suppressSizeToFit: true,
				cellRendererSelector: (params) =>
					params.data.show_refresh_link && { component: 'refreshDataDownload' },
				cellRendererParams: {
					clicked: this.downloadRefreshedData.bind(this)
				}
			},
			// {
			// 	headerName: 'Created On',
			// 	field: 'createdOn',
			// 	sortingOrder: ['desc', 'asc'],
			// 	filter: false,
			// 	width: 150,
			// 	lockPosition: true,
			// 	autoHeight: true,
			// 	sortable: true,
			// 	suppressSizeToFit: true,
			// 	cellRenderer: function (params) {
			// 		return params.value ? moment(params.value).format('DD MMM YYYY') : '---';
			// 	}
			// },
			{
				headerName: 'Updated On',
				field: 'updatedOn',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				width: 100,
				sortable: true,
				autoHeight: true,
				lockPosition: true,
				suppressSizeToFit: true,
				cellRenderer: function (params) {
					return params.value ? moment(params.value).format('DD MMM YYYY') : '---';
				}
			},

			{
				headerName: '',
				field: 'delete',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				sortable: false,
				width: 100,
				autoHeight: true,
				lockPosition: true,
				suppressSizeToFit: true,
				onCellClicked: (event: CellClickedEvent) => {
					if (event.data.noOfLeads <= 0) this.onCellClicked(event, 'delete');
				},
				cellRenderer: function (params) {
					if (params.data.noOfLeads <= 0) {
						return '<a href="javascript:void(0)" class="deleteIcon"><i class="fa fa-trash-o" aria-hidden="true"></i></a>';
					}
				}
			}
		];
		this.sortingOrders = ['desc', 'asc', null];
		this.paginationPageSize = 10;
		this.defaultColDef = { resizable: true };
	}
	// get isMenuOpen() {
	// 	return
	// }
	onFirstDataRendered(params) {
		params.api.sizeColumnsToFit();
	}
	onGridReady(params) {
		this.paramsData = params;
		this.gridApi = params.api;

		this.gridColumnApi = params.columnApi;
		// this.amplizService.getAllList(this.offset, this.count).subscribe(
		//   (res) => {
		//     this.datasource = res.savedlistInfoList;
		this.paramsData.api.setRowData(this.datasource);
		// this.gridApi.sizeColumnsToFit();
		//   },
		//   (error) => {}
		// );
	}

	ngOnInit() {
		//  this.renderDataTable();
		this.getDashboardDetails();
		this.setPage(1);
	}

	openPortal() {
		// this.paragonService.connectParagon();
	}

	get isPaidUser() {
		return localStorage.getItem('SubscriptionisActive') === 'false' ? false : true;
	}

	get showIntegrations() {
		return this.isParaAuthenitcated && this.isPaidUser && this.isIntegration;
	}

	get showCrmRequest() {
		return (!this.isPaidUser || !this.isIntegration) && this.isParaAuthenitcated;
	}

	onPageSizeChanged(ev) {
		var value = ev.target.value;
		this.count = value;
		this.setPage(1);
	}

	renderDataTable() {
		if (this.searchString) {
			this.amplizService.searchList(this.searchString, this.offset, this.count).subscribe(
				(res) => {
					this.datasource = res.savedlistInfoList;
					this.paramsData.api.setRowData(this.datasource);
					// this.gridApi.sizeColumnsToFit();
				},
				(error) => {}
			);
		} else {
			this.amplizService.getAllList(this.offset, this.count, true).subscribe(
				(res) => {
					this.datasource = res.savedlistInfoList;
					this.paramsData.api.setRowData(this.datasource);
					// this.gridApi.sizeColumnsToFit();
				},
				(error) => {}
			);
		}
	}

	cancelBtnClick(value: boolean) {
		this.createDrawer = value;
		this.renderDataTable();
		this.setPage(1);
	}

	onCellClicked(ev, action) {
		if (action == 'listDetails') {
			if (ev.data.noOfLeads > 0) {
				this.router.navigate(['/lists', ev.data.listId]);
			}
		}

		if (action == 'delete') {
			this.clickedListId = ev.data.listId;
			this.showConfirmDialog = true;
		}
	}

	exportDownloadAll(ev) {
		if (this.isDownload) {
			this.clickedListId = ev.data.listId;
			this.downloadAllCsv(this.clickedListId);
			this.clickedListId = ev.data.listId;
		} else {
			this.messageService.displayError(
				true,
				'Contact the team admin to get the permissions',
				'Not authorized to download'
			);
		}
	}

	openRequestMobileContact(event) {
		this.dialogRef = this.dialog.open(this.requestMobileDialog, {
			height: '200px',
			disableClose: true,
			data: event.data
		});
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}

	requestMobileContact(event, count) {
		this.amplizService.requestMobileContactForList(event.listId, count).subscribe(
			(res) => {
				this.dialogRef.close();
				this.requestMobileCount = 0;
				this.messageService.display(true, 'Request sucessfully created');
				this.renderDataTable();
			},
			(err) => {
				this.dialogRef.close();
				this.requestMobileCount = 0;
			}
		);
	}

	downloadAllCsv(listId) {
		this.amplizService.downloadCSVAll(listId).subscribe((el) => {
			const a = document.createElement('a');
			const blob = new Blob([el.body], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);

			a.href = url;
			a.download = listId + '.csv';
			a.click();
			window.URL.revokeObjectURL(url);
			a.remove();
		});
	}

	downloadRefreshedData(params) {
		// this.messageService.display(true, 'Clicked');
		this.amplizService.downloadRefreshData(params.data.listId).subscribe((res) => {
			const a = document.createElement('a');
			const blob = new Blob([res.body], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);

			a.href = url;
			a.download = params.data.listId + '.csv';
			a.click();
			window.URL.revokeObjectURL(url);
			a.remove();
			this.renderDataTable();
		});
	}

	showLoading(show) {
		if (this.gridApi) {
			if (show) {
				this.gridApi.showLoadingOverlay();
			} else {
				this.gridApi.hideOverlay();
			}
		}
	}

	setPage(page: any) {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		this.showLoading(true);
		// this.displayDataInTable()
		// get pager object from service
		this.offset = this.count * (page - 1);
		if (this.searchString) {
			this.amplizService.searchList(this.searchString, this.offset, this.count).subscribe(
				(res) => {
					this.showLoading(false);
					this.datasource = res.savedlistInfoList;
					if (this.datasource.length != 0) {
						this.totalSize = res.totalCount;
						this.pager = this.pagerservice.getPager(this.totalSize, page, this.count);
						this.pagedItems = this.datasource.slice(this.pager.startIndex, this.pager.endIndex + 1);
						//
						this.paramsData.api.setRowData(this.datasource);
					} else {
					}
				},
				(error) => {}
			);
		} else {
			this.amplizService.getAllList(this.offset, this.count, true).subscribe(
				(res) => {
					this.showLoading(false);
					this.datasource = res.savedlistInfoList;
					//
					if (this.datasource.length != 0) {
						this.totalSize = res.totalCount;
						//
						this.pager = this.pagerservice.getPager(this.totalSize, page, this.count);
						this.pagedItems = this.datasource.slice(this.pager.startIndex, this.pager.endIndex + 1);
						//
						if (this.paramsData.api) {
							this.paramsData.api.setRowData(this.datasource);
						}
						// this.loaderService.display(false);
					} else {
						this.loaderService.display(false);
					}
				},
				(error) => {
					this.showLoading(false);
				}
			);
		}
	}

	deleteList() {
		this.loaderService.display(true);
		this.amplizService.deleteList(this.clickedListId).subscribe(
			(res) => {
				this.messageService.display(true, 'Selected list successfully deleted');
				this.loaderService.display(false);
				// this.hideCancelOption.nativeElement.click();
				this.renderDataTable();
				this.setPage(1);
			},
			(error) => {
				this.loaderService.display(false);
				this.messageService.displayError(true, error.error[0].message);
				// this.hideCancelOption.nativeElement.click();
			}
		);
		this.showConfirmDialog = false;
	}

	onFilterTextBoxChanged(ev) {
		this.renderDataTable();
		this.setPage(1);
	}

	async getDashboardDetails() {
		const authToken = await localStorage.getItem('auth_token');
		// const userId = await localStorage.getItem('user_id');
		const refreshToken = await localStorage.getItem('refresh_token');
		//
		if (authToken !== null && refreshToken !== null) {
			this.amplizService.getDashboardDetails().subscribe(
				(res) => {
					this.subscriptions = res.Subscriptions;
					this.isDownload = res.isDownload;
					this.isIntegration = res.isintegration;
					localStorage.setItem('isDownload', res.isDownload);
					if (this.subscriptions[0].SubscriptionType == 'Free') {
						localStorage.setItem('SubscriptionisActive', 'false');
						this.subscribed = false;

						this.headerData = 'Request Pricing';
					}
					if (this.subscriptions[0].SubscriptionType == 'Paid') {
						//
						localStorage.setItem('SubscriptionisActive', 'true');
						this.subscribed = true;
					}
				},
				(error) => {
					if (error.status === 401) {
						this.amplizService.logout();
					}
					//
				}
			);
		} else {
			this.amplizService.logout();
		}
	}

	setCRMMenu(value: boolean) {
		this.isCRMMenu = value;
	}

	onRowSelect() {
		if (this.gridApi.getSelectedRows().length > 0) {
			this.selectedListDetails = this.gridApi.getSelectedRows()[0];
		} else {
			this.selectedListDetails = [];
		}
	}
	clearSelectedList() {
		this.selectedListDetails = [];
		this.gridApi.deselectAll();
	}
	requestCRM() {
		window.open(
			'https://www.ampliz.com/talk-to-us?utm_source=ampliz&utm_medium=healthcare&utm_campaign=integrations',
			'_blank'
		);
	}
}
