<div class="flex-row lowCredCont">
    <div class="flex-row flex1">
        <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.0938 5.91016V4.84375C20.0938 2.21875 15.7695 0.15625 10.25 0.15625C4.73047 0.15625 0.40625 2.21875 0.40625 4.84375V9.53125C0.40625 11.7695 3.55859 13.5977 7.90625 14.0898V15.1562C7.90625 17.7812 12.2305 19.8438 17.75 19.8438C23.2695 19.8438 27.5938 17.7812 27.5938 15.1562V10.4688C27.5938 8.25391 24.5352 6.41406 20.0938 5.91016ZM26.6562 10.4688C26.6562 12.2852 23.082 14.2188 17.75 14.2188C16.6507 14.2202 15.5531 14.1301 14.4688 13.9492V13.7852C17.8203 13.0469 20.0938 11.4414 20.0938 9.53125V6.85937C23.8672 7.30469 26.6562 8.81641 26.6562 10.4688ZM8.41016 13.1992H8.375C7.88281 13.1523 7.41406 13.0938 6.96875 13.0117V9.27344C8.05392 9.44635 9.15114 9.53256 10.25 9.53125C11.3489 9.53256 12.4461 9.44635 13.5312 9.27344V13.0117C12.4474 13.1965 11.3495 13.2867 10.25 13.2812C9.60547 13.2812 8.99609 13.2578 8.41016 13.1992ZM19.1562 6.87109V9.53125C19.1562 10.8086 17.375 12.1445 14.4688 12.8242V9.09766C16.5312 8.64062 18.1953 7.85547 19.1562 6.87109ZM10.25 1.09375C15.582 1.09375 19.1562 3.02734 19.1562 4.84375C19.1562 6.66016 15.582 8.59375 10.25 8.59375C4.91797 8.59375 1.34375 6.66016 1.34375 4.84375C1.34375 3.02734 4.91797 1.09375 10.25 1.09375ZM1.34375 9.53125V6.87109C2.30469 7.85547 3.96875 8.64062 6.03125 9.09766V12.8242C3.125 12.1445 1.34375 10.8086 1.34375 9.53125ZM8.84375 15.1562V14.1719C9.30078 14.207 9.76953 14.2188 10.25 14.2188C10.7305 14.2188 11.1992 14.207 11.6562 14.1719C12.2675 14.3963 12.8941 14.5764 13.5312 14.7109V18.4492C10.625 17.7695 8.84375 16.4336 8.84375 15.1562ZM14.4688 18.6367V14.8984C15.5542 15.069 16.6512 15.1552 17.75 15.1562C18.8489 15.1576 19.9461 15.0714 21.0312 14.8984V18.6367C18.8585 18.9961 16.6415 18.9961 14.4688 18.6367ZM26.6562 15.1562C26.6562 16.4336 24.875 17.7695 21.9688 18.4492V14.7227C24.0312 14.2656 25.6953 13.4805 26.6562 12.4961V15.1562Z"
                fill="#2d8cff" />
        </svg>

        <span class="runningLowText px-3"><strong>Running low </strong>with your credit, Earn free credits</span>
    </div>
    <div class="flex-row">
        <span (click)="handleGetFreeClick()" class="freeCreditText mx-2">
            Get free credit
            <!-- <i class=" fa fa-arrow-right" aria-hidden="true"></i> https://feathericons.com/ -->
            <i-feather name="arrow-right" class="feather" style="width:18px; margin-left: 5px;"></i-feather>
        </span>
        <!-- <i-feather (click)="handleCloseClick()" name="x" class="feather" style="width:18px; cursor: pointer;"></i-feather> -->
        <!-- <i (click)="handleCloseClick()" style="cursor: pointer;" class="fa fa-times pl-5" aria-hidden="true"></i> -->
    </div>
</div>