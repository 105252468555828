
<div class="placeholder-item">
        <div class="loader_container row" *ngFor="let i of numSequence(loopCount)" >
            <div class="col-sm-9">
            <div class="image-box pull-left"></div>
            <div class="">
                <div class="text-placeholder"></div>
                <div class="text-placeholder w-50"></div>
                <div class="text-placeholder"></div>
                <div class="text-placeholder w-50"></div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="">
                <div class="text-placeholder"></div>
                <div class="text-placeholder w-50"></div>
            </div>
            <div class="justify-content-start mt-3">
                <div class="buttonskelton mr-2 pull-left"></div>
                <div class="buttonskelton pull-left"></div>
            </div>
        </div>
    </div>
    
</div>