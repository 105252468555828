import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-auto-complete-drop-drow',
	templateUrl: './auto-complete-drop-drow.component.html',
	styleUrls: ['./auto-complete-drop-drow.component.css']
})
export class AutoCompleteDropDrowComponent implements OnInit {
	@Input() title: string = '';
	@Input() selectedData: Array<any> = [];
	@Input() itemName: any = '';
	@Input() itemValue: any = '';
	@Input() placeholder: any = '';
	@Input() list: Array<any> = [];
	@Output() onItemSelect = new EventEmitter();
	@Output() onChange = new EventEmitter();
	@Input() returnObject: boolean = false;
	@Input() loader: boolean = false;
	selectable = true;
	removable = true;

	@ViewChild('autoInput')
	autoInput: ElementRef<HTMLInputElement>;
	control: UntypedFormControl = new UntypedFormControl();

	@Output() selectedDataChange = new EventEmitter();
	@Output() listChange = new EventEmitter();

	constructor() {}

	ngOnInit(): void {
		this.handleForm();
	}
	handleForm() {
		this.control.valueChanges.subscribe((value: string) => {
			let hv = value !== null ? value : '';
			if (typeof value != 'object' && hv.length >= 2) {
				this.onChange.emit(value);
			}
		});
	}
	removeCity(item: any) {
		this.selectedData = this.selectedData.filter((el) => {
			if (this.itemName) {
				return el[this.itemName] !== item[this.itemName];
			}
			return el !== item;
		});
		this.omitChanges();
	}

	clear() {
		this.selectedData = [];
		this.omitChanges();
	}
	onSelect(event) {
		const receivedCity = event.option.value;
		const found = this.selectedData.findIndex((ele) => {
			if (this.itemName) {
				ele[this.itemName] === receivedCity[this.itemName];
			} else {
				ele === receivedCity;
			}
		});
		if (found === -1 && receivedCity) {
			this.selectedData = [...this.selectedData, receivedCity];
			this.omitChanges();
		}
	}
	omitChanges() {
		this.autoInput.nativeElement.value = '';
		this.control.setValue(null);
		this.listChange.emit([]);
		this.selectedDataChange.emit(this.selectedData);
		this.onItemSelect.emit();
	}
}
