<div class="wrapper">
  <div class="btn-wrapper">
    <span class="processing-text mr-1">Processing</span>
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
  <!-- <p *ngIf="label" class="label-wrapper">
    {{ label }}
    <span class="contact-count-text">contacts to complete</span>
  </p>
  <p *ngIf="!label" class="prcoessing-text">
    We will notify you on your email 
    once your list <br/> is ready
  </p> -->
