<div class="search-container float-left">
	<div *ngIf="showSearch" class="search-Input-container">
		<label class="search-input-label">
			<i class="fa fa-search" aria-hidden="true"></i>
			<input
				class="search-input"
				(ngModelChange)="getSearchData($event)"
				[ngModel]="searchVal"
				placeholder="Search for Physician Name"
			/>
			<i
				*ngIf="searchVal"
				class="fa fa-times clearBtn"
				aria-hidden="true"
				(click)="hideModel($event)"
			></i>
		</label>
	</div>

	<div *ngIf="modelState" class="search-result-conntainer">
		<ul class="search-result-block">
			<li *ngIf="searchState.healthExecuticeList.length" class="block-col">
				<h2 class="search-heading">
					<span class="border5">{{ 'executive' | uppercase }}</span>
				</h2>
				<ul>
					<li *ngFor="let item of searchState.healthExecuticeList">
						<a
							style="display: flex"
							[routerLink]="['/executiveOverview/', item.healthExecutiveId]"
							target="_blank"
						>
							<img
								[src]="
									item.gender === 'M'
										? 'assets/images/Executive-M.png'
										: item.gender === 'F'
										? 'assets/images/Execuitve-F.png'
										: 'assets/img/Executive-hc.png'
								"
								alt="Executive"
								class="imageClass"
							/>

							<span
								class="list-label srch_font"
								innerHTML="{{ item.fullName | texthighlight : searchVal }}"
							></span>
						</a>
					</li>
				</ul>
			</li>

			<li *ngIf="searchState.physicianList.length" class="block-col">
				<h2 class="search-heading">
					<span>{{ 'physician' | uppercase }}</span>
				</h2>
				<ul>
					<li *ngFor="let item of searchState.physicianList">
						<a
							style="display: flex"
							[routerLink]="['/physicianOverview/', item.physicianId]"
							target="_blank"
						>
							<!-- <span class="icon_image">
                                <img [src]="item.gender==='M'? 'https://drive.google.com/thumbnail?id=1xxuLN8APUD-C0nB82nxSEs67oakAFjFN' : 'https://drive.google.com/thumbnail?id=1Vf4mzlWQGLjEyCGkKEkfTZvheqoVlDh-'"
                                alt="Doctor" width="100%"></span> -->

							<img
								[src]="
									item.gender === 'M'
										? 'assets/images/doctor-M.png'
										: item.gender === 'F'
										? 'assets/images/Doctor-F.png'
										: 'assets/img/Physician-hc.png'
								"
								alt="Doctor"
								class="imageClass"
							/>

							<span
								class="list-label srch_font"
								innerHTML="{{ item.fullName | texthighlight : searchVal }}"
							></span>
						</a>
					</li>
				</ul>
			</li>

			<li *ngIf="searchState.hospitalList.length" class="block-col">
				<h2 class="search-heading">
					<span>{{ 'hospital' | uppercase }}</span>
				</h2>
				<ul>
					<li *ngFor="let item of searchState.hospitalList">
						<a
							style="display: flex"
							[routerLink]="['/hospitalOverView/', item.hospitalId]"
							target="_blank"
						>
							<!-- <span *ngIf="item.profileIconLink!=''; else iconplaceholder" class="icon_image list-Icon"> -->
							<img
								[src]="item.profileIconLink !== '' ? item.profileIconLink : 'assets/img/hospital-hc.png'"
								alt="Hospital"
								class="imageClass"
							/>

							<span
								class="list-label srch_font"
								innerHTML="{{ item.hospitalName | texthighlight : searchVal }}"
							></span>
						</a>
					</li>
				</ul>
			</li>

			<li *ngIf="searchState.savedList.length" class="block-col">
				<h2 class="search-heading">
					<span>{{ 'lists' | uppercase }}</span>
				</h2>
				<ul>
					<li
						*ngFor="let item of searchState.savedList"
						(click)="searchClickHandler('lists', item.listName)"
					>
						<span class="icon_image list-Icon"
							><img src="/assets/images/list-Icon.png" alt="list-Icon"
						/></span>
						<span
							class="list-label srch_font"
							innerHTML="{{ item.listName | texthighlight : searchVal }}"
						></span>
					</li>
				</ul>
			</li>

			<li *ngIf="searchState.specialityList.length" class="block-col">
				<h2 class="search-heading">
					<span>{{ 'specialty' | uppercase }}</span>
				</h2>
				<ul>
					<li *ngFor="let item of searchState.specialityList">
						<a style="display: flex" (click)="handleSpecialtyClick(item)" target="_blank">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 576 512"
								class="imageClass mr-2"
								style="height: 30px"
							>
								<path
									fill="#dddddd"
									d="M142.4 21.9c5.6 16.8-3.5 34.9-20.2 40.5L96 71.1 96 192c0 53 43 96 96 96s96-43 96-96l0-120.9-26.1-8.7c-16.8-5.6-25.8-23.7-20.2-40.5s23.7-25.8 40.5-20.2l26.1 8.7C334.4 19.1 352 43.5 352 71.1L352 192c0 77.2-54.6 141.6-127.3 156.7C231 404.6 278.4 448 336 448c61.9 0 112-50.1 112-112l0-70.7c-28.3-12.3-48-40.5-48-73.3c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3l0 70.7c0 97.2-78.8 176-176 176c-92.9 0-168.9-71.9-175.5-163.1C87.2 334.2 32 269.6 32 192L32 71.1c0-27.5 17.6-52 43.8-60.7l26.1-8.7c16.8-5.6 34.9 3.5 40.5 20.2zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
								/>
							</svg>
							<span
								class="list-label srch_font"
								innerHTML="{{ item | texthighlight : searchVal }}"
							></span>
						</a>
					</li>
				</ul>
			</li>
		</ul>
		<!-- && searchState.specialityList.length === 0 -->
		<div
			class="text-center mt-2"
			*ngIf="
				searchState.savedList.length === 0 &&
				searchState.hospitalList.length === 0 &&
				searchState.physicianList.length === 0 &&
				searchState.healthExecuticeList.length === 0
			"
		>
			Sorry, No result found !!!
		</div>
	</div>
</div>

<div *ngIf="modelState" class="search-overlay" (click)="outsideClickHandler()"></div>
