<span class="form-label" *ngIf="title">{{ title }}</span>
<mat-form-field>
	<mat-select multiple [(value)]="selectedData">
		<mat-select-trigger>
			<mat-chip-list>
				<mat-basic-chip
					[removable]="removable"
					*ngFor="let item of selectedData"
					class="mat-basic-chip"
					(removed)="remove(item)"
				>
					{{ valueName ? item[itemName] : item }}
					<mat-icon matChipRemove>close</mat-icon>
				</mat-basic-chip>
			</mat-chip-list>
		</mat-select-trigger>
		<mat-option
			*ngFor="let item of list"
			[value]="valueItem ? item[itemValue] : item"
			(click)="selectItem(item)"
		>
			{{ itemName ? item[itemName] : item }}
		</mat-option>
	</mat-select>
</mat-form-field>
