export class SearchExecutiveModel {
	constructor(
		public hospitalNameList: Array<string> = [],
		public city: Array<any> = [],
		public offset: number = 0,
		public stateList: Array<any> = [],
		public limit: number = 5,
		public titleInclude: string[] = [],
		public keywords: string[] = [],
		public levelID: any[] = [],
		public department: string[] = [],
		public fullName: string = '',
		public cleared: boolean = false,
		public firmType: string = '',
		public hospitalClassification: string[] = [],
		public searchType: string = 'TOTAL',
		public leadWithPhone: boolean = false,
		public leadWithEmail: boolean = false,
		public bedsrange: string[] = []
	) {}

	public toJson(): object {
		return {
			hospitalNameList: this.hospitalNameList,
			offset: this.offset,
			city: this.city.map((el) => el?.city || el),
			stateList: this.stateList.map((el) => el?.state || el),
			limit: this.limit,
			titleInclude: this.titleInclude,
			keywords: this.keywords,
			levelID: this.levelID.map((el) => el.id),
			department: this.department,
			fullName: this.fullName,
			cleared: this.cleared,
			firmType: this.firmType ? [this.firmType] : [],
			hospitalClassification: this.hospitalClassification,
			searchType: this.searchType,
			leadWithPhone: this.leadWithPhone,
			leadWithEmail: this.leadWithEmail,
			bedsrange: this.bedsrange
		};
	}

	public fromJson(obj: any): SearchExecutiveModel {
		return new SearchExecutiveModel(
			obj.hospitalNameList,
			obj.city,
			obj.offset,
			obj.stateList,
			obj.limit,
			obj.titleInclude,
			obj.keywords,
			obj.levelID,
			obj.department,
			obj.fullName,
			obj.cleared,
			obj.firmType,
			obj.hospitalClassification,
			obj.bedsrange
		);
	}
}
