<span class="form-label" *ngIf="title">{{ title }}</span>
<mat-form-field>
	<mat-chip-list #ListChip aria-label="city name input">
		<mat-basic-chip
			*ngFor="let city of selectedData"
			[selectable]="selectable"
			[removable]="true"
			(removed)="removeCity(city)"
		>
			{{ itemName && returnObject ? city[itemName] : city }}
			<mat-icon matChipRemove>close</mat-icon>
		</mat-basic-chip>
		<input
			[placeholder]="placeholder"
			#autoInput
			aria-label="Search city"
			matInput
			[formControl]="control"
			[matAutocomplete]="AutoComplete"
			floatPlaceholder="never"
			[matChipInputFor]="ListChip"
		/>
		<app-circle-loader class="px-1" [radius]="22" *ngIf="loader"></app-circle-loader>
	</mat-chip-list>
	<mat-icon matSuffix class="closeIcon" *ngIf="selectedData.length > 0" (click)="clear()">close</mat-icon>

	<mat-autocomplete
		#AutoComplete="matAutocomplete"
		class="app-autocomplete"
		(optionSelected)="onSelect($event)"
	>
		<mat-option *ngFor="let city of list" [value]="itemValue ? city[itemValue] : city">
			{{ itemName ? city[itemName] : city }}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
