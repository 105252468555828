<div id="wrapper">
	<app-header [user]="" style="width: 100%" [elementName]="'npi-lookup'"></app-header>
	<!-- <app-sidemenu elementName="npi-lookup"></app-sidemenu> -->
	<div class="content-page">
		<div class="npi-container enlarged">
			<app-npi-custom-data></app-npi-custom-data>
			<section class="content-container">
				<div class="info-container">
					<div
						[ngClass]="{
							'card-container': true,
							'card-selected': npiQuestion.npi1
						}"
					>
						<p class="card-heading" (click)="cardClick('npi1')">
							<svg
								style="color: #040404"
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="17"
								viewBox="0 0 17 17"
								fill="none"
							>
								<g clip-path="url(#clip0_52_6)">
									<path
										d="M8.49984 15.5833C12.4119 15.5833 15.5832 12.412 15.5832 8.49999C15.5832 4.58797 12.4119 1.41666 8.49984 1.41666C4.58782 1.41666 1.4165 4.58797 1.4165 8.49999C1.4165 12.412 4.58782 15.5833 8.49984 15.5833Z"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M6.43896 6.37501C6.6055 5.9016 6.9342 5.50242 7.36685 5.24814C7.7995 4.99387 8.30818 4.90092 8.8028 4.98576C9.29741 5.0706 9.74604 5.32775 10.0692 5.71167C10.3924 6.09559 10.5693 6.5815 10.5685 7.08334C10.5685 8.50001 8.44355 9.20834 8.44355 9.20834"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M8.5 12.0417H8.51"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</g>
								<defs>
									<clipPath id="clip0_52_6">
										<rect width="17" height="17" fill="white" />
									</clipPath>
								</defs>
							</svg>
							What is an NPI Number?
						</p>
						<p class="card-content" *ngIf="npiQuestion.npi1">
							The National Provider Identifier (NPI) is a 10-digit identification number issued by the Center for
							Medicare and Medicaid Services (CMS) to healthcare providers in the United States.
						</p>
					</div>
					<div
						[ngClass]="{
							'card-container': true,
							'card-selected': npiQuestion.npi2
						}"
					>
						<p class="card-heading" (click)="cardClick('npi2')">
							<svg
								style="color: #040404"
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="17"
								viewBox="0 0 17 17"
								fill="none"
							>
								<g clip-path="url(#clip0_52_6)">
									<path
										d="M8.49984 15.5833C12.4119 15.5833 15.5832 12.412 15.5832 8.49999C15.5832 4.58797 12.4119 1.41666 8.49984 1.41666C4.58782 1.41666 1.4165 4.58797 1.4165 8.49999C1.4165 12.412 4.58782 15.5833 8.49984 15.5833Z"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M6.43896 6.37501C6.6055 5.9016 6.9342 5.50242 7.36685 5.24814C7.7995 4.99387 8.30818 4.90092 8.8028 4.98576C9.29741 5.0706 9.74604 5.32775 10.0692 5.71167C10.3924 6.09559 10.5693 6.5815 10.5685 7.08334C10.5685 8.50001 8.44355 9.20834 8.44355 9.20834"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M8.5 12.0417H8.51"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</g>
								<defs>
									<clipPath id="clip0_52_6">
										<rect width="17" height="17" fill="white" />
									</clipPath>
								</defs>
							</svg>
							Why are NPIs important in healthcare?
						</p>
						<p class="card-content" *ngIf="npiQuestion.npi2">
							An NPI is a unique identification number used to improve the efficiency and effectiveness of electronic
							transmissions of medical information. According to Health Insurance law, medical service providers must
							have an NPI number in order to provide their services in the United States.
						</p>
					</div>
					<div
						[ngClass]="{
							'card-container': true,
							'card-selected': npiQuestion.npi3
						}"
					>
						<p class="card-heading" (click)="cardClick('npi3')">
							<svg
								style="color: #040404"
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="17"
								viewBox="0 0 17 17"
								fill="none"
							>
								<g clip-path="url(#clip0_52_6)">
									<path
										d="M8.49984 15.5833C12.4119 15.5833 15.5832 12.412 15.5832 8.49999C15.5832 4.58797 12.4119 1.41666 8.49984 1.41666C4.58782 1.41666 1.4165 4.58797 1.4165 8.49999C1.4165 12.412 4.58782 15.5833 8.49984 15.5833Z"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M6.43896 6.37501C6.6055 5.9016 6.9342 5.50242 7.36685 5.24814C7.7995 4.99387 8.30818 4.90092 8.8028 4.98576C9.29741 5.0706 9.74604 5.32775 10.0692 5.71167C10.3924 6.09559 10.5693 6.5815 10.5685 7.08334C10.5685 8.50001 8.44355 9.20834 8.44355 9.20834"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M8.5 12.0417H8.51"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</g>
								<defs>
									<clipPath id="clip0_52_6">
										<rect width="17" height="17" fill="white" />
									</clipPath>
								</defs>
							</svg>
							How do I find physicians using NPI numbers?
						</p>
						<p class="card-content" *ngIf="npiQuestion.npi3">
							Enter the 10-digit NPI number on the search bar to find Physicians and healthcare services providers.
							With our real-time NPI lookup, you can narrow down physicians, doctors and other healthcare services
							easily.
						</p>
					</div>
					<div
						[ngClass]="{
							'card-container': true,
							'card-selected': npiQuestion.npi4
						}"
					>
						<p class="card-heading" (click)="cardClick('npi4')">
							<svg
								style="color: #040404"
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="17"
								viewBox="0 0 17 17"
								fill="none"
							>
								<g clip-path="url(#clip0_52_6)">
									<path
										d="M8.49984 15.5833C12.4119 15.5833 15.5832 12.412 15.5832 8.49999C15.5832 4.58797 12.4119 1.41666 8.49984 1.41666C4.58782 1.41666 1.4165 4.58797 1.4165 8.49999C1.4165 12.412 4.58782 15.5833 8.49984 15.5833Z"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M6.43896 6.37501C6.6055 5.9016 6.9342 5.50242 7.36685 5.24814C7.7995 4.99387 8.30818 4.90092 8.8028 4.98576C9.29741 5.0706 9.74604 5.32775 10.0692 5.71167C10.3924 6.09559 10.5693 6.5815 10.5685 7.08334C10.5685 8.50001 8.44355 9.20834 8.44355 9.20834"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M8.5 12.0417H8.51"
										stroke="#040404"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</g>
								<defs>
									<clipPath id="clip0_52_6">
										<rect width="17" height="17" fill="white" />
									</clipPath>
								</defs>
							</svg>
							How important is it to verify the NPI number?
						</p>
						<p class="card-content" *ngIf="npiQuestion.npi4">
							Using NPI validation, you can quickly track records of provider, specialty (taxonomy), practice
							address, and also ensure critical healthcare functions in compliance, human resources, credentialing,
							procurement, claims processing, and more.
						</p>
					</div>
				</div>
				<app-npi-file-upload style="width: 100%"></app-npi-file-upload>
			</section>
			<div id="wrapper" style="margin-top: 20px; width: 100%">
				<!-- Start content -->
				<div class="container-fluid">
					<!-- Start Edit profile row -->
					<div class="">
						<div class="table-wrapper">
							<div class="search_pagination_wrapper">
								<div class="row p-4">
									<div class="col-md-3 col-sm-12 p-0 m-0 header_container">
										<h3 class="table_heading">Uploaded Files</h3>
									</div>
									<div class="col-md-9 col-sm-12">
										<div class="paginationWrapper">
											<div class="paginationWrapper" *ngIf="totalCount > 0">
												<ul class="pagination">
													<li
														[ngClass]="{
															disabled: pager.currentPage === 1
														}"
													>
														<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
															><i class="fa fa-chevron-left" aria-hidden="true"></i
														></a>
														<a
															*ngIf="pager.currentPage !== 1"
															(click)="onPaginationClick(pager.currentPage - 1)"
															style="border-radius: 5px 0 0 5px !important"
															><i class="fa fa fa-chevron-left" aria-hidden="true"></i
														></a>
													</li>
													<li
														[ngClass]="{
															disabled: pager.currentPage === pager.totalPages
														}"
													>
														<a *ngIf="pager.currentPage === pager.totalPages" style="border-radius: 0 5px 5px 0 !important"
															><i class="fa fa-chevron-right" aria-hidden="true"></i
														></a>
														<a
															*ngIf="pager.currentPage !== pager.totalPages"
															(click)="onPaginationClick(pager.currentPage + 1)"
															style="border-radius: 0 5px 5px 0 !important"
															><i class="fa fa-chevron-right" aria-hidden="true"></i
														></a>
													</li>
												</ul>
												<div class="pull-right paginationInfo">
													{{ offset }}-{{ count }} of
													{{ totalCount }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div style="display: flex; flex-direction: row; height: 100%">
									<div style="overflow: hidden; flex-grow: 1">
										<ag-grid-angular
											style="width: 100%; height: 350px; max-height: 600px"
											class="ag-theme-material list1"
											(gridReady)="onGridReady($event)"
											#agGrid
											[defaultColDef]="defaultColDef"
											[columnDefs]="columnDefs"
											[animateRows]="true"
											(cellClicked)="onCellClicked($event)"
											[sortingOrder]="sortingOrders"
											(firstDataRendered)="onFirstDataRendered($event)"
											[paginationPageSize]="paginationPageSize"
											[frameworkComponents]="frameworkComponents"
										>
										</ag-grid-angular>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
