<!-- Location panel -->

<mat-expansion-panel [disabled]="disabled" #panelLoc hideToggle>
	<mat-expansion-panel-header
		[ngClass]="panelLoc.expanded ? 'expanded' : ''"
		[collapsedHeight]="'70px'"
		[expandedHeight]="'70px'"
	>
		<mat-panel-title>
			<div class="mat-people-title">Location</div>
			<div class="sub-header" *ngIf="selectedStatesFromFilter.length == 0 && !panelLoc.expanded">
				{{ title }}
			</div>
			<div class="selected" *ngIf="selectedStatesFromFilter.length > 0">
				<mat-chip-list cdkDropList cdkDropListOrientation="horizontal">
					<mat-basic-chip [ngClass]="'selected'" [selectable]="selectable" [removable]="true">
						{{ selectedStatesFromFilter[0].stateFullName }}
					</mat-basic-chip>
					<span *ngIf="selectedStatesFromFilter?.length > 1" class="additional-selection">
						+ {{ selectedStatesFromFilter.length - 1 }} more
					</span>
				</mat-chip-list>
			</div>
		</mat-panel-title>
		<mat-panel-description>
			<mat-icon class="matrl-plus">{{ panelLoc.expanded ? 'remove' : 'add' }}</mat-icon>
			<mat-icon class="filter-title matrl-plus" *ngIf="disabled">lock</mat-icon>
		</mat-panel-description>
	</mat-expansion-panel-header>

	<div>
		<div>
			<mat-form-field>
				<mat-chip-list #statesChipList aria-label="State input">
					<mat-basic-chip
						*ngFor="let state of selectedStatesFromFilter"
						[selectable]="selectable"
						[removable]="true"
						(removed)="removeState(state)"
					>
						{{ state.stateFullName }}
						<mat-icon matChipRemove>close</mat-icon>
					</mat-basic-chip>
					<input
						placeholder="State"
						#stateInput
						aria-label="stateList"
						matInput
						[formControl]="stateControl"
						[matAutocomplete]="stateAuto"
						floatPlaceholder="never"
						[matChipInputFor]="statesChipList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					/>
				</mat-chip-list>
				<mat-icon matSuffix class="closeIcon" *ngIf="selectedStatesFromFilter.length > 0" (click)="clearState()"
					>close
				</mat-icon>

				<mat-autocomplete
					#stateAuto="matAutocomplete"
					(optionSelected)="selectStates($event)"
					panelClass="app-autocomplete"
					class="app-autocomplete"
				>
					<mat-option *ngFor="let state of stateList" [value]="state">
						{{ state.stateFullName }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field>
				<mat-chip-list #cityListChip aria-label="city name input">
					<mat-basic-chip
						*ngFor="let city of selectedCitiesFromFilter"
						[selectable]="selectable"
						[removable]="true"
						(removed)="removeCity(city)"
					>
						{{ city.city }}
						<mat-icon matChipRemove>close</mat-icon>
					</mat-basic-chip>
					<input
						placeholder="City"
						#cityInput
						aria-label="Search city"
						matInput
						[formControl]="cityControl"
						[matAutocomplete]="cityAutoComplete"
						floatPlaceholder="never"
						[matChipInputFor]="cityListChip"
					/>
				</mat-chip-list>
				<mat-icon matSuffix class="closeIcon" *ngIf="selectedCitiesFromFilter.length > 0" (click)="clearCity()"
					>close</mat-icon
				>

				<mat-autocomplete
					#cityAutoComplete="matAutocomplete"
					class="app-autocomplete"
					(optionSelected)="onCitySelect($event)"
				>
					<mat-option *ngFor="let city of cityList" [value]="city">
						{{ city.city }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<ng-container *ngIf="isZipCode">
<mat-form-field>
				<mat-chip-list #zipCodeChipList aria-label="Zip Code">
					<mat-basic-chip
						*ngFor="let zip of selectedZipFromFilter"
						[selectable]="selectable"
						[removable]="true"
						(removed)="removeZip(zip)"
					>
						{{ zip }}
						<mat-icon matChipRemove>close</mat-icon>
					</mat-basic-chip>
					<input
						placeholder="Zip Code"
						#zipInput
						aria-label="Zip Code"
						matInput
						[formControl]="zipControl"
						[matAutocomplete]="zipCodeAuto"
						floatPlaceholder="never"
						[matChipInputFor]="zipCodeChipList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						numbersOnly
					/>
				</mat-chip-list>

				<mat-autocomplete #zipCodeAuto="matAutocomplete" class="app-autocomplete" (optionSelected)="onZipSelect($event)">
					<mat-option *ngFor="let zip of zipCodeList" [value]="zip" >
						<mat-checkbox [checked]="selectedZipFromFilter.includes(zip)"></mat-checkbox>
						{{ zip }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
			</ng-container>
			

		</div>
	</div>
</mat-expansion-panel>
