import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-select-drop-down',
	templateUrl: './select-drop-down.component.html',
	styleUrls: ['./select-drop-down.component.css']
})
export class SelectDropDownComponent implements OnInit, OnChanges {
	@Input() title: string = '';
	@Input() selectedData: Array<any> = [];
	@Input() itemName: any = '';
	@Input() itemValue: any = '';
	@Input() placeholder: any = '';
	@Input() list: Array<any> = [];
	@Input() returnObject: boolean = false;
	@Output() onItemSelect = new EventEmitter();
	selectable = true;
	removable = true;

	@ViewChild('autoInput')
	autoInput: ElementRef<HTMLInputElement>;
	control: UntypedFormControl = new UntypedFormControl();

	@Output() selectedDataChange = new EventEmitter();
	@Output() listChange = new EventEmitter();

	constructor(private cdRef: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['selectedData'] && !changes['selectedData'].isFirstChange()) {
			this.updateFormControl();
		}
	}

	private updateFormControl(): void {
		this.control.setValue(this.selectedData);
		this.cdRef.detectChanges();
	}

	ngOnInit(): void {
		this.cdRef.detectChanges();
	}
	selectItem(item) {
		this.omitChange();
	}
	get valueItem() {
		return this.itemValue && !this.returnObject;
	}
	get valueName() {
		return this.itemName && this.returnObject;
	}
	remove(item) {
		this.selectedData = this.selectedData.filter((el) => {
			if (this.itemName) {
				return el[this.itemName] !== item[this.itemName];
			}
			return el !== item;
		});
		this.omitChange();
	}
	omitChange() {
		this.selectedDataChange.emit(this.selectedData);
		this.onItemSelect.emit();
	}
}
