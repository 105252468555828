import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-pricing',
  templateUrl: './payment_history.component.html',
  styleUrls: ['./payment_history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
 

  ngOnInit() {
   
  }
   

}
