import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AmplizService } from '../../../services/ampliz.service';
@Component({
	selector: 'app-physician-hospital-card',
	templateUrl: './physician-hospital-card.component.html',
	styleUrls: ['./physician-hospital-card.component.css']
})
export class PhysicianHospitalCardComponent implements OnInit {
	@Input() hospitalList: any;
	@Input() offset: number;
	@Output() offsetChange = new EventEmitter<number>();
	@Input() showViewMoreBtn: boolean = true;

	constructor(private amplizService: AmplizService) {}

	ngOnInit(): void {
		// this.amplizService.getUniqueHospitalInPhysician(this.physicianFilter).subscribe((res) => {
		// 	console.log('res', res);
		// });
	}

	updateOffset(): void {
		this.offset += 10;
		this.offsetChange.emit(this.offset);
	}
}
