import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-more-fliter-header',
	templateUrl: './more-fliter-header.component.html',
	styleUrls: ['./more-fliter-header.component.css']
})
export class MoreFliterHeaderComponent implements OnInit {
	@Input() totalRecords: number = 0;
	@Input() loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	@Output() clear = new EventEmitter();
	@Output() closeFilter = new EventEmitter();
	@Output() applyFilter = new EventEmitter();

	constructor(private cdRef: ChangeDetectorRef) {}
	// moreFilterAccordian.closeAll()
	ngOnInit(): void {}
	closeDialogBox() {
		this.closeFilter.emit();
	}
	applyMoreFilters() {
		this.applyFilter.emit();
	}
	handleClear() {
		this.clear.emit();
		this.cdRef.detectChanges();
	}
	get countLoader() {
		return this.loader.value;
	}
}
