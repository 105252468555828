import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ChangeDetectorRef,
	Renderer2,
	TemplateRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { tickStep } from 'd3';
import { BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { B2bService } from 'src/app/modules/B2B/services/b2b.service';
import { FilterStorageService } from 'src/app/modules/B2B/services/filter-storage.service';
import { SearchImagingModel } from '../../../models/SearchImagingModel';
import { ImagingDataService } from '../../../services/imaging-data.service';
import { ImagingService } from '../../../services/imaging.service';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';

@Component({
	selector: 'app-filter-imaging-center',
	templateUrl: './filter-imaging-center.component.html',
	styleUrls: ['./filter-imaging-center.component.css']
})
export class FilterImagingCenterComponent implements OnInit, OnDestroy {
	@Output() onFilterChange = new EventEmitter<any>();
	@Input() isSubscribed: boolean = false;
	@Input() isPaid: boolean = false;
	filterData: SearchImagingModel = new SearchImagingModel();
	tempDialogFilters: SearchImagingModel = new SearchImagingModel();
	dialogRef: any;
	dialogState: boolean = false;
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;
	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showConfirmDialog: boolean = false;
	@ViewChild('cptCodeInput', { static: false })
	cptCodeInput: ElementRef<HTMLInputElement>;
	@ViewChild('centerName', { static: false })
	centerName: ElementRef<HTMLInputElement>;
	selectable = true;
	removable = true;
	codeControl = new FormControl();
	selectedCodes = [];
	separatorKeysCodes: number[] = [ENTER, COMMA];
	noOfCompanyList = [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
		'13',
		'14',
		'15',
		'16',
		'17',
		'18',
		'19',
		'20',
		'21',
		'22',
		'23',
		'24',
		'25',
		'26',
		'27',
		'28',
		'29',
		'30',
		'31',
		'32',
		'33',
		'34',
		'35',
		'36',
		'37',
		'38',
		'39',
		'40',
		'41',
		'42',
		'43',
		'44',
		'45',
		'46',
		'48',
		'50',
		'51',
		'52',
		'54',
		'55',
		'56',
		'57',
		'59',
		'60',
		'61',
		'62',
		'63',
		'66',
		'68',
		'69',
		'71',
		'73',
		'74',
		'75',
		'77',
		'78',
		'80',
		'82',
		'83',
		'84',
		'85',
		'86',
		'88',
		'90',
		'92',
		'94',
		'95',
		'96',
		'99',
		'100',
		'101',
		'102',
		'104',
		'108',
		'110',
		'113',
		'114',
		'120',
		'121',
		'126',
		'129',
		'130',
		'132',
		'134',
		'137',
		'141',
		'149',
		'150',
		'151',
		'154',
		'167',
		'168',
		'170',
		'172',
		'179',
		'180',
		'181',
		'198',
		'199',
		'200',
		'201',
		'209',
		'213',
		'222',
		'236',
		'237',
		'240',
		'246',
		'274',
		'276',
		'280',
		'293',
		'308',
		'311',
		'346',
		'349',
		'350',
		'372',
		'376',
		'392',
		'396',
		'400',
		'401',
		'420',
		'433',
		'444',
		'450',
		'476',
		'501',
		'503',
		'526',
		'546',
		'549',
		'559',
		'590',
		'592',
		'629',
		'650',
		'652',
		'668',
		'688',
		'698',
		'741',
		'753',
		'800',
		'2481',
		'273,000'
	];

	//company Variables
	companyList: Array<any> = [];

	//Country Variables
	countryList: Array<any> = [];
	selectedCountry: Array<any> = [];

	//State Variables
	stateList: Array<any> = [];
	selectedStates: Array<any> = [];

	//City Variables

	//Search List variables
	cityList: Array<any> = [];
	selectedCities: Array<any> = [];

	// Revenue Variables
	revenueList: Array<any> = [];
	includedRevenueRange: any = [];
	subscription: Subscription;

	imagingNumber: any = null;

	selectedImagingNumber: any = [];
	imagingEqupmentsList: Array<any> = [];
	cptControl = new FormControl();
	centerNameController = new FormControl();
	companyCodeModel = '';
	icptCodeList: any = [];
	icdLoader = false;
	@ViewChild('codeInput', { static: false })
	codeInput: ElementRef<HTMLInputElement>;

	get selectedCode() {
		return this.filterData.icdTenCodes;
	}
	constructor(
		private b2bService: ImagingService,
		private filterStorageService: FilterStorageService,
		private dataService: ImagingDataService,
		private cdr: ChangeDetectorRef,
		private amplizService: AmplizService,
		private renderer: Renderer2,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService
	) {}
	get selectedImagingEquipments() {
		return this.filterData.imagingEquipments;
	}

	ngOnInit() {
		this.getPersistData();
		this.getAllListData();
		this.getImagingCenterEquipment();

		// this.subscription = this.dataService.imagingSearchData.subscribe((res) => {
		//   if (res.fromSearch) {
		//     this.filterData=res.data;
		//     this.omitChanges();
		//   }
		// });
	}

	get numberOfImagingEquipmentList() {
		const numberOfImagingEquipmentList = [];
		for (let i = 0; i <= 25; i++) {
			numberOfImagingEquipmentList.push(i + '');
		}
		return numberOfImagingEquipmentList;
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
		// this.dataService.passSearchImagingInput(this.filterData, false);
	}

	// Imaging functions
	onCompanyCodeSelect() {
		this.filterData.noOfImagingLocation = [
			...new Set([...this.filterData.noOfImagingLocation, this.companyCodeModel])
		];
		this.storeFilterData();
		this.omitChanges();
	}
	get groupSelectedForCenterTab() {
		return [...(this.filterData?.centerName || []), ...(this.filterData?.noOfImagingLocation || [])];
	}

	removeCompanyCode(code) {
		this.filterData.noOfImagingLocation = this.filterData.noOfImagingLocation.filter((el) => el != code);
		this.companyCodeModel = '';
		this.storeFilterData();
		this.omitChanges();
	}
	triggerAutoFocus(eleId: string) {
		const element = this.renderer.selectRootElement(eleId);
		setTimeout(() => element.focus(), 100);
	}
	getAllListData() {
		this.cptControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getImagingCenterEquipment(hv);
			}
		});
		this.centerNameController.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getCenterName(hv);
			}
		});
		this.codeControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			this.getCpdtList(hv);
		});
	}
	removeICDCode(code: string) {
		this.filterData.icdTenCodes = this.filterData.icdTenCodes.filter((el) => el != code);
		this.omitChanges();
		this.storeFilterData();
	}
	selectCode(event: MatAutocompleteSelectedEvent): void {
		if (this.filterData.icdTenCodes.findIndex((hospital) => hospital === event.option.viewValue) === -1) {
			this.filterData.icdTenCodes.push(event.option.value);
			this.codeInput.nativeElement.value = '';
			this.codeControl.setValue(null);
			this.getCpdtList();
			this.omitChanges();
			this.storeFilterData();
		}
	}
	getCpdtList(searchPhrase = '') {
		if (searchPhrase.length >= 3) {
			this.icdLoader = true;
			this.amplizService.getAllicdtenCodesForImage({ searchPhrase }).subscribe((response: any) => {
				this.icptCodeList = response.icdtenCodes;
				this.icdLoader = false;
			});
		}
	}
	getCenterName(searchPhase) {
		this.b2bService.getCompanyList(searchPhase).subscribe((res) => {
			this.companyList = res.companyListImagingCenter;
		});
	}

	getPersistData() {
		const newFilterData = this.filterStorageService.get('imaging_center_filter_data');
		Object.keys(newFilterData).map((el) => {
			this.filterData[el] = newFilterData[el];
		});
		this.cdr.detectChanges();
		this.omitChanges();
		this.getRecordCount();
	}
	get noOfImagingLocation() {
		return this.filterData.noOfImagingLocation;
	}

	storeFilterData() {
		this.filterStorageService.set('imaging_center_filter_data', this.filterData);
	}

	omitChanges() {
		this.onFilterChange.emit(this.filterData.toJson());
		this.storeFilterData();
	}

	clearFilter() {
		const isValid = this.filterData.validateImagingSearch();
		if (isValid) {
			this.filterData = new SearchImagingModel();
			this.omitChanges();
		}
	}
	hanldeLocationValueChange(locationBody: any) {
		this.omitChanges();
	}
	getImagingCenterEquipment(searchPhase = '') {
		this.b2bService.getImagingCenterEquipment(searchPhase, 'Company').subscribe((res) => {
			this.imagingEqupmentsList = res.icEquipmentList;
		});
	}
	removeCode(code: string) {
		this.filterData.imagingEquipments = this.filterData.imagingEquipments.filter((el) => el !== code);
		this.getImagingCenterEquipment();
		this.omitChanges();
		this.storeFilterData();
	}
	removeCenterName(code: string) {
		this.filterData.centerName = this.filterData.centerName.filter((el) => el !== code);
		this.getCenterName('');
		this.omitChanges();
		this.storeFilterData();
	}
	onCodeSelect(event: MatAutocompleteSelectedEvent) {
		this.filterData.imagingEquipments = [
			...new Set([...this.filterData.imagingEquipments, event.option.value])
		];
		this.cptCodeInput.nativeElement.value = '';
		setTimeout(() => {
			this.getImagingCenterEquipment();
		}, 500);
		this.omitChanges();
		this.storeFilterData();
	}
	get selectedCenterName() {
		return this.filterData.centerName;
	}
	onCenterSelect(event: MatAutocompleteSelectedEvent) {
		this.filterData.centerName = [...new Set([...this.filterData.centerName, event.option.value])];
		this.centerName.nativeElement.value = '';
		setTimeout(() => {
			this.getCenterName('');
		}, 500);
		this.omitChanges();
		this.storeFilterData();
	}
	clearTempFilter() {
		const isValid = this.tempDialogFilters.validateImagingSearch();
		if (isValid) {
			this.tempDialogFilters = new SearchImagingModel();
			this.getRecordCount();
		}
	}
	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		Object.keys(this.filterData).map((el) => {
			this.tempDialogFilters[el] = this.filterData[el];
		});
		this.getRecordCount();
		this.dialogState = true;
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}
	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, cloneDeep(this.filterData));
	}
	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearTempFilter();
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}
	getRecordCount() {
		this.recordCountLoader.next(true);
		this.b2bService.searchImagings(this.tempDialogFilters.toJson()).subscribe(
			(res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				this.recordCountLoader.next(false);
			},
			(err) => {
				this.recordCountLoader.next(false);
			}
		);
	}
	applyMoreFilters() {
		Object.keys(this.tempDialogFilters).map((el) => {
			this.filterData[el] = this.tempDialogFilters[el];
		});
		this.omitChanges();
		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}
	get tempImagingExpandData() {
		const data: any = [...this.tempDialogFilters.imagingEquipments];
		if (this.tempDialogFilters.noOfEqupment) {
			data.push(this.tempDialogFilters.noOfEqupment);
		}
		return data;
	}
	get ImagingExpandData() {
		const data: any = [...this.filterData.imagingEquipments];
		if (this.filterData.noOfEqupment) {
			data.push(this.filterData.noOfEqupment);
		}
		return data;
	}
}
