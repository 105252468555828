import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { paragon, SDK_EVENT } from '@useparagon/connect';
import { AmplizService } from './ampliz.service';
import {
	HttpClient,
	HttpHeaders,
	HttpResponse,
	HttpParams,
	HttpBackend
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
	providedIn: 'root'
})
export class ParagonIntegrationService {
	private PROJECT_KEY = environment.paragonProjectKey;
	paraUser = new BehaviorSubject<any>([]);
	integration = new BehaviorSubject<any>([]);

	btnStatus = new BehaviorSubject<string>('');

	isAuthenticated = new BehaviorSubject<boolean>(false);
	isIntegrationActive = new BehaviorSubject<boolean>(false);
	activatedIntegrations = new BehaviorSubject<any>([]);
	activatedUserIntegrations = new BehaviorSubject<any>([]);

	private integrationAppEvents = [
		{
			name: 'salesforce',
			eventName: 'SF_List_Export'
		},
		{
			name: 'zohocrm',
			eventName: 'Zoho_List_Export'
		},
		{
			name: 'hubspot',
			eventName: 'Hubspot_List_Export'
		},
		{
			name: 'mailchimp',
			eventName: 'Mailchimp_List_Export'
		}
	];

	constructor(
		private apiService: AmplizService,
		private http: HttpClient,
		private handler: HttpBackend
	) {
		this.http = new HttpClient(handler); // Bypassing default interceptor for Auth

		paragon.subscribe(SDK_EVENT.ON_PORTAL_CLOSE, () => {
			// this.getUpdatedIntegrations.next(this.paragonUser());

			this.paraUser.next(paragon.getUser());
			this.getActivatedIntegrations();
		});
	}

	get paragonUser() {
		const paraUser: any = paragon.getUser();
		return paraUser;
	}

	private fetchJWT() {
		return new Promise((resolve, reject) => {
			this.apiService.getParagonJWT().subscribe(
				(res: any) => {
					resolve(res.paraToken);
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	private paraAuthenticate(paraJWT: any) {
		paragon
			.authenticate(this.PROJECT_KEY, paraJWT)
			.then(() => {
				this.isAuthenticated.next(this.paragonUser.authenticated);
				this.getActivatedIntegrations();
				if (this.paragonUser.authenticated) {
					this.paraUser.next(paragon.getUser());
					this.getIntegrationStatus();
				}
			})
			.catch((err) => {
				this.isAuthenticated.next(false);
				console.log('paraAuth', err);
			});
	}

	private getIntegrationStatus() {
		const tempIntegration = [];
		paragon.getIntegrationMetadata().map((integration) => {
			tempIntegration.push(integration);
		});
		this.integration.next(tempIntegration);
	}

	authenticate() {
		this.fetchJWT().then((key) => {
			this.paraAuthenticate(key);
		});
	}

	exportList(listObj: any, integration: any): any {
		const eventObj: any = {
			exportList: listObj.exportList,
			userId: this.paragonUser?.userId,
			auth: localStorage.getItem('auth_token')
		};
		const integrationType = this.integrationAppEvents.filter((item) => {
			return item.name == integration;
		});

		paragon
			.event(integrationType[0].eventName, eventObj)
			.then()
			.catch(() => {
				this.authenticate();
			});
	}

	connectParagon(type: string) {
		paragon.connect(type, {});
	}

	getExecutingTasks() {
		const url =
			environment.paragonBaseUrl +
			`/${environment.paragonProjectKey}/task-history/workflow-executions`;
		const query: any = {
			userId: this.paragonUser.userId,
			status: 'EXECUTING'
		};

		const options = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + environment.paragonApiKey
			}),
			params: query
		};
		return this.http.get(url, options);
	}

	private getActivatedIntegrations() {
		const integrations = this.paragonUser?.integrations;
		const activatedArr = [];
		for (const key in integrations) {
			if (integrations[key].enabled === true) {
				activatedArr.push(key);
			}
		}

		this.integration.subscribe((val) => {
			const tempObject = val.filter((integration) => activatedArr.includes(integration.type));
			this.activatedUserIntegrations.next(tempObject);
		});

		this.activatedIntegrations.next(activatedArr);
	}
}
