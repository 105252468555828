import {
	Component,
	OnInit,
	ViewChild,
	Output,
	EventEmitter,
	Input,
	OnChanges,
	NgZone,
	Renderer2,
	TemplateRef
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import { finalize, debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FilterStorageService } from '../../../services/filter-storage.service';
import { Router } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { ImagingService } from 'src/app/modules/ImagingCenter/services/imaging.service';
import { isEqual, cloneDeep } from 'lodash';
import { SearchExecutiveModel } from '../../../models/searchExecutiveModel';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';
@Component({
	selector: 'app-filter-executive',
	templateUrl: './filter-executive.component.html',
	styleUrls: ['./filter-executive.component.css']
})
export class FilterExecutiveComponent implements OnInit, OnChanges {
	@Output() onFilterChange = new EventEmitter<any>();
	@Input() isSubscribed: boolean;
	@Input() leadWithPhone: boolean = false;
	@Input() leadWithEmail: boolean = false;
	removable = true;
	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	separatorKeysCodes: number[] = [ENTER, COMMA];
	dialogState: boolean = false;
	dialogRef: any;
	levelListData: any = [
		{ id: 1, level: 'C Level' },
		{ id: 2, level: 'VP Level' },
		{ id: 3, level: 'Director' },
		{ id: 4, level: 'Manager' },
		{ id: 5, level: 'Staff' },
		{ id: 6, level: 'Others' }
	];
	firmListData = ['Health System', 'Hospital', 'Physician Group', 'Others'];
	noOfBedList = ['0-50', '51-100', '101-250', '251-500', '501-1000'];

	healthSystemList = [
		'Childrens Hospital',
		'Critical Access Hospital',
		'Department of Defense Hospital',
		'Long Term Acute Care Hospital',
		'Psychiatric Hospital',
		'Rehabilitation Hospital',
		'Religious Non-Medical Health Care Institution',
		'Short Term Acute Care Hospital',
		'VA Hospital'
	];
	healthSystem1List = ['Health System'];

	otherHospitalList = [
		'Accountable Care Organization',
		'Ambulatory Surgery Center',
		'Ambulatory Surgery Center Corporation',
		'Assisted Living Facility',
		'Assisted Living Facility Corporation',
		'Clinically Integrated Network',
		'Federally Qualified Health Center',
		'Federally Qualified Health Center Look-alike',
		'Federally Qualified Health Center Look-alike Service Site',
		'Federally Qualified Health Center Service Site',
		'Group Purchasing Organization',
		'Health Information Exchange',
		'Home Health Agency',
		'Home Health Agency Corporation',
		'Hospice',
		'Hospice Corporation',
		'Hospice Corporation',
		'Imaging Center',
		'Imaging Center Corporation',
		'Payor',
		'Payor Subsidiary',
		'Regional Purchasing Coalition',
		'Renal Dialysis Corporation',
		'Renal Dialysis Facility',
		'Retail Clinic',
		'Retail Clinic Corporation',
		'Rural Health Clinic',
		'Skilled Nursing Facility',
		'Skilled Nursing Facility Corporation',
		'Specialty Pharmacy',
		'Specialty Pharmacy Corporation',
		'Urgent Care Clinic',
		'Urgent Care Corporation'
	];
	physicianGroupList = [
		'Single/Multi-Specialty Physician Group',
		'Independent Practice Association',
		'Academic/Faculty Practice'
	];
	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;
	showConfirmDialog: boolean = false;
	tempDialogFilters: SearchExecutiveModel = new SearchExecutiveModel();
	filterData: SearchExecutiveModel = new SearchExecutiveModel();
	cloneTempDialogFilters: any;
	titleListData: any[] = [];
	isPaid: boolean = false;
	departmentListData: string[] = [];

	constructor(
		public router: Router,
		private amplizService: AmplizService,
		private filterStorageService: FilterStorageService,
		private ngZone: NgZone,
		private healthCareDataService: DataService,
		private loaderService: LoaderService,
		private messageService: MessageService,
		private renderer: Renderer2,
		private b2bService: ImagingService,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService
	) {}
	get hospitalExpandData() {
		return [
			...this.filterData.hospitalNameList,
			this.filterData.firmType,
			...this.filterData.hospitalClassification
		].filter(Boolean);
	}
	get tempHospitalExpandData() {
		return [
			...this.tempDialogFilters.hospitalNameList,
			this.tempDialogFilters.firmType,
			...this.tempDialogFilters.hospitalClassification,
			...this.tempDialogFilters.bedsrange
		].filter(Boolean);
	}
	get HospitalExpandData() {
		return [
			...this.filterData.hospitalNameList,
			this.filterData.firmType,
			...this.filterData.hospitalClassification,
			...this.filterData.bedsrange
		].filter(Boolean);
	}
	ngOnChanges() {
		// this.isPaid = false;
		this.isPaid = !this.isSubscribed;
		this.changeSearchData();
		// this.getRecordCount();
	}
	omitChange(isCleared: boolean = false) {
		this.storeFilterData();
		this.filterData.cleared = isCleared;
		this.onFilterChange.emit(this.filterData.toJson());
		this.changeSearchData();
	}

	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		Object.keys(this.filterData).map((el) => {
			this.tempDialogFilters[el] = this.filterData[el];
		});
		this.dialogState = true;
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
		this.getRecordCount();
	}

	changeSearchData() {
		this.healthCareDataService.changePhysicainSearchData(this.filterData.toJson());
	}

	get getListForHospitalClasification() {
		const firms = [this.filterData.firmType];
		if (!this.filterData.firmType) {
			return [
				...this.healthSystemList,
				...this.healthSystem1List,
				...this.physicianGroupList,
				...this.otherHospitalList
			].sort();
		}
		if (firms.includes('Health System')) {
			return this.healthSystem1List;
		}

		if (firms.includes('Physician Group')) {
			return this.physicianGroupList;
		}
		if (firms.includes('Others')) {
			return this.otherHospitalList;
		}
		return this.healthSystemList;
	}

	ngOnInit() {
		this.getPersistData();
		this.autoPopulateData();
	}
	autoPopulateData() {
		this.amplizService.getDepartmentList(null).subscribe((response) => {
			this.departmentListData = response.departmentList;
		});
	}

	getPersistData() {
		const newFilterData = this.filterStorageService.get('executive_filter_data') || {};
		Object.keys(newFilterData).map((el) => {
			this.filterData[el] = newFilterData[el];
		});

		this.omitChange();
	}
	clearAll() {
		this.filterData = new SearchExecutiveModel();
		this.storeFilterData();
		this.omitChange(true);
	}
	storeFilterData() {
		this.filterStorageService.set('executive_filter_data', this.filterData);
	}
	public openItem(path: string): void {
		this.ngZone.run(() => this.router.navigateByUrl(path)).then();
	}
	getTitleList(value) {
		this.amplizService
			.getTitleList({
				searchPhrase: value
			})
			.pipe(debounceTime(1000))
			.subscribe((response) => {
				this.titleListData = response.titleList;
			});
	}
	async requestPricing() {
		const emailId = await localStorage.getItem('email_id');
		this.loaderService.display(true);
		const body = { package: 'Enterprise', email: emailId };
		this.amplizService.getPrice(body).subscribe(
			(res) => {
				this.loaderService.display(false);

				this.messageService.display(true, 'Thanks for asking, will get back to you in 24 hrs');
			},
			(error) => {
				this.loaderService.display(false);
				this.messageService.displayError(true, error.error.msg ? error.error.msg : 'Server Error !!!');
			}
		);
	}
	triggerAutoFocus(eleId: string) {
		const element = this.renderer.selectRootElement(eleId);
		setTimeout(() => element.focus(), 100);
	}
	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, cloneDeep(this.filterData));
	}
	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearFilter();
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}
	applyMoreFilters() {
		Object.keys(this.tempDialogFilters).map((el) => {
			this.filterData[el] = this.tempDialogFilters[el];
		});
		this.omitChange();
		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}
	get countLoader() {
		return 0;
	}
	clearFilter() {
		this.tempDialogFilters = new SearchExecutiveModel();
	}
	getRecordCount() {
		this.recordCountLoader.next(true);
		this.amplizService.searchExecutive({}, this.tempDialogFilters.toJson()).subscribe(
			(res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				this.recordCountLoader.next(false);
			},
			(err) => {
				this.recordCountLoader.next(false);
			}
		);
	}
	onFirmChange() {
		this.filterData.hospitalClassification = [];
		this.omitChange();
	}
	onTempFirmChange() {
		this.tempDialogFilters.hospitalClassification = [];
		this.getRecordCount();
	}

	addPersonName(event: any): void {
		this.filterData.fullName = event.target.value;
		//
		if (event.target.value.length >= 3 || event.target.value.length == 0) {
			//
			this.omitChange();
			this.storeFilterData();
		}
	}
	addTempPersonName(event: any): void {
		this.tempDialogFilters.fullName = event.target.value;
		if (event.target.value.length >= 3 || event.target.value.length == 0) {
			this.getRecordCount();
		}
	}
	get name() {
		return this.filterData.fullName ? [this.filterData.fullName] : [];
	}
	get tempName() {
		return this.tempDialogFilters.fullName ? [this.tempDialogFilters.fullName] : [];
	}
	clear(name) {
		switch (name) {
			case 'levels':
				this.tempDialogFilters.levelID = [];
				break;
			case 'department':
				this.tempDialogFilters.department = [];
				break;
		}
		this.getRecordCount();
	}
	remove(item, itemName) {
		switch (itemName) {
			case 'levels':
				this.tempDialogFilters.levelID = this.tempDialogFilters.levelID.filter((el) => el.id !== item.id);
				break;
			case 'department':
				this.tempDialogFilters.department = this.tempDialogFilters.department.filter((el) => el !== item);
				break;

			default:
				break;
		}
		this.getRecordCount();
	}
}
