import { Injectable } from '@angular/core';
import { SearchPayourModel } from '../models/search-payor-model.model';
import { PayorModel } from '../models/payor-model.model';
import { BehaviorSubject } from 'rxjs/Rx';
import { PayorExecutive } from '../models/payor-executive.model';
import { SearchPayorExecutive } from '../models/search-payor-executive.model';
import { SearchPhysicianExecutive } from '../models/search-physician-executive';
import { PhysicianGroupExecutive } from '../models/physician-group-executive-model';
import { ClinicalExecutives } from '../models/clinical-model/clinical-executives';
import { ClinicalExecutivesSearch } from '../models/clinical-model/clinical-executives-search';

@Injectable({
	providedIn: 'root'
})
export class DataService {
	public ltcSearchData = new BehaviorSubject({
		data: new SearchPayourModel(),
		fromSearch: false
	});
	public payourSearchData = new BehaviorSubject({
		data: new SearchPayorExecutive(),
		fromSearch: false
	});
	public physicianSearchExecutiveData = new BehaviorSubject({
		data: new SearchPhysicianExecutive(),
		fromSearch: false
	});
	public clinicalExecutivesSearchData = new BehaviorSubject({
		data: new ClinicalExecutivesSearch(),
		fromSearch: false
	});
	public savedPayor: BehaviorSubject<Array<PayorExecutive>> = new BehaviorSubject([]);
	public savedClinicalExecutives: BehaviorSubject<Array<ClinicalExecutives>> = new BehaviorSubject(
		[]
	);

	public savedPhysicianGroup: BehaviorSubject<Array<PhysicianGroupExecutive>> = new BehaviorSubject(
		[]
	);
	private apacList = new BehaviorSubject([]);
	public mainSelectedTab = new BehaviorSubject(0);

	private firstTimeLoad = new BehaviorSubject(true);

	public specialtySearchInPhysicianGroup = new BehaviorSubject('');

	public executiveFromClinic = new BehaviorSubject('');

	firstLoad = this.firstTimeLoad.asObservable();
	apacListSubscriber = this.apacList.asObservable();

	constructor() {}

	passSearchLTCInput(contact: SearchPayourModel, fromSearch: boolean = true) {
		this.ltcSearchData.next({ data: contact, fromSearch: fromSearch });
	}
	passSearchPayourInput(contact: SearchPayorExecutive, fromSearch: boolean = true) {
		this.payourSearchData.next({ data: contact, fromSearch: fromSearch });
	}
	passSearchPhysicianExecutiveInput(contact: SearchPhysicianExecutive, fromSearch: boolean = true) {
		this.physicianSearchExecutiveData.next({ data: contact, fromSearch: fromSearch });
	}
	passSearchClinicalInput(contact: ClinicalExecutivesSearch, fromSearch: boolean = true) {
		this.clinicalExecutivesSearchData.next({
			data: contact,
			fromSearch: fromSearch
		});
	}

	addToSavedLTC(value: Array<PayorExecutive>) {
		this.savedPayor.next(value);
	}
	addToSavedClinicalExecutives(value: Array<ClinicalExecutives>) {
		this.savedClinicalExecutives.next(value);
	}
	addToSavedPhysicianGROUP(value: Array<PhysicianGroupExecutive>) {
		this.savedPhysicianGroup.next(value);
	}
	changeApacList(apac: any) {
		this.apacList.next(apac);
	}
	addToSavedContacts(value: Array<PayorExecutive>) {
		this.savedPayor.next(value);
	}
	changeSelectedTab(tab: number) {
		this.mainSelectedTab.next(tab);
	}
	searchSpecialtyInPhysicianGroup(value: any) {
		this.specialtySearchInPhysicianGroup.next(value);
	}
	passExecutiveFromClinic(value: any) {
		this.executiveFromClinic.next(value);
	}
}
