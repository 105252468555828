<div id="wrapper">
  <app-header [user]="user" style="width: 100%" [elementName]="'pricing'"></app-header>{{ user }}
  <!-- <app-header *ngIf="router.url != '/payment'" [header-data]="headerData"></app-header> -->

  <!-- <app-sidemenu elementName="pricing"></app-sidemenu> -->
  <!-- ============================================================== -->
  <div class="content-page">
    <!-- Start content -->
    <div class="content">
      <div class="container-fluid">
       
        <!-- end row -->
        <div class="">
          <div class="tab-content">
            <div class="tab-pane active" id="home-b1">
              <div class="row">
                <div class="col-md-8">
                  <!-- Personal-Information -->
                  <div class="row mt-2">
                    <!--Pricing Column-->
                    <!--Pricing Column-->
                    <article
                      class="pricing-column col-md-4"
                      style="height: fit-content"
                    >
                      <div class="sele-paln" style="border: 1px solid #eee">
                        <div class="plan-header text-center">
                          <h3 class="plan-title">Bulk / API</h3>
                          <h2 class="plan-price">Enterprise</h2>
                        </div>
                        <div class="text-center pb-4">
                          <span></span>
                          <button
                            class="btn-bordred btn-rounded no_plan_selected"
                            (click)="requestPricing()"
                          >
                            Request Pricing
                          </button>
                        </div>
                        <ul
                          class="plan-stats list-unstyled text-center"
                          style="visibility: hidden"
                        >
                          <li><strong>5</strong> free credits</li>
                          <li>No credit Card required</li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  <!-- Personal-Information -->
                </div>
                <div class="col-md-4">
                  <!-- Personal-Information -->
                  <!-- <div class="panel panel-default panel-fill mt-2">
                    <div class="panel-heading">
                      <h3 class="panel-title">Billing Information</h3>
                    </div>
                    <div class="panel-body">
                      <div *ngIf="planSelected == 'pro'" class="m-b-20">
                        <b style="color: #000"
                          ><span class="pr-5">Pro Plan:</span>
                          <span class="pl-5"
                            >${{
                              priceDetails.proPlan.price | numberWithCommas
                            }}</span
                          >
                        </b>
                        <p class="mt-3">
                          <span
                            >{{
                              priceDetails.proPlan.credits | numberWithCommas
                            }}
                            Credits/{{
                              selectedTab == "monthly" ? "Month" : "Year"
                            }}</span
                          >
                        </p>

                        <hr />
                        <!-- <p class="pt-2">*When you exceed your limit, you incur additional charges of $14.99/mo per additional 50 Credits &nbsp;</p> -->
                      <!-- </div> 
                      <div *ngIf="planSelected == 'standard'" class="m-b-20">
                        <b style="color: #000"
                          ><span class="pr-5">Standard Plan:</span>
                          <span class="pl-5"
                            >${{ priceDetails.standardPlan.price }}</span
                          >
                        </b>
                        <p class="mt-3">
                          <span
                            >{{ priceDetails.standardPlan.credits }} Credits/{{
                              selectedTab == "monthly" ? "Month" : "Year"
                            }}</span
                          >
                        </p>

                        <hr/>
                        <!-- <p class="pt-2">*When you exceed your limit, you incur additional charges of $14.99/mo per additional 50 Credits &nbsp;</p> -->
                      <!--</div>
                      <div class="col">
                        <div #paypal></div>
                      </div>
                    </div>
                  </div> -->

                  <!-- Personal-Information -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- container -->
    </div>
    <!-- content -->
  </div>
</div>
