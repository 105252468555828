<div class="card column filter-component" style="width: 23rem">
	<div class="image-filter">
		<div class="flex-row filter-header">
			<div>
				<h6 class="card-title titleCardStyle">Showing results for</h6>
			</div>
			<div
				class="searchButton primary"
				[ngClass]="filterData.validateImagingSearch() ? 'primaryBtn' : ''"
				(click)="clearFilter()"
			>
				<span> Clear </span>
			</div>
		</div>
		<mat-accordion>
			<app-individual-filter
				#companyAuto
				(valueChanges)="companyValueChanges($event)"
				[totalItems]="companyList"
				[(includedItems)]="filterData.company"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Company name"
				title="Company"
			></app-individual-filter>
			<app-expand-item
				title="Full name"
				[selectedData]="filterData.fullName"
				[isPaid]="isPaid"
				placeholder="Contact name"
			>
				<app-keyword-input
					title="Contact name"
					[storageKey]="'fullnameList_physician_executive_Page_filterdata'"
					[(selectedData)]="filterData.fullName"
					(onItemAdd)="omitChanges()"
				></app-keyword-input>
			</app-expand-item>
			<app-expand-item
				title="Title"
				[selectedData]="filterData.keywords"
				[isPaid]="isPaid"
				placeholder="Enter Title"
			>
				<app-keyword-input
					title="Enter Title"
					[storageKey]="'keywords_physician_executive_Page'"
					[(selectedData)]="filterData.keywords"
					(onItemAdd)="omitChanges()"
				></app-keyword-input>
			</app-expand-item>
			<app-expand-item
				title="Department"
				[(selectedData)]="filterData.department"
				[isPaid]="isPaid"
				placeholder="Enter Department"
			>
				<app-select-drop-down
					title="Select Department"
					[list]="departmentList"
					[(selectedData)]="filterData.department"
					(onItemSelect)="omitChanges()"
				></app-select-drop-down>
			</app-expand-item>
			<app-expand-item
				title="Seniority"
				[(selectedData)]="filterData.seniority"
				[isPaid]="isPaid"
				placeholder="Enter Seniority"
			>
				<app-select-drop-down
					title="Select Seniority"
					[list]="seniorityList"
					[(selectedData)]="filterData.seniority"
					(onItemSelect)="omitChanges()"
				></app-select-drop-down>
			</app-expand-item>
			<app-expand-item
				title="Physician Group"
				[(selectedData)]="filterData.physicianGroupType"
				[isPaid]="isPaid"
				placeholder="Physician Group type"
				[isString]="true"
			>
				<app-signle-select-drop-down
					title="Physician Group type"
					[list]="numberOfImagingEquipmentList"
					[(selectedData)]="filterData.physicianGroupType"
					(onItemSelect)="omitChanges()"
				></app-signle-select-drop-down>
			</app-expand-item>

			<div class="location">
				<app-individual-location-filter
					[countryList]="countryList"
					(locationValueChanged)="hanldeLocationValueChange($event)"
					[disabled]="!isSubscribed"
					[(selectedStatesFromFilter)]="filterData.stateList"
					[(selectedCitiesFromFilter)]="filterData.cityList"
				>
				</app-individual-location-filter>
			</div>
			<app-more-fliter-button
				[isPaid]="!isSubscribed"
				(openMoreFilter)="openMoreFilter()"
			></app-more-fliter-button>
		</mat-accordion>
	</div>
	<div class="upgrade-card" (click)="requestPricing()" *ngIf="isPaid">
		<div>
			<div class="blt-background">
				<mat-icon class="mt-ico">bolt</mat-icon>
			</div>
		</div>
		<div style="padding-left: 10px">
			<div class="upgrd-to-unlock">Request to unlock more filters</div>
			<div class="improve-search">Improve your search result with additional filters</div>
		</div>
	</div>
</div>

<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="recordCountLoader"
		[totalRecords]="moreFilterRecordCount.value"
		(clear)="moreFilterAccordian.closeAll(); clearTempFilter()"
		(applyFilter)="applyMoreFilters()"
		(closeFilter)="closeDialogBox('mainDialog')"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Company name"
					[(selectedData)]="tempDialogFilters.company"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Company"
						[(list)]="companyList"
						[(selectedData)]="tempDialogFilters.company"
						(onItemSelect)="getRecordCount()"
						(onChange)="companyValueChanges($event)"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Full name"
					[(selectedData)]="tempDialogFilters.fullName"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-keyword-input
						title="Contact name"
						[storageKey]="'fullnameList_physician_executive_Page'"
						[(selectedData)]="filterData.fullName"
						(onItemAdd)="getRecordCount()"
					></app-keyword-input>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Title"
					[(selectedData)]="tempDialogFilters.keywords"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-keyword-input
						title="Enter Title"
						[storageKey]="'keywords_physician_executive_Page'"
						[(selectedData)]="filterData.keywords"
						(onItemAdd)="getRecordCount()"
					></app-keyword-input>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Department"
					[(selectedData)]="tempDialogFilters.department"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Department"
						[list]="departmentList"
						[(selectedData)]="tempDialogFilters.department"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Seniority"
					[(selectedData)]="tempDialogFilters.seniority"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Seniority"
						[list]="seniorityList"
						[(selectedData)]="tempDialogFilters.seniority"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Physician Group"
					[(selectedData)]="tempDialogFilters.physicianGroupType"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-signle-select-drop-down
						title="Physician Group type"
						[list]="numberOfImagingEquipmentList"
						[(selectedData)]="tempDialogFilters.physicianGroupType"
						(onItemSelect)="getRecordCount()"
					></app-signle-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Group Practice Members"
					[(selectedData)]="tempDialogFilters.numberofgrouppracticemembers"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Group Practice Members"
						[list]="groupPracticeNumber"
						[(selectedData)]="tempDialogFilters.numberofgrouppracticemembers"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="EHR/EMR Devices"
					[selectedData]="insuranceData"
				>
					<app-auto-complete-drop-drow
						title="EHR/EMR Product Name"
						[(list)]="productNameList"
						[(selectedData)]="tempDialogFilters.ehremrproductname"
						(onItemSelect)="getRecordCount()"
						(onChange)="getCPTCodes($event)"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="EHR/EMR Vendor Name"
						[(list)]="vendorList"
						[(selectedData)]="tempDialogFilters.ehremrvendorname"
						(onItemSelect)="getRecordCount()"
						(onChange)="getVendorList($event)"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="Insurance"
						[(list)]="insuranceList"
						[(selectedData)]="tempDialogFilters.insurance"
						(onItemSelect)="getRecordCount()"
						(onChange)="getInsuranceList($event)"
					></app-auto-complete-drop-drow>
					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.ehremrproductname"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.ehremrvendorname"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.insurance"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<app-location-filter
					class="dialog-flex-container"
					[countryList]="[]"
					(locationValueChanged)="getRecordCount()"
					[(selectedStatesFromFilter)]="tempDialogFilters.stateList"
					[(selectedCitiesFromFilter)]="tempDialogFilters.cityList"
				>
				</app-location-filter>
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<app-more-fliter-close-dialog
		[(showDialog)]="showConfirmDialog"
		(closeFilter)="closeDialogBox('mainDialog', true)"
	></app-more-fliter-close-dialog>
</ng-template>
