<div id="wrapper">
	<app-header
		[user]="user"
		style="width: 100%"
		[header-data]="headerData"
		[elementName]="'b2b-list'"
	></app-header
	>{{ user }}
	Hello
	<!-- <app-header *ngIf="!subscribed" [header-data]="headerData"></app-header> -->
	<!-- <app-sidemenu elementName="lists"></app-sidemenu> -->
	<app-createlist
		[createListDiv]="createDrawer"
		(cancelBtnClick)="cancelBtnClick($event)"
		*ngIf="createDrawer"
	></app-createlist>
	<!-- <app-footer></app-footer> -->
	<div class="content-page enlarged">
		<div class="content mx-auto">
			<div class="container-fluid">
				<div class="row">
					<!-- Page Header Starts -->
					<div class="page-header-wrapper row col-12">
						<mat-icon class="arrowback-icon" (click)="goBackToList()">arrow_back</mat-icon>
						<span class="page-header-text">{{ listNameTemp }}</span>
						<span class="page-header-count" *ngIf="totalSize">{{ totalSize }}</span>
					</div>
					<!-- Page Header Ends -->

					<!-- List Header Starts -->

					<div class="list-header-wrapper row col-12">
						<!-- Checkbox Starts -->
						<!-- *ngIf="!partiallySelected" / -->
						<!-- <div class="checkbox-wrapper row col-2">
              <div class="checkbox col-1">
                <input type="checkbox" (change)="selectAll()" [(ngModel)]="selectAllCheck" id="check-box" />
                <span class="checkbox-checkmark"></span>
                <span class="checkbox-secondary-checkmark"></span>
              </div>
              <span class="selected-count col-6"> 2 Selected </span>
            </div> -->
						<div class="checkbox-wrapper row col-2">
							<label class="container col-1" *ngIf="(isCheckboxEmpty || allContactSelected) && !partiallySelected">
								<input type="checkbox" id="check-box" (change)="selectAll()" [checked]="allContactSelected" />
								<span class="checkmark"></span>
							</label>

							<label class="container col-1" *ngIf="partiallySelected && !isCheckboxEmpty && !allContactSelected">
								<input type="checkbox" id="check-box" (change)="selectAll()" [checked]="partiallySelected" />
								<span class="partiallySelected"></span>
							</label>
							<span class="selected-count col-10">{{ checkedRowCount }} Selected</span>
						</div>
						<!-- Checkbox Ends -->

						<!-- Search Bar Starts -->
						<div class="search-bar-wrapper col-3">
							<!-- (keyup)="onFilterTextBoxChanged($event)" -->
							<!-- [(ngModel)]="searchString" -->
							<input
								type="text"
								id="filter-text-box"
								class="form-control form-control-lg search-bar"
								placeholder="Search for contacts"
							/>
						</div>
						<!-- Search Bar Ends -->

						<!-- Export Button Starts -->
						<div class="export-btn-wrapper col-2">
							<button
								type="button"
								class="export-btn"
								[disabled]="isCheckboxEmpty || isLoading"
								[class.disable-btn]="isCheckboxEmpty"
								[class.spinner]="isLoading"
								[class.loader-disable]="isLoading"
								(click)="exportCsv()"
							>
								<svg
									viewBox="0 0 24 24"
									width="18"
									height="18"
									stroke="currentColor"
									stroke-width="2"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="css-i6dzq1"
								>
									<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
									<polyline points="7 10 12 15 17 10"></polyline>
									<line x1="12" y1="15" x2="12" y2="3"></line>
								</svg>
								<span class="export-btn-text">Export</span>
							</button>
						</div>
						<!-- Export Button Ends -->

						<!-- Pagination Starts -->
						<div class="pagination-wrapper col-5" *ngIf="totalSize > 0">
							<ul *ngIf="pager.pages && pager.pages.length" class="pagination">
								<li [ngClass]="{ disabled: pager.currentPage === 1 }">
									<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
										><i class="fa fa-chevron-left" aria-hidden="true"></i
									></a>
									<a
										*ngIf="pager.currentPage !== 1"
										(click)="setPage(pager.currentPage - 1)"
										style="border-radius: 5px 0 0 5px !important"
										><i class="fa fa fa-chevron-left" aria-hidden="true"></i
									></a>
								</li>

								<li
									[ngClass]="{
										disabled: pager.currentPage === pager.totalPages
									}"
								>
									<a *ngIf="pager.currentPage === pager.totalPages" style="border-radius: 0 5px 5px 0 !important"
										><i class="fa fa-chevron-right" aria-hidden="true"></i
									></a>
									<a
										*ngIf="pager.currentPage !== pager.totalPages"
										(click)="setPage(pager.currentPage + 1)"
										style="border-radius: 0 5px 5px 0 !important"
										><i class="fa fa-chevron-right" aria-hidden="true"></i
									></a>
								</li>
							</ul>
							<div class="pull-right pagination-info">
								{{ offset + 1 }}-{{ offset + count > totalSize ? totalSize : count + offset }} of {{ totalSize }}
							</div>
						</div>
						<!-- Pagination Ends -->
					</div>
					<!-- List Header Ends -->

					<!-- List Starts -->
				</div>
				<div class="list-wrapper" style="display: flex; flex-direction: row; height: 100%; width: 100%">
					<div style="overflow: hidden; flex-grow: 1">
						<ag-grid-angular
							style="width: 100%; height: 100%"
							class="ag-theme-material listDetails"
							(gridReady)="onGridReady($event)"
							#agGrid
							[defaultColDef]="defaultColDef"
							[columnDefs]="columnDefs"
							[rowHeight]="55"
							[rowSelection]="rowSelection"
							[suppressRowClickSelection]="true"
							[animateRows]="true"
							[sortingOrder]="sortingOrders"
							(firstDataRendered)="onFirstDataRendered($event)"
							[paginationPageSize]="paginationPageSize"
							(rowSelected)="onRowSelected($event)"
							[frameworkComponents]="frameworkComponents"
							[headerHeight]="45"
							[domLayout]="autoHeight"
							[overlayLoadingTemplate]="overlayLoadingTemplate"
							[context]="context"
						>
							<!-- (selectionChanged)="onSelectionChanged($event)" -->
						</ag-grid-angular>
					</div>
				</div>
				<!-- List Ends -->
			</div>
		</div>
	</div>
</div>
