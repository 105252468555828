import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';

@Component({
	selector: 'app-individual-location-filter',
	templateUrl: './individual-location-filter.component.html',
	styleUrls: ['./individual-location-filter.component.css']
})
export class IndividualLocationFilterComponent implements OnInit {
	@Input() title: string = 'Select location';
	@Input() selectedCountry: Array<any> = [];
	@Input() selectedStatesFromFilter: Array<any> = [];
	@Input() selectedCitiesFromFilter: Array<any> = [];
	@Input() selectedZipFromFilter: Array<any> = [];
	@Input() countryList: Array<any> = [];
	@Input() stateList: Array<any> = [];
	@Input() cityList: Array<any> = [];
	@Input() disabled: boolean = false;
	@Input() searchQuota: number = 10;
	@Input() isZipCode: boolean = false;
	separatorKeysCodes: number[] = [ENTER, COMMA];

	@Output() locationValueChanged = new EventEmitter();
	@Output() selectedStatesFromFilterChange = new EventEmitter();
	@Output() selectedCitiesFromFilterChange = new EventEmitter();
	@Output() selectedZipFromFilterChange = new EventEmitter();

	@ViewChild('countryInput')
	countryInput: ElementRef<HTMLInputElement>;
	countryControl: UntypedFormControl = new UntypedFormControl();

	@ViewChild('stateInput')
	stateInput: ElementRef<HTMLInputElement>;
	stateControl: UntypedFormControl = new UntypedFormControl();

	@ViewChild('cityInput')
	cityInput: ElementRef<HTMLInputElement>;
	cityControl: UntypedFormControl = new UntypedFormControl();

	@Input() selectedCities: Array<any> = [];
	selectable: boolean = true;
	recentStates: string[] = [];
	recentCities: string[] = [];

	@ViewChild('zipInput')
	zipInput: ElementRef<HTMLInputElement>;
	zipControl = new UntypedFormControl();

	zipCodeList: string[] = [];

	constructor(private amplizService: AmplizService) {}

	ngOnInit() {
		this.handleForms();
	}

	handleForms() {
		this.stateControl.valueChanges.subscribe((value: string) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 2) {
				this.getStateList(value);
			} else {
				const tempStates: any = [];
				this.stateList = tempStates;
			}
		});

		this.cityControl.valueChanges.subscribe((value: string) => {
			if (typeof value != 'object') {
				this.getCityList(value);
			}
		});

		this.zipControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				this.amplizService.getZipCodeList(value).subscribe((response) => {
					this.zipCodeList = response.zipcodeList;
				});
			} else {
				const temp: any = [];
				this.zipCodeList = temp;
			}
		});
	}

	getCountryList(value = '') {}

	removeState(state) {
		this.selectedStatesFromFilter = [...this.selectedStatesFromFilter].filter((item) => {
			return item.stateId !== state.stateId;
		});
		this.omitChanges();
	}

	removeCity(city) {
		this.selectedCitiesFromFilter = [...this.selectedCitiesFromFilter].filter((item) => {
			return item.cityId !== city.cityId;
		});
		this.omitChanges();
	}

	removeZip(zip) {
		this.selectedZipFromFilter = [...this.selectedZipFromFilter].filter((item) => {
			return item !== zip;
		});
		this.omitChanges();
	}

	getStateList(value = '') {
		this.amplizService.getStateList({ searchPhase: value }).subscribe((res) => {
			this.stateList = res.stateDataList;
			this.getCityList();
		});
	}

	selectStates(event) {
		this.stateInput.nativeElement.value = '';
		const receivedState = event.option.value;
		const found = this.selectedStatesFromFilter.findIndex((ele) => ele.state === receivedState.state);
		if (found === -1 && receivedState) {
			this.stateInput.nativeElement.value = '';
			this.stateControl.setValue(null);
			this.selectedStatesFromFilter = [...this.selectedStatesFromFilter, receivedState];
			this.omitChanges();
		}
	}
	getCityList(value = '') {
		if (this.selectedStatesFromFilter.length > 0) {
			var tStates = [...this.selectedStatesFromFilter].map((state) => state.stateId);
			var params = { stateId: tStates, searchPhase: value };
			this.amplizService.searchCitiesForStates(params).subscribe((res) => {
				this.cityList = res.cityDataList;
			});
		}
	}

	onCitySelect(event) {
		this.cityInput.nativeElement.value = '';
		const receivedCity = event.option.value;
		const found = this.selectedCitiesFromFilter.findIndex((ele) => ele.city === receivedCity.city);
		if (found === -1 && receivedCity) {
			this.cityInput.nativeElement.value = '';
			this.cityControl.setValue(null);
			this.selectedCitiesFromFilter = [...this.selectedCitiesFromFilter, receivedCity];
			this.omitChanges();
		}
	}

	onZipSelect(event) {
		this.zipInput.nativeElement.value = '';
		const receivedZip = event.option.value;
		const found = this.selectedZipFromFilter.findIndex((ele) => ele === receivedZip);
		if (found === -1 && receivedZip) {
			this.zipInput.nativeElement;
			this.zipControl.setValue(null);
			this.selectedZipFromFilter = [...this.selectedZipFromFilter, receivedZip];
			this.omitChanges();
		}
	}

	omitChanges() {
		this.selectedStatesFromFilterChange.emit(this.selectedStatesFromFilter);
		this.selectedCitiesFromFilterChange.emit(this.selectedCitiesFromFilter);
		this.selectedZipFromFilterChange.emit(this.selectedZipFromFilter);
		this.locationValueChanged.emit();
	}
	clearState() {
		this.selectedCitiesFromFilter = [];
		this.selectedStatesFromFilter = [];
		this.omitChanges();
	}
	clearCity() {
		this.selectedCitiesFromFilter = [];
		this.omitChanges();
	}
	clearZip() {
		this.selectedZipFromFilter = [];
		this.omitChanges();
	}
}
