export class SearchPhysicianGroup {
	constructor(
		public centerName: Array<any> = [],
		public cityList: Array<any> = [],
		public stateList: Array<any> = [],
		public offset: number = 0,
		public limit: number = 5,
		public industry: string | string[] = '',
		public numberofgrouppracticemembers: string | string[] = [],
		public ehremrproductname: string[] = [],
		public ehremrvendorname: string[] = [],
		public insurance: string[] = [],
		public physicianGroupType: string = '',
		public specialty_list: string[] = []
	) {}

	public toJson(): object {
		return {
			centerName: this.centerName,
			cityList: this.cityList.map((el) => el?.city || el),
			stateList: this.stateList.map((el) => el?.state || el),
			offset: this.offset,
			limit: this.limit,
			industry: this.industry || [],
			numberofgrouppracticemembers: this.numberofgrouppracticemembers,
			ehremrproductname: this.ehremrproductname,
			ehremrvendorname: this.ehremrvendorname,
			insurance: this.insurance,
			physicianGroupType: this.physicianGroupType,
			specialty_list: this.specialty_list
		};
	}

	public fromJson(obj: any): SearchPhysicianGroup {
		return new SearchPhysicianGroup(
			obj.centerName,
			obj.cityList,
			obj.stateList,
			obj.offset,
			obj.limit,
			obj.industry,
			obj.numberofgrouppracticemembers,
			obj.ehremrproductname,
			obj.ehremrvendorname,
			obj.insurance,
			obj.physicianGroupType
		);
	}

	validateImagingSearch() {
		return (
			this.centerName.length > 0 ||
			this.cityList.length > 0 ||
			this.stateList.length > 0 ||
			this.industry.length > 0 ||
			this.numberofgrouppracticemembers.length > 0 ||
			this.ehremrproductname.length > 0 ||
			this.ehremrvendorname.length > 0 ||
			this.insurance.length > 0 ||
			this.physicianGroupType.length > 0 ||
			this.specialty_list.length > 0
		);
	}

	getChangedItems() {
		let changedItems = [];
		SearchPhysicianGroup.titleKeyArray.map((item) => {
			if (this[item.key].length > 0) {
				item['value'] = this[item.key];
				changedItems.push(item);
			}
		});
		return changedItems;
	}

	static titleKeyArray = [
		{ key: 'companyList', title: 'Company List', type: 'array' },
		{ key: 'fullNameList', title: 'Full Name', type: 'array' },
		{ key: 'titleInclude', title: 'Title Include', type: 'array' },
		{ key: 'titleExclude', title: 'Title Exclude', type: 'array' },
		{ key: 'industryInclude', title: 'Industry Include', type: 'array' },
		{ key: 'industryExclude', title: 'Industry Exclude', type: 'array' },
		{ key: 'deptInclude', title: 'Department Include', type: 'array' },
		{ key: 'deptExclude', title: 'Department Exclude', type: 'array' },
		{ key: 'skillInclude', title: 'Skill Include', type: 'array' },
		{ key: 'skillExclude', title: 'Skill Exclude', type: 'array' },
		{ key: 'seniority', title: 'Seniority', type: 'array' },
		{ key: 'countryList', title: 'Country List', type: 'array' },
		{ key: 'stateList', title: 'State List', type: 'array' },
		{ key: 'cityList', title: 'City List', type: 'array' },
		{ key: 'revenue', title: 'Revenue', type: 'text' },
		{ key: 'employeeRangeList', title: 'Employee Range', type: 'array' }
	];
}
