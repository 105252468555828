<div>
	<div class="backdrop" *ngIf="show"></div>

	<div class="createListWrapper" [ngClass]="show === true ? 'slide-in' : 'slide-out'">
		<div class="d-flex justify-content-between align-items-center p-3">
			<span style="color: #040404; font-weight: 500; font-size: 16px">{{ title }}</span>
			<mat-icon
				class="close-dialog-icon"
				(click)="closePanel.emit(false)"
				style="cursor: pointer"
				*ngIf="enableCloseIcon"
				>close</mat-icon
			>
		</div>
		<hr class="m-0 p-0" />
		<ng-content class="mt-2"></ng-content>
	</div>
</div>
