import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-chip-container',
	templateUrl: './chip-container.component.html',
	styleUrls: ['./chip-container.component.css']
})
export class ChipContainerComponent implements OnInit {
	@Input() data: Array<any> | string;
	@Input() isSingle: boolean = false;
	@Input() itemName: string = '';
	@Input() itemValue: string = '';
	@Output() dataChange = new EventEmitter();
	@Output() onChange = new EventEmitter();
	removable = true;

	get chipData() {
		return Array.isArray(this.data) ? this.data : this.data ? [this.data] : '';
	}

	constructor() {}

	ngOnInit(): void {}
	remove(item) {
		if (Array.isArray(this.data)) {
			this.data = this.data.filter((el) => {
				if (this.itemValue) {
					return el[this.itemValue] !== item[this.itemValue];
				}
				return el !== item;
			});
		} else {
			this.data = '';
		}
		this.dataChange.emit(this.data);
		this.onChange.emit();
	}
}
