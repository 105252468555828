<div class="main-container">
	<h6>{{ edit ? 'Edit' : 'Add more' }} credits</h6>
	<div class="card">
		<div class="header d-flex align-items-center">
			<div>
				<span
					>Your total credit <span class="credit-value">{{ remainingCredits }}</span></span
				>
			</div>
			<div>
				<span
					>Your mobile credit <span class="credit-value">{{ remainingMobileCredits }}</span></span
				>
			</div>
		</div>

		<div class="content-section d-flex flex-column">
			<div class="input-container d-flex">
				<div class="credit-container col-3 flex-column">
					<div class="input-header">
						<span>Credits</span>
					</div>
					<div class="input-group">
						<div class="input-group-prepend">
							<button
								class="btn range-button"
								type="button"
								(click)="decrement(true)"
								[class.range-error]="!isCreditCorrect()"
							>
								<i class="fa fa-minus"></i>
							</button>
						</div>
						<input
							type="number"
							min="1"
							class="form-control"
							[(ngModel)]="params.credit"
							(keypress)="
								(($event.charCode != 8 && $event.charCode == 0) || ($event.charCode >= 48 && $event.charCode <= 57))
							"
							[class.input-error]="!isCreditCorrect()"
						/>
						<div class="input-group-append">
							<button
								class="btn range-button"
								type="button"
								(click)="increment(true)"
								[class.range-error]="!isCreditCorrect()"
							>
								<i class="fa fa-plus"></i>
							</button>
						</div>
					</div>
				</div>

				<div class="mobile-credit-container col-3 flex-column">
					<div class="input-header">
						<span class>Mobile credits</span>
					</div>
					<div class="input-group">
						<div class="input-group-prepend">
							<button
								class="btn range-button"
								type="button"
								(click)="decrement()"
								[class.range-error]="!isMobileCreditCorrect()"
							>
								<i class="fa fa-minus"></i>
							</button>
						</div>
						<input
							type="number"
							min="1"
							class="form-control"
							[(ngModel)]="params.mobileCredit"
							(keypress)="(($event.charCode != 8 && $event.charCode == 0) || ($event.charCode >= 48 && $event.charCode <= 57))"
							[class.input-error]="!isMobileCreditCorrect()"
						/>
						<div class="input-group-append">
							<button
								class="btn range-button"
								type="button"
								(click)="increment()"
								[class.range-error]="!isMobileCreditCorrect()"
							>
								<i class="fa fa-plus"></i>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div class="action-button-container d-flex align-items-center justify-content-end mt-3 gap-3">
				<span class="canel-btn" (click)="handleCancel()">Cancel</span>
				<button class="save-btn" type="button" (click)="updateCredits()" [class.enable-btn]="validateInput">
					{{ edit ? 'Update' : 'Add' }}
				</button>
			</div>
		</div>
	</div>
</div>
