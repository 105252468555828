import { Component, OnInit, Input, Output, EventEmitter, NgZone, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ClinicalModel } from '../../models/clinical-model/clinical.model';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { PayorService } from '../../service/payor.service';

@Component({
	selector: 'app-clinical-card',
	templateUrl: './clinical-card.component.html',
	styleUrls: ['./clinical-card.component.css']
})
export class ClinicalCardComponent implements OnInit {
	@Input() clinicalData: ClinicalModel;
	@Input() dataIndex: any;
	@Output() DataRefreshed: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('contactPanelTrigger') contactPanelTrigger: MatMenuTrigger;
	notCorrectDrawer: boolean = false;
	saveDrawer: boolean = false;
	smallResolution: boolean = false;
	constructor(
		private router: Router,
		private clipboard: Clipboard,
		private messageService: MessageService,
		private loaderService: LoaderService,
		private payorService: PayorService
	) {}
	get location() {
		return `${this.clinicalData.city}, ${this.clinicalData.state}`;
	}

	ngOnInit() {
		if (window.innerWidth < 1100) {
			this.smallResolution = true;
		} else {
			this.smallResolution = false;
		}
	}

	cancelBtnClick(value: boolean) {
		this.saveDrawer = value;
		this.notCorrectDrawer = value;
		setTimeout(() => {
			this.DataRefreshed.emit(true);
		}, 1000);
	}

	gotoContacts(hospitalName: string) {}

	openSocial(link: any) {
		window.open(link, '_blank').focus();
	}

	handleAction(action: any, value?: any) {
		if (action === 'close') {
			this.contactPanelTrigger.closeMenu();
		} else {
			this.clipboard.copy(value);
			this.messageService.display(true, 'Copied!');
		}
	}
	get getWebAddress() {
		if (this.clinicalData.webaddress) {
			return this.clinicalData.webaddress.includes('http')
				? this.clinicalData.webaddress
				: 'https://' + this.clinicalData.webaddress;
		}
		return '';
	}
	onImagingNameClicked(iv) {
		if (iv.clinicId !== null && iv.clinicId !== undefined) {
			//     this.loaderService.display(true);
			//     const body = {
			//       clinicId: iv.clinicId,
			//     };
			//     this.payorService.viewImagingCenterFromList(body).subscribe(
			//       (res) => {
			//         this.loaderService.display(false);
			//         this.router.navigate([]).then((result) => {
			window.open(`clinicalOverview/${iv.clinicId}`, '_blank');
			//         });
			//       },
			//       (err) => {
			//         this.loaderService.display(false);
			//       }
			//     );
		}
	}
}
