import { Component, OnInit, Input } from '@angular/core';
import { AmplizService } from '../../../services/ampliz.service';
import { FormControl } from '@angular/forms';
@Component({
	selector: 'app-hospital-icd10',
	templateUrl: './hospital-icd10.component.html',
	styleUrls: ['./hospital-icd10.component.css']
})
export class HospitalIcd10Component implements OnInit {
	@Input() hospitalID: string;
	alert: boolean = false;
	columnDefs: any;
	searchString: string = '';
	paginationPageSize: number;
	sortingOrders: any;
	gridApi: any;
	gridColumnApi: any;
	paramsData: any;
	datasource: any;
	defaultColDef: any;
	pager: any = { currentPage: 1, offset: 10, totalPages: 1 };
	icd10Control = new FormControl();
	totalCount = 0;
	public overlayLoadingTemplate =
		'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
	public noRowsTemplate = `"<span">no rows to show</span>"`;

	context: any;
	public domLayout = 'autoHeight';
	constructor(private amplizService: AmplizService) {}

	ngOnInit(): void {
		this.getIcd10Details();
		this.getICD10DetailsBySearch();
		this.context = {
			componentParent: this
		};
		this.columnDefs = [
			{ tooltipField: 'col1' },
			{
				headerName: 'Code',
				field: 'code',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				autoHeight: true,
				lockPosition: true,
				width: 100,
				cellStyle: { 'white-space': 'normal', lineHeight: '20px' }
			},
			{
				headerName: 'Description',
				field: 'description',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				autoHeight: true,
				lockPosition: true,
				sortable: true,
				width: 550,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			},
			{
				headerName: 'Average Estimated Payments Or Claims',
				field: 'avgEstimatedPaymentsOrClaims',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				autoHeight: true,
				sortable: true,
				lockPosition: true,
				width: 200,
				suppressSizeToFit: true,
				cellStyle: { fontWeight: '500' }
			},
			{
				headerName: 'Estimated Total Charges',
				field: 'estimatedTotalCharges',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				lockPosition: true,
				autoHeight: true,
				sortable: true,
				width: 200,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			},
			{
				headerName: 'Estimated Total Number of Claims',
				field: 'estimatedTotalNumberOfClaims',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				wrapText: true,
				lockPosition: true,
				autoHeight: true,
				sortable: true,
				width: 200,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			},
			{
				headerName: 'Estimated Total Payments',
				field: 'estimatedTotalPayments',
				sortingOrder: ['desc', 'asc'],
				filter: false,
				wrapText: true,
				lockPosition: true,
				autoHeight: true,
				sortable: true,
				width: 200,
				suppressSizeToFit: true,
				cellStyle: { color: '#515050', fontWeight: '400' }
			}
		];
		this.sortingOrders = ['desc', 'asc', null];
		this.paginationPageSize = 10;
		this.defaultColDef = {
			resizable: true
		};
	}

	onGridReady(params, dataSource?: any) {
		this.paramsData = params;
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.paramsData.api.setRowData(dataSource);
	}

	onPaginationClick(currentPage) {
		this.pager = { ...this.pager, currentPage };
		this.getIcd10Details();
	}

	get offset() {
		const total = this.pager.offset * (this.pager.currentPage - 1);
		return +total + 1;
	}

	get count() {
		const pageCount = this.pager.currentPage * this.pager.offset;
		return this.pager.currentPage === this.pager.totalPages
			? this.totalCount
			: pageCount > this.totalCount
			? this.totalCount
			: pageCount;
	}

	getIcd10Details() {
		const offset = (this.pager.currentPage - 1) * this.pager.offset;
		this.amplizService
			.getHospitalICD10Details({
				hospitalId: 'HOSP8527657',
				offset,
				count: this.pager.offset,
				icdtenCode: this.searchString
			})
			.subscribe((res) => {
				this.paramsData.api.setRowData(res.icdtenCodeDetailList);
				this.totalCount = res.totalCount;
				this.pager = {
					...this.pager,
					totalPages: Math.ceil(this.totalCount / this.pager.offset)
				};
			});
	}
	getICD10DetailsBySearch() {
		this.icd10Control.valueChanges.debounceTime(300).subscribe((value) => {
			let hv = value !== null ? value : '';

			this.searchString = hv;
			this.getIcd10Details();
		});
	}
}
