import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
	selector: 'app-no-member-limit',
	templateUrl: './no-member-limit.component.html',
	styleUrls: ['./no-member-limit.component.css']
})
export class NoMemberLimitComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit(): void {}

	handleUpgrade() {
		window.open('https://www.ampliz.com/book-your-demo', '_blank');
	}
}
