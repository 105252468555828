<div class="iframe_container" *ngIf="hideMap">
	<section class="map_list">
		<div
			[ngClass]="{
				map_card: true,
				selected:
					hospital.hospitalLocation === hospitalLocation.hospitalLocation &&
					hospital.hospitalName === hospitalLocation.hospitalName
			}"
			*ngFor="let hospital of mapList"
			(click)="onSelectList(hospital)"
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<path
					d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
					stroke="black"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
					stroke="black"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
			<section class="hospital_name">
				{{ hospital.hospitalName }}
				<p class="hospital_location">
					<span>{{ hospital.hospitalLocation }}, </span>
				</p>
				<p class="clickable" *ngIf="!hideMore">More physicians from this location</p>
			</section>
		</div>
	</section>
	<section class="map_iframe_container">
		<iframe [frameBorder]="0" width="100%" height="300" [src]="MapUrl"></iframe>
	</section>
</div>
<div *ngIf="!hideMap" class="not_found">
	<p>No {{ name }} Found</p>
</div>

<h4 class="heading" *ngIf="similarPhysicianList.length !== 0">More physicians practicing in this location</h4>
<div class="similar_physician_container" *ngIf="similarPhysicianList.length !== 0">
	<div *ngFor="let hospital of similarPhysicianList">
		<app-similar-physician
			[name]="hospital.fullName"
			[hospital]="hospital.hospitalName"
			[specialty]="hospital.specialty"
			[physicianId]="hospital.physicianId"
		></app-similar-physician>
	</div>
	<div class="dummy_card" *ngFor="let i of [0, 1, 2, 3,5]"></div>
</div>
