<div class="card column filter-component" style="width: 23rem">
	<div class="image-filter">
		<div class="flex-row filter-header">
			<div>
				<h6 class="card-title titleCardStyle">Showing results for</h6>
			</div>
			<div
				class="searchButton primary"
				[ngClass]="filterData.validateLTCSearch() ? 'primaryBtn' : ''"
				(click)="clearFilter()"
			>
				<span> Clear </span>
			</div>
		</div>
		<mat-accordion>
			<app-individual-filter
				#companyAuto
				(valueChanges)="companyValueChanges($event)"
				[totalItems]="companyList"
				[(includedItems)]="filterData.companyList"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Name of hospital / clinic"
				title="Hospital / Clinic"
				filterType="LTC"
			></app-individual-filter>

			<app-individual-filter
				#fullnameText
				type="text"
				[totalItems]="[]"
				[(includedItems)]="filterData.fullNameList"
				(includedItemsChange)="omitChanges()"
				includePlaceholder="Full name"
				title="Name"
				[disabled]="!isSubscribed"
				filterType="LTC"
			>
			</app-individual-filter>

			<app-individual-filter
				#titleAuto
				(valueChanges)="titleValueChanges($event)"
				[totalItems]="titleList"
				[(includedItems)]="filterData.titleInclude"
				[(excludedItems)]="filterData.titleExclude"
				includePlaceholder="Include title"
				title="Title"
				excludePlaceholder="Exclude title"
				(includedItemsChange)="omitChanges()"
				(excludedItemsChange)="omitChanges()"
				[disabled]="!isSubscribed"
				filterType="LTC"
			>
			</app-individual-filter>
			<app-expand-item
				title="LTC"
				[selectedData]="filterData.ltcTypeInclude"
				[isPaid]="!isSubscribed"
				placeholder="LTC Type"
			>
				<app-select-drop-down
					title="LTC Type"
					[list]="ltcTypeList"
					[(selectedData)]="filterData.ltcTypeInclude"
					(onItemSelect)="omitChanges()"
				></app-select-drop-down>
			</app-expand-item>
			<app-expand-item
				title="Department"
				[selectedData]="filterData.department"
				[isPaid]="!isSubscribed"
				placeholder="Select Department"
			>
				<app-select-drop-down
					title="Select Department"
					[list]="departmentList"
					[(selectedData)]="filterData.department"
					(onItemSelect)="omitChanges()"
				></app-select-drop-down>
			</app-expand-item>
			<app-expand-item
				title="Seniority"
				[selectedData]="filterData.seniority"
				[isPaid]="!isSubscribed"
				placeholder="Select Seniority"
			>
				<app-select-drop-down
					title="Select Seniority"
					[list]="seniorityList"
					[(selectedData)]="filterData.seniority"
					(onItemSelect)="omitChanges()"
				></app-select-drop-down>
			</app-expand-item>
			<div class="location">
				<app-individual-location-filter
					[countryList]="[]"
					(locationValueChanged)="omitChanges()"
					[(selectedStatesFromFilter)]="filterData.stateList"
					[(selectedCitiesFromFilter)]="filterData.cityList"
					[disabled]="!isSubscribed"
				>
				</app-individual-location-filter>
			</div>

			<app-more-fliter-button
				[isPaid]="!isSubscribed"
				(openMoreFilter)="openMoreFilter()"
			></app-more-fliter-button>
		</mat-accordion>
	</div>
</div>

<ng-template #filterDialog let-data>
	<app-more-fliter-header
		[loader]="recordCountLoader"
		[totalRecords]="moreFilterRecordCount.value"
		(clear)="moreFilterAccordian.closeAll(); clearTempFilter()"
		(applyFilter)="applyMoreFilters()"
		(closeFilter)="closeDialogBox('mainDialog')"
	>
	</app-more-fliter-header>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Hospital / Clinic"
					[(selectedData)]="tempDialogFilters.companyList"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-auto-complete-drop-drow
						title="Hospital / Clinic"
						[(list)]="companyList"
						[(selectedData)]="tempDialogFilters.companyList"
						(onItemSelect)="getRecordCount()"
						(onChange)="companyValueChanges($event)"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Title"
					[(selectedData)]="tempDialogFilters.titleInclude"
				>
					<app-auto-complete-drop-drow
						title="Include title"
						[(list)]="titleList"
						[(selectedData)]="tempDialogFilters.titleInclude"
						(onItemSelect)="getRecordCount()"
						(onChange)="titleValueChanges($event)"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="Exclude title"
						[(list)]="titleList"
						[(selectedData)]="tempDialogFilters.titleExclude"
						(onItemSelect)="getRecordCount()"
						(onChange)="titleValueChanges($event)"
					></app-auto-complete-drop-drow>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Name"
					[(selectedData)]="tempDialogFilters.fullNameList"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-keyword-input
						title="Full Name"
						[storageKey]="'ltc_fullNameList_list'"
						[(selectedData)]="tempDialogFilters.fullNameList"
						(onItemAdd)="getRecordCount()"
					></app-keyword-input>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="LTC"
					[(selectedData)]="tempDialogFilters.ltcTypeInclude"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="LTC Type"
						[list]="ltcTypeList"
						[(selectedData)]="tempDialogFilters.ltcTypeInclude"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Department"
					[(selectedData)]="tempDialogFilters.department"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Department"
						[list]="departmentList"
						[(selectedData)]="tempDialogFilters.department"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Seniority"
					[(selectedData)]="tempDialogFilters.seniority"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-select-drop-down
						title="Select Seniority"
						[list]="seniorityList"
						[(selectedData)]="tempDialogFilters.seniority"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
				</app-more-filter-expand-item>
				<app-location-filter
					class="dialog-flex-container"
					[countryList]="[]"
					(locationValueChanged)="getRecordCount()"
					[(selectedStatesFromFilter)]="tempDialogFilters.stateList"
					[(selectedCitiesFromFilter)]="tempDialogFilters.cityList"
				>
				</app-location-filter>
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<app-more-fliter-close-dialog
		[(showDialog)]="showConfirmDialog"
		(closeFilter)="closeDialogBox('mainDialog', true)"
	></app-more-fliter-close-dialog>
</ng-template>
